/* eslint-disable react/destructuring-assignment */

/* eslint-disable react/jsx-props-no-spreading */
import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@src/components/commons/containerPage.common';
import CreateListedEventForm from '@src/components/forms/createListedEvent.form';
import EventDateForm from '@src/components/forms/eventDate.form';
import ListedEventLocation from '@src/components/forms/listedEventLocation.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import { mapNewEventFormInputData } from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialFormInput = {
  image: '',
  title: '',
  link: '',
  desc: '',
  type: '',
  tags: '',
  stream: '',
  organiser_name: '',
  org_desc: '',
  org_image: '',
  learn_video: '',
  event_offline: false,
  street: '',
  country: '',
  postal_code: '',
  city: '',
  unit_number: '',
  event_date: null,
  from: null,
  to: null,
  latitude: '',
  longitude: '',
  is_hybrid: false,
  is_cme: false,
  sorts: '',
};
const format_date = { year: 'numeric', month: 'long', day: 'numeric' };

export default function CreateNewEvent() {
  const navigate = useNavigate();
  const { pathname: path, state } = useLocation();
  const { auth } = useAuth();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [formInput, setFormInput] = React.useState(initialFormInput);

  // organiser_image
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const [learnVideoTus, setLearnVideoTus] = React.useState(null);
  const [eventImageTus, setEventImageTus] = React.useState(null);
  const [orgImageTus, setOrgImageTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const setFormInputState = (data) => {
    setIsLoading(true);
    if (isMounted.current) setFormInput(data);
    setIsLoading(false);
  };

  const setOpenModalState = (data) => {
    if (isMounted.current) setOpen(data);
  };

  const handleCheckboxChange = async (event) => {
    setFormInput({ ...formInput, event_offline: event.target.checked });
  };

  const handleOpenModal = async () => {
    setOpenModalState(true);
  };

  const handleCloseModal = async () => {
    setOpenModalState(false);
    navigate(state?.prevLocation || -1);
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const postListEvent = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let learnVideo;
      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('List new event');

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapNewEventFormInputData(formInput);

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/event/event-list`,
            uploadFormData,
            {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${idToken}`,
              },
            },
          )
          .then((response) => {
            setFormInputState({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            // handleOpenModal();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const postUnlistEvent = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let learnVideo;
      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Save new event');

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapNewEventFormInputData(formInput);

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/event/event-unlist`,
            uploadFormData,
            {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${idToken}`,
              },
            },
          )
          .then((response) => {
            setFormInputState({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            // handleOpenModal();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const handleImageChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const orgImage = await tusUtils.createUploadData(
          file,
          handleOnUploadResume,
          {
            Authorization: `Bearer ${auth.idToken}`,
          },
        );
        setOrgImageTus(orgImage);
      } catch (e) {
        console.error(e);
      }

      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        org_image: file,
      }));
    }

    setIsLoading(false);
  };

  const handleListButtonClick = async () => {
    if (!isLoading) {
      if (!formInput?.event_title) {
        toast.error('Title is required.');
        return;
      }
      if (!formInput?.roles) {
        toast.error('Role is required.');
        return;
      }
      if (!formInput?.organiser_name) {
        toast.error('Organiser name is required.');
        return;
      }
      if (
        !formInput?.street &&
        !formInput?.city &&
        !formInput?.postal_code &&
        !formInput?.country &&
        !formInput?.unit_number &&
        !formInput?.live_stream
      ) {
        toast.error('Location/Live stream link is required.');
        return;
      }
      if (
        /* eslint-disable prettier/prettier */
        !formInput?.event_date &&
        !formInput?.date &&
        !formInput?.event_from &&
        !formInput?.from &&
        !formInput?.event_to &&
        !formInput?.to
        /* eslint-enable prettier/prettier */
      ) {
        console.log('formInput', formInput);
        toast.error('Date & time are required.');
        return;
      }

      postListEvent();
    }
  };

  const handleSaveButtonClick = async () => {
    if (!isLoading) {
      if (!formInput?.event_title) {
        toast.error('Title is required.');
        return;
      }
      if (!formInput?.roles) {
        toast.error('Role is required.');
        return;
      }
      if (!formInput?.organiser_name) {
        toast.error('Organiser name is required.');
        return;
      }
      if (
        !formInput?.street &&
        !formInput?.city &&
        !formInput?.postal_code &&
        !formInput?.country &&
        !formInput?.unit_number &&
        !formInput?.live_stream
      ) {
        toast.error('Location is required.');
        return;
      }
      if (
        /* eslint-disable prettier/prettier */
        !formInput?.event_date &&
        !formInput?.date &&
        !formInput?.event_from &&
        !formInput?.from &&
        !formInput?.event_to &&
        !formInput?.to
        /* eslint-enable prettier/prettier */
      ) {
        console.log('formInput', formInput);
        toast.error('Date & time is required.');
        return;
      }

      postUnlistEvent();
    }
  };

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef]);

  React.useEffect(() => {
    if (setIsLoading) {
      if (!auth?.idToken) {
        setIsLoading(true);
      } else if (auth?.idToken) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setIsLoading]);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  
  function formatEventTypes(eventTypes) {
    if (!eventTypes || !Array.isArray(eventTypes) || eventTypes.length === 0)
      return 'No role';

    const formattedEventTypes = eventTypes
      .map((eventType) =>
        eventType
          .split(/[._]/)
          .map((word) => capitalize(word))
          .join('-'),
      )
      .join(', ');

    return formattedEventTypes;
  }

  return (
    <Container pathUrl={path}>
      <UploadedFilesDialog ref={uploadResumeRef} />
      <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <div className='grid grid-cols-2'>
          <div className='col-end-1'>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '569px',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <CreateListedEventForm
                formInput={formInput}
                setFormInput={setFormInput}
                setFileTus={{ setLearnVideoTus, setEventImageTus }}
                onTusResume={handleOnUploadResume}
                setIsLoading={setIsLoading}
              />
              <div className='py-6' />
            </Box>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '569px',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='py-2'>
                <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                  <div className='sm:col-span-6'>
                    <label className='text-md block py-4 font-bold leading-6 text-gray-600'>
                      Organiser Image (Optional)
                    </label>

                    <Box
                      sx={{
                        width: '100px',
                        height: '100px',

                        overflow: 'hidden',
                      }}
                      className='flex justify-center rounded-xl bg-gray-300'
                    >
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        id='upload-file-inputs'
                      />

                      {selectedFile ||
                      previewUrl ||
                      formInput?.organiser_image ? (
                        <div>
                          <img
                            src={previewUrl || formInput?.organiser_image}
                            alt=''
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      ) : null}
                    </Box>
                    <div className='py-5'>
                      <label htmlFor='upload-file-inputs'>
                        <Button
                          sx={{ textTransform: 'none' }}
                          variant='contained'
                          component='span'
                          className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
                        >
                          Upload Image
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                  <div className='py-2 sm:col-span-6'>
                    <label className='font-poppins text-md block font-bold leading-6 text-gray-600 after:ml-0.5 after:text-gray-600 after:content-["*"]'>
                      Organiser Name
                    </label>
                    <div className='mt-2'>
                      <input
                        id='organiser_image'
                        placeholder='Enter Organiser Name'
                        type='text'
                        className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                        value={formInput?.organiser_name || ''}
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            organiser_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                  <div className='sm:col-span-6'>
                    <label className='text-md block font-bold leading-6 text-gray-600'>
                      Organiser Description
                    </label>
                    <div className='mt-2 font-medium'>
                      <textarea
                        placeholder='Add Organiser Description'
                        rows='4'
                        className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        value={formInput.organiser_desc}
                        onChange={(e) =>
                          setFormInput({
                            ...formInput,
                            organiser_desc: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </Box>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '569px',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='flex justify-between'>
                <div className='text-md py-8 px-6 font-bold text-gray-600'>
                  Event Location
                </div>
                <div className='flex justify-start px-2 py-4'>
                  <div className='text-md px-2 py-4 font-bold text-gray-600'>
                    This is an offline event
                  </div>
                  <Checkbox
                    checked={Boolean(formInput?.event_offline)}
                    onChange={handleCheckboxChange}
                    style={{ color: '#1788b9' }}
                  />
                </div>
              </div>
              <ListedEventLocation
                formInput={formInput}
                setFormInput={setFormInput}
                // disabled={Boolean(formInput?.event_offline)}
              />
              <div className='py-6' />
            </Box>
            <Box
              className='rounded-xl'
              sx={{
                margin: 2,
                height: '162px',
                width: '569px',
                backgroundColor: 'white',
              }}
            >
              <div className='text-md py-6 px-6 font-bold text-gray-600 after:ml-0.5 after:text-gray-600 after:content-["*"]'>
                Event Date/Time
              </div>
              <EventDateForm
                formInput={formInput}
                setFormInput={setFormInput}
              />
            </Box>
          </div>
          <div className='col-auto'>
            <div className='py-4 px-6 text-lg font-bold text-gray-600'>
              Preview
            </div>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '400px',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='py-6 px-6'>
                <div className='py-2 text-sm text-gray-400'>
                  Event Type: {formatEventTypes(formInput?.roles)}
                </div>
                <div className='py-4 font-bold'>{formInput?.event_title}</div>
                <div className='flex justify-start py-2'>
                  <Icon
                    icon='material-symbols:location-on-outline'
                    color='#189bd3'
                    width='20'
                  />
                  <div className='px-1 text-sm text-gray-400'>
                    {(formInput?.unit_number ||
                      formInput?.street ||
                      formInput?.city ||
                      formInput?.country ||
                      formInput?.postal_code) &&
                    formInput?.live_stream ? (
                      <div>Hybrid event</div>
                    ) : formInput?.unit_number ||
                      formInput?.street ||
                      formInput?.city ||
                      formInput?.country ||
                      formInput?.postal_code ? (
                      `${formInput?.unit_number}, ${formInput?.street}, ${formInput?.city}, ${formInput?.country}, ${formInput?.postal_code}`
                    ) : formInput?.live_stream ? (
                      <div>Online at: {formInput?.live_stream}</div>
                    ) : (
                      'No location added'
                    )}
                  </div>
                </div>
                <div className='flex justify-start py-2'>
                  <Icon
                    icon='material-symbols:calendar-month-outline'
                    color='#189bd3'
                    width='20'
                  />
                  <div className='px-1 text-sm text-gray-400'>
                    {formInput?.date
                      ? new Date(formInput?.date).toLocaleDateString(
                          'au-AU',
                          format_date,
                        )
                      : 'No date added'}
                  </div>
                </div>
                <div className='py-2 px-1 font-bold text-cyan-500'>
                  Organised by: {formInput?.organiser_name}
                </div>
                <span className='flex justify-between py-6'>
                  <div className='justify-right flex'>
                    <Button
                      sx={{
                        textTransform: 'none',
                        margin: 1,
                        height: '50px',
                        width: '150px',
                      }}
                      className='rounded-md border border-cyan-600 px-6 font-bold text-cyan-600'
                      variant='outlined'
                      disabled={!isUploadedFilesDialogMounted && isLoading}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleSaveButtonClick}
                    >
                      Save
                    </Button>
                  </div>
                  <Box sx={{ width: '400px' }} className='flex justify-end'>
                    <Button
                      sx={{
                        textTransform: 'none',
                        margin: 1,
                        height: '50px',
                        width: '150px',
                      }}
                      className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                      variant='outlined'
                      disabled={!isUploadedFilesDialogMounted && isLoading}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleListButtonClick}
                    >
                      List
                    </Button>
                  </Box>
                </span>
              </div>
            </Box>
          </div>
        </div>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Task created</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The event creation task has been added successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
