import { Box, Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data/residential-status/create`;

export default function AddResidenStatus({
  openModal,
  setOpenModal,
  fetchData,
}) {
  const { auth } = useAuth();
  const [data, setData] = useState([]);

  const handlePost = () => {
    const formData = {
      code: data?.code,
      desc: data?.desc,
      label: data?.label,
      note: data?.note,
    };

    axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item added successfully:', response);
        setData({ ...data });
        fetchData();
        toast.success('Item added successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new item</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <div>Code*</div>
          <br />
          <input
            className='font-poppins w-full rounded-md'
            style={{
              width: '360px',
              height: '30px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            onChange={(e) =>
              setData({
                ...data,
                code: e.target.value,
              })
            }
          />
          <br />
          <br />

          <div>Description*</div>
          <br />
          <input
            className='font-poppins w-full rounded-md'
            style={{
              width: '360px',
              height: '30px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            onChange={(e) =>
              setData({
                ...data,
                desc: e.target.value,
              })
            }
          />
          <br />
          <br />
          <div>Label*</div>
          <br />
          <div>
            <input
              className='font-poppins w-full rounded-md'
              style={{
                width: '360px',
                height: '30px',
                fontSize: 14,
                color: '#2d3748',
                paddingLeft: 5,
              }}
              onChange={(e) =>
                setData({
                  ...data,
                  label: e.target.value,
                })
              }
            />
            <br />
            <br />
            <div>Note</div>
            <br />
            <input
              className='font-poppins w-full rounded-md'
              style={{
                width: '360px',
                height: '30px',
                fontSize: 14,
                color: '#2d3748',
                paddingLeft: 5,
              }}
              onChange={(e) =>
                setData({
                  ...data,
                  note: e.target.value,
                })
              }
            />
          </div>

          <br />
          <br />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={handlePost}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
