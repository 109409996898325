// eslint-disable-next-line import/no-extraneous-dependencies
import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddNewEventInformationForm from '@src/components/forms/addNewEventInformation.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import { mapUpdateNewEventInformationFormInputData } from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/event`;
const initialFormInput = {
  past_link: '',
  past_desc: '',
  source_link: '',
  link_question: '',
  event_title: '',
  event_link: '',
  event_desc: '',
  organiser_name: '',
  organiser_desc: '',
  event_image: '',
  organiser_image: '',
  learning_file: '',
  source_slide: '',
  links: [],
};

export default function UpdateNewEventInformation() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { eventId: eventIdParam } = useParams();
  const { state } = useLocation();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [eventId, setEventId] = useState(eventIdParam);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  // const [isVideo, setIsVideo] = useState(false);
  const [formInput, setFormInput] = useState(null);
  const [update, setUpdate] = useState(initialFormInput);

  const [eventImageTus, setEventImageTus] = React.useState(null);
  const [slidePdfTus, setSlidePdfTus] = React.useState(null);
  const [learnVideoTus, setLearnVideoTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const imageUrl = React.useMemo(
    () => previewImage || formInput?.event_image,
    [previewImage, formInput?.event_image],
  );
  const showImagePreview = React.useMemo(
    () => selectedImage || previewImage || formInput?.event_image,
    [selectedImage, previewImage, formInput?.event_image],
  );

  const setPostStateData = (data) => {
    setIsLoading(true);
    if (isMounted.current) {
      setFormInput(data);
      setUpdate(data);
    }
    setIsLoading(false);
  };

  const setOpenModalState = (data) => {
    if (isMounted.current) setOpen(data);
  };

  const handleOpenModal = async () => {
    setOpenModalState(true);
  };

  const handleCloseModal = async () => {
    setOpenModalState(false);
    // navigate(state?.prevLocation || -1);
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const updateNewLearning = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let eventImage;
      let slidePdf;
      let learnVideo;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Update learning journey information');

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (slidePdfTus) {
        slidePdf = slidePdfTus;
        uploads.push(slidePdf);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapUpdateNewEventInformationFormInputData(formInput);

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (slidePdf && slidePdf.upload.url) {
          formData.source_slide = slidePdf.upload.url;
        }

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .put(`${url}/learning-journey/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setPostStateData({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            handleOpenModal();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  // const handleBack = async () => {
  //   navigate(state?.prevLocation || -1);
  // };

  const handleEventImageChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const eventImage = await tusUtils.createUploadData(
          file,
          handleOnUploadResume,
          {
            Authorization: `Bearer ${auth.idToken}`,
          },
        );
        setEventImageTus(eventImage);
      } catch (e) {
        console.error(e);
      }

      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({ ...prevFormInput, image: file }));
      // setIsVideo(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const u = new URL(window.location.href);
    const evId = u.searchParams.get('eventId');
    if (evId) setEventId(evId);
  }, []);

  useEffect(() => {
    if (auth?.idToken && eventId) {
      axios
        .get(`${url}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setFormInput(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [auth, eventId]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (eventId && uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef, eventId]);

  return (
    <div>
      <UploadedFilesDialog ref={uploadResumeRef} />
      <Box sx={{ width: 'calc(100vw - 25rem)', fontFamily: 'Poppins' }}>
        {/* <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button> */}
        <div className='mr-6 grid grid-cols-12 gap-6'>
          <div className='col-span-7'>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '100%',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <AddNewEventInformationForm
                formInput={formInput}
                setFormInput={setFormInput}
                update={update}
                setUpdate={setUpdate}
                setFileTus={{
                  setSlidePdfTus,
                  setLearnVideoTus,
                }}
                onTusResume={handleOnUploadResume}
                setIsLoading={setIsLoading}
              />
              <div className='py-6' />
            </Box>
          </div>
          <div className='col-auto'>
            <div className='py-4 px-9 text-lg font-bold text-gray-600'>
              Preview
            </div>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '400px',
                backgroundColor: 'white',
                margin: 4,
              }}
            >
              <div className='py-6 px-6'>
                <div className='flex justify-center'>
                  <Box
                    sx={{
                      width: '203px',
                      height: '125px',

                      overflow: 'hidden',
                    }}
                    className='flex justify-center rounded-xl bg-gray-300'
                  >
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleEventImageChange}
                      style={{ display: 'none' }}
                      id='upload-file-input'
                    />
                    {showImagePreview ? (
                      <div>
                        <img
                          src={imageUrl}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ) : null}
                  </Box>
                </div>
                <div className='py-4 font-bold'>
                  Title: {formInput?.event_title}
                </div>

                <Box
                  className='rounded-md'
                  sx={{
                    width: '345px',
                    height: 'auto',
                    backgroundColor: 'white',
                  }}
                >
                  <div className='py-2 font-bold text-cyan-700'>
                    Video Description:
                  </div>
                  <div className='justify-center py-2 px-1 text-sm font-bold text-cyan-600'>
                    {formInput?.past_desc &&
                      formInput?.past_desc
                        .split('\n')
                        .map((paragraph, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <p key={index} style={{ textAlign: 'justify' }}>
                            {paragraph}{' '}
                          </p>
                        ))}
                  </div>
                </Box>
                <br />
                <Box
                  className='rounded-md'
                  sx={{
                    width: '345px',
                    height: 'auto',
                    backgroundColor: '#F3F3F3',
                  }}
                >
                  <div className='px-4 py-4 font-bold text-cyan-700'>
                    Meet the organiser: <br />
                  </div>
                  <div className='px-4 py-4 text-sm font-bold text-cyan-600'>
                    <div className='justify-center py-2 px-1 text-sm font-bold text-cyan-600'>
                      {formInput?.organiser_desc &&
                        formInput?.organiser_desc
                          .split('\n')
                          .map((paragraph, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p key={index} style={{ textAlign: 'justify' }}>
                              {paragraph}{' '}
                            </p>
                          ))}
                    </div>
                  </div>
                </Box>
                <span className='flex justify-between py-6'>
                  {/* <div className="flex justify-right">
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '330px',
                    }}
                    className="font-bold px-6 border border-cyan-600 rounded-md text-cyan-600"
                    variant="outlined"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => {
                      postUnlistEvent();
                      navigate('/event-management');
                    }}
                  >
                    Save
                  </Button>
                </div> */}
                  <Box sx={{ width: '400px' }} className='flex justify-center'>
                    <Button
                      sx={{
                        textTransform: 'none',
                        height: '50px',
                        width: '340px',
                      }}
                      className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                      variant='outlined'
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        updateNewLearning();
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </span>
              </div>
            </Box>
          </div>
        </div>
      </Box>

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Update Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Learning Journey has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
