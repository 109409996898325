/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/ww-platform/admin/v2/organization/license`;

export default function LicensePackageDetail({
  packages,
  setPackage,
  list,
  setList,
  detail,
  fetchData,
}) {
  const { auth } = useAuth();
  const { licenseId } = useParams();

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/detail/${licenseId}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setList(response.data.response.data.organization_license_info);
        })
        .catch((error) => {
          console.log('Error', error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const [selectedPackageId, setSelectedPackageId] = useState(null);

  useEffect(() => {
    if (auth?.idToken) {
      const requestData = {
        filter: {
          license_package_type: '',
          license_package_code: '',
          license_package_name: '',
          license_package_active_status: '',
        },
        pagination: {
          page: 1,
          page_size: 50,
        },
      };

      axios
        .post(`${url}-package-ref/search`, requestData, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setPackage(response.data.response.data);
          const index = Math.floor(
            Math.random() * response.data.response.data.length,
          );

          if (response.data.response.data.length > 0) {
            setSelectedPackageId(
              response.data.response.data[index].license_package_id,
            );
          }

          console.log('index', index);

          console.log(
            'Selected Package ID:',
            response.data.response.data[index].license_package_id,
          );
        })

        .catch((error) => {
          console.log('Error', error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    console.log('Selected Package ID in Effect:', selectedPackageId);
  }, [selectedPackageId]);

  const handleChangePackage = async () => {
    if (auth?.idToken) {
      try {
        await axios.put(
          `${url}/package/change/${licenseId}/${selectedPackageId}`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        );
        fetchData();

        toast.success('License package has been updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      } catch (error) {
        console.log('Error', error.message);
        toast.error('Failed to update license package', {
          position: 'top-center',
          autoClose: 2000,
        });
      }
    }
  };

  const [openChangeModal, setOpenChangeModal] = useState(false);
  const showChangeModal = () => {
    setOpenChangeModal(true);
  };
  return (
    <div>
      <span className='flex flex-col items-start px-4 py-4'>
        <TableContainer
          className='rounded-xl'
          component={Paper}
          style={{
            width: '980px',
            paddingBottom: '12px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ width: '100px' }}
                >
                  License Type
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '130px' }}
                >
                  Package Code
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '170px' }}
                >
                  Package Name
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '100px' }}
                >
                  Price ($)
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '150px' }}
                >
                  Description
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '120px' }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(packages) &&
                packages.length > 0 &&
                packages.map((row) => (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.license_package_id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      className='border-white text-sm font-bold text-gray-500'
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        {row?.license_package_type}
                      </span>
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {row?.license_package_code}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {row.license_package_name}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      ${row?.license_package_price} SGD
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {row.license_package_description ||
                        'No description added'}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      <Button
                        variant='outlined'
                        className='text-xs font-bold text-cyan-600'
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#FAF9FC',
                          '&:hover': {
                            backgroundColor: 'white',
                          },
                          width: '100px',
                          height: '35px',
                        }}
                        onClick={() => showChangeModal(selectedPackageId)}
                        disabled={row.license_package_id === selectedPackageId}
                      >
                        Change
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openChangeModal} onClose={setOpenChangeModal}>
          <DialogTitle>Confirm Change</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to change this package?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenChangeModal(false)}
              color='secondary'
              sx={{ textTransform: 'none' }}
            >
              Done
            </Button>
            <Button
              onClick={() => handleChangePackage(selectedPackageId)}
              color='primary'
              sx={{ textTransform: 'none' }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    </div>
  );
}
