import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ButtonAction from '@src/components/buttons/action.button';
import Container from '@src/components/commons/containerPage.common';
import LabTabs from '@src/components/commons/tabs.common';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// eslint-disable-next-line import/default
import BranchList from './branchListCard';
import OrganisationAnalytic from './organisationAnalytics';

export default function OrganisationOverview() {
  const path = useLocation().pathname;
  const { orgId } = useParams();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/verified-organisation/${orgId}`);
  };
  const categories = [
    {
      idx: 0,
      title: 'Overview',
      component: (
        <div className='py-4'>
          <BranchList />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Analytics',
      component: (
        <div>
          <OrganisationAnalytic />
        </div>
      ),
    },
  ];
  return (
    <Box
      sx={{ width: '100%', fontFamily: 'Poppins' }}
      className='py-4 w-3/4'
      pathUrl={path}
      actionRight={<ButtonAction label='Create New Clinic' />}
    >
      <div className='px-4 py-8'>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-8 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <LabTabs menus={categories} />
      </div>
    </Box>
  );
}
