/* eslint-disable import/no-extraneous-dependencies */
import '@fontsource/poppins';
import { getUserProfileById } from '@src/api/Users';
import { useAuth } from '@src/contexts/authContext';

import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import DrawerButton from '../buttons/drawer.button';

export default function DrawerEditProfile({ open, toggleDrawer }) {
  const [users, setUser] = useState({});
  const { auth } = useAuth();
  const id = localStorage.getItem('authentication');
  const { user } = JSON.parse(id);

  React.useEffect(() => {
    if (auth.idToken) {
      getUserProfileById(auth.idToken)
        .then((data) => {
          setUser(data.data);
        })
        .catch((error) => {
          console.error('Error', error);
        });
    }
  }, [auth.idToken]);
  return (
    <>
      <DrawerButton toggleDrawer={toggleDrawer} />
      <Drawer
        open={open}
        onClose={toggleDrawer}
        direction='right'
        className='z-[102] overflow-y-auto pr-8'
        size={350}
        style={{ fontFamily: 'Poppins' }}
      >
        <div className='mt-10 w-full px-7'>
          <div className='flex flex-col items-start'>
            <div className='flex items-center justify-center gap-x-5'>
              <img
                src='https://i.pravatar.cc/300'
                alt='Admin'
                className='h-20 w-20 rounded-full border-2 border-cyan-500 shadow'
              />
              <div className='leading-3'>
                <p className='text-[16px] font-semibold text-[#005073]'>
                  {user?.full_name}
                </p>
                <p className='text-[14px] text-gray-500'>{user?.email}</p>
                <p className='text-[14px] text-gray-500'>
                  {user?.phone_number}
                </p>
              </div>
            </div>
            {/* <div className='flex items-center justify-center gap-x-3'>
              <p className='rounded-full bg-blue-50 px-3 py-1 font-bold text-[#117DAC]'>
                MCR No: T12354D
              </p>
              <p className='rounded-full bg-blue-50 px-3 py-1 font-bold text-[#117DAC]'>
                LOC - XXXXXX
              </p>
            </div> */}
            {/* <button
              type='button'
              className='mx-auto mt-4 flex cursor-pointer items-center justify-center rounded-lg border-cyan-600 bg-white px-8 py-2.5 text-[14px] font-semibold text-cyan-600 text-white shadow hover:shadow-cyan-500'
            >
              Done Editing
            </button> */}
          </div>
          {/* <div className='font-bold'>ABOUT</div>
          <div className='text-gray-500'>
            <p>Lorem ipsum</p>
          </div>
          <div className='py-4 font-bold'>CERTIFICATES</div>
          <div className='flex justify-between '>
            <div className='py-4 text-gray-500'>
              Graduate Diploma in Family Medicine
            </div>
            <div className='justify-right flex'>
              <Button
                sx={{
                  textTransform: 'none',
                  margin: 2,
                  height: '30px',
                  width: '100px',
                }}
                className='rounded-md bg-blue-200 bg-opacity-50 px-6 font-bold text-cyan-600'
                variant='contained'
              >
                Checked
              </Button>
            </div>
          </div>
          <div className='flex justify-between '>
            <div className='py-4 text-gray-500'>
              Graduate Diploma in Family Dermatology
            </div>
            <div className='justify-right flex'>
              <Button
                sx={{
                  textTransform: 'none',
                  margin: 2,
                  height: '30px',
                  width: '100px',
                }}
                className='bg-cyan-600 from-cyan-500 to-cyan-600 px-6 font-bold'
                variant='contained'
              >
                Check
              </Button>
            </div>
          </div>
          <div className='py-4 font-bold'>VACCINATION CERTIFICATES</div>
          <div className='flex justify-between '>
            <div className='py-4 text-gray-500'>
              Measles Vaccination / Immunity
            </div>
            <div className='justify-right flex'>
              <Button
                sx={{
                  textTransform: 'none',
                  margin: 2,
                  height: '30px',
                  width: '100px',
                }}
                className='rounded-md bg-blue-200 bg-opacity-50 px-6 font-bold text-cyan-600'
                variant='contained'
              >
                Checked
              </Button>
            </div>
          </div>
          <div className='flex justify-between '>
            <div className='py-4 text-gray-500'>Diphtheria Vaccination</div>
            <div className='justify-right flex'>
              <Button
                sx={{
                  textTransform: 'none',
                  margin: 2,
                  height: '30px',
                  width: '100px',
                }}
                className='bg-cyan-600 from-cyan-500 to-cyan-600 px-6 font-bold'
                variant='contained'
              >
                Check
              </Button>
            </div>
          </div>
          <div className='py-4 font-bold'>PREFERENCES</div>
          <div className='text-gray-500'>
            <div className='flex justify-between'>
              <Icon
                icon='fluent:checkbox-checked-20-filled'
                color='#4ed39c'
                width='30'
                height='30'
              />
              <div className='px-2'>
                I do attend to children patient from 1-7 Years old
              </div>
            </div>
            <div className='flex justify-between'>
              <Icon
                icon='fluent:checkbox-checked-20-filled'
                color='#4ed39c'
                width='30'
                height='30'
              />
              <div className='px-2'>
                I do attend to children patient from 1-7 Years old
              </div>
            </div>
            <div className='flex justify-between'>
              <Icon
                icon='fluent:checkbox-checked-20-filled'
                color='#4ed39c'
                width='30'
                height='30'
              />
              <div className='px-2'>
                I do attend to children patient from 1-7 Years old
              </div>
            </div>
          </div> */}
        </div>
      </Drawer>
    </>
  );
}
