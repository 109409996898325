import ProtectedRoute from '@src/components/commons/protectedRoute';
import ListingInformationForm from '@src/components/forms/listingInformation.form';
import ListingInformationNurseForm from '@src/components/forms/listingInformationNurse.form';
import AddAdditionalInfo from '@src/components/modals/addAdditionalInfo';
import LoginPage from '@src/pages/auth/login.page';
import ResendCodePage from '@src/pages/auth/resendCode.page';
import VerifyCodePage from '@src/pages/auth/verifyCode.page';
import AddHubBanner from '@src/pages/dashboards/announcement/addHubBanner';
// import ApplicationPage from '@src/pages/dashboards/announcement/addMobBanner';
import AddMobCampaign from '@src/pages/dashboards/announcement/addMobBanner';
import AddWebCampaign from '@src/pages/dashboards/announcement/addWebCampaign';
import MarketingContentPage from '@src/pages/dashboards/announcement/marketingContent.page';
import NotificationPage from '@src/pages/dashboards/announcement/notification.page';
import UpdateHubBanner from '@src/pages/dashboards/announcement/updateHubBanner.page';
import UpdateHubCampaign from '@src/pages/dashboards/announcement/updateHubBanner.page';
import UpdateMobCampaign from '@src/pages/dashboards/announcement/updateMobBanner.page';
import UpdateAdminAdvert from '@src/pages/dashboards/announcement/updateWebCampaign.page';
import AppUserPage from '@src/pages/dashboards/appUsers/appUser.page';
import CreateAccountCLA from '@src/pages/dashboards/appUsers/createAccountCLA';
import CreateAccountDoctor from '@src/pages/dashboards/appUsers/createAccountDoctor';
import EditCertItemsDoctor from '@src/pages/dashboards/appUsers/editCertItemsDoctor';
import UnverifiedAccountInformation from '@src/pages/dashboards/appUsers/unverifiedAccount.information';
import UnverifiedCLAInformation from '@src/pages/dashboards/appUsers/unverifiedCla.information';
import UnverifiedDoctorInformation from '@src/pages/dashboards/appUsers/unverifiedDoctor.information';
import VerifiedCLAInformation from '@src/pages/dashboards/appUsers/verifiedCLA.information';
import VerifiedDoctorInformation from '@src/pages/dashboards/appUsers/verifiedDoctor.information';
import AuditLogPage from '@src/pages/dashboards/auditLog.page';
import CertificatePage from '@src/pages/dashboards/certificate/certificate.page';
import CreateCertificate from '@src/pages/dashboards/certificate/createCertificate';
import CreateTemplate from '@src/pages/dashboards/certificate/createTemplate';
import EditTemplate from '@src/pages/dashboards/certificate/editTemplate';
import ChatPage from '@src/pages/dashboards/chat.page';
import ClinicBranchPage from '@src/pages/dashboards/clinicBranches/clinic.page';
import ClinicSetting from '@src/pages/dashboards/clinicBranches/clinicSetting';
// import ClinicListingDetail from '@src/pages/dashboards/clinicBranches/clinicListingDetail';
import CreateAccountClinic from '@src/pages/dashboards/clinicBranches/createAccountClinic';
import ClinicDetail from '@src/pages/dashboards/clinicBranches/detailClinic';
import ListingInformation from '@src/pages/dashboards/clinicBranches/listingInformation';
import ViewClinicPage from '@src/pages/dashboards/clinicBranches/viewClinic';
import PaymentRequestClinic from '@src/pages/dashboards/clinicPayment/paymentRequestClinic';
import DownloadListPage from '@src/pages/dashboards/downloadList.page';
// import CreateLearningJourney from '@src/pages/dashboards/eventManagement/createLearningJourney';
import LearningJourneyPage from '@src/pages/dashboards/eventManagement/createLearningJourney.page';
import CreateNewEvent from '@src/pages/dashboards/eventManagement/createNewEvent';
import DuplicatePastEvent from '@src/pages/dashboards/eventManagement/duplicatePastEvent';
import EventManagementPage from '@src/pages/dashboards/eventManagement/eventManagement.page';
import EventReportPage from '@src/pages/dashboards/eventManagement/eventReport.page';
import LearningJourney from '@src/pages/dashboards/eventManagement/learningJourney.page';
import QuestionnarePage from '@src/pages/dashboards/eventManagement/questionnare.page';
import UpdateEventHistory from '@src/pages/dashboards/eventManagement/updateEventHistory';
import UpdateEventListed from '@src/pages/dashboards/eventManagement/updateEventListed';
import UpdateEventUnlisted from '@src/pages/dashboards/eventManagement/updateEventUnlisted';
import VideoAnalytic from '@src/pages/dashboards/eventManagement/videoAnalytic';
import ViewDetailPastEvent from '@src/pages/dashboards/eventManagement/viewDetailPastEvent';
import EditInterviewPage from '@src/pages/dashboards/interviewApplicant/editInterview.page';
import InterviewPage from '@src/pages/dashboards/interviewApplicant/interview.page';
import CreateNewSlot from '@src/pages/dashboards/jobs/createNewSlotDoctor';
import CreateNewSlotNurse from '@src/pages/dashboards/jobs/createNewSlotNurse';
import UpdatePastSlot from '@src/pages/dashboards/jobs/updatePastSlotDoctor';
import Dashboard from '@src/pages/dashboards/main.dashboard';
import MasterDataPage from '@src/pages/dashboards/masterData/masterData.page';
import CreateAccountNurse from '@src/pages/dashboards/nurse/createAccountNurse';
import UnverifiedNurseInformation from '@src/pages/dashboards/nurse/unverifiedNurse.information';
import VerifiedNurseInformation from '@src/pages/dashboards/nurse/verifiedNurse.information';
import CreateAccountOrganisation from '@src/pages/dashboards/organisation/createAccountOrganisation';
import CreateAdminOrganisation from '@src/pages/dashboards/organisation/createAdminOrganisation';
import ListAdminOrg from '@src/pages/dashboards/organisation/listAdminOrganisation';
import OrganisationPage from '@src/pages/dashboards/organisation/organisation.page';
import OrganisationFinances from '@src/pages/dashboards/organisation/organisationFinances';
import OrganisationOverview from '@src/pages/dashboards/organisation/organisationOverview';
import OrganisationUnverified from '@src/pages/dashboards/organisation/updateUnverifOrganisation';
import UpdateVerifiedPage from '@src/pages/dashboards/organisation/updateVerified.page';
import OrganisationVerified from '@src/pages/dashboards/organisation/updateVerifiedOrganisation';
import DetailLicense from '@src/pages/dashboards/organisationLicense/detailLicense';
import OrganisationLicensePage from '@src/pages/dashboards/organisationLicense/organisationLicense.page';
import PaymentRequestPage from '@src/pages/dashboards/paymentRequest/paymentRequest.page';
import CreateNewResource from '@src/pages/dashboards/resources/createResource';
import TableResources from '@src/pages/dashboards/resources/tableResources';
import UpdateResource from '@src/pages/dashboards/resources/updateResource';
import CreateSuperAdmin from '@src/pages/dashboards/superAccess/createSuperAdmin';
import SuperAccessPage from '@src/pages/dashboards/superAccess/superAccess.page';
import VerificationPage from '@src/pages/dashboards/verification.page';
import { mainRoute } from '@src/utils/consts/routesMainMenu.const';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

export default function RouteEntry() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Auth */}
        <Route path='/login' element={<LoginPage />} />
        {/* <Route path="/dashboard" element={<Layout />} /> */}
        <Route path='/verify-code' element={<VerifyCodePage />} />
        <Route path='/resend-code' element={<ResendCodePage />} />
        {/* Main Menu */}
        <Route
          path={mainRoute.DATABASE_APP_USERS}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route
            path={mainRoute.DATABASE_APP_USERS}
            element={<AppUserPage />}
          />
          <Route
            path={'/create-account-doctor'}
            element={<CreateAccountDoctor />}
          />
          <Route path={'/create-account-cla'} element={<CreateAccountCLA />} />
          <Route
            path={'/create-account-nurse'}
            element={<CreateAccountNurse />}
          />
          <Route path={'/edit-cert-doctor'} element={<EditCertItemsDoctor />} />
          <Route
            path={'/verified-doctor-detail/:userId'}
            element={<VerifiedDoctorInformation />}
          />
          <Route
            path={'/verified-cla-detail/:userId'}
            element={<VerifiedCLAInformation />}
          />
          <Route
            path={'/unverified-doctor-detail/:userId'}
            element={<UnverifiedDoctorInformation />}
          />
          <Route
            path={'/unverified-cla-detail/:userId'}
            element={<UnverifiedCLAInformation />}
          />
          <Route
            path={'/verified-nurse-detail/:userId'}
            element={<VerifiedNurseInformation />}
          />
          <Route
            path={'/unverified-nurse-detail/:userId'}
            element={<UnverifiedNurseInformation />}
          />
          <Route
            path={'/unverified-account-detail/:userId'}
            element={<UnverifiedAccountInformation />}
          />
          <Route
            path={mainRoute.DATABASE_ORGANISATIONS}
            element={<OrganisationPage />}
          />
          <Route path={'/organisation'} element={<OrganisationPage />} />
          <Route
            path={mainRoute.DATABASE_ORGANISATIONS_LICENSE}
            element={<OrganisationLicensePage />}
          />
          <Route
            path={'/organisation-license'}
            element={<OrganisationLicensePage />}
          />
          <Route
            path={'/detail-license/:licenseId'}
            element={<DetailLicense />}
          />
          <Route
            path={mainRoute.DATABASE_CLINIC_BRANCH}
            element={<ClinicBranchPage />}
          />
          <Route
            path={mainRoute.DATABASE_PAYMENT_REQUEST}
            element={<PaymentRequestPage />}
          />
          <Route
            path={mainRoute.DATABASE_CLINIC_PAYMENT}
            element={<PaymentRequestClinic />}
          />
          <Route
            path={mainRoute.DATABASE_DOWNLOAD_LIST}
            element={<DownloadListPage />}
          />
          <Route
            path={mainRoute.DATABASE_MASTER_DATA}
            element={<MasterDataPage />}
          />
          <Route path={'/clinic-branch'} element={<ClinicBranchPage />} />
          <Route
            path={'/create-clinic/:orgId'}
            element={<CreateAccountClinic />}
          />
          <Route
            path={'/create-organisation'}
            element={<CreateAccountOrganisation />}
          />
          <Route
            path={'/organisation-finance'}
            element={<OrganisationFinances />}
          />
          <Route
            path={'/create-admin-organisation/:orgId'}
            element={<CreateAdminOrganisation />}
          />
          <Route path={'/view-admin-org/:orgId'} element={<ListAdminOrg />} />
          {/* <Route
            path={'/verification-clinic/:clinicId'}
            element={<ClinicUnverified />}
          /> */}
          <Route
            path={'/verification-organisation/:orgId'}
            element={<OrganisationUnverified />}
          />
          <Route
            path={'/verified-organisation/:orgId'}
            element={<UpdateVerifiedPage />}
          />
          <Route
            path={'/view-branch/:orgId'}
            element={<OrganisationOverview />}
          />
          <Route
            path={'/view-listing-detail/:jobsId'}
            element={<ListingInformationForm />}
          />
          <Route
            path={'/view-listing-detail-nurse/:jobsId'}
            element={<ListingInformationNurseForm />}
          />
          <Route
            path={'/verified-clinic/:clinicId'}
            element={<ViewClinicPage />}
          />
          <Route path={'/detail-clinic/:clinicId'} element={<ClinicDetail />} />
          <Route
            path={'/clinic-setting/:clinicId'}
            element={<ClinicSetting />}
          />
          <Route
            path={'/add-additional-info/:clinicId'}
            element={<AddAdditionalInfo />}
          />
          <Route
            path={mainRoute.ANNOUNCEMENT_MARKETING}
            element={<MarketingContentPage />}
          />
          <Route
            path={mainRoute.ANNOUNCEMENT_INTERVIEW}
            element={<InterviewPage />}
          />
          <Route
            path={'edit-request-interview/:interviewId'}
            element={<EditInterviewPage />}
          />

          <Route
            path={mainRoute.ANNOUNCEMENT_NOTIFICATION}
            element={<NotificationPage />}
          />
          <Route path={mainRoute.ANNOUNCEMENT_CHAT} element={<ChatPage />} />
          <Route
            path={mainRoute.MISC_EVENT_MANAGEMENT}
            element={<EventManagementPage />}
          />
          <Route path={mainRoute.MISC_AUDIT_LOG} element={<AuditLogPage />} />
          <Route path={mainRoute.MISC_RESOURCES} element={<TableResources />} />
          <Route
            path={mainRoute.MISC_REJECTED_VERIFICATION}
            element={<VerificationPage />}
          />
          <Route
            path={mainRoute.MISC_SUPER_ACCESS}
            element={<SuperAccessPage />}
          />
          <Route path={'/create-super-admin'} element={<CreateSuperAdmin />} />
          <Route path={'/create-new-event'} element={<CreateNewEvent />} />
          <Route path={'/event-management'} element={<EventManagementPage />} />
          <Route
            path={'/update-listed-event/:eventId'}
            element={<UpdateEventListed />}
          />
          <Route
            path={'/update-unlisted-event/:eventId'}
            element={<UpdateEventUnlisted />}
          />
          <Route
            path={'/update-history-event/:eventId'}
            element={<UpdateEventHistory />}
          />
          <Route
            path={'/duplicate-event/:eventId'}
            element={<DuplicatePastEvent />}
          />
          <Route
            path={'/view-detail-past-event/:eventId'}
            element={<ViewDetailPastEvent />}
          />
          <Route
            path={'/create-learning-journey'}
            // element={<CreateLearningJourney />}
            element={<LearningJourneyPage />}
          />
          <Route
            path={'/update-learning-journey/:eventId'}
            element={<LearningJourney />}
          />
          <Route
            path={'/event-report/:eventId'}
            element={<EventReportPage />}
          />
          <Route
            path={'/video-analytic/:eventId'}
            element={<VideoAnalytic />}
          />
          <Route path={'/event-certificate'} element={<CertificatePage />} />
          <Route path={'/create-certificate'} element={<CreateCertificate />} />
          <Route
            path={'/create-certificate-template'}
            element={<CreateTemplate />}
          />
          <Route
            path={'/edit-certificate-template'}
            element={<EditTemplate />}
          />
          <Route path={'/event-questionnare'} element={<QuestionnarePage />} />
          <Route path={'/add-web-campaign'} element={<AddWebCampaign />} />
          <Route path={'/add-mobile-banner'} element={<AddMobCampaign />} />
          <Route
            path={'/update-mobile-banner/:adsId'}
            element={<UpdateMobCampaign />}
          />
          <Route
            path={'/update-web-campaign/:bannerId'}
            element={<UpdateAdminAdvert />}
          />
          {/* <Route
            path={'/announcement-marketing'}
            element={<ApplicationPage />}
          /> */}
          <Route path={'/create-resource'} element={<CreateNewResource />} />
          <Route
            path={'/update-resource/:resId'}
            element={<UpdateResource />}
          />

          <Route
            path={'/create-new-slot/:clinicId'}
            element={<CreateNewSlot />}
          />

           <Route
            path={'/edit-past-slot/:jobsId'}
            element={<UpdatePastSlot />}
          />

          <Route
            path={'/create-new-slot-nurse/:id'}
            element={<CreateNewSlotNurse />}
          />

          {/* HUB */}
          <Route path={'/add-hub-banner'} element={<AddHubBanner />} />
          <Route
            path={'/update-hub-banner/:adsId'}
            element={<UpdateHubBanner />}
          />
        </Route>

        {/* Main Menu */}

        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
