import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}/admin/events/attendances`;
export default function EventAttendee() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { eventId } = useParams();
  const [listEvents, setListEvents] = useState([]);

  // const handleBack = () => {
  //   navigate('/event-management');
  // };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${urls}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setListEvents(response.data);
        })
        .catch((error) => {
          console.log('Error', error.message);
        });
    }
  }, [auth, eventId]);
  return (
    <>
      {/* <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button> */}

      {/* <div className='py-8 px-6 font-bold text-gray-600'>Event Report Page</div> */}
      <div className='px-4'>
        <TableContainer
          className='rounded-xl px-3'
          component={Paper}
          style={{
            width: '960px',
            paddingBottom: '12px',
            paddingRight: '12px',
            backgroundColor: '#ffffff',
            borderColor: 'ffffff',
          }}
        >
          <Table
            sx={{
              minWidth: 350,
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-sm font-bold text-gray-400'
                  style={{ width: '250px', border: 'gray' }}
                >
                  Attendee
                </TableCell>
                <TableCell
                  className='text-sm font-bold text-gray-400'
                  style={{ width: '250px', border: 'gray' }}
                >
                  Role
                </TableCell>
                <TableCell
                  className='text-sm font-bold text-gray-400'
                  style={{ width: '250px', border: 'gray' }}
                >
                  Role ID
                </TableCell>
                <TableCell
                  className='text-sm font-bold text-gray-400'
                  style={{ width: '250px', border: 'gray' }}
                >
                  Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(listEvents) && listEvents.length > 0 ? (
                listEvents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row?._id}>
                      <TableCell className='border border-white'>
                        {row?.full_name}
                      </TableCell>
                      <TableCell className='border border-white'>
                        {row?.role === 'clinic_assistants'
                          ? 'Clinic Assistant'
                          : row?.role === 'doctor'
                          ? 'Doctor'
                          : row?.role === 'nurse'
                          ? 'Nurse'
                          : row?.role}
                      </TableCell>
                      <TableCell className='border border-white'>
                        {row?.role_id}
                      </TableCell>
                      <TableCell className='border border-white'>
                        {row?.email}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align='center'
                    style={{ fontStyle: 'italic' }}
                  >
                    No attendee
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box
            className='flex justify-end py-3'
            sx={{ width: '960px', fontFamily: 'Poppins' }}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={listEvents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      </div>
    </>
  );
}
