import { Typography } from '@mui/material';
import { compareKeyAndValue } from '@src/utils/functions/compareUrlInMenu.util';

import BreadcrumbCustom from './breadcumb.common';

export default function Container({ children, pathUrl = '/', actionRight }) {
  const currentMenu = compareKeyAndValue(pathUrl)?.split('_')[0];
  const currentPathUrl = compareKeyAndValue(pathUrl)
    ?.split('_')
    .slice(1)
    .join(' ');
  return (
    <div className='px-4 pt-10'>
      <div className='relative'>
        <BreadcrumbCustom>
          <Typography className='text-[16px] font-medium uppercase text-[#54657E]'>
            {currentMenu}
          </Typography>
          <Typography className='text-[16px] font-semibold uppercase text-[#9081D5]'>
            {currentPathUrl}
          </Typography>
        </BreadcrumbCustom>

        {actionRight ? (
          <div className='absolute right-96 top-0 flex items-center justify-center'>
            {actionRight}
          </div>
        ) : null}
      </div>

      <div className='mt-7'>{children}</div>
    </div>
  );
}
