import '@fontsource/poppins';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useState } from 'react';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data`;

export default function PersonalInformation({ getUser, setGetUser }) {
  const [open, setOpen] = React.useState(false);
  const { auth } = useAuth();
  const [nationality, setNationality] = React.useState([]);
  const [residen, setResiden] = React.useState([]);
  const getResident = () => {
    axios
      .get(`${url}/residential-status`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setResiden(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNationality = () => {
    axios
      .get(`${url}/country`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setNationality(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getResident();
    getNationality();
  }, []);

  return (
    <>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Name as per ID*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={getUser?.Name}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  name: e.target.value,
                  Name: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Nationality*
          </label>
          <div className='mt-2'>
            <select
              id='resident'
              value={getUser?.Nationality}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  nationality: e.target.value,
                  Nationality: e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              style={{ height: '35px', width: '195px' }}
            >
              <option value=''>Select one</option>
              {nationality.map((nat) => (
                <option value={nat.label}>{nat?.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            NRIC*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={getUser?.NRIC}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  nric_fin: e.target.value,
                  NRIC: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Gender*
          </label>
          <div className='mt-2'>
            <select
              id='gender'
              value={getUser?.Gender}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  gender: e.target.value,
                  Gender: e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              style={{ height: '35px', width: '195px' }}
            >
              <option value=''>Select one</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            DOB*
          </label>
          <div className='mt-2'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='text-xs'
                  reduceAnimations
                  open={open}
                  sx={{ width: '200px' }}
                  format='D MMMM YYYY'
                  value={
                    getUser?.['Date of Birth']
                      ? dayjs(getUser?.['Date of Birth'], 'D MMMM YYYY')
                      : null
                  }
                  onChange={(date) => {
                    setGetUser({
                      ...getUser,
                      date: date ? date.format('YYYY-MM-DD') : '',
                    });
                    setOpen(false);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      onClick: () => setOpen(true),
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Residential Status*
          </label>
          <div className='mt-2'>
            <select
              id='resident'
              value={getUser?.['residential Status']}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  residential_status: e.target.value,
                  'residential Status': e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              style={{ height: '35px', width: '195px' }}
            >
              <option value=''>Select one</option>
              {residen.map((res) => (
                <option value={res.label}>{res?.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-span-full'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Residential Address*
          </label>
          <div className='mt-2'>
            <textarea
              rows='3'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={getUser?.Address}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  Address: e.target.value,
                  address: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
