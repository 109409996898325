import { TQueue, TQueueStatus, useTaskQueue } from '@contexts/taskQueueContext';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MuiLinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { uuid } from '@utils';

import React from 'react';

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
      <Box component='span' sx={{ display: 'block', width: '100%', mr: 1 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box component='span' sx={{ display: 'block', minWidth: 35 }}>
        <Typography
          component='span'
          variant='body2'
          color='text.secondary'
          // eslint-disable-next-line react/destructuring-assignment
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Box
      sx={{ width: '100%', overflow: 'hidden' }}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && <Box sx={{ width: '100%', px: 3 }}>{children}</Box>}
    </Box>
  );
}

const TaskListItem = React.memo(function TaskListItem({
  title,
  description,
  progress,
}) {
  return (
    <ListItem sx={{ display: 'block' }}>
      <ListItemText primary={title} secondary={description} />
      <LinearProgressWithLabel value={progress} />
    </ListItem>
  );
});

const TaskList = React.memo(function TaskList({
  tasks,
  inProgress = false,
  complete = false,
  failed = false,
}) {
  return (
    <List
      component={Box}
      sx={{ overflowY: 'scroll', paddingRight: '32px', maxHeight: '75vh' }}
    >
      {tasks.map((task, index) => {
        const progress = task.getProgress();
        const listItem = (
          <TaskListItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${uuid()}`}
            title={task.title}
            description={
              complete ? 'Completed' : failed ? 'Failed' : task.getDescription()
            }
            progress={progress}
          />
        );

        if (
          task.status === TQueueStatus.IN_PROGRESS &&
          inProgress &&
          parseInt(progress, 10) < 100
        ) {
          return listItem;
        }

        if (
          (task.status === TQueueStatus.COMPLETE && complete) ||
          parseInt(progress, 10) === 100
        ) {
          return listItem;
        }

        if (task.status === TQueueStatus.FAILED && failed) {
          return listItem;
        }

        return null;
      })}
    </List>
  );
});

function QueueBox({ open, onClose }) {
  const { taskQueue, dispatch: dispatchTask } = useTaskQueue();
  const queueData = React.useMemo(
    () => taskQueue?.queue || [],
    [taskQueue?.queue],
  );
  const [tabValue, setTabValue] = React.useState(0);
  const [inProgressTasks, setInProgressTasks] = React.useState([]);
  const [completeTasks, setCompleteTasks] = React.useState([]);
  const [failedTasks, setFailedTasks] = React.useState([]);

  const getInProgressTasks = (q) =>
    q.filter((task) => task.status === TQueueStatus.IN_PROGRESS);
  const getCompleteTasks = (q) =>
    q.filter((task) => task.status === TQueueStatus.COMPLETE);
  const getFailedTasks = (q) =>
    q.filter((task) => task.status === TQueueStatus.FAILED);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    const newInProgressTasks = getInProgressTasks(queueData);
    const newCompleteTasks = getCompleteTasks(queueData);
    const newFailedTasks = getFailedTasks(queueData);

    setInProgressTasks(newInProgressTasks);
    setCompleteTasks(newCompleteTasks);
    setFailedTasks(newFailedTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueData]);

  React.useEffect(() => {
    if (taskQueue?.queueBoxUpdates) {
      const { queueBoxUpdates } = taskQueue;

      if (queueBoxUpdates[TQueueStatus.IN_PROGRESS]) {
        const { queue = [] } = taskQueue;
        const tasks = getInProgressTasks(queue);
        setInProgressTasks(tasks);
        dispatchTask({
          type: TQueue.QUEUE_BOX_UPDATE,
          payload: { status: TQueueStatus.IN_PROGRESS, value: false },
        });
      }

      if (queueBoxUpdates[TQueueStatus.COMPLETE]) {
        const { queue = [] } = taskQueue;
        const tasks = getCompleteTasks(queue);
        setCompleteTasks(tasks);
        dispatchTask({
          type: TQueue.QUEUE_BOX_UPDATE,
          payload: { status: TQueueStatus.COMPLETE, value: false },
        });
      }

      if (queueBoxUpdates[TQueueStatus.FAILED]) {
        const { queue = [] } = taskQueue;
        const tasks = getFailedTasks(queue);
        setFailedTasks(tasks);
        dispatchTask({
          type: TQueue.QUEUE_BOX_UPDATE,
          payload: { status: TQueueStatus.FAILED, value: false },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskQueue?.queueBoxUpdates]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullwidth
      sx={{ minWidth: 'fit-content', paddingRight: '16px' }}
    >
      <DialogTitle>Tasks</DialogTitle>
      <DialogContent
        style={{ minHeight: '300px', width: '500px', overflow: 'hidden' }}
      >
        <Box display='flex' sx={{ overflow: 'hidden' }}>
          <Tabs
            orientation='vertical'
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              overflow: 'hidden',
              '& .MuiTabs-indicator': { transition: 'none' },
            }}
          >
            <Tab label='In Progress' />
            <Tab label='Complete' />
            <Tab label='Failed' />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <TaskList tasks={inProgressTasks} inProgress />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <TaskList tasks={completeTasks} complete />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <TaskList tasks={failedTasks} failed />
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(QueueBox);
