import '@fontsource/poppins';

import React from 'react';

export default function MailingDetails({
  createOrg,
  setCreateOrg,
  updateOrg,
  setUpdateOrg,
}) {
  return (
    <>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Business Name*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.mailing_details_business_name ||
                    createOrg?.mailing_details?.business_name
                  : updateOrg?.mailing_details?.business_name || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      mailing_details_business_name: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      mailing_details_business_name: e.target.value,
                    })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Postal Code*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.mailing_details_postal_code ||
                    createOrg?.mailing_details?.postal_code
                  : updateOrg?.mailing_details?.postal_code || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      mailing_details_postal_code: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      mailing_details_postal_code: e.target.value,
                    })
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='col-span-full'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Business Address*
          </label>
          <div className='mt-2'>
            <textarea
              id='about'
              name='about'
              rows='3'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.mailing_details_delivery_address ||
                    createOrg?.mailing_details?.delivery_address
                  : updateOrg?.mailing_details?.delivery_address || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      mailing_details_delivery_address: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      mailing_details_delivery_address: e.target.value,
                    })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
