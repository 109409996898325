import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import { openLinkInPopUpWindow, uuid } from '@utils';
import * as tusUtils from '@utils/tus';

import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player/lazy';

export default function AddNewEventInformationForm({
  formInput,
  setFormInput,
  update,
  setUpdate,
  setFileTus,
  onTusResume,
  setIsLoading,
}) {
  const { auth } = useAuth();
  const videoPlayerRef = React.useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [previewVideoUrl, setPreviewVideoUrl] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [linkType, setLinkType] = useState('link');
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [videoPlayerId, setVideoPlayerId] = useState(uuid());
  const [isReloadVideoPlayer, setIsReloadVideoPlayer] = useState(false);
  const [isVideoPlayerReloaded, setIsVideoPlayerReloaded] = useState(false);
  const [linkList, setLinkList] = useState([]);
  const [newLink, setNewLink] = useState();
  const handleOpenSlide = (file) => async () => openLinkInPopUpWindow(file);

  const videoUrl = React.useMemo(
    () => previewVideoUrl || formInput?.learning_file,
    [previewVideoUrl, formInput?.learning_file],
  );
  const showVideoPlayer = React.useMemo(
    () => (selectedVideo && isVideo) || videoUrl,
    [selectedVideo, isVideo, videoUrl],
  );
  const videoAttachment = React.useMemo(() => {
    if (selectedVideo && selectedVideo.name) return selectedVideo.name;
    if (videoUrl) {
      const parts = videoUrl.split('/');
      return parts[parts.length - 1];
    }

    return 'Add attachment';
  }, [selectedVideo, videoUrl]);

  const handleLinkTypeChange = async (event) => {
    setLinkType(event.target.value);
  };

  const handleAddLink = async () => {
    if (newLink?.trim() !== '') {
      setLinkList([...linkList, newLink]);
      setFormInput((prevInput) => ({
        ...prevInput,
        links: [...(prevInput?.links || []), newLink],
      }));
      setNewLink('');
    }
  };

  const handleLinkChange = (e, index) => {
    const updatedLinks = [...linkList];
    updatedLinks[index] = e.target.value;
    setLinkList(updatedLinks);

    // Update the formInput state
    setFormInput((prevInput) => ({
      ...prevInput,
      links: updatedLinks,
    }));
  };

  const handleRemoveLink = (indexToRemove) => {
    setFormInput((prevInput) => {
      const updatedLinks = prevInput?.links?.filter(
        (_, index) => index !== indexToRemove,
      );
      return {
        ...prevInput,
        links: updatedLinks,
      };
    });
  };

  const handleVideoFileChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const learnVideo = await tusUtils.createUploadData(file, onTusResume, {
          Authorization: `Bearer ${auth.idToken}`,
        });
        setFileTus.setLearnVideoTus(learnVideo);
      } catch (e) {
        console.error(e);
      }

      setSelectedVideo(file);
      setPreviewVideoUrl(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        learn_video: file,
      }));
      setIsVideo(file.type.includes('video'));

      setTimeout(() => {
        setIsVideoPlayerReloaded(true);
        setVideoPlayerId(uuid());
      }, 1000);
    }

    setIsLoading(false);
  };

  const handleDeleteVideo = async () => {
    setSelectedVideo(null);
    setPreviewVideoUrl('');
    setIsVideo(false);
    setFileTus.setLearnVideoTus(null);
    setFormInput((prevFormInput) => {
      const updatedFormInput = { ...prevFormInput };
      delete updatedFormInput.learn_video;
      delete updatedFormInput.learning_file;
      return updatedFormInput;
    });
  };

  const handlePdfChange = async (ev) => {
    setIsLoading(true);

    const file = ev.target.files[0];

    if (file) {
      try {
        const slidePdf = await tusUtils.createUploadData(file, onTusResume, {
          Authorization: `Bearer ${auth.idToken}`,
        });
        setFileTus.setSlidePdfTus(slidePdf);
      } catch (e) {
        console.error(e);
      }

      setSelectedSlide(file);
      setFormInput((prevInput) => ({
        ...prevInput,
        slide: file,
      }));
    }

    setIsLoading(false);
  };

  const extractFileName = (url) => {
    try {
      const parsedUrl = new URL(url);
      const pathSegments = parsedUrl.pathname.split('/');
      const fileName = pathSegments[pathSegments.length - 1];
      // const shortFileName = fileName.slice(-10);
      return fileName || 'No file chosen';
    } catch (error) {
      console.error(url);
      return '';
    }
  };

  React.useEffect(() => {
    if (setIsLoading) {
      if (!auth?.idToken) {
        setIsLoading(true);
      } else if (auth?.idToken) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setIsLoading]);

  React.useEffect(() => {
    if (formInput?.learning_file) {
      setTimeout(() => {
        setVideoPlayerId(uuid());
      }, 1000);
    }
  }, [formInput?.learning_file]);

  React.useEffect(() => {
    if (videoPlayerRef?.current && !isReloadVideoPlayer) {
      if (!isVideoPlayerReloaded) {
        setIsVideoPlayerReloaded(true);
        setIsReloadVideoPlayer(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPlayerRef?.current, isReloadVideoPlayer]);

  React.useEffect(() => {
    if (isReloadVideoPlayer) {
      setVideoPlayerId(uuid());
      setIsReloadVideoPlayer(false);
    }
  }, [isReloadVideoPlayer]);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  
  function formatEventTypes(eventTypes) {
    if (!eventTypes || !Array.isArray(eventTypes) || eventTypes.length === 0)
      return 'No role';

    const formattedEventTypes = eventTypes
      .map((eventType) =>
        eventType
          .split(/[._]/)
          .map((word) => capitalize(word))
          .join('-'),
      )
      .join(', ');

    return formattedEventTypes;
  }

  return (
    <>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-3 font-bold leading-6 text-gray-600'>
            Add Link
          </label>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='Enter Link'
              id='eventLink'
              type='text'
              className='font-poppins  block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                formInput ? formInput?.past_link || '' : update.past_link || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, past_link: e.target.value })
                  : setUpdate({ ...update, past_link: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-2 font-bold leading-6 text-gray-600'>
            Add Video
          </label>
          <div>
            <input
              type='file'
              accept='video/*'
              onChange={handleVideoFileChange}
              style={{ display: 'none' }}
              id='upload-file-input'
            />
            <label htmlFor='upload-file-input'>
              <Button
                sx={{
                  justifyContent: 'left',
                  width: '545px',
                  height: '48px',
                  textTransform: 'none',
                }}
                variant='outlined'
                component='span'
                className='w-full border border-gray-300 bg-gray-100 font-medium text-gray-400'
              >
                {videoAttachment}
              </Button>
            </label>
            <div className='relative mt-8 w-[200px]'>
              {/* player */}
              <ReactPlayer
                key={videoPlayerId}
                ref={(player) => {
                  videoPlayerRef.current = player;
                }}
                url={videoUrl}
                controls
                width='200px'
                height='auto'
                style={{
                  display: showVideoPlayer ? 'block' : 'none',
                  marginTop: 20,
                }}
              />

              {/* image preview */}
              <img
                src={previewVideoUrl}
                alt='Preview'
                style={{
                  display:
                    !showVideoPlayer && previewVideoUrl ? 'block' : 'none',
                  width: '200px',
                  marginTop: 20,
                }}
              />

              {/* delete icon */}
              <div
                className='group absolute top-1 right-1 py-2 pt-1'
                style={{
                  display: videoUrl || previewVideoUrl ? 'block' : 'none',
                }}
              >
                <div className='absolute -right-1/2 -top-1/2 h-10 w-10 translate-x-1/2 -translate-y-1/2 transform rounded-full bg-gray-300 transition-all duration-300 group-hover:bg-white group-hover:shadow-lg'>
                  <Icon
                    onClick={handleDeleteVideo}
                    icon='clarity:remove-solid'
                    color='#FF5555'
                    width='28'
                    className='h-10 w-10 scale-100 transform rounded-full transition-transform group-hover:scale-110'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-1 font-bold leading-6 text-gray-600'>
            Add Video Description
          </label>
          <div className='mt-2 font-medium'>
            <textarea
              placeholder='Enter Video Description'
              rows='4'
              className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={
                formInput ? formInput?.past_desc || '' : update?.past_desc || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, past_desc: e.target.value })
                  : setUpdate({ ...update, past_desc: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-1 font-bold leading-6 text-gray-600'>
            Selected Role
          </label>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='Selected role'
              id='role'
              type='text'
              className='font-poppins  block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={formatEventTypes(formInput?.roles || 'No role')}
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, roles: e.target.value })
                  : setUpdate({ ...update, roles: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-3 px-5 py-2'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-3 font-bold leading-6 text-gray-600'>
            Add Link/Slide
          </label>
        </div>

        <div className='py-2'>
          <RadioGroup
            value={linkType}
            onChange={handleLinkTypeChange}
            row
            aria-label='link-type'
            name='link-type'
          >
            <FormControlLabel value='link' control={<Radio />} label='Link' />
            <FormControlLabel
              value='slides'
              control={<Radio />}
              label='Slide'
            />
          </RadioGroup>
          <div className='font-poppins mt-2 py-2 font-medium'>
            {linkType === 'link' ? (
              <>
                <div className='flex justify-between'>
                  <Button
                    sx={{ variant: 'text', textTransform: 'none' }}
                    className='px-0 py-2 text-cyan-600 underline'
                    onClick={handleAddLink}
                  >
                    Add new
                  </Button>
                </div>
                <div className='py-2'>
                  {formInput?.links?.map((link, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className='flex items-center justify-between py-2'
                    >
                      <input
                        href={link}
                        className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-1 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                        value={link}
                        onChange={(e) => handleLinkChange(e, index)}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Icon
                        onClick={() => handleRemoveLink(index)}
                        icon='clarity:remove-solid'
                        color='#FF5555'
                        width='30'
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div>
                <input
                  type='file'
                  id='attachment'
                  accept='.pdf,.ppt,.pptx'
                  onChange={handlePdfChange}
                />
                <div className='py-4 text-xs'>
                  <Button
                    variant='text'
                    className='justify-left flex text-xs font-bold text-cyan-600 underline'
                    sx={{ textTransform: 'none', padding: 0 }}
                    onClick={handleOpenSlide(formInput?.source_slide || '')}
                  >
                    {selectedSlide?.name ||
                      extractFileName(formInput?.source_slide || '')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <div className='flex justify-between'>
            <label className='text-md block py-2 font-bold leading-6 text-gray-600'>
              Add Event Questionnare
            </label>
            {/* <div>
              <Button
                variant="contained"
                sx={{ textTransform: 'none' }}
                component="span"
                className="bg-cyan-600 from-cyan-500 to-cyan-600 font-bold"
              >
                Create Questionnare
              </Button>
            </div> */}
          </div>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='Enter Link'
              id='question'
              type='text'
              className='font-poppins  block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                formInput
                  ? formInput?.link_question || ''
                  : update.link_question || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({
                      ...formInput,
                      link_question: e.target.value,
                    })
                  : setUpdate({ ...update, link_question: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

/*
{
  <input
                  placeholder='Enter link'
                  id='link'
                  type='text'
                  className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-1 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  value={newLink}
                   onChange={(e, index) =>
                     setFormInput({
                       ...formInput,
                       links: [e.target.value, formInput?.links?.[index] || ''],
                     })
                   }
                   value={formInput?.links?.[0] || ''}
                   onChange={(e) => setNewLink(e.target.value)}
                  disabled={linkType !== 'link'}
                /> 

 &nbsp; &nbsp; &nbsp;
                <Icon
                  icon='carbon:add-filled'
                  color='#1788b9'
                  width='31'
                  className='cursor-pointer py-2'
                  onClick={handleAddLink}
                /> 
} */
