// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin/user-activities`;

export default function AppUserActivities() {
  const { auth } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [activities, setActivities] = useState([]);
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };
  const handleClick = (id) => {
    navigate(`/view-listing-detail/${id}`);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(`${url}?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setActivities(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error', error.message);
        setLoading(false);
      });
  }, [auth]);

  const filteredList = activities.filter((row) => {
    const lowerCase = searchQuery.toLowerCase();
    return (
      (row.clinic && row.clinic.toLowerCase().includes(lowerCase)) ||
      (row.date && row.date.toLowerCase().includes(lowerCase)) ||
      (row.ID && row.ID.toLowerCase().includes(lowerCase))
    );
  });

  return (
    <div>
      <Box sx={{ width: '100%' }} className='flex w-2/3 justify-end px-10 py-8'>
        <input
          placeholder='Search by clinic, ID, or date'
          value={searchQuery}
          onChange={handleSearch}
          className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-xs text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:leading-6'
          style={{ height: '20px', width: '250px' }}
        />
      </Box>
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <span className='flex w-2/3 flex-col items-start px-4 pr-20'>
          <TableContainer
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
            className='rounded-xl'
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '250px' }}
                  >
                    Clinic
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '150px' }}
                  >
                    Job ID
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '220px' }}
                  >
                    Event
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '150px' }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '120px' }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '100px' }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredList) && filteredList.length > 0 ? (
                  filteredList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className='border-white text-sm font-bold text-gray-500'
                        >
                          {row.clinic}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0, margin: 2 }}
                        >
                          {row.ID}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row.event}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row?.date && row.date
                            ? `${new Date(row.date).toLocaleDateString(
                                'en-AU',
                                format_date,
                              )}`
                            : 'No date'}
                        </TableCell>

                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          <Button
                            sx={{
                              textTransform: 'none',
                              width: '100px',
                              height: '35px',
                            }}
                            className='bg-gray-100 text-xs font-bold text-cyan-600'
                            variant='contained'
                            // onClick={() => handleClick(row?.ID)}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No data
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={activities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
      )}
    </div>
  );
}
