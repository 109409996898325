// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ButtonAction from '@src/components/buttons/action.button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin/hub-banners`;

export default function HubBannerPage() {
  const [banner, setBanners] = useState([]);
  const { auth } = useAuth();
  const navigate = useNavigate();
  // const format_date = { year: 'numeric', month: 'long', day: 'numeric' };
  const [refresh, setRefresh] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  // const [pausedIds, setPausedIds] = useState([]);

  const [loading, setLoading] = useState();

  const handleUpdateEvent = (adsId) => {
    navigate(`/update-hub-banner/${adsId}`);
  };

  const [status, setStatus] = useState(['coming_soon', 'ongoing', 'expired']);

  useEffect(() => {
    if (auth.idToken) {
      setLoading(true);
      const statusQueries = status?.map((s) => `ads_status=${s}`).join('&');

      axios
        .get(`${url}?${statusQueries}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setBanners(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error:', error.message);
          setLoading(false);
        });
    }
  }, [status, auth, deleteStatus]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/mobile-banners/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'My-Custom-Header': 'foobar',
          },
        },
      );
      console.log(response);
      setDeleteStatus('Delete successful');
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const formatOptions = {
    timeZone: 'Asia/Singapore',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-AU', formatOptions);
  };

  const formatTime = (time) => {
    return new Date(time).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Singapore',
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatTypes(types) {
    if (!types) return 'No role';

    return types
      .split(/[._]/)
      .map((word) => capitalize(word))
      .join('-');
  }
  return (
    <div>
      <Box
        sx={{ width: '100%', height: '60px' }}
        className='flex w-3/4 justify-end'
      >
        <ButtonAction
          onNavigate={() => {
            navigate('/add-hub-banner');
          }}
          // route="/create-new-event"
          label='Add New Banner'
        />
      </Box>
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <span className='flex w-3/4 flex-col items-start pr-20'>
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingRight: '12px',
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
            }}
          >
            <Table
              sx={{
                minWidth: 350,
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '300px', border: 'gray' }}
                  >
                    Banner Title
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '170px', border: 'gray' }}
                  >
                    Start Date/Time
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '150px', border: 'gray' }}
                  >
                    End Date/Time
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '100px', border: 'gray' }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '100px', border: 'gray' }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '220px', border: 'gray' }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(banner) && banner.length > 0 ? (
                  banner
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row._id}
                        hover
                        tabIndex={-1}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <div className='flex text-sm font-bold text-gray-500'>
                            <img
                              className='rounded-xl py-2'
                              src={row?.file[0]?.file?.path}
                              alt=''
                              width='59px'
                              height='32px'
                            />
                            <div className='px-4 py-3'>{row?.ads_title}</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {formatDate(row?.start_date)} <br />
                          {formatTime(row?.start_date)}
                        </TableCell>
                        <TableCell
                          className='text-sm font-bold text-gray-500'
                          style={{ padding: 4 }}
                        >
                          {formatDate(row?.end_date)} <br />
                          {formatTime(row?.end_date)}
                        </TableCell>
                        <TableCell
                          className='text-sm font-bold text-gray-500'
                          style={{ padding: 4 }}
                        >
                          <div>{formatTypes(row?.ads_type)}</div>
                        </TableCell>
                        <TableCell style={{ padding: 8 }}>
                          <div className='text-sm font-bold text-[#a020f0]'>
                            {row?.ads_status
                              ? row.ads_status
                                  .replace(/_/g, ' ')
                                  .split(' ')
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1),
                                  )
                                  .join(' ')
                              : ''}
                          </div>
                        </TableCell>
                        <TableCell
                          className=' text-sm font-bold'
                          style={{ padding: 8 }}
                        >
                          <>
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-cyan-600'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() => handleUpdateEvent(row?._id)}
                            >
                              View
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-red-500'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() =>
                                showDeleteConfirmationDialog(row?._id)
                              }
                            >
                              Delete
                            </Button>
                          </>
                          {showDeleteConfirmation && (
                            <Dialog
                              open={showDeleteConfirmation}
                              onClose={handleConfirmationDialogClose}
                            >
                              <DialogTitle>Confirm Delete</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to delete this item?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleConfirmationDialogClose}
                                  color='primary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmationDialogConfirm}
                                  color='error'
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No banner listed
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={banner.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
      )}
    </div>
  );
}
