/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

const options = [
  { value: 'Wound Dressing Management', label: 'Wound Dressing Management' },
  { value: 'Injections (Intramuscular)', label: 'Injections (Intramuscular)' },
  { value: 'Injections (Intravenous)', label: 'Injections (Intravenous)' },
  { value: 'Injections (Subcutaneous)', label: 'Injections (Subcutaneous)' },
  {
    value: 'Venepuncture (Blood-taking)',
    label: 'Venepuncture (Blood-taking)',
  },
  {
    value: 'Removal of stitches/staples',
    label: 'Removal of stitches/staples',
  },
  { value: 'Male catherization', label: 'Male catherization' },
  { value: 'Female catheterization', label: 'Female catheterization' },
  { value: 'Stoma care', label: 'Stoma care' },

  { value: 'NG Tube insertion', label: 'NG Tube insertion' },
  {
    value: 'Tracheostomy care and suctioning',
    label: 'Tracheostomy care and suctioning',
  },
  { value: 'Syringe pump', label: 'Syringe pump' },
  { value: 'BIPAP machine', label: 'BIPAP machine' },
  { value: 'CPAP machine', label: 'CPAP machine' },
  { value: 'Maintain CVC lines', label: 'Maintain CVC lines' },
  { value: 'Chest drainage', label: 'Chest drainage' },
  { value: 'Peritoneal dialysis', label: 'Peritoneal dialysis' },
  { value: 'PEG tube', label: 'PEG tube' },
  { value: 'VAC machine', label: 'VAC machine' },
  { value: 'Feeding pump', label: 'Feeding pump' },
];

function CheckboxGroup({ selectedValues = [], onChange }) {
  return (
    <div className='text-md py-2 px-2 text-gray-600'>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                className='py-1'
                style={{ color: '#1788b9' }}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => onChange(option.value, e.target.checked)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default function NurseSkill({ getSkill, setGetSkill }) {
  const { auth } = useAuth();
  const { userId } = useParams();

  const handleChangeSkill = async () => {
    if (auth?.idToken) {
      try {
        await axios.put(
          `${url}/admin/skill-items/${userId}`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        );

        toast.success('Skills has been updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      } catch (error) {
        console.log('Error', error.message);
        toast.error('Failed to update skill', {
          position: 'top-center',
          autoClose: 2000,
        });
      }
    }
  };

  const [openChangeModal, setOpenChangeModal] = useState(false);
  const showChangeModal = () => {
    setOpenChangeModal(true);
  };

  const handleType = (value, isChecked) => {
    if (getSkill) {
      const skills = Array.isArray(getSkill.item) ? getSkill.item : [];
      const updatedSelection = isChecked
        ? [...skills, value]
        : skills.filter((selected) => selected !== value);

      setGetSkill({ ...getSkill, item: updatedSelection });
    } else {
      console.error('getSkill is not defined');
    }
  };
  return (
    <div>
      <span className='flex flex-col items-start px-4 py-4'>
        <CheckboxGroup
          selectedValues={getSkill?.item || []}
          onChange={handleType}
        />

        <Dialog
          open={openChangeModal}
          onClose={() => setOpenChangeModal(false)}
        >
          <DialogTitle>Confirm Change</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to change this skill?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenChangeModal(false)}
              color='secondary'
              sx={{ textTransform: 'none' }}
            >
              Done
            </Button>
            <Button
              onClick={() => handleChangeSkill()}
              color='primary'
              sx={{ textTransform: 'none' }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    </div>
  );
}
