import '@fontsource/poppins';
// import { Icon } from '@iconify/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TimePicker from '@src/components/picker/TimePicker';
import dayjs from 'dayjs';

import React from 'react';

export default function EventDateForm({ formInput, setFormInput }) {
  const [open, setOpen] = React.useState(false);
  // const [addDate, setAddDate] = React.useState();

  // function handleAddDate(event) {
  //   setAddDate({ ...formInput, sec_date: event.target.addDate });
  // }

  const setEventDate = (newValue) => {
    setFormInput(newValue);
  };

  const handleDateChange = (newDate) => {
    setEventDate({
      ...formInput,
      date: newDate ? newDate.format('YYYY-MM-DDTHH:mm') : '',
    });
    setOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {formInput ? (
        <div className='flex'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='px-6 py-4'>
              <DatePicker
                reduceAnimations
                open={open}
                sx={{ width: '200px' }}
                // label='Event Date'
                // className='bg-gray-100'
                format='LL'
                value={
                  formInput?.event_date || formInput?.date
                    ? dayjs(
                        formInput?.event_date || formInput?.date,
                        'YYYY-MM-DDTHH:mm',
                      )
                    : null
                }
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    onClick: () => setOpen(true),
                  },
                }}
              />
            </div>
            <br />
            <div className='flex justify-between'>
              <div>
                <span className='font-bold text-gray-600'>From:</span>
                <TimePicker
                  value={
                    formInput.event_from
                      ? dayjs(formInput.event_from, 'YYYY-MM-DDTHH:mm')
                      : formInput.from
                      ? dayjs(formInput.from, 'HH:mm')
                      : dayjs().startOf('day')
                  }
                  onChange={(from) => {
                    setEventDate({
                      ...formInput,
                      from: from ? from.format('HH:mm') : '',
                    });
                  }}
                />
              </div>
              <div>
                <span className='font-bold text-gray-600'>To:</span>
                <TimePicker
                  value={
                    formInput.event_to
                      ? dayjs(formInput.event_to, 'YYYY-MM-DDTHH:mm')
                      : formInput.to
                      ? dayjs(formInput.to, 'HH:mm')
                      : dayjs().startOf('day')
                  }
                  onChange={(to) =>
                    setEventDate({
                      ...formInput,
                      to: to ? to.format('HH:mm') : '',
                    })
                  }
                />
              </div>
            </div>
          </LocalizationProvider>
          {/* <div className='py-4'>
            <Icon
              // eslint-disable-next-line react/jsx-no-bind
              onClick={handleAddDate}
              icon='zondicons:add-solid'
              color='#1788b9'
              width='30'
            />
          </div> */}
        </div>
      ) : null}
    </>
  );
}
