import { cloneElement } from 'react';

export default function ContextProvidersHelper({ providers, children }) {
  const renderProvider = (prov, child) => {
    const [provider, ...theRestProviders] = prov;

    if (provider) {
      return cloneElement(
        provider,
        null,
        renderProvider(theRestProviders, child),
      );
    }
    return children;
  };

  return renderProvider(providers, children);
}
