import '@fontsource/poppins';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { openLinkInPopUpWindow } from '@utils';
import dayjs from 'dayjs';

import React from 'react';

export default React.memo(function BusinessDocuments({
  createOrg,
  setCreateOrg,
  // updateOrg,
  // setUpdateOrg,
}) {
  const [selectedPdf, setSelectedPdf] = React.useState(null);
  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedPdf(file.name);
      setCreateOrg((prevInput) => ({
        ...prevInput,
        moh_licence: file,
      }));
    }
  };

  const [selectedPdfs, setSelectedPdfs] = React.useState(null);
  const handlePdfChanges = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedPdfs(file.name);
      setCreateOrg((prevInput) => ({
        ...prevInput,
        acra: file,
      }));
    }
  };

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);

  const extractFileName = (url) => {
    try {
      const parsedUrl = new URL(url);
      const pathSegments = parsedUrl.pathname.split('/');
      const fileName = pathSegments[pathSegments.length - 1];
      // const shortFileName = fileName.slice(-10);
      return fileName || 'No file chosen';
    } catch (error) {
      console.error(url);
      return '';
    }
  };

  const handleOpenSlide = (file_name) => async () =>
    openLinkInPopUpWindow(file_name);

  return (
    <>
      <div className='col-span-full px-6'>
        <label className='block text-sm font-medium leading-6 text-gray-600'>
          MOH Licence*
        </label>
        <div className='mt-2'>
          <input
            type='file'
            id='moh_licence'
            accept='.pdf'
            onChange={handlePdfChange}
            className='font-poppins block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
          />
          <div className='py-3 px-2'>
            <Button
              variant='text'
              className='justify-left flex text-xs font-bold text-cyan-600 underline'
              sx={{ textTransform: 'none', padding: 0 }}
              onClick={handleOpenSlide(
                createOrg?.documents?.moh_licence?.file_name || '',
              )}
            >
              {extractFileName(createOrg?.documents?.moh_licence?.file_name)}
            </Button>
          </div>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-6 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Valid from*
          </label>
          <div className='mt-2'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='px-2 text-xs'
                  reduceAnimations
                  open={open}
                  sx={{ width: 'auto' }}
                  format='YYYY-MM-DD'
                  value={
                    createOrg?.documents?.moh_licence?.valid_from
                      ? dayjs(
                          createOrg?.documents?.moh_licence?.valid_from,
                          'YYYY-MM-DD',
                        )
                      : null
                  }
                  onChange={(from) => {
                    setCreateOrg({
                      ...createOrg,
                      from: from ? from.format('YYYY-MM-DD') : '',
                    });
                    setOpen(false);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      onClick: () => setOpen(true),
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Valid to*
          </label>
          <div className='mt-2'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='px-2 text-xs'
                  reduceAnimations
                  open={opens}
                  sx={{ width: 'auto' }}
                  format='YYYY-MM-DD'
                  value={
                    createOrg?.documents?.moh_licence?.valid_to
                      ? dayjs(
                          createOrg?.documents?.moh_licence?.valid_to,
                          'YYYY-MM-DD',
                        )
                      : null
                  }
                  onChange={(to) => {
                    setCreateOrg({
                      ...createOrg,
                      to: to ? to.format('YYYY-MM-DD') : '',
                    });
                    setOpens(false);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      onClick: () => setOpens(true),
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>
      </div>

      <div className='col-span-full px-6 py-6'>
        <label className='block text-sm font-medium leading-6 text-gray-600'>
          Business ACRA*
        </label>
        <div className='mt-2'>
          <input
            type='file'
            id='acra'
            accept='.pdf'
            onChange={handlePdfChanges}
            className='font-poppins block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
          />

          <div className='py-3 px-2'>
            <Button
              variant='text'
              className='justify-left flex text-xs font-bold text-cyan-600 underline'
              sx={{ textTransform: 'none', padding: 0 }}
              onClick={handleOpenSlide(
                createOrg?.documents?.business_acra?.file_name || '',
              )}
            >
              {extractFileName(createOrg?.documents?.business_acra?.file_name)}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});
