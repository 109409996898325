/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@src/components/commons/containerPage.common';
import LabTabs from '@src/components/commons/tabs.common';

import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppUserNurse from '../nurse/appUserNurse';
import AppUserAnalytic from './appUserAnalytics';
import AppUserCLA from './appUserCLA';
import AppUserDoctor from './appUserDoctor';
import ExpiredPracticingCert from './expiredPracticingCert';
import TableAccountUnverified from './tableAccountUnverified';

export default function AppUserPage() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const menuAppUsers = [
    {
      idx: 0,
      title: 'DOCTORS',
      component: (
        <div>
          <AppUserDoctor />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'CLINIC ASSISTANTS',
      component: (
        <div>
          <AppUserCLA />
        </div>
      ),
    },
    {
      idx: 2,
      title: 'NURSES',
      component: (
        <div>
          <AppUserNurse />
        </div>
      ),
    },
    {
      idx: 3,
      title: 'UNFINISHED ACCOUNT',
      component: (
        <div>
          <TableAccountUnverified />
        </div>
      ),
    },
    {
      idx: 4,
      title: 'PC INFORMATION',
      component: (
        <div>
          <ExpiredPracticingCert />
        </div>
      ),
    },
    // {
    //   idx: 5,
    //   title: 'APP OVERVIEW',
    //   component: (
    //     <div>
    //       <AppUserAnalytic />
    //     </div>
    //   ),
    // },
  ];

  const [anchorElAddNew, setAnchorElAddNew] = React.useState(null);
  const openAddNew = Boolean(anchorElAddNew);
  const handelAddNew = {
    click: (ev) => setAnchorElAddNew(ev.currentTarget),
    close: () => setAnchorElAddNew(null),
    doctor: () => navigate('/create-account-doctor'),
    cla: () => navigate('/create-account-cla'),
    nurse: () => navigate('/create-account-nurse'),
  };  

  return (
    <Container
      pathUrl={path}
      actionRight={
        <div className='flex'>
          <Button
            onClick={handelAddNew.click}
            className='h-[35px] w-[200px] rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-0 px-4 text-xs font-semibold normal-case text-white shadow hover:shadow-cyan-500'
            id='add-new-button'
            aria-controls={openAddNew ? 'add-new-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openAddNew ? 'true' : undefined}
            disableElevation
          >
            Create New Account
          </Button>
          <Menu
            id='add-new'
            MenuListProps={{
              'aria-labelledby': 'add-new-button',
            }}
            anchorEl={anchorElAddNew}
            open={openAddNew}
            onClose={handelAddNew.close}
          >
            <MenuItem onClick={handelAddNew.doctor} disableRipple>
              Doctor
            </MenuItem>
            <MenuItem onClick={handelAddNew.cla} disableRipple>
              CLA
            </MenuItem>
            <MenuItem onClick={handelAddNew.nurse} disableRipple>
              Nurse
            </MenuItem>
          </Menu>
        </div>
      }
    >
      <div className='py-8'>
        <LabTabs menus={menuAppUsers} />
      </div>
    </Container>
  );
}
