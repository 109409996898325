import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ButtonAction from '@src/components/buttons/action.button';
import Container from '@src/components/commons/containerPage.common';
import AddCountry from '@src/components/modals/addCountry';
import EditCountry from '@src/components/modals/editCountry';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data/country`;

export default function CountryCode() {
  const { auth } = useAuth();

  const [loading, setLoading] = useState();
  const [country, setCountry] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setCountry(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [deleteStatus]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredList = country.filter((row) => {
    return (
      row?.country_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row?.code_alfa_2.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
      });

      if (response.status === 200) {
        console.log(response);
        setDeleteStatus('Delete successful');
        setShowDeleteSuccess(true);
        toast.success('Item deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      } else {
        console.error('Delete operation failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };

  const [openModals, setOpenModals] = useState(false);
  const showModals = (itemId) => {
    setOpenModals(itemId);
  };

  return (
    <div>
      <div className='py-4'>
        <Box
          sx={{ width: '100%' }}
          className='flex w-3/4 justify-between px-3 py-2'
        >
          <input
            placeholder='Search by name/code alfa'
            value={searchQuery}
            onChange={handleSearch}
            className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            style={{ height: '20px', width: '200px' }}
          />
          <ButtonAction
            onNavigate={() => {
              showModal();
            }}
            label='Add New Item'
          />
        </Box>
        {loading ? (
          <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
        ) : (
          <span className='flex w-3/4 flex-col items-start py-4 pr-20'>
            <TableContainer
              className='rounded-xl'
              component={Paper}
              style={{
                width: '100%',
                paddingBottom: '12px',
                paddingRight: '12px',
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
            >
              <Table
                sx={{
                  minWidth: 350,
                  backgroundColor: '#ffffff',
                  borderColor: 'ffffff',
                }}
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{
                        width: '200px',
                        border: 'gray',
                        paddingLeft: 20,
                      }}
                    >
                      Country Name
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '110px', border: 'gray' }}
                    >
                      Code Alfa 2
                    </TableCell>

                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '110px', border: 'gray' }}
                    >
                      Code Alfa 3
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '110px', border: 'gray' }}
                    >
                      Code Numeric
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '200px', border: 'gray' }}
                    >
                      Label
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '100px', border: 'gray' }}
                    >
                      Note
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '170px', border: 'gray' }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(filteredList) && filteredList.length > 0 ? (
                    filteredList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => (
                        <TableRow
                          key={row?._id}
                          hover
                          tabIndex={-1}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            style={{ margin: 2, paddingLeft: 20 }}
                            className='text-sm font-bold text-gray-500'
                          >
                            {row?.country_name}
                          </TableCell>
                          <TableCell
                            className='text-sm font-bold text-gray-500'
                            style={{ padding: 4 }}
                          >
                            {row?.code_alfa_2 || '-'}
                          </TableCell>

                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.code_alfa_3 || '-'}
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.code_numeric || '-'}
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.label || '-'}
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.note || '-'}
                          </TableCell>
                          <TableCell style={{ padding: 4, margin: 2 }}>
                            <div className='flex justify-start '>
                              <Button
                                variant='contained'
                                className='text-xs font-bold text-purple-600'
                                sx={{
                                  textTransform: 'none',
                                  backgroundColor: '#FAF9FC',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() => showModals(row?._id)}
                              >
                                Edit
                              </Button>
                              &nbsp; &nbsp;
                              <Button
                                variant='contained'
                                className='text-xs font-bold text-red-500'
                                sx={{
                                  textTransform: 'none',
                                  backgroundColor: '#FAF9FC',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() =>
                                  showDeleteConfirmationDialog(row?._id)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                            {showDeleteConfirmation && (
                              <Dialog
                                open={showDeleteConfirmation}
                                onClose={handleConfirmationDialogClose}
                              >
                                <DialogTitle>Confirm Delete</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to delete this item?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleConfirmationDialogClose}
                                    color='primary'
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={handleConfirmationDialogConfirm}
                                    color='error'
                                  >
                                    Delete
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        className='border border-white'
                        colSpan={6}
                        align='center'
                      >
                        <div className='font-poppins py-6 text-gray-500'>
                          No data
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box
                className='flex justify-end py-3'
                sx={{ width: '100%', fontFamily: 'Poppins' }}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  component='div'
                  count={country.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </TableContainer>
          </span>
        )}
      </div>

      <AddCountry
        setOpenModal={setOpenModal}
        showModal={showModal}
        openModal={openModal}
        fetchData={fetchData}
      />

      <EditCountry
        setOpenModals={setOpenModals}
        showModals={showModals}
        openModals={openModals}
        fetchData={fetchData}
      />
      <br />
      <br />
      <br />
    </div>
  );
}
