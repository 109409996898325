import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import React from 'react';

export const TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';
export const ERROR = 'error';

function Toast({
  setOpen,
  open = false,
  onClose = () => {},
  severity = 'success',
  text = 'Success.',
  vertical = 'top',
  horizontal = 'center',
  timeout = null,
  callback = () => {},
}) {
  React.useEffect(() => {
    let to;
    if (timeout && open && setOpen && callback) {
      to = setTimeout(() => {
        callback();
        setOpen(false);
      }, timeout);
    }

    return () => {
      if (to) clearTimeout(to);
    };
  }, [timeout, open, setOpen, callback]);

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onClose}
      key={`${vertical}${horizontal}`}
      sx={{ top: 'auto' }}
    >
      <Alert
        severity={severity}
        onClose={onClose}
        sx={{ borderRadius: '12px' }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
