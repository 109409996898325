import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import { useEffect } from 'react';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/jobs/group/past-direct-listing/create`;

export default function CreateNewSlotNurse() {
  const { id } = useParams();
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [createSlot, setCreateSlot] = React.useState({
    clinicId: '',
    shared_to: '',
    sharedTo: '',
    profession: '',
    price: '',
    scope: '',
    job_desc: '',
    listingType: '',
    nursing: {
      slotType: '',
      noOfPax: '',
      wagePerHour: '',
      practicingScope: '',
      selectedDates: '',
      date: { from: '', to: '' },
      duration: { from: '', to: '' },
      isMultipleDate: '',
    },
    breaks: [
      {
        break_start: '',
        break_finish: '',
      },
    ],
  });

  useEffect(() => {
    if (id) {
      setCreateSlot((prevSlot) => ({
        ...prevSlot,
        clinicId: id,
      }));
    }
  }, [id]);

  function createNewSlot() {
    const formData = {
      clinicId: createSlot?.clinicId,
      shared_to: { 0: createSlot.shared_to },
      sharedTo: { 0: createSlot.shared_to },
      profession: 'nurse',
      price: createSlot.price,
      scope: createSlot.scope,
      job_description: createSlot.job_desc,
      listing_type: 'direct_listing',
      nursing: {
        slotType: 'NO_REPEAT',
        noOfPax: 1,
        wagePerHour: createSlot.price,
        practicingScope: createSlot.nursing?.practicingScope,
        selectedDates: { 0: createSlot.nursing.date?.from },
        date: {
          from: createSlot.nursing.date.from,
          to: createSlot.nursing.date.from,
        },

        duration: {
          from: createSlot.nursing.duration.from,
          to: createSlot.nursing.duration.to,
        },
        isMultipleDate: false,
      },
    };

    createSlot.breaks.forEach((b, idx) => {
      formData[`break[${idx}][break_start]`] = b.break_start;
      formData[`break[${idx}][break_finish]`] = b.break_finish;
    });

    axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Slot created successfully:', response.data);
        setCreateSlot({ ...createSlot });
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 2000,
          position: 'top-center',
        });
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate(state?.prevLocation || -1);
  };

  const handleStartDateChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setCreateSlot((prevSlot) => ({
        ...prevSlot,
        nursing: {
          ...prevSlot?.nursing,
          date: {
            ...prevSlot?.nursing?.date,
            from: newDate.format('YYYY-MM-DD'),
          },
        },
      }));
    }
  };

  const handleStartTimeChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setCreateSlot((prevSlot) => ({
        ...prevSlot,
        nursing: {
          ...prevSlot?.nursing,
          duration: {
            ...prevSlot?.nursing?.duration,
            from: newDate.format('YYYY-MM-DDTHH:mm'),
          },
        },
      }));
    }
  };

  const handleEndTimeChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setCreateSlot((prevSlot) => ({
        ...prevSlot,
        nursing: {
          ...prevSlot?.nursing,
          duration: {
            ...prevSlot?.nursing?.duration,
            to: newDate.format('YYYY-MM-DDTHH:mm'),
          },
        },
      }));
    }
  };

  const handleStartBreakChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setCreateSlot((prevSlot) => ({
        ...prevSlot,
        breaks: prevSlot.breaks.map((b, idx) =>
          idx === 0
            ? {
                ...b,
                break_start: newDate.format('YYYY-MM-DDTHH:mm'),
              }
            : b,
        ),
      }));
    }
  };

  const handleEndBreakChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setCreateSlot((prevSlot) => ({
        ...prevSlot,
        breaks: prevSlot.breaks.map((b, idx) =>
          idx === 0
            ? {
                ...b,
                break_finish: newDate.format('YYYY-MM-DDTHH:mm'),
              }
            : b,
        ),
      }));
    }
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-2/3 py-8'>
        <div className='flex justify-center'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '561px',
              backgroundColor: 'white',
            }}
          >
            <div className='font-poppins py-8 px-4 text-lg font-bold'>
              Slot Information
            </div>
            <div className='mt-3 grid grid-cols-2 gap-x-5 gap-y-6 px-5'>
              <div className='col-span-1'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Practicing Scope*
                </label>
                <div className='font-poppins mt-2 font-medium'>
                  <FormControl>
                    <Select
                      placeholder='Select'
                      style={{ height: '53px', width: '250px', fontSize: 14 }}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value) {
                          setCreateSlot((prevSlot) => ({
                            ...prevSlot,
                            nursing: {
                              ...prevSlot?.nursing,
                              practicingScope: value,
                            },
                          }));
                        }
                      }}
                      value={createSlot?.nursing?.practicingScope || ''}
                      className='rounded-md text-gray-600'
                    >
                      <MenuItem disabled value=''>
                        <em style={{ color: '#8C8C8C' }}>Select</em>
                      </MenuItem>
                      <MenuItem value='NURSE_EN'>EN</MenuItem>
                      <MenuItem value='NURSE_SN'>SN</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className='col-span-1'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Share to*
                </label>
                <div className='font-poppins mt-2 font-medium'>
                  <input
                    placeholder='Enter Locum ID'
                    id='id'
                    type='text'
                    style={{ width: '225px' }}
                    className='font-poppins focus:ring-600 block w-full rounded-md border-0 px-3 py-3.5 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
              focus:ring-1 focus:ring-inset sm:leading-6'
                    value={createSlot?.shared_to || ''}
                    onChange={(e) =>
                      setCreateSlot({
                        ...createSlot,
                        shared_to: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Working Date*
                </label>
                <div className='mt-4 flex justify-between font-medium'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '520px' }}
                      className='bg-white'
                      label='Date Start-End'
                      format='DD MMM YYYY'
                      value={
                        createSlot?.nursing?.date?.from
                          ? dayjs(createSlot?.nursing?.date?.from, 'YYYY-MM-DD')
                          : null
                      }
                      onChange={handleStartDateChange}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Working Hours*
                </label>
                <div className='mt-4 flex justify-between font-medium'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      sx={{ width: '250px' }}
                      className='bg-white'
                      label='Time Start'
                      format='hh:mm A'
                      value={
                        createSlot?.nursing?.duration?.from
                          ? dayjs(
                              createSlot?.nursing?.duration?.from,
                              'YYYY-MM-DDTHH:mm',
                            )
                          : null
                      }
                      onChange={handleStartTimeChange}
                    />

                    <TimePicker
                      sx={{ width: '250px' }}
                      className='bg-white'
                      placeholder='Time Start'
                      format='hh:mm A'
                      label='Time End'
                      value={
                        createSlot?.nursing?.duration?.to
                          ? dayjs(
                              createSlot?.nursing?.duration?.to,
                              'YYYY-MM-DDTHH:mm',
                            )
                          : null
                      }
                      onChange={handleEndTimeChange}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Break Time (optional)
                </label>
                <div className='mt-4 flex justify-between font-medium'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      sx={{ width: '250px' }}
                      className='bg-white'
                      label='Time Start'
                      format='DD MMM YYYY-hh:mm A'
                      value={
                        createSlot?.breaks?.break_start
                          ? dayjs(
                              createSlot?.breaks?.break_start,
                              'YYYY-MM-DDTHH:mm',
                            )
                          : null
                      }
                      onChange={handleStartBreakChange}
                    />

                    <DateTimePicker
                      sx={{ width: '250px' }}
                      className='bg-white'
                      placeholder='Time Start'
                      format='DD MMM YYYY-hh:mm A'
                      label='Time End'
                      value={
                        createSlot?.breaks?.break_finish
                          ? dayjs(
                              createSlot?.breaks?.break_finish,
                              'YYYY-MM-DDTHH:mm',
                            )
                          : null
                      }
                      onChange={handleEndBreakChange}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Hourly rate* (only number)
                </label>
                <div className='mt-2 flex font-medium'>
                  <input
                    id='price'
                    placeholder='Enter price (only number)'
                    style={{ width: '225px' }}
                    className='font-poppins focus:ring-600 text-md block w-full rounded-md border-0 px-3 py-3.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                    focus:ring-1 focus:ring-inset sm:leading-6'
                    value={createSlot?.price}
                    onChange={(e) =>
                      setCreateSlot({
                        ...createSlot,
                        price: e.target.value,
                      })
                    }
                  />
                  <div className='py-3 px-6'>SGD</div>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Scope*
                </label>
                <div className='mt-2'>
                  <FormControl>
                    <Select
                      id='scope'
                      className='rounded-md text-gray-600'
                      style={{ height: '53px', width: '520px', fontSize: 14 }}
                      value={createSlot?.scope || []}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (createSlot) {
                          setCreateSlot({
                            ...createSlot,
                            scope:
                              typeof value === 'string'
                                ? value.split(',')
                                : value,
                          });
                        }
                      }}
                      multiple
                      renderValue={(selected) =>
                        Array.isArray(selected) ? selected.join(', ') : ''
                      }
                    >
                      <MenuItem disabled value=''>
                        <em style={{ color: '#8C8C8C' }}>Select</em>
                      </MenuItem>
                      <MenuItem value='GP'>GP</MenuItem>
                      <MenuItem value='Aesthetics'>Aesthetics</MenuItem>
                      <MenuItem value='CHAS'>CHAS</MenuItem>
                      <MenuItem value='PHPC'>PHPC</MenuItem>
                      <MenuItem value='Covid Vaccines'>Covid Vaccines</MenuItem>
                      <MenuItem value='DWD'>DWD</MenuItem>
                      <MenuItem value='2nd Doctor'>2nd Doctor</MenuItem>
                      <MenuItem value='Low Load'>Low Load</MenuItem>
                      <MenuItem value='Moderate Load'>Moderate Load</MenuItem>
                      <MenuItem value='Heavy Load'>Heavy Load</MenuItem>
                      <MenuItem value='Specialist Clinic'>
                        Specialist Clinic
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Job Description*
                </label>
                <div className='mt-2 font-medium'>
                  <textarea
                    id='desc'
                    rows='3'
                    placeholder='Enter description'
                    style={{ width: '497px' }}
                    className='font-poppins focus:ring- -600 block w-full rounded-md border-0 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
                    focus:ring-inset sm:text-sm sm:leading-6'
                    value={createSlot?.job_desc}
                    onChange={(e) =>
                      setCreateSlot({
                        ...createSlot,
                        job_desc: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='flex justify-center px-6 py-8'>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '200px',
                  height: '48px',
                }}
                className='text-md rounded-md bg-cyan-600 font-bold text-white'
                variant='contained'
                onClick={() => createNewSlot()}
              >
                Submit
              </Button>
            </div>
          </Box>
        </div>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The past slot has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
