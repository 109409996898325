import QueueBox from '@components/queuebox/QueueBox';
import { TQueueStatus, useTaskQueue } from '@contexts/taskQueueContext';

import React from 'react';

function NotifBadge({ isNotif }) {
  return (
    <span
      className={`absolute top-1 right-1 h-3 w-3 ${
        isNotif ? 'flex' : 'hidden'
      }`}
    >
      <span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-orange-400 opacity-75' />
      <span className='relative inline-flex h-3 w-3 rounded-full bg-orange-500' />
    </span>
  );
}

export default function DrawerButton({ toggleDrawer }) {
  const {
    taskQueue: { queue },
  } = useTaskQueue();
  const [queueBoxOpen, setQueueBoxOpen] = React.useState(false);
  const queueBoxNotif = React.useMemo(() => {
    const inProgressTasks = queue.filter(
      (task) => task.getStatus() === TQueueStatus.IN_PROGRESS,
    );

    return !!inProgressTasks.length;
  }, [queue]);

  const handleClickQueue = async () => {
    setQueueBoxOpen(!queueBoxOpen);
  };

  const handleCloseQueueBox = () => {
    setQueueBoxOpen(false);
  };

  return (
    <div
      className='fixed right-0 top-0 min-h-screen w-5 bg-white'
      style={{ borderLeft: '1px solid #DFDFDF' }}
    >
      <QueueBox open={queueBoxOpen} onClose={handleCloseQueueBox} />

      <div className='relative flex flex-col items-center justify-center gap-y-4'>
        <div className='absolute top-28 -left-8'>
          <div className='flex flex-col items-center justify-center gap-y-3'>
            <button
              onClick={toggleDrawer}
              type='button'
              className='h-[43px] w-[43px] cursor-pointer rounded-full border-none bg-white shadow hover:shadow-purple-600'
            >
              <div className='mx-auto flex items-center justify-center rounded-full bg-gradient-to-t from-[#3922A3] to-[#9081D5] p-1 text-center text-2xl text-white'>
                <iconify-icon icon='material-symbols:keyboard-double-arrow-left-rounded' />
              </div>
            </button>

            {/* queue box icon */}
            <button
              onClick={handleClickQueue}
              type='button'
              className='relative h-[43px] w-[43px] cursor-pointer rounded-full border-none bg-white shadow hover:shadow-purple-600'
            >
              <NotifBadge isNotif={queueBoxNotif} />
              <div className='mx-auto flex items-center justify-center rounded-full bg-gray-200 p-1 text-center text-xl text-[#3922A3]'>
                <iconify-icon icon='ant-design:database-filled' />
              </div>
            </button>

            <button
              type='button'
              className='h-[43px] w-[43px] cursor-pointer rounded-full border-none bg-white shadow hover:shadow-purple-600'
            >
              <div className='mx-auto flex items-center justify-center rounded-full bg-gray-200 p-1 text-center text-xl text-[#3922A3]'>
                <iconify-icon icon='wpf:message' />
              </div>
            </button>

            <button
              type='button'
              className='h-[43px] w-[43px] cursor-pointer rounded-full border-none bg-white shadow hover:shadow-purple-600'
            >
              <div className='mx-auto flex items-center justify-center rounded-full bg-gray-200 p-1 text-center text-2xl text-[#3922A3]'>
                <iconify-icon icon='carbon:overflow-menu-horizontal' />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div />
    </div>
  );
}
