import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonAction from '@src/components/buttons/action.button';
import LabTabs from '@src/components/commons/tabs.common';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import OrganisationFinances from './organisationFinances';
import OrganisationVerified from './updateVerifiedOrganisation';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;

export default function UpdateVerifiedPage() {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { auth } = useAuth();
  const [createOrg, setCreateOrg] = React.useState(null);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setCreateOrg(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);
  const categories = [
    {
      idx: 0,
      title: 'Profile',
      component: (
        <div className='py-4'>
          <OrganisationVerified />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Finance',
      component: (
        <div>
          <OrganisationFinances />
        </div>
      ),
    },
  ];

  const handleBack = () => {
    navigate('/organisation');
  };
  return (
    <div>
      <div>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-8 px-8 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <Box
          sx={{ width: 'auto', fontFamily: 'Poppins' }}
          className='flex w-3/4 justify-end'
        >
          <ButtonAction
            onNavigate={() => {
              navigate(`/create-clinic/${orgId}`);
            }}
            label='Create New Clinic'
          />
          &nbsp; &nbsp; &nbsp;
          <ButtonAction
            onNavigate={() => {
              navigate(`/create-admin-organisation/${orgId}`);
            }}
            label='Create Admin for Organisation'
          />
        </Box>
      </div>
      <br />
      <div className='px-2 py-4'>
        <LabTabs menus={categories} />
      </div>
    </div>
  );
}
