import '@fontsource/poppins';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/users/create-nurse`;

export default function CreateAccountNurse() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [createNurse, setCreateNurse] = React.useState({
    name: '',
    email: '',
    password: '',
    phone_number: '',
  });

  function createNewNurse() {
    const formData = {
      name: createNurse.name,
      email: createNurse.email,
      password: createNurse.password,
      phone_number: createNurse.phone_number,
    };

    axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account created successfully:', response.data);
        setCreateNurse({ ...createNurse });
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 2000,
          position: 'top-center',
        });
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/');
  };

  const handleSaveButtonClick = () => {
    if (!createNurse?.name) {
      toast.error('Name is required.');
      return;
    }

    if (!createNurse?.phone_number) {
      toast.error('Phone number is required.');
      return;
    }
    if (!createNurse?.email) {
      toast.error('Email is required.');
      return;
    }
    if (!createNurse?.password) {
      toast.error('Password is required.');
      return;
    }
    setTimeout(() => {
      createNewNurse();
      handleOpenModal();
    }, 1000);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-2/3 py-8'>
        <div className='flex justify-center'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '531px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-8 px-4 text-lg font-bold'>
              Sign-up Information
            </div>
            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Name*
                </label>
                <div className='font-poppins mt-2 font-medium'>
                  <input
                    placeholder='Enter name'
                    id='name'
                    type='text'
                    style={{ width: '470px' }}
                    className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                    value={createNurse?.name || ''}
                    onChange={(e) =>
                      setCreateNurse({
                        ...createNurse,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Email*
                </label>
                <div className='mt-2 font-medium'>
                  <input
                    id='email'
                    placeholder='Enter email'
                    type='email'
                    style={{ width: '470px' }}
                    className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                    value={createNurse?.email}
                    onChange={(e) =>
                      setCreateNurse({
                        ...createNurse,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Phone Number*
                </label>
                <div className='font-poppins mt-2 font-medium'>
                  <input
                    placeholder='Enter phone number'
                    id='phone'
                    type='text'
                    style={{ width: '470px' }}
                    className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                    value={createNurse?.phone_number || ''}
                    onChange={(e) =>
                      setCreateNurse({
                        ...createNurse,
                        phone_number: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
              <div className='py-2 sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Password for Account*
                </label>
                <FormControl className='py-2' sx={{ width: '49ch' }}>
                  <OutlinedInput
                    className='flex-1 rounded-md border-0 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6'
                    placeholder='Enter password'
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    value={createNurse?.password || ''}
                    onChange={(e) =>
                      setCreateNurse({
                        ...createNurse,
                        password: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className='flex justify-center px-6 py-8'>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '200px',
                  height: '48px',
                }}
                className='text-md rounded-md bg-cyan-600 font-bold text-white'
                variant='contained'
                onClick={handleSaveButtonClick}
              >
                Sign up
              </Button>
            </div>
          </Box>
        </div>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Nurse account has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
