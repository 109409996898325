export default function ButtonAction({
  action = () => {},
  label = 'button',
  onNavigate,
}) {
  return (
    <div>
      <button
        onClick={onNavigate}
        type='button'
        className='cursor-pointer rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-2.5 px-6 font-semibold text-white shadow hover:shadow-cyan-500'
      >
        {label}
      </button>
    </div>
  );
}
