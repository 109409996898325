import api from '@api';

export const loginUserByEmailAndPassword = async (
  loginForm,
  axiosInstance = api,
) => {
  try {
    // const response = await axiosInstance.post('/auth/login', loginForm);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/login`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginForm),
        credentials: 'include',
      },
    );
    const content = await response.json();

    return {
      status: response.status,
      data: content || { message: content },
    };
  } catch (error) {
    return {
      data: { message: error.message },
    };
  }
};

export const getUserProfileById = async (token, id, axiosInstance = api) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // const response = await axiosInstance.get(`/admin/get-admins/${id}`, config);

    const response = await axiosInstance.get(`/admin/get-admins`, config);

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.log(error);
    return error;
  }
};
