// eslint-disable-next-line import/order
import { fileDownloader } from '@api/FileDownloader';
import List from '@components/list/List';
import { Button, CircularProgress } from '@mui/material';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import { downloadFile } from '@utils';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const downloadFileList = [
  {
    url: '/admin/users/download-doctors-list',
    name: 'loc-doctor-list',
    label: 'Download Doctor List',
  },
  {
    url: '/admin/users/download-cla-list',
    name: 'cla-clinical-assistant-list',
    label: 'Download Clinical Assistant List',
  },
  {
    url: '/admin/users/download-nurses-list',
    name: 'nurse-list',
    label: 'Download Nurse List',
  },
  {
    url: '/download-pending-users',
    name: 'unverified-user-list',
    label: 'Download Unverified User List',
  },
  {
    url: '/admin/download/confirmed',
    name: 'confirmed-list',
    label: 'Download Confirmed Slots List',
  },
  {
    url: '/admin/download/download-cancelled-slots',
    name: 'cancelled-slots-list',
    label: 'Download Cancelled Slots List',
  },
  {
    url: '/admin/download/download-deleted-slots',
    name: 'deleted-slots-list',
    label: 'Download Deleted Slots List',
  },
  {
    url: '/jobs/download/empty-slots',
    name: 'empty-slots-list',
    label: 'Download Empty Slots List',
    config: {
      params: { doc_type: 'xlsx' },
    },
  },
  {
    url: '/admin/download/organizations',
    name: 'organizations-list',
    label: 'Download Organisations List',
  },

  // {
  //   name: 'nurse-list',
  //   label: 'Download Unverified List (Nurse)',
  // },
  // {
  //   name: 'nurse-list',
  //   label: 'Download Confirmed List (Nurse)',
  // },
  // {
  //   name: 'nurse-list',
  //   label: 'Download Cancelled List (Nurse)',
  // },
  // {
  //   name: 'nurse-list',
  //   label: 'Download Deleted List (Nurse)',
  // },
  // {
  //   name: 'nurse-list',
  //   label: 'Download Empty List (Nurse)',
  // },
];

function DownloadListPage() {
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const listData = React.useMemo(() => {
    if (auth && auth.user) {
      const filterList = (item) =>
        // allow list other than these
        item.name !== 'organizations-list' ||
        item.name !== 'cancelled-slots-list' ||
        item.name !== 'deleted-slots-list' ||
        item.name !== 'empty-slots-list' ||
        // check userId for these list
        ((item.name === 'organizations-list' ||
          item.name === 'cancelled-slots-list' ||
          item.name === 'deleted-slots-list' ||
          item.name === 'empty-slots-list') &&
          auth &&
          auth.user &&
          // for skincaresg@production && locum65@staging
          (auth.user._id === '642e3b44188500986b4ea473' ||
            auth.user._id === '637c816e4b3a098e6f38065e'));

      return downloadFileList.filter(filterList);
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const [loading, setLoading] = React.useState(false);

  const handleDownload = (url, fileName, config) => async () => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [fileName]: true,
    }));

    try {
      const result = await fileDownloader(auth.idToken, url, config);
      downloadFile(result, fileName);
      toast.success('Download successful', {
        // position: 'top-center',
        autoClose: 2000,
      });
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error(`${error.message}`, {
        // position: 'top-center',
        autoClose: 2000,
      });
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [fileName]: false,
      }));
    }
  };
  return (
    <Container pathUrl={path}>
      <div style={{ padding: '16px' }}>
        {listData.map((item, index) => (
          <Button
            className='font-poppins font-bold'
            type='button'
            variant='text'
            style={{
              color: 'cyan-500',
              display: 'block',
              textTransform: 'none',
            }}
            onClick={handleDownload(item.url, item.name, item.config)}
            disabled={loading[item.name]}
          >
            {loading[item.name] && <CircularProgress size={24} />}
            {!loading[item.name] && item.label}
          </Button>
        ))}
      </div>
    </Container>
  );
}

export default DownloadListPage;
