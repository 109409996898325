import Container from '@src/components/commons/containerPage.common';

import { useLocation } from 'react-router-dom';

export default function ChatPage() {
  const path = useLocation().pathname;
  return (
    <Container pathUrl={path}>
      <div>Chat Page</div>
    </Container>
  );
}
