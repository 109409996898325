import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
// import { styled } from '@mui/material/styles';
import { capitalizeWords, uuid } from '@utils';

import React from 'react';

export default function TemplateSelection({
  handleSelectTemplate,
  templates = [],
}) {
  const [currentTypeIndex, setCurrentTypeIndex] = React.useState(-1);
  const [currentTemplateIndex, setCurrentTemplateIndex] = React.useState(-1);
  const isTypeSelected = React.useMemo(
    () => currentTypeIndex > -1,
    [currentTypeIndex],
  );
  const currentTemplateData = React.useMemo(() => {
    if (isTypeSelected && Array.isArray(templates[currentTypeIndex]?.data)) {
      return templates[currentTypeIndex].data;
    }

    return [];
  }, [isTypeSelected, currentTypeIndex, templates]);
  const [selectedIndex, setSelectedIndex] = React.useState({
    type: -1,
    template: -1,
  });
  const mappedTemplateType = React.useMemo(() => {
    return templates.map((item) => ({
      id: uuid(),
      type: item.type,
    }));
  }, [templates]);

  const handleSelectTypeIndex = async (ev) => {
    const isNN = !Number.isNaN(ev.target.value);
    const newTypeIndex = isNN ? ev.target.value : -1;

    if (newTypeIndex !== currentTypeIndex && templates[newTypeIndex]) {
      setCurrentTypeIndex(newTypeIndex);
      setCurrentTemplateIndex(-1);
    }
  };

  const handleSelectTemplateIndex = async (ev) => {
    if (currentTypeIndex > -1 && templates[currentTypeIndex]) {
      const isNN = !Number.isNaN(ev.target.value);
      const newTemplateIndex = isNN ? +ev.target.value : -1;

      if (newTemplateIndex !== currentTemplateIndex) {
        setCurrentTemplateIndex(newTemplateIndex);
      }
      return;
    }

    setCurrentTemplateIndex(-1);
  };

  React.useEffect(() => {
    if (
      currentTypeIndex !== selectedIndex.type ||
      currentTemplateIndex !== selectedIndex.template
    ) {
      const newIndex = {
        type: currentTypeIndex,
        template: currentTemplateIndex,
      };
      const newSelectedTemplate = {
        template: currentTemplateData[currentTemplateIndex],
        index: newIndex,
      };

      setSelectedIndex(newIndex);

      handleSelectTemplate(newSelectedTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTypeIndex, currentTemplateIndex, currentTemplateData]);

  return (
    <>
      <Box
        style={{
          color: 'rgba(0, 0, 0, 0.8)',
          fontSize: '1.3rem',
          marginBottom: '12px',
        }}
      >
        Select Certificate Template
      </Box>
      <Box component='form' sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FormControl sx={{ m: '8px', minWidth: 200 }}>
          <InputLabel id='select-template-type-label'>Type</InputLabel>
          <Select
            autoWidth
            labelId='select-template-type-label'
            id='templateTypeIndex'
            value={currentTypeIndex}
            onChange={handleSelectTypeIndex}
            input={<OutlinedInput label='Type' />}
          >
            <MenuItem disabled value={-1}>
              <em>{mappedTemplateType.length === 0 ? 'No item' : 'None'}</em>
            </MenuItem>
            {mappedTemplateType.length > 0
              ? mappedTemplateType.map((item, index) => (
                  <MenuItem key={item.id} value={index}>
                    <span>{capitalizeWords(item.type)}</span>
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl sx={{ m: '8px 8px 8px 16px', minWidth: 200 }}>
          <InputLabel id='select-template-id'>ID</InputLabel>
          <Select
            autoWidth
            labelId='select-template-id'
            id='templateIndex'
            value={currentTemplateIndex}
            onChange={handleSelectTemplateIndex}
            input={<OutlinedInput label='ID' />}
          >
            <MenuItem disabled value={-1}>
              <em>{currentTemplateData.length === 0 ? 'No item' : 'None'}</em>
            </MenuItem>
            {isTypeSelected && currentTemplateData.length > 0
              ? currentTemplateData.map((item, index) => (
                  <MenuItem key={item.id} value={index}>
                    <span>{item.code}</span>
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
