// eslint-disable-next-line import/no-extraneous-dependencies
import { LOGIN } from '@contexts/contextConsts';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import { useFormik } from 'formik';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertContent, setAlertContent] = useState('');
  const { dispatch: authDispatch } = useAuth();

  // const [loginPayload, setLoginPayload] = useState({
  //   email: '',
  //   password: '',
  // });

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Password Required';
    } else if (values.password.length < 8) {
      errors.password = 'Must be 8 characters';
    }

    // if (!values.confirmPassword) {
    //   errors.confirmPassword = 'Confirm Password Required';
    // } else if (values.confirmPassword !== values.password) {
    //   errors.confirmPassword = 'Passwords do not match';
    // }
    return errors;
  };

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/admin/auth/login`,
        {
          email: values.email,
          password: values.password,
        },
      );
      // console.log(response);

      if (response.data.message === 'The code has been sent to your email.') {
        toast.success('The code has been sent to your email!', {
          position: 'top-center',
          autoClose: 2000,
        });
      }
      navigate(`/verify-code?email=${values.email}`);

      if (response?.status === 200 && response.data?.Admin?._id) {
        authDispatch({
          type: LOGIN,
          payload: {
            isAuthenticated: true,
            user: response.data.Admin,
            idToken: response.data.idToken,
            refreshToken: response.data.refreshToken,
          },
        });

        setTimeout(() => {
          navigate(location.state ? location.state.from.pathname : '/');
        }, 500);

        return;
      }
      // console.log(response.data);

      if (response?.data.message === 'Invalid credentials!') {
        setAlertContent('Email or Password is wrong');
      } else {
        setAlertContent(
          `Error: ${
            response.data?.message
              ? response.data.message
              : response.data
              ? JSON.stringify(response.data)
              : JSON.stringify(response)
          }`,
        );
      }
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit,
  });

  const isBtnFormActive = !formik.isValid || formik.isSubmitting;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='flex w-full flex-col justify-center gap-y-5'
    >
      <div className='flex flex-col gap-y-2'>
        <input
          id='email'
          name='email'
          type='email'
          placeholder='E.g, name@email.com'
          className='font-poppins rounded-xl border border-gray-200 bg-[#FAFAFA] py-3 px-3 shadow-inner focus:outline-none focus:ring-0'
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.errors.email ? (
          <div className='text-sm text-red-500'>{formik.errors.email}</div>
        ) : null}
      </div>

      <div className='flex flex-col gap-y-2'>
        <input
          id='password'
          name='password'
          type='password'
          placeholder='Enter your password'
          className='font-poppins rounded-xl border border-gray-200 bg-[#FAFAFA] py-3 px-3 shadow-inner focus:outline-none focus:ring-0'
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        {formik.errors.password ? (
          <div className='text-sm text-red-500'>{formik.errors.password}</div>
        ) : null}
      </div>

      <button
        type='submit'
        disabled={isBtnFormActive}
        className={`${
          isBtnFormActive ? 'bg-blue-200' : 'bg-blue-500'
        } mt-5 w-full rounded-2xl border-transparent py-2 text-lg font-bold text-white focus:border-transparent focus:ring-0`}
      >
        Login
      </button>
    </form>
  );
}
