/* eslint-disable no-undef */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import * as React from 'react';
import { useLocation } from 'react-router-dom';

import TableAssistantDeleted from './tableAssistantDeleted';
import TableAssistantSuspended from './tableAssistantSuspended';
import TableAssistantUnverified from './tableAssistantUnverified';
import TableAssistantVerified from './tableAssistantVerified';

function TabPanel(props) {
  const { children, value, index, style = {} } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabLabel(index) {
  return {
    id: `simple-tab-${index}`,
    poppins: `simple-tabpanel-${index}`,
  };
}

export default function AppUserCLA() {
  const path = useLocation().pathname;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div>
        <Box sx={{ width: '100%' }} className=' py-6'>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            className=' border-gray-50'
          >
            <Tabs
              value={value}
              indicatorColor='white'
              onChange={handleChange}
              aria-label='basic tabs example'
              className='px-4'
            >
              <Tab
                sx={{ textTransform: 'none' }}
                label='Unverified'
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 0 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(0)}
              />
              <Box sx={{ width: '10px' }} />
              <Tab
                sx={{ textTransform: 'none' }}
                label='Verified'
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 2 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(1)}
              />
              <Box sx={{ width: '10px' }} />
              <Tab
                label='Suspended'
                sx={{ textTransform: 'none' }}
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 4 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(2)}
              />
              <Box sx={{ width: '10px' }} />
              <Tab
                label='Deleted'
                sx={{ textTransform: 'none' }}
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 6 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(3)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableAssistantUnverified />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableAssistantVerified />
          </TabPanel>

          <TabPanel value={value} index={4}>
            <TableAssistantSuspended />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <TableAssistantDeleted />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
