import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/settings/notification`;

export default function EditNotificationChannel({
  openModal,
  setOpenModal,
  fetchData,
}) {
  const { auth } = useAuth();
  const { notifId } = useParams();
  const [notif, setNotif] = useState();

  React.useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (openModal) {
          const response = await axios.get(`${url}/${openModal}`, {
            headers: {
              Authorization: `Bearer ${auth.idToken}`,
            },
          });
          setNotif(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAsync();
  }, [auth.idToken, openModal]);

  const handleEdit = () => {
    const formData = {
      channel_value: notif.channel_value,
      channel_type: notif.channel_type,
    };

    axios
      .put(`${url}/${openModal}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Updated successfully:', response);
        setNotif({ ...notif });
        fetchData();
        toast.success('Updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Edit Notification Channel</DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          {/* <div>Type: </div>
          <br />
          <select
            id='type'
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            style={{ height: '38px', width: '370px' }}
            onChange={(e) =>
              setNotif({
                ...notif,
                channel_type: e.target.value,
              })
            }
          >
            <option value=''>Select one</option>
            <option value='WA'>WhatsApp</option>
          
          </select> */}
          <br />
          <br />
          <div className='py-3'>Value: </div>
          <br />
          <input
            className='font-poppins text-md w-full rounded-md'
            style={{ width: '360px', height: '30px' }}
            value={notif?.channel_value || ''}
            onChange={(e) =>
              setNotif({
                ...notif,
                channel_value: e.target.value,
              })
            }
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => handleEdit()}
          color='primary'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
