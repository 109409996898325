import { Link, useLocation } from 'react-router-dom';

export default function MenuItem({ title = 'Menu', menus }) {
  const path = useLocation().pathname;

  return (
    <ul className='mb-4 list-none pl-0'>
      <li className='px-10'>
        <div className='mb-3 flex h-8 flex-row items-center'>
          <div className='font-semibold tracking-wide text-[#005073]'>
            {title}
          </div>
        </div>
      </li>
      {menus
        ? menus?.map((menu) => (
            <li key={menu.id}>
              <Link
                to={menu.to}
                className='group relative flex h-11 flex-row items-center pr-6 pl-10 text-gray-600 no-underline hover:bg-gray-50 hover:text-gray-800 focus:outline-none'
                style={{
                  borderLeft: `${
                    menu.to === path ? '4px solid #1DBBD6' : 'none'
                  }`,
                }}
              >
                <span className='ml-2 truncate text-[16px] font-light tracking-wide group-hover:font-normal'>
                  {menu.name}
                </span>
                {menu?.count !== 0 ? (
                  <span className='ml-auto rounded-full bg-gray-50 px-2 py-0.5 text-xs font-bold tracking-wide text-[#117DAC] group-hover:bg-white'>
                    {menu.count}
                  </span>
                ) : null}
              </Link>
            </li>
          ))
        : null}
    </ul>
  );
}
