import { uuid } from '@utils';
import PropTypes from 'prop-types';

import React from 'react';

function List({
  data,
  keyName,
  children,
  customKey,
  prefixKey = '',
  suffixKey = '',
}) {
  const generateKey = React.useMemo(
    () => (item, index) => {
      if (customKey) {
        return customKey;
      }

      if (keyName && typeof keyName === 'string') {
        return `${prefixKey}${item[keyName]}${suffixKey}`;
      }

      return `${prefixKey}${uuid()}${suffixKey}`;
    },
    [customKey, keyName, prefixKey, suffixKey],
  );

  return (
    <>
      {data.map((item, index) => {
        const key = generateKey(item, index);

        return (
          <React.Fragment key={key}>
            {children({ item, index, key })}
          </React.Fragment>
        );
      })}
    </>
  );
}

List.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  customKey: PropTypes.string,
  prefixKey: PropTypes.string,
  suffixKey: PropTypes.string,
};

List.defaultProps = {
  keyName: undefined,
  customKey: undefined,
  prefixKey: '',
  suffixKey: '',
};

export default List;
