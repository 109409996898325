import { ERROR, INFO, SUCCESS } from '@components/toast';
import { noop } from '@utils';

import { createContext, memo, useContext, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const TQueue = {
  ADD_NEW_TASK: 'add_new_taks',
  UPDATE_TASK_STATUS: 'update_task_status',
  UPDATE_TASK_PROGRESS: 'update_task_progress',
  UPDATE_TASK_DATA: 'update_task_data',
  OPEN_TOAST: 'open_toast',
  CLOSE_TOAST: 'close_toast',
  POP_UP_TOAST: 'pop_up_toast',
  QUEUE_BOX_UPDATE: 'queue_box_update',
  COMPLETE_TASK: 'complete_task',
  FAIL_TASK: 'fail_task',
};

export const TQueueStatus = {
  PENDING: 'pending',
  IN_PROGRESS: 'inProgress',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
};

export const TTaskStatus = {
  COMPLETED_TASK: 'Completed',
  FAILED_TASK: 'Failed',
};

const VQueueStatus = Object.values(TQueueStatus);

let _updatesQueueBox = () => {};

const shownToasts = new Set();
const createdTasksIds = new Set();
const taskTitles = new Map();

export function getNumberedTitle(title) {
  if (!taskTitles.has(title)) {
    taskTitles.set(title, 1);
    return title;
  }

  const count = taskTitles.get(title) + 1;
  taskTitles.set(title, count);
  return `${title} #${count}`;
}

export function prepareTaskData({
  taskId,
  title,
  uploads = [],
  uploadsStatus = [],
  onTaskComplete = noop,
  runImmediately = false,
}) {
  return {
    runImmediately,

    // files upload
    uploads,
    uploadsStatus,

    // ---
    id: taskId,
    currentUploadIndex: -1,
    title,
    description: 'Uploading...',
    progress: 0,
    status: TQueueStatus.PENDING,
    get data() {
      return this.uploads;
    },
    getDescription() {
      const context = this;

      if (context.status === TQueueStatus.COMPLETE) {
        context.description = TTaskStatus.COMPLETED_TASK;
        context.progress = 100.0;
        return context.description;
      }

      if (context.status === TQueueStatus.FAILED) {
        context.description = TQueueStatus.FAILED_TASK;
        return context.description;
      }

      if (context.currentUploadIndex !== -1) {
        if (
          typeof context.progress !== 'undefined' &&
          context.uploads[context.currentUploadIndex].isSuccess
        ) {
          context.description = TTaskStatus.COMPLETED_TASK;
          context.progress = 100.0;
          return context.description;
        }

        context.description = `Uploading: ${
          context.uploads[context.currentUploadIndex]?.uploadFile?.name
        }`;
        return context.description;
      }

      context.description = 'Processing ...';

      return context.description;
    },
    getProgress() {
      const context = this;

      if (context.status === TQueueStatus.COMPLETE) {
        context.progress = 100.0;
        return 100.0;
      }

      const totalBytes = context.uploads.reduce((sum, item) => {
        return sum + (item?.uploadFile?.size || 0);
      }, 0);
      const totalUploadedBytes = context.uploads.reduce((sum, item) => {
        return sum + (item?.uploadedBytes || 0);
      }, 0);
      let currentProgress = 0.0;

      if (context.currentUploadIndex !== -1) {
        currentProgress = ((totalUploadedBytes / totalBytes) * 100).toFixed(2);

        if (context.progress < currentProgress) {
          context.progress = currentProgress;
          return currentProgress;
        }

        return context.progress;
      }

      return 0.0;
    },
    getStatus() {
      const context = this;

      const inProgress = context.uploadsStatus.includes(
        TQueueStatus.IN_PROGRESS,
      );

      if (inProgress) {
        return TQueueStatus.IN_PROGRESS;
      }

      const allSuccess = context.uploadsStatus.every(
        (val) => val === TQueueStatus.COMPLETE,
      );

      if (allSuccess) {
        return TQueueStatus.COMPLETE;
      }

      const someFailed = context.uploadsStatus.includes(TQueueStatus.FAILED);

      if (someFailed) {
        return TQueueStatus.FAILED;
      }

      return TQueueStatus.PENDING;
    },

    // callbacks
    onError(index) {
      const context = this;
      return (e) => {
        console.error('Upload error:', e);
        context.uploads[index].isError = true;
        context.uploads[index].isOnProgress = false;
        context.uploadsStatus[index] = TQueueStatus.FAILED;
        context.status = TQueueStatus.FAILED;
      };
    },
    onProgress(index) {
      const context = this;
      return (uploaded, total) => {
        context.uploads[index].isOnProgress = true;
        context.uploads[index].uploadedBytes = uploaded;
        context.uploads[index].totalBytes = total;
        context.uploadsStatus[index] = TQueueStatus.IN_PROGRESS;
        context.status = TQueueStatus.IN_PROGRESS;
      };
    },
    onComplete(index) {
      const context = this;
      return (url) => {
        context.uploads[index].uploadUrl = url;
        context.uploads[index].isSuccess = true;
        context.uploads[index].isOnProgress = false;
        context.uploadsStatus[index] = TQueueStatus.COMPLETE;
        // context.status = TQueueStatus.COMPLETE;
      };
    },

    updatesQueueBox: (status, value, log) => {
      _updatesQueueBox(status, value, log);
    },

    // on task complete post form data
    onTaskComplete,

    // on start
    async onTaskStart() {
      const context = this;

      // if task not exits register it
      if (!createdTasksIds.has(context.id)) {
        createdTasksIds.add(context.id);
      } else {
        // task already exist and started, no need to re-start
        return;
      }

      if (context.uploads.length === 0 && context.uploadsStatus.length === 0) {
        context.status = TQueueStatus.COMPLETE;
        context.description = TTaskStatus.COMPLETED_TASK;
        context.progress = 100.0;
        context.updatesQueueBox(TQueueStatus.COMPLETE, true);
        // onTaskComplete
        await context.onTaskComplete(context.data);
        return;
      }

      let isError = false;

      context.updatesQueueBox(TQueueStatus.COMPLETE, false);

      for (let i = 0; i < context.uploads.length; i += 1) {
        const upload = context.uploads[i];
        context.currentUploadIndex = i;
        context.uploads[i].isError = false;
        context.uploads[i].isSuccess = false;
        context.uploads[i].isOnProgress = true;
        context.uploadsStatus[i] = TQueueStatus.IN_PROGRESS;
        context.status = TQueueStatus.IN_PROGRESS;
        context.description = context.getDescription();
        context.updatesQueueBox(TQueueStatus.IN_PROGRESS, true);

        try {
          /* eslint-disable no-await-in-loop */
          const result = await upload.startUpload(
            context.onError(i),
            context.onProgress(i),
            context.onComplete(i),
          );
          context.uploads[i].isSuccess = true;
          context.uploads[i].isOnProgress = false;
          context.uploadsStatus[i] = TQueueStatus.COMPLETE;
          /* eslint-enable no-await-in-loop */
        } catch (e) {
          isError = true;
          context.uploads[i].isError = true;
          context.uploads[i].isSuccess = false;
          context.uploads[i].isOnProgress = false;
          context.uploadsStatus[i] = TQueueStatus.FAILED;
          context.status = TQueueStatus.FAILED;
          context.description = TQueueStatus.FAILED_TASK;
          context.progress = context.getProgress();
          context.updatesQueueBox(TQueueStatus.FAILED, true);
          console.error(e);
        }
      }

      if (!isError) {
        context.uploads.forEach((_, index) => {
          context.uploads[index].isSuccess = true;
          context.uploads[index].isOnProgress = false;
        });
        context.uploadsStatus.forEach((_, index) => {
          context.uploadsStatus[index] = TQueueStatus.COMPLETE;
        });
        context.status = TQueueStatus.COMPLETE;
        context.description = TTaskStatus.COMPLETED_TASK;
        context.progress = 100.0;
        context.updatesQueueBox(TQueueStatus.COMPLETE, true);
        // onTaskComplete
        await context.onTaskComplete(context.data);
      }

      console.log(new Error('Unknown task start condition'));
    },
  };
}

const TaskQueueContext = createContext();

const taskQueueInitialState = {
  queue: [],
  queueBoxUpdates: {
    [TQueueStatus.IN_PROGRESS]: true,
    [TQueueStatus.COMPLETE]: true,
    [TQueueStatus.FAILED]: true,
  },
  updatesQueueBox(status, value, log) {
    _updatesQueueBox(status, value, log);
  },
  openToast: false,
  toastData: {
    severity: ERROR,
    text: 'Error',
    callback: noop,
    timeout: 2000,
    unique: true,
    hideProgressBar: false,
    get options() {
      const context = this;
      return {
        position: 'top-right',
        autoClose: context?.timeout ?? 2000,
        hideProgressBar: context?.hideProgressBar ?? false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
      };
    },
  },
};

const taskQueueReducer = (prevState, action) => {
  const state = { ...prevState, queue: [...prevState.queue] };

  switch (action.type) {
    case TQueue.ADD_NEW_TASK: {
      const { taskData, toastData } = action.payload;

      if (!taskData) return state;

      if (taskData.runImmediately) {
        taskData.onTaskStart();
      }

      state.queue.push(taskData);

      state.toastData = {
        ...state.toastData,
        ...toastData,
      };
      state.toastData.hideProgressBar = true;
      state.toastData.severity = INFO;
      state.openToast = true;

      return state;
    }
    case TQueue.UPDATE_TASK_STATUS: {
      const { id, status } = action.payload;

      const taskItemIndex = state.queue.findIndex((item) => item.id === id);

      if (taskItemIndex !== -1 && VQueueStatus.includes(status)) {
        state.queue[taskItemIndex].status = status;
      }

      return state;
    }
    case TQueue.UPDATE_TASK_PROGRESS: {
      const { id, description, progress } = action.payload;

      const taskItemIndex = state.queue.findIndex((item) => item.id === id);

      if (taskItemIndex !== -1) {
        state.queue[taskItemIndex].progress =
          progress || state.queue[taskItemIndex].getProgress();

        if (description) {
          state.queue[taskItemIndex].description = description;
        }

        if (!description && state.queue[taskItemIndex].getDescription) {
          state.queue[taskItemIndex].description =
            state.queue[taskItemIndex].getDescription();
        }
      }

      return state;
    }
    case TQueue.UPDATE_TASK_DATA: {
      const { id, data } = action.payload;
      const taskItemIndex = state.queue.findIndex((item) => item.id === id);

      if (taskItemIndex !== -1 && VQueueStatus.includes(data)) {
        state.queue[taskItemIndex] = { ...state.queue[taskItemIndex], ...data };
      }

      return state;
    }
    case TQueue.QUEUE_BOX_UPDATE: {
      if (
        typeof action.payload.status === 'undefined' &&
        typeof action.payload.value === 'undefined'
      ) {
        return state;
      }

      if (action.payload.status === TQueueStatus.COMPLETE) {
        state.queueBoxUpdates = {
          ...state.queueBoxUpdates,
          [TQueueStatus.IN_PROGRESS]: true,
          [TQueueStatus.COMPLETE]: true,
        };

        return state;
      }

      state.queueBoxUpdates = {
        ...state.queueBoxUpdates,
        [action.payload.status]: action.payload.value,
      };

      return state;
    }
    case TQueue.OPEN_TOAST: {
      state.openToast = true;
      return state;
    }
    case TQueue.CLOSE_TOAST: {
      state.openToast = false;
      return state;
    }
    case TQueue.POP_UP_TOAST: {
      const {
        text,
        unique = true,
        severity = SUCCESS,
        callback = noop,
        timeout = 2000,
      } = action.payload;
      state.toastData = {
        ...state.toastData,
        severity,
        text,
        callback,
        timeout,
        unique,
      };
      state.openToast = true;
      return state;
    }
    case TQueue.FAIL_TASK: {
      const {
        id,
        // status,
        text,
        reason,
        unique = true,
        severity = ERROR,
        callback = noop,
        timeout = 2000,
      } = action.payload;

      const taskItemIndex = state.queue.findIndex((item) => item.id === id);

      if (taskItemIndex !== -1) {
        let isUploadFailed = false;

        state.queue[taskItemIndex].status = TQueueStatus.FAILED;
        state.queue[taskItemIndex].uploadsStatus.forEach((item) => {
          if (item === TQueueStatus.FAILED) {
            isUploadFailed = true;
          }
        });
        state.queue[taskItemIndex].description = isUploadFailed
          ? TQueueStatus.FAILED
          : `Task failed: ${reason}`;
        state.queue[taskItemIndex].progress =
          state.queue[taskItemIndex].getProgress();
        state.queue[taskItemIndex].updatesQueueBox(TQueueStatus.FAILED, true);

        state.queueBoxUpdates = {
          ...state.queueBoxUpdates,
          [TQueueStatus.IN_PROGRESS]: true,
          [TQueueStatus.COMPLETE]: true,
          [TQueueStatus.FAILED]: true,
        };
      }

      state.toastData = {
        ...state.toastData,
        severity,
        text,
        callback,
        timeout,
        unique,
      };
      state.openToast = true;
      return state;
    }
    case TQueue.COMPLETE_TASK: {
      const {
        id,
        // status,
        text,
        unique = true,
        severity = SUCCESS,
        callback = noop,
        timeout = 2000,
      } = action.payload;

      const taskItemIndex = state.queue.findIndex((item) => item.id === id);

      if (taskItemIndex !== -1) {
        state.queue[taskItemIndex].status = TQueueStatus.COMPLETE;
        state.queue[taskItemIndex].description = TTaskStatus.COMPLETED_TASK;
        state.queue[taskItemIndex].progress =
          state.queue[taskItemIndex].getProgress();

        state.queue[taskItemIndex].uploads.forEach((_, index) => {
          state.queue[taskItemIndex].uploads[index].isSuccess = true;
          state.queue[taskItemIndex].uploads[index].isOnProgress = false;
        });
        state.queue[taskItemIndex].uploadsStatus.forEach((_, index) => {
          state.queue[taskItemIndex].uploadsStatus[index] =
            TQueueStatus.COMPLETE;
        });
        state.queue[taskItemIndex].status = TQueueStatus.COMPLETE;
        state.queue[taskItemIndex].description = TTaskStatus.COMPLETED_TASK;
        state.queue[taskItemIndex].progress = 100.0;
        state.queue[taskItemIndex].updatesQueueBox(TQueueStatus.COMPLETE, true);

        state.queueBoxUpdates = {
          ...state.queueBoxUpdates,
          [TQueueStatus.IN_PROGRESS]: true,
          [TQueueStatus.COMPLETE]: true,
        };
      }

      state.toastData = {
        ...state.toastData,
        severity,
        text,
        callback,
        timeout,
        unique,
      };
      state.openToast = true;
      return state;
    }
    default: {
      return prevState;
    }
  }
};

const TaskQueueContextProvider = memo(function TaskQueueContextProvider({
  children,
}) {
  const [taskQueue, dispatch] = useReducer(
    taskQueueReducer,
    taskQueueInitialState,
  );
  const { openToast, toastData } = taskQueue;

  const handleToast = {
    open: () => {
      dispatch({ type: TQueue.OPEN_TOAST });
    },
    close: () => {
      dispatch({ type: TQueue.CLOSE_TOAST });
    },
    setOpen: (state) => {
      if (state) {
        return dispatch({ type: TQueue.OPEN_TOAST });
      }

      return dispatch({ type: TQueue.CLOSE_TOAST });
    },
  };

  _updatesQueueBox = (status, value, log) => {
    if (log) console.log(log);
    dispatch({ type: TQueue.QUEUE_BOX_UPDATE, payload: { status, value } });
  };

  useEffect(() => {
    if (openToast) {
      if (toastData.severity === ERROR) {
        if (toastData.unique) {
          if (!shownToasts.has(toastData.text)) {
            toast.error(toastData.text, { ...toastData.options });
            shownToasts.add(toastData.text);
          }
        } else {
          toast.error(toastData.text, { ...toastData.options });
        }

        setTimeout(() => {
          handleToast.close();
        }, toastData.timeout);

        return;
      }

      if (toastData.severity === INFO) {
        if (toastData.unique) {
          if (!shownToasts.has(toastData.text)) {
            toast.info(toastData.text, { ...toastData.options });
            shownToasts.add(toastData.text);
          }
        } else {
          toast.info(toastData.text, { ...toastData.options });
        }

        setTimeout(() => {
          handleToast.close();
        }, toastData.timeout);

        return;
      }

      if (toastData.severity === SUCCESS) {
        if (toastData.unique) {
          if (!shownToasts.has(toastData.text)) {
            toast.success(toastData.text, toastData.options);
            shownToasts.add(toastData.text);
          }
        } else {
          toast.success(toastData.text, toastData.options);
        }

        setTimeout(() => {
          handleToast.close();
        }, toastData.timeout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskQueue]);

  return (
    <TaskQueueContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        taskQueue,
        dispatch,
      }}
    >
      {children}
    </TaskQueueContext.Provider>
  );
});

export const useTaskQueue = () => {
  const context = useContext(TaskQueueContext);

  if (!context) {
    throw new Error(
      'useTaskQueue must be used within an TaskQueueContextProvider',
    );
  }

  return context;
};

export default TaskQueueContextProvider;
