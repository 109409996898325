import '@fontsource/poppins';
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ButtonAction from '@src/components/buttons/action.button';
import AddAdditionalInfo from '@src/components/modals/addAdditionalInfo';
import { useAuth } from '@src/contexts/authContext';
import { openLinkInPopUpWindow } from '@utils';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/informations/attachments`;

export default function ClinicAdditionalInfo() {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [info, setInfo] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };
  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    const filteredList = info.filter((row) => {
      if (row?.profession?.length > 0) {
        return row.profession.some((prof) =>
          prof.toLowerCase().includes(searchQuery.toLowerCase()),
        );
      }
      return false;
    });

    setSortedList(filteredList);
  }, [info, searchQuery]);

  const [loading, setLoading] = useState();

  const fetchData = () => {
    setLoading(true);
    if (auth?.idToken) {
      axios
        .get(`${url}?clinic_id=${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setInfo(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error fetching data', error);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [deleteStatus]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/${clinicId}/item/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
      });
      console.log(response);
      setDeleteStatus('Delete successful');
      toast.success(`${response.data.message}`, {
        autoClose: 2000,
        position: 'top-center',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-3/4'>
      <Box
        sx={{ width: '100%', height: '60px' }}
        className='flex w-full justify-end'
      >
        <ButtonAction
          onNavigate={() => {
            showModal();
          }}
          label='Add New Info'
        />
      </Box>
      <Box sx={{ width: '100%' }} className='flex justify-between px-10 py-4'>
        <div className='flex justify-between'>
          <select
            id='profession'
            value={searchQuery}
            onChange={handleSearch}
            className='font-poppins block w-full rounded-md border-0 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            style={{ height: '42px', width: '200px' }}
            onClick={clearSearch}
          >
            <option value=''>ALL</option>
            <option value='doctor'>Doctor</option>
            <option value='clinical assistant'>Clinical assistant</option>
            <option value='nurse'>Nurse</option>
          </select>
          &nbsp; &nbsp;
          <Button
            variant='contained'
            className='rounded-md bg-cyan-600 py-2 text-sm font-bold'
            sx={{
              width: '80px',
              height: '38px',
              textTransform: 'none',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            onClick={clearSearch}
          >
            Clear
          </Button>
        </div>
      </Box>

      {loading ? (
        <div className='font-poppins py-6 px-10 text-gray-500'>Loading...</div>
      ) : (
        <div className='px-4 text-gray-600'>
          {sortedList?.length > 0 ? (
            sortedList?.map((data) => (
              <Box
                className='rounded-xl'
                sx={{
                  height: 'auto',
                  width: 'auto',
                  backgroundColor: 'white',
                  margin: 3,
                }}
              >
                <div>
                  <div className='flex justify-between'>
                    <div className='font-poppins text-md py-4 px-6 font-bold text-gray-600'>
                      Title:
                    </div>
                    <div className='py-4 px-6'>
                      <Button
                        variant='outlined'
                        sx={{
                          textTransform: 'none',
                          width: 'auto',
                          height: '32px',
                        }}
                        className='rounded-xl border-purple-300 text-purple-500'
                      >
                         {data?.profession && data.profession.join(', ')}
                      </Button>
                    </div>
                  </div>
                  <div className='px-6 text-sm text-gray-500'>
                    {data?.title}
                  </div>
                </div>
                <Divider
                  sx={{ marginLeft: 3 }}
                  variant='inset'
                  className='w-3/4 py-2 px-16'
                />
                <div>
                  <div className='flex justify-between'>
                    <div className='font-poppins text-md py-2 px-6 font-bold text-gray-600'>
                      Attachments:
                    </div>
                    <div className='px-4 py-1'>
                      <Icon
                        onClick={() => showDeleteConfirmationDialog(data._id)}
                        icon='clarity:remove-solid'
                        color='#FF5555'
                        width='auto'
                        cursor='pointer'
                        className='h-7 w-7 scale-100 transform rounded-full transition-transform group-hover:scale-110'
                      />
                    </div>
                  </div>
                  <div className='flex justify-start px-4'>
                    {data?.items.length > 0 ? (
                      <ul>
                        {data.items.map((item) => (
                          <li
                            key={item._id}
                            className='px-2 text-sm text-gray-500'
                          >
                            <div>
                              {item.name ? (
                                <Button
                                  variant='text'
                                  className='justify-start text-gray-500'
                                  sx={{
                                    textTransform: 'none',
                                    padding: 0,
                                    width: 'auto',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    openLinkInPopUpWindow(item.link || '');
                                  }}
                                >
                                  {item.name}
                                </Button>
                              ) : (
                                'no data'
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      'no data'
                    )}
                  </div>
                </div>

                <br />
                {showDeleteConfirmation && (
                  <Dialog
                    open={showDeleteConfirmation}
                    onClose={handleConfirmationDialogClose}
                  >
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete this item?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleConfirmationDialogClose}
                        color='primary'
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleConfirmationDialogConfirm}
                        color='error'
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </Box>
            ))
          ) : (
            <div className='px-8 py-8'>no data</div>
          )}
        </div>
      )}

      <AddAdditionalInfo
        setOpenModal={setOpenModal}
        showModal={showModal}
        openModal={openModal}
        info={info}
        setInfo={setInfo}
        fetchData={fetchData}
      />
    </Box>
  );
}
