import { Outlet } from 'react-router-dom';

import SidebarMenu from './sidebar.common';

export default function Layout() {
  return (
    <div className='m-0 flex min-h-screen flex-auto flex-shrink-0 flex-col overflow-hidden bg-[#FAF9FC] text-gray-800 antialiased'>
      <SidebarMenu />
      <div className='ml-80 w-full'>
        <Outlet />
      </div>
    </div>
  );
}

// import React, { useState } from 'react';
// import { Outlet } from 'react-router-dom';

// import SidebarMenu from './sidebar.common';

// export default function Layout() {
//   const [isSidebarVisible, setSidebarVisibility] = useState(true);

//   const toggleSidebarVisibility = () => {
//     setSidebarVisibility((prev) => !prev);
//   };

//   return (
//     <div className='m-0 flex min-h-screen flex-auto flex-shrink-0 flex-col overflow-hidden bg-[#FAF9FC] text-gray-800 antialiased'>
//       <button
//         type='button'
//         onClick={toggleSidebarVisibility}
//         className='fixed top-4 left-4 z-10 cursor-pointer p-2 text-xl'
//       >
//         {isSidebarVisible ? 'Hide Sidebar' : 'Show Sidebar'}
//       </button>
//       {isSidebarVisible && <SidebarMenu />}
//       <div className={`ml-${isSidebarVisible ? '80' : '0'} w-full`}>
//         <Outlet />
//       </div>
//     </div>
//   );
// }
