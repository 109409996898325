import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import * as tusUtils from '@utils/tus';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player';

const options = [
  { value: 'doctor', label: 'Doctors' },
  { value: 'clinic_assistants', label: 'Clinic Assistants' },
  { value: 'nurse', label: 'Nurses' },
  { value: 'personal.specialist', label: 'Hub-Specialist' },
  { value: 'personal.allied-healthcare', label: 'Hub-Allied Healthcare' },
  { value: 'org.healthcare', label: 'Hub-Organisations' },
  { value: 'personal.gp', label: 'Hub-GP Locum' },

  // { value: 'ALL', label: 'ALL' },
];
const url = `${process.env.REACT_APP_API_URL}/event-tag-items`;

function CheckboxGroup({ selectedValues = [], onChange }) {
  return (
    <div className='text-md py-2 px-2 text-gray-600'>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                className='py-1'
                style={{ color: '#1788b9' }}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => onChange(option.value, e.target.checked)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default function CreateLearningJourneyForm({
  formInput,
  setFormInput,
  update,
  setUpdate,
  setFileTus,
  onTusResume,
  setIsLoading,
}) {
  const { auth } = useAuth();
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [previewUrl, setPreviewUrl] = useState('');
  // const [isVideo, setIsVideo] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const showPrevievImage = React.useMemo(
    () => selectedImage || previewImage || formInput?.event_image,
    [selectedImage, previewImage, formInput?.event_image],
  );

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  //   setPreviewUrl(URL.createObjectURL(file));
  //   setFormInput((prevFormInput) => ({
  //     ...prevFormInput,
  //     learn_video: file,
  //   }));
  //   setIsVideo(file.type.includes('video'));
  // };

  const handleEventImageChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const eventImage = await tusUtils.createUploadData(file, onTusResume, {
          Authorization: `Bearer ${auth.idToken}`,
        });
        setFileTus.setEventImageTus(eventImage);
      } catch (e) {
        console.error(e);
      }

      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({ ...prevFormInput, image: file }));
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (setIsLoading) {
      if (!auth?.idToken) {
        setIsLoading(true);
      } else if (auth?.idToken) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setIsLoading]);

  const [getItem, setGetItem] = useState();
  const handleType = (value, isChecked) => {
    if (formInput) {
      const eventTypes = Array.isArray(formInput.roles) ? formInput.roles : [];

      const updatedSelection = isChecked
        ? [...eventTypes, value]
        : eventTypes.filter((selected) => selected !== value);

      setFormInput({ ...formInput, roles: updatedSelection });
    } else {
      console.error('formInput is not defined');
    }
  };

  useEffect(() => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setGetItem(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className='px-6 py-2'>
        <label className='font-poppins text-md block py-4 font-bold leading-6 text-gray-600'>
          Add Event Image (Optional)
        </label>

        <Box
          sx={{
            width: '173px',
            height: '95px',

            overflow: 'hidden',
          }}
          className='flex justify-center rounded-xl bg-gray-300'
        >
          <input
            type='file'
            accept='image/*'
            onChange={handleEventImageChange}
            style={{ display: 'none' }}
            id='upload-file-input'
          />

          {showPrevievImage ? (
            <div>
              <img
                src={previewImage || formInput?.event_image}
                alt=''
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
          ) : null}
        </Box>
        <div className='py-5'>
          <label htmlFor='upload-file-input'>
            <Button
              variant='contained'
              component='span'
              className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
            >
              Upload Image
            </Button>
          </label>
        </div>
        <div className='sm:col-span-6'>
          <div className='text-xs  text-gray-300'>*size 375 x 190</div>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='py-2 sm:col-span-6'>
          <label className='text-md block py-1 font-bold leading-6 text-gray-600 after:ml-0.5 after:text-gray-600 after:content-["*"]'>
            Add Event Title
          </label>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='Enter Event Title'
              id='title'
              type='text'
              className='font-poppins  block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                formInput
                  ? formInput.event_title || ''
                  : update.event_title || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, event_title: e.target.value })
                  : setUpdate({ ...update, event_title: e.target.value })
              }
            />
          </div>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-1 font-bold leading-6 text-gray-600'>
            Add Event Description
          </label>
          <div className='mt-2 font-medium'>
            <textarea
              placeholder='Add Description'
              rows='4'
              className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={
                formInput ? formInput.event_desc || '' : update.event_desc || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, event_desc: e.target.value })
                  : setUpdate({ ...update, event_desc: e.target.value })
              }
            />
          </div>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='py-2 sm:col-span-6'>
          <label className='text-md block py-1 font-bold leading-6 text-gray-600'>
            Add Event Link
          </label>
          <div className='mt-2 font-medium'>
            <input
              id='link'
              placeholder='https://'
              type='text'
              className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
              value={
                formInput ? formInput.event_link || '' : update.event_link || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, event_link: e.target.value })
                  : setUpdate({ ...update, event_link: e.target.value })
              }
            />
          </div>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='py-2 sm:col-span-6'>
          <label className='text-md block font-bold leading-6 text-gray-600 after:ml-0.5 after:text-gray-600 after:content-["*"]'>
            Select Role
          </label>
          <CheckboxGroup
            selectedValues={formInput ? formInput.roles : update.roles}
            onChange={handleType}
          />
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='py-2 sm:col-span-6'>
          <label className='text-md block font-bold leading-6 text-gray-600'>
            Select Event Tag
          </label>
          <div
            className='mt-2'
            style={{
              overflowY: 'auto',
              maxHeight: '150px',
            }}
          >
            <select
              style={{ height: '50px', width: '250px' }}
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, event_tags: e.target.value })
                  : setUpdate({ ...update, event_tags: e.target.value })
              }
              value={formInput ? formInput.event_tags : update.event_tags}
              className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-2 py-3.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
              disabled={
                formInput?.roles === 'Clinic Assistants' ||
                (update && update.roles === 'Clinic Assistants')
              }
            >
              <option value={''}>Select One</option>
              {getItem?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
