// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import LabTabs from '@src/components/commons/tabs.common';
import Button from '@mui/material/Button';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CreateLearningJourney from './createLearningJourney';
import UpdateLearningJourney from './updateLearningJourney';
import UpdateNewEventInformation from './updateNewEventInformation';

export default function LearningJourneyPage() {
  const path = useLocation().pathname;
  const { pathname: state } = useLocation();
  const navigate = useNavigate();

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const menuEvent = [
    {
      idx: 0,
      title: 'History',
      component: (
        <div>
          <CreateLearningJourney />
          {/* <UpdateLearningJourney /> */}
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Add Event Information',
      component: (
        <div>
          <UpdateNewEventInformation />
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    return () => {
      const learningJourneyGeneratedId = window.sessionStorage.getItem(
        'learningJourneyGeneratedId',
      );

      if (learningJourneyGeneratedId) {
        window.sessionStorage.removeItem('learningJourneyGeneratedId');
      }
    };
  }, []);

  return (
    <div className='py-8 px-2'>
       <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <LabTabs menus={menuEvent} />
    </div>
  );
}
