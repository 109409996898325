import Container from '@src/components/commons/containerPage.common';
import LabTabs from '@src/components/commons/tabs.common';

import { useLocation } from 'react-router-dom';

export default function VerificationPage() {
  const path = useLocation().pathname;
  const categories = [
    {
      idx: 0,
      title: 'User Verification',
      component: <div className='py-4'>{/* <ClinicAnalytic /> */}</div>,
    },
    {
      idx: 1,
      title: 'Clinic Verification',
      component: <div>{/* <ClinicBranchList /> */}</div>,
    },
  ];
  return (
    <Container pathUrl={path}>
      <div className='px-2 py-8'>
        <LabTabs menus={categories} />
      </div>
    </Container>
  );
}
