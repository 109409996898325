import Box from '@mui/material/Box';

import React from 'react';

export default function NotifAnnouncement() {
  return (
    <div>
      <Box
        sx={{ width: '486px', height: '419px', backgroundColor: 'white' }}
        className='py-10'
      />
    </div>
  );
}
