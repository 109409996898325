import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TableClinicBranchFinance from './tableOrgFinance';

export default function OrganisationFinances() {
  const state = useLocation();
  const navigate = useNavigate();
  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  return (
    <>
      <Box
        sx={{
          height: '960px',
          width: '960px',
          margin: 3,
        }}
      >
        <div className='px-2  font-bold text-cyan-700'>Current Month</div>
        <div className='px-4 py-8 text-sm font-bold text-gray-400'>
          Invoice will be sent to email “test@abcmedical.com“
        </div>

        <Grid container spacing={1} justifyContent='left' className='px-2 py-4'>
          <Grid item className='flex-row- flex'>
            <Box
              sx={{
                margin: 1,
                width: 305,
                height: 112,
                fontFamily: 'Poppins',
                fontWeight: 4,
                fontSize: 14,
              }}
              className='rounded-xl bg-gradient-to-r from-teal-800 to-blue-500 text-center'
            >
              <div className=' px-6 py-3 text-center text-white'>
                Next Invoice Date
              </div>
              <div className='px-16 text-xl font-bold text-white'>
                07 November 2022
              </div>
            </Box>
            <Box
              sx={{
                margin: 1,
                width: 305,
                height: 112,
                fontFamily: 'Poppins',
                fontWeight: 4,
                fontSize: 14,
                border: '1px solid',
                borderColor: '#189BD3',
              }}
              className='rounded-xl text-center'
            >
              <div className='tepx-6 py-3 text-center font-bold'>
                Invoice Amount
              </div>
              <div className='font-poppins px-16 text-2xl font-bold text-cyan-600'>
                $123
              </div>
            </Box>
            <Box
              sx={{
                margin: 1,
                width: 305,
                height: 112,
                fontFamily: 'Poppins',
                fontWeight: 4,
                fontSize: 14,
                border: '1px solid',
                borderColor: '#189BD3',
              }}
              className='rounded-xl text-center'
            >
              <div className='px-6 py-3  text-center font-bold'>
                Total Clinic Licences
              </div>
              <div className='font-poppins px-16 text-2xl font-bold text-cyan-600'>
                5
              </div>
            </Box>
          </Grid>
        </Grid>
        <div className='px-2  py-8 font-bold text-cyan-700'>History</div>
        <TableClinicBranchFinance />
      </Box>
    </>
  );
}
