/* eslint-disable react/no-unknown-property */

/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/ww-platform/admin/v2/organization/license/search`;

export default function OrganisationLicensePage() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };
  const handleClickDetail = (licenseId) => {
    navigate(`/detail-license/${licenseId}`);
  };
  const [loading, setLoading] = useState();

  const [startDate, setStartDate] = useState(null);
  const [startFrom, setStartFrom] = useState();
  const [expiredDate, setExpiredDate] = useState(null);
  const [expTo, setExpTo] = useState();

  const [searchName, setSearchName] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchActive, setSearchActive] = useState();

  const clearSearch = () => {
    setSearchName('');
    setSearchId('');
    setSearchStatus('');
    setSearchActive('');
    setSearchType('');
    setStartDate(null);
    setStartFrom(null);
    setExpiredDate(null);
    setExpTo(null);
  };

  const handleName = (event) => {
    setSearchName(event.target.value);
  };

  const handleId = (event) => {
    setSearchId(event.target.value);
  };

  const handleStatus = (event) => {
    setSearchStatus(event.target.value);
  };

  const handleType = (event) => {
    setSearchType(event.target.value);
  };

  const handleActive = (event) => {
    setSearchActive(event.target.value);
  };

  const formatToDateTime = (date) => {
    return dayjs(date).format('YYYY-MM-DDTHH:mm');
  };

  const handleStartDate = (date) => {
    setStartDate(formatToDateTime(date));
  };

  const handleFrom = (date) => {
    setStartFrom(formatToDateTime(date));
  };

  const handleExpDate = (date) => {
    setExpiredDate(formatToDateTime(date));
  };

  const handleTo = (date) => {
    setExpTo(formatToDateTime(date));
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    if (auth?.idToken) {
      setLoading(true);
      const requestData = {
        filter: {
          organization_name: searchName,
          organization_code: searchId,
          organization_status: searchStatus,
          license_active_status: searchActive,
          license_start_date: {
            from: startDate || '',
            to: startFrom || '',
          },
          license_expired_date: {
            from: expiredDate || '',
            to: expTo || '',
          },
          license_package_type: searchType,
        },
        pagination: {
          page: 1,
          page_size: 1000,
        },
      };

      axios
        .post(`${url}`, requestData, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setList(response.data.response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error', error.message);
          toast.error('No data found', {
            // position: 'top-center',
            autoClose: 2000,
          });
        });
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchName,
    searchId,
    searchStatus,
    searchActive,
    startDate,
    startFrom,
    expiredDate,
    expTo,
    searchType,
  ]);

  return (
    <div>
      <div>
        <Container pathUrl={path} />
      </div>
      <div className='flex justify-start px-8'>
        <Box
          sx={{ width: '100%', height: '280px' }}
          className='rounded-xl bg-white w-2/3 px-8'
        >
          <div className='flex px-8 py-4'>
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                Organisation Name
              </div>
              <input
                id='name'
                value={searchName}
                onChange={handleName}
                className='font-poppins  block  rounded-md border-0 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                style={{ height: '10px', width: '190px' }}
              />
            </div>
            &nbsp; &nbsp; &nbsp;
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                Organisation ID
              </div>
              <input
                id='id'
                value={searchId}
                onChange={handleId}
                className='font-poppins  block  rounded-md border-0 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                style={{ height: '10px', width: '190px' }}
              />
            </div>
            &nbsp; &nbsp; &nbsp;
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                Organisation Status
              </div>
              <select
                id='status'
                value={searchStatus}
                onChange={handleStatus}
                className='font-poppins block w-full rounded-md border-0 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                style={{ height: '34px', width: '200px' }}
              >
                <option value='all'>ALL</option>
                <option value='created'>Created</option>
                <option value='verified'>Verified</option>
                <option value='banned'>Banned</option>
                <option value='deleted'>Deleted</option>
              </select>
            </div>
            &nbsp; &nbsp; &nbsp;
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                License Type
              </div>
              <select
                id='type'
                value={searchType}
                onChange={handleType}
                className='font-poppins block w-full rounded-md border-0 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                style={{ height: '34px', width: '175px' }}
              >
                <option value='trial'>Trial</option>
                <option value='paid'>Paid</option>
              </select>
            </div>
          </div>
          <div className='flex px-8'>
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                Active Status
              </div>
              <select
                id='status'
                value={searchActive}
                onChange={handleActive}
                className='font-poppins block w-full rounded-md border-0 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                style={{ height: '34px', width: '120px' }}
              >
                <option value='all'>ALL</option>
                <option value='true'>True</option>
                <option value='false'>False</option>
              </select>
            </div>
            &nbsp; &nbsp; &nbsp;
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                Start Date
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='font-poppins w-3/3 block rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                  <DatePicker
                    className='px-2 text-xs'
                    reduceAnimations
                    value={startDate}
                    onChange={handleStartDate}
                    sx={{ width: '150px' }}
                    format='YYYY-MM-DD'
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        InputProps: {
                          sx: {
                            fontSize: '14px',
                            '& > input': {
                              padding: '3px 2px',
                            },
                            '& > fieldset': {
                              border: 'none',
                            },
                          },
                        },
                      },
                    }}
                  />
                  <DatePicker
                    className='px-2 text-xs'
                    reduceAnimations
                    value={startFrom}
                    onChange={handleFrom}
                    sx={{ width: '150px' }}
                    format='YYYY-MM-DD'
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        InputProps: {
                          sx: {
                            fontSize: '14px',
                            '& > input': {
                              padding: '3px 2px',
                            },
                            '& > fieldset': {
                              border: 'none',
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </LocalizationProvider>
            </div>
            &nbsp; &nbsp; &nbsp;
            <div>
              <div className='font-poppins py-3 text-sm text-gray-400'>
                Expired Date
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='font-poppins w-3/3 block rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                  <DatePicker
                    className='px-2 text-xs'
                    reduceAnimations
                    value={expiredDate}
                    onChange={handleExpDate}
                    sx={{ width: '150px' }}
                    format='YYYY-MM-DD'
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        InputProps: {
                          sx: {
                            fontSize: '14px',
                            '& > input': {
                              padding: '3px 2px',
                            },
                            '& > fieldset': {
                              border: 'none',
                            },
                          },
                        },
                      },
                    }}
                  />
                  <DatePicker
                    className='px-2 text-xs'
                    reduceAnimations
                    value={expTo}
                    onChange={handleTo}
                    sx={{ width: '150px' }}
                    format='YYYY-MM-DD'
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        InputProps: {
                          sx: {
                            fontSize: '14px',
                            '& > input': {
                              padding: '3px 2px',
                            },
                            '& > fieldset': {
                              border: 'none',
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </LocalizationProvider>
            </div>
          </div>

          <div className='flex justify-end px-16 py-8'>
            <Button
              variant='contained'
              className='rounded-xl bg-cyan-600 text-sm font-bold'
              sx={{
                width: '80px',
                height: '30px',
                textTransform: 'none',
                backgroundColor: '#FAF9FC',
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
              onClick={() => handleSearch()}
            >
              Filter
            </Button>
            &nbsp; &nbsp;
            <Button
              variant='contained'
              className='rounded-xl bg-red-600 text-sm font-bold'
              sx={{
                width: '80px',
                height: '30px',
                textTransform: 'none',
                backgroundColor: '#FAF9FC',
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
              onClick={clearSearch}
            >
              Clear
            </Button>
          </div>
        </Box>
      </div>
      <Box sx={{ width: '100%' }} className='px-8 py-10'>
        {loading ? (
          <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
        ) : (
          <span className='w-2/3 flex flex-col items-start pr-20'>
            <TableContainer
              className='rounded-xl'
              component={Paper}
              style={{
                width: '100%',
                paddingBottom: '12px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ width: '300px' }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 0, width: '130px' }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 0, width: '100px' }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 0, width: '80px' }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 0, width: '80px' }}
                    >
                      Is Active
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 0, width: '170px' }}
                    >
                      Start At
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 0, width: '170px' }}
                    >
                      Expired At
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '150px' }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(list) && list.length > 0 ? (
                    list
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={row.code}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            className='border-white text-sm font-bold text-gray-500'
                          >
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                              }}
                            >
                              {row?.organization_name}
                            </span>
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            {row?.organization_code}
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            {row.organization_status}
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            {row?.license_package_type}
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            {row?.license_is_active ? 'true' : 'false'}
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            {row.license_start_at ? (
                              <>
                                {new Date(
                                  row.license_start_at,
                                ).toLocaleDateString('au-AU', format_date)}
                              </>
                            ) : (
                              'No date'
                            )}
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            {row.license_expired_at ? (
                              <>
                                {new Date(
                                  row.license_expired_at,
                                ).toLocaleDateString('au-AU', format_date)}
                              </>
                            ) : (
                              'No date'
                            )}
                          </TableCell>
                          <TableCell
                            className='border-white text-sm font-bold text-gray-500'
                            style={{ padding: 0 }}
                          >
                            <div className='flex'>
                              <Button
                                variant='contained'
                                className='rounded-md text-xs font-bold text-cyan-600'
                                sx={{
                                  width: '80px',
                                  height: '33px',
                                  textTransform: 'none',
                                  backgroundColor: '#FAF9FC',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() =>
                                  handleClickDetail(row?.license_id)
                                }
                              >
                                View
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        className='border border-white'
                        colSpan={6}
                        align='center'
                      >
                        <div className='font-poppins py-6 text-gray-500'>
                          No organisations listed
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box
                className='flex justify-end py-3'
                sx={{ width: '100%', fontFamily: 'Poppins' }}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component='div'
                  count={list.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </TableContainer>
          </span>
        )}
      </Box>
      <style jsx>{`
        @media (max-width: 768px) {
          .flex {
            display: block;
          }
          .pr-20 {
            padding-right: 0;
          }
        }
        @media (min-width: 769px) {
          .flex {
            display: flex;
          }
          .pr-20 {
            padding-right: 20px;
          }
        }
      `}</style>
    </div>
  );
}
