// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useAuth } from '@src/contexts/authContext';
import { openLinkInPopUpWindow } from '@utils';
import axios from 'axios';
import dayjs from 'dayjs';
import image from 'src/assets/images/rectangle.png';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}/event/get-event`;

export default function TableEventUnlisted() {
  const [events, setEvents] = useState([]);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [sortOrder, setSortOrder] = useState('desc');
  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    const filteredList = events?.filter((row) => {
      return (
        row?.event_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (row?.roles &&
          row.roles.some((role) =>
            role.toLowerCase().includes(searchQuery.toLowerCase()),
          )) ||
        row?.event_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row?.organiser_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    const sortedData = [...filteredList].sort((a, b) => {
      const dateA = new Date(a.event_date).getTime();
      const dateB = new Date(b.event_date).getTime();
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    setSortedList(sortedData);
  }, [events, searchQuery, sortOrder]);

  const handleSortToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpdateEvent = (eventId) => {
    navigate(`/update-unlisted-event/${eventId}`);
  };

  const [status, setStatus] = useState('unlisted');

  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${urls}?event_status=${status}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        });

        setEvents(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Error', error.message);
      }
    };
    if (auth?.idToken) {
      fetchData();
    }
  }, [auth, deleteStatus]);

  const formatOptions = {
    timeZone: 'Asia/Singapore',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-AU', formatOptions);
  };

  const formatTime = (time) => {
    return dayjs(time).format('LT');
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/event/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'My-Custom-Header': 'foobar',
          },
        },
      );
      setDeleteStatus('Delete successful');
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const handleOpenSlide = (event_link, event_title) => async () =>
    openLinkInPopUpWindow(event_link, event_title);

  const extractHostName = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (error) {
      console.error('url');
      return 'unknown link';
    }
  };

  const handleEventReport = (eventId) => {
    navigate(`/event-report/${eventId}`);
  };

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatEventTypes(eventTypes) {
    if (!eventTypes || !Array.isArray(eventTypes) || eventTypes.length === 0)
      return 'No role';

    const formattedEventTypes = eventTypes
      .map((eventType) =>
        eventType
          .split(/[._]/)
          .map((word) => capitalize(word))
          .join('-'),
      )
      .join(', ');

    return formattedEventTypes;
  }

  return (
    <>
      <Box sx={{ width: '100%' }} className='flex w-3/4 justify-end px-4 py-4'>
        <input
          placeholder='Search by title, role, date'
          value={searchQuery}
          onChange={handleSearch}
          className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
          style={{ height: '20px', width: '200px' }}
        />
      </Box>
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <span className='flex w-3/4 flex-col items-start pr-20'>
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingRight: '12px',
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
            }}
          >
            <Table
              sx={{
                minWidth: 350,
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '60px', border: 'gray' }}
                  >
                    Event Image
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '320px', border: 'gray' }}
                  >
                    Event Title
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '200px', border: 'gray' }}
                  >
                    Organiser Name
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '300px', border: 'gray' }}
                  >
                    <TableSortLabel
                      active
                      direction={sortOrder}
                      onClick={handleSortToggle}
                      className='text-xs font-bold text-gray-400'
                      sx={{ width: '50px' }}
                    >
                      Event Date/Time
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '230px', border: 'gray' }}
                  >
                    Location
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '100px', border: 'gray' }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '200px', border: 'gray' }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(sortedList) && sortedList.length > 0 ? (
                  sortedList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row?._id}
                        hover
                        tabIndex={-1}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className='border-white  py-5'
                        >
                          <img
                            src={row?.event_image ? row?.event_image : image}
                            alt='event'
                            width='59px'
                            height='32px'
                          />
                        </TableCell>
                        <TableCell
                          className=' border-white text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {row?.event_title}
                        </TableCell>

                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 4 }}
                        >
                          {row?.organiser_name}
                        </TableCell>
                        <TableCell
                          style={{ padding: 4 }}
                          className='border-white'
                        >
                          <div className='flex justify-start text-sm font-bold text-gray-500'>
                            {row?.event_date ? (
                              <>
                                {formatDate(row?.event_date)}
                                <br />
                                {formatTime(row?.event_from)} -{' '}
                                {formatTime(row?.event_to)}
                              </>
                            ) : (
                              'No date'
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 4 }}
                        >
                          {(row?.unit_number ||
                            row?.street ||
                            row?.city ||
                            row?.country ||
                            row?.postal_code) &&
                          row?.live_stream ? (
                            <div>Hybrid event</div>
                          ) : row?.unit_number ||
                            row?.street ||
                            row?.city ||
                            row?.country ||
                            row?.postal_code ? (
                            `${row?.unit_number}, ${row?.street}, ${row?.city}, ${row?.country}, ${row?.postal_code}`
                          ) : row?.live_stream ? (
                            <div>
                              Online at:{' '}
                              <Button
                                variant='text'
                                className='justify-left flex text-sm font-bold text-cyan-600 underline'
                                sx={{ textTransform: 'none', padding: 0 }}
                                onClick={() =>
                                  handleOpenSlide(row.live_stream || '')
                                }
                              >
                                {extractHostName(row.live_stream)}
                              </Button>
                            </div>
                          ) : (
                            'No location added'
                          )}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {formatEventTypes(row?.roles || 'No role')}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-cyan-600'
                          style={{ padding: 0 }}
                        >
                          <div className='flex'>
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-cyan-600'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() => handleUpdateEvent(row?._id)}
                            >
                              View
                            </Button>
                            &nbsp;
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-red-500'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() =>
                                showDeleteConfirmationDialog(row?._id)
                              }
                            >
                              Delete
                            </Button>
                            &nbsp; &nbsp;
                            <Icon
                              icon='fluent:info-24-regular'
                              color='#1788b9'
                              width='26'
                              onClick={() => handleEventReport(row?._id)}
                            />
                          </div>
                          {showDeleteConfirmation && (
                            <Dialog
                              open={showDeleteConfirmation}
                              onClose={handleConfirmationDialogClose}
                            >
                              <DialogTitle>Confirm Delete</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to delete this event?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleConfirmationDialogClose}
                                  color='primary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmationDialogConfirm}
                                  color='error'
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No events listed
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
      )}
    </>
  );
}
