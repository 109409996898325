/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import ButtonAction from '@src/components/buttons/action.button';
import Container from '@src/components/commons/containerPage.common';
import PropTypes from 'prop-types';

import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TableOrganisationBanned from './tableOrganisationBanned';
import TableOrganisationDeleted from './tableOrganisationDeleted';
import TableOrganisationSuspended from './tableOrganisationSuspended';
import TableOrganisationUnverified from './tableOrganisationUnverified';
import TableOrganisationVerified from './tableOrganisationVerified';

function TabPanel(props) {
  const { children, value, index, style = {} } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabLabel(index) {
  return {
    id: `simple-tab-${index}`,
    poppins: `simple-tabpanel-${index}`,
  };
}

export default function OrganisationPage() {
  const path = useLocation().pathname;
  // const [value, setValue] = React.useState('one');
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <Container
          pathUrl={path}
          actionRight={
            <ButtonAction
              onNavigate={() => {
                navigate('/create-organisation');
              }}
              label='Create New Organisation'
            />
          }
        />
      </div>
      <div>
        <Box sx={{ width: '100%' }} className='py-20'>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            className=' border-gray-50'
          >
            <Tabs
              indicatorColor='white'
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              className='px-6'
            >
              <Tab
                sx={{ textTransform: 'none' }}
                label='Unverified'
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 0 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(0)}
              />
              <Box sx={{ width: '12px' }} />
              <Tab
                sx={{ textTransform: 'none' }}
                label='Verified'
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 2 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(2)}
              />
              <Box sx={{ width: '12px' }} />
              <Tab
                label='Banned'
                sx={{ textTransform: 'none' }}
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 4 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(4)}
              />
              <Box sx={{ width: '12px' }} />
              <Tab
                label='Suspended'
                sx={{ textTransform: 'none' }}
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 6 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(6)}
              />
              <Box sx={{ width: '12px' }} />
              <Tab
                label='Deleted'
                sx={{ textTransform: 'none' }}
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 8 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(8)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableOrganisationUnverified />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableOrganisationVerified />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <TableOrganisationBanned />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <TableOrganisationSuspended />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <TableOrganisationDeleted />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
