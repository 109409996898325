import { Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/settings/notification`;
const urls = `${process.env.REACT_APP_API_URL}/admin/clinics`;

export default function AddNotifChannel({ addModal, setAddModal, fetchData }) {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [data, setData] = useState([]);

  const [notif, setNotif] = useState({ channel_type: 'WA', channel_value: '' });
  const [warning, setWarning] = useState('');

  const validateWhatsApp = (value) => {
    if (!value.startsWith('+65') || !/^\+\d+$/.test(value)) {
      setWarning('Please enter a valid WhatsApp number starting with +65 and containing only digits');
      return false;
    }
    setWarning('');
    return true;
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setWarning('Please enter a valid email address');
      return false;
    }
    setWarning('');
    return true;
  };


  const handleChange = (e) => {
    const { value } = e.target;
    if (notif?.channel_type === 'WA') {
      validateWhatsApp(value);
    } else if (notif?.channel_type === 'EMAIL') {
      validateEmail(value);
    }
    setNotif({ ...notif, channel_value: value });
  };


  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${urls}/${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth.idToken, clinicId]);

  const handlePost = () => {

      if (notif?.channel_type === 'WA' && !validateWhatsApp(notif.channel_value)) {
        return;
      }
      if (notif?.channel_type === 'EMAIL' && !validateEmail(notif.channel_value)) {
        return;
      }

    const formData = {
      clinic_id: data._id,
      channel_type: notif?.channel_type,
      channel_value: notif?.channel_value,
      // channel_note: notif?.channel_note,
    };

    axios
      .post(`${url}/create`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item added successfully:', response);
        setNotif({ ...notif, channel_value: '' });
        fetchData();
        toast.success('Item added successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response?.data?.message || 'An error occurred'}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={addModal}
      onClose={() => setAddModal(false)}
    >
      <DialogTitle>Add new item</DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          <br />
          <div>Type*</div>
          <br />
          <div>
            <select
              id='type'
              onChange={(e) =>
                setNotif({
                  ...notif,
                  channel_type: e.target.value,
                  channel_value: '',
                })
              }
              className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              style={{ width: '360px', height: '45px' }}
              value={notif.channel_type}
            >
              {/* <option value=''>Select one</option> */}
              <option value='WA'>WhatsApp</option>
              <option value='EMAIL'>Email</option>
            </select>
            <br />
            <br />
          </div>
          <br />

          <div>Value*</div>
          <br />
          {notif.channel_type === 'WA' && (
            <div>
              <input
                placeholder='+65'
                className='font-poppins w-full rounded-md'
                style={{
                  width: '350px',
                  height: '38px',
                  fontSize: 14,
                  color: '#2d3748',
                  paddingLeft: 5,
                }}
                value={notif.channel_value}
                onChange={handleChange}
              />
            </div>
          )}

          {notif.channel_type === 'EMAIL' && (
            <div>
              <input
                placeholder='clinic@gmail.com'
                className='font-poppins w-full rounded-md'
                style={{
                  width: '350px',
                  height: '38px',
                  fontSize: 14,
                  color: '#2d3748',
                  paddingLeft: 5,
                }}
                value={notif.channel_value}
                onChange={handleChange}
              />
            </div>
          )}
           {warning && (
                <p style={{ color: 'red', fontSize: 12 }}>{warning}</p>
              )}
          <br />

          {/* <div>Note</div>
          <br />
          <input
            placeholder='enter note (optional)'
            className='font-poppins w-full rounded-md'
            style={{
              width: '350px',
              height: '38px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            onChange={(e) =>
              setNotif({
                ...notif,
                channel_note: e.target.value,
              })
            }
          /> */}

          <br />
          <br />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setAddModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={handlePost}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
