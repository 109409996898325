/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/anchor-is-valid */
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import Box from '@mui/material/Box';
import UpdatePersonalDocumentBCLS from '@src/components/forms/nurseBCLS.form';
import UpdatePersonalDocumentSNB from '@src/components/forms/nurseSNB.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function NurseCertificate() {
  const { auth } = useAuth();
  const { userId } = useParams();

  const [getUser, setGetUser] = useState({
    name: '',
    gender: '',
    nationality: '',
    nric_fin: '',
    date: '',
    residential_status: '',
    address: '',
    referal: '',
  });
  const [getBcls, setGetBcls] = useState({
    user_id: '',
    valid_from: '',
    valid_to: '',
    file: '',
    registration_number: '',
    scope_of_practice: '',
  });
  const [getSnb, setGetSnb] = useState({
    user_id: '',
    valid_from: '',
    valid_to: '',
    file: '',
    registration_number: '',
    scope_of_practice: '',
  });

  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${url}/personal-informations/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetUser(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [auth]);

  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${url}/bcls-aed-certificates/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetBcls(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [auth]);

  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${url}/snb-certificates/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetSnb(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [auth]);

  const handlePutSnb = () => {
    const formData = {
      user_id: getSnb.user_id || getUser.userId,
      valid_from: getSnb.from,
      valid_to: getSnb.to,
      file: getSnb.file,
      registration_number: getSnb.registration_number,
      scope_of_practice: getSnb.scope_of_practice,
    };

    // console.log('data', formData);

    const urlEndpoint = getSnb.user_id
      ? `${url}/snb-certificates/${getSnb.user_id}`
      : `${url}/snb-certificates/${getUser.userId}`;

    axios
      .request({
        method: getSnb.user_id ? 'patch' : 'post',
        url: urlEndpoint,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Certificate updated successfully:', response.data);
        setGetSnb({ ...getSnb });
        toast.success('Certificate updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        // toast.error(`Error: ${error.response.data.message}`, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      });
  };

  const handlePutBcls = () => {
    const formData = {
      user_id: getBcls.user_id || getUser.userId,
      valid_from: getBcls.from,
      valid_to: getBcls.to,
      file: getBcls.file,
      registration_number: getBcls.registration_number,
      scope_of_practice: getBcls.scope_of_practice,
    };

    console.log('data', formData);

    const urlEndpoint = getBcls.user_id
      ? `${url}/bcls-aed-certificates/${getBcls.user_id}`
      : `${url}/bcls-aed-certificates/${getUser.userId}`;

    axios
      .request({
        method: getBcls.user_id ? 'patch' : 'post',
        url: urlEndpoint,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setGetBcls({ ...getBcls });
        toast.success('Certificate updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        // toast.error(`Error: ${error.response.data.message}`, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      });
  };

  return (
    <Box
      sx={{
        width: '980px',
        fontFamily: 'Poppins',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div>
        <Box
          className='rounded-xl'
          sx={{
            height: 'auto',
            width: '660px',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <UpdatePersonalDocumentSNB
            getSnb={getSnb}
            setGetSnb={setGetSnb}
            handlePutSnb={handlePutSnb}
          />
        </Box>
        <Box
          className='rounded-xl'
          sx={{
            height: 'auto',
            width: '660px',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <UpdatePersonalDocumentBCLS
            getBcls={getBcls}
            setGetBcls={setGetBcls}
            handlePutBcls={handlePutBcls}
          />
        </Box>
      </div>
    </Box>
  );
}
