import qrcode from '@assets/images/qr-code.png';
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin`;

export default function ClinicBranchProfile() {
  const { auth } = useAuth();
  const { clinicId } = useParams();

  const [clinic, setClinic] = useState({
    group: '',
  });

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/clinics/${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setClinic(response.data);
        })
        .catch((error) => {
          console.log('Error fetching data', error);
        });
    }
  }, [auth, clinicId]);

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins', margin: 2 }} className='w-1/2'>
      <div className='grid grid-cols-2'>
        <div className='col-end-1'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 500,
                // bgcolor: 'background.paper',
              }}
              className='px-2'
            >
              <ListItem alignItems='flex-start'>
                <ListItemAvatar>
                  <Avatar src={clinic?.logo} />
                </ListItemAvatar>
                <ListItemText
                  className='px-2'
                  primary={
                    <span className='font-bold text-cyan-600'>
                      {clinic?.clinicName}
                    </span>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component='span'
                        variant='body2'
                        color='text.secondary'
                      >
                        <div className='py-1'>
                          {clinic?.companyTel || 'No phone added'}
                        </div>
                      </Typography>

                      <Typography
                        sx={{ display: 'inline' }}
                        component='span'
                        variant='body2'
                        color='text.secondary'
                      >
                        <div className='py-1'>
                          {clinic?.corporateEmail || 'No email added'}
                        </div>
                      </Typography>

                      <Typography
                        sx={{ display: 'inline' }}
                        component='span'
                        variant='body2'
                        color='text.secondary'
                      >
                        <div className='py-1'>
                          {clinic?.clinicAddress || 'No address added'}
                        </div>
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>

            <div className='px-6'>
              {/* <div className='inline-block rounded-xl bg-blue-100 bg-opacity-20 px-8 py-1 text-sm font-bold text-cyan-600 underline'>
                ORG-01111
              </div>
              <br /> */}

              <div className='inline rounded-xl bg-blue-100 bg-opacity-20 px-10 py-1 text-sm font-bold text-cyan-600 underline'>
                {clinic?.code}
              </div>
            </div>
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6 text-lg font-bold'>
              About
            </div>

            <div className='px-6  text-sm text-gray-500'>
              {clinic?.description
                ? clinic?.description.split('\n').map((paragraph, index) => (
                    <p
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{ textAlign: 'justify' }}
                    >
                      {paragraph}{' '}
                    </p>
                  ))
                : 'No about added'}
            </div>
            <br />
          </Box>
        </div>
        <div className='col-auto'>
          <Box
            className='rounded-xl'
            sx={{
              height: '325px',
              width: '347px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <Box className='py-7 px-10'>
              <div className='flex justify-center'>
                <img src={qrcode} alt='qr-code' width='195px' height='195px' />
              </div>
              <div className='flex justify-center'>
                <Button
                  sx={{ textTransform: 'none', margin: 1, boxShadow: 'none' }}
                  className='rounded-md bg-blue-200 bg-opacity-50 font-bold text-cyan-500'
                  variant='contained'
                >
                  Download QR
                </Button>
                <Button
                  sx={{ textTransform: 'none', margin: 1, boxShadow: 'none' }}
                  variant='contained'
                  className='rounded-md bg-blue-200 bg-opacity-50'
                >
                  <Icon icon='tabler:refresh' color='#0ea4d6' width='24' />
                </Button>
              </div>
            </Box>

            <div className='flex justify-center from-cyan-500 to-cyan-600 text-2xl font-bold text-cyan-600 underline' />
          </Box>
        </div>
      </div>
    </Box>
  );
}
