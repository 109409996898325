import { useAuth } from '@contexts/authContext';
import LoginForm from '@src/components/forms/login.form';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import bgLogin from '../../assets/images/bg_login.svg';

export default function LoginPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.isAuthenticated !== null && auth.isAuthenticated) {
      navigate('/verify-code');
    }
  }, [auth, navigate]);
  return (
    <main className='font-poppins flex items-center justify-center overflow-hidden bg-[#F7FBFF]'>
      <aside
        className='h-screen w-1/2 bg-cover bg-bottom'
        style={{
          backgroundImage: `url(${bgLogin})`,
        }}
      >
        {/* <div className="flex justify-center">
          <img
            src="/logo.svg"
            alt="logo"
            className="flex justify-center px-16 fixed top-10 left-10 w-48"
          />
        </div> */}
      </aside>
      <aside className='h-full w-1/2'>
        <div className='px-20'>
          <h1 className='font-poppins mb-10 font-bold'>Login</h1>
          <LoginForm />
        </div>
      </aside>
    </main>
  );
}
