import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

import React, { useState } from 'react';

let okCb = () => {};
let cancelCb = () => {};

const shortenString = (inputString, maxLength = 42) => {
  if (String(inputString).length <= maxLength) {
    return inputString;
  }

  const ellipsis = ' ... ';
  const clippedLength = maxLength - ellipsis.length;

  const startLength = Math.ceil(clippedLength / 2);
  const endLength = clippedLength - startLength;

  const startPortion = inputString.slice(0, startLength);
  const endPortion = inputString.slice(-endLength);

  return startPortion + ellipsis + endPortion;
};

function UploadedFilesDialog(props, ref) {
  const [open, setOpen] = React.useState(false);
  const [selectTimeout, setSelectTimeout] = React.useState(15000);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const awaitRef = React.useRef({ isAwaiting: false, selectedFileIndex: -1 });

  const isValidIndex = (index) => {
    const numericIndex = parseInt(index, 10);
    return (
      !Number.isNaN(numericIndex) &&
      numericIndex >= 0 &&
      numericIndex < uploadedFiles.length
    );
  };

  const handleInputChange = (ev) => {
    const {
      target: { value },
    } = ev;
    const isValid = isValidIndex(value);

    if (isValid) {
      const numericIndex = parseInt(value, 10);
      awaitRef.current.selectedFileIndex = numericIndex;
      setSelectedFileIndex(numericIndex);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    awaitRef.current.isAwaiting = true;
  };

  const handleClose = () => {
    awaitRef.current.isAwaiting = false;
    setOpen(false);
  };

  const handleOk = () => {
    awaitRef.current.isAwaiting = false;
    awaitRef.current.selectedFileIndex = selectedFileIndex;
    setOpen(false);
    okCb(selectedFileIndex);
  };

  const handleCancel = () => {
    awaitRef.current.isAwaiting = false;
    awaitRef.current.selectedFileIndex = -1;
    setOpen(false);
    cancelCb(-1);
  };

  const askToResumeUpload = (newUploadedFiles, timeout = 30000) => {
    return new Promise((resolve, reject) => {
      try {
        setSelectedFileIndex(newUploadedFiles.length - 1);
        setUploadedFiles(newUploadedFiles);
        setSelectTimeout(timeout);

        const waitForUserInteraction = () =>
          new Promise((innerResolve, innerReject) => {
            try {
              const cancelCheckInterval = setInterval(() => {
                setSelectTimeout((prevTimeout) =>
                  Math.max(prevTimeout - 1000, 0),
                );
              }, 1000);

              setTimeout(() => {
                clearInterval(cancelCheckInterval);
                innerResolve(-1);
              }, timeout);

              okCb = (index) => {
                clearInterval(cancelCheckInterval);
                innerResolve(index);
              };

              cancelCb = () => {
                clearInterval(cancelCheckInterval);
                innerResolve(-1);
              };
            } catch (ie) {
              innerReject(ie);
            }
          });

        waitForUserInteraction()
          .then((result) => {
            handleClose();
            resolve(result);
          })
          .catch((ie) => reject(ie));

        handleOpen();
      } catch (e) {
        reject(e);
      }
    });
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    ref.current = {
      // func
      askToResumeUpload,
      // states
      open,
      uploadedFiles,
      selectedFileIndex,
      // handlers
      handleOpen,
      handleClose,
      handleCancel,
      handleOk,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, uploadedFiles, selectedFileIndex]);

  return (
    <div ref={ref}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Confirmation</DialogTitle>
        <DialogContent sx={{ overflowY: 'hidden' }}>
          {uploadedFiles[0]?.metadata?.filename ? (
            <>
              <DialogContentText>
                File:{' '}
                <i>{`${shortenString(uploadedFiles[0].metadata.filename)}`}</i>
              </DialogContentText>
              <br />
              <DialogContentText>
                You tried to upload this file previously at these times.
              </DialogContentText>
              {/* <Box sx={{ overflowY: 'scroll', maxHeight: '63vh' }}>
                <List>
                  {uploadedFiles.map((fileMetadata, index) => (
                    <ListItem
                      button
                      // eslint-disable-next-line react/no-array-index-key
                      key={`uploaded-file-${index}`}
                      onClick={() => setSelectedFileIndex(index)}
                    >
                      <ListItemText
                        primary={`[${index}] ${fileMetadata.creationTime}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box> */}
              {/* <DialogContentText sx={{ my: 1 }}>
                Enter the corresponding number to resume an upload or press
                Cancel to start a new upload.
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                label='Enter the number to select'
                fullwidth
                value={selectedFileIndex === -1 ? '' : selectedFileIndex}
                onChange={handleInputChange}
              /> */}
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color='primary'
            autoFocus
            sx={{ textTransform: 'none' }}
          >
            Continue
          </Button>
          {/* <Button onClick={handleOk} color='primary'>
            OK
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.forwardRef(UploadedFilesDialog);
