/* eslint-disable react/destructuring-assignment */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/no-extraneous-dependencies
import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateLearningJourneyForm from '@src/components/forms/createLearningJourney.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import { mapNewLearningJourneyFormInputData } from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';

import { useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/event/get-new-learning-id`;

const urls = `${process.env.REACT_APP_API_URL}/event`;

const initialFormInput = {
  _id: '',
  title: '',
  link: '',
  desc: '',
  org_name: '',
  org_desc: '',
  type: '',
  tags: '',
  source_link: '',
  past_link: '',
  past_desc: '',
  image: '',
  event_image: '',
  org_image: '',
  slide: '',
  learn_video: '',
  links: [],
};

export default function CreateLearningJourney() {
  const { auth } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [formInput, setFormInput] = React.useState(initialFormInput);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [previewUrl, setPreviewUrl] = React.useState('');

  const [eventImageTus, setEventImageTus] = React.useState(null);
  const [orgImageTus, setOrgImageTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const setFormInputState = (data) => {
    setIsLoading(true);
    if (isMounted.current) setFormInput(data);
    setIsLoading(false);
  };

  const setOpenModalState = (data) => {
    if (isMounted.current) setOpen(data);
  };

  const handleOpenModal = async () => {
    setOpenModalState(true);
  };

  const handleCloseModal = async () => {
    setOpenModalState(false);
    // navigate(state?.prevLocation || -1);
  };

  const handleSetQueryParam = async (eventId) => {
    window.history.replaceState(null, null, `?eventId=${eventId}`);
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const postNewLearning = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Save new learning journey');

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapNewLearningJourneyFormInputData(formInput);

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/event/learning-journey`,
            uploadFormData,
            {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${idToken}`,
              },
            },
          )
          .then((response) => {
            setFormInputState({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            handleOpenModal();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const handleSaveButtonClick = async () => {
    if (!isLoading) {
      if (!formInput?.event_title) {
        toast.error('Title is required.');
        return;
      }
      if (!formInput?.roles) {
        toast.error('Role is required.');
        return;
      }
      if (!formInput?.organiser_name) {
        toast.error('Organiser name is required.');
        return;
      }

      postNewLearning();
    }
  };

  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const handleImageChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const orgImage = await tusUtils.createUploadData(
          file,
          handleOnUploadResume,
          {
            Authorization: `Bearer ${auth.idToken}`,
          },
        );
        setOrgImageTus(orgImage);
      } catch (e) {
        console.error(e);
      }

      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        org_image: file,
      }));
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (formInput?._id && uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef, formInput]);

  const { eventId: eventIdParam } = useParams();
  const [eventId, setEventId] = useState(eventIdParam);

  React.useEffect(() => {
    const learningJourneyGeneratedId = window.sessionStorage.getItem(
      'learningJourneyGeneratedId',
    );

    if (learningJourneyGeneratedId) {
      handleSetQueryParam(learningJourneyGeneratedId);
      setFormInput({ ...formInput, _id: learningJourneyGeneratedId });

      return;
    }

    if (auth?.idToken) {
      axios
        .get(`${url}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          if (response?.data?.generatedId) {
            const {
              data: { generatedId },
            } = response;
            handleSetQueryParam(generatedId);
            setFormInput({ ...formInput, _id: generatedId });
            window.sessionStorage.setItem(
              'learningJourneyGeneratedId',
              generatedId,
            );

            return;
          }

          throw new Error('generatedId not found');
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setIsLoading]);

  React.useEffect(() => {
    const u = new URL(window.location.href);
    const evId = u.searchParams.get('eventId');
    if (evId) setEventId(evId);
  }, []);

  React.useEffect(() => {
    if (auth?.idToken && eventId) {
      axios
        .get(`${urls}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setFormInput(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [auth, eventId]);

  return (
    <Box sx={{ width: 'calc(100vw - 25rem)', fontFamily: 'Poppins' }}>
      <UploadedFilesDialog ref={uploadResumeRef} />
      {/* <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button> */}
      <div className='mr-6 grid grid-cols-12 gap-6'>
        <div className='col-span-7'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '100%',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <CreateLearningJourneyForm
              formInput={formInput}
              setFormInput={setFormInput}
              setFileTus={{ setEventImageTus }}
              onTusResume={handleOnUploadResume}
              setIsLoading={setIsLoading}
            />
            <div className='py-6' />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '569px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='py-2'>
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='sm:col-span-6'>
                  <label className='text-md block py-4 font-bold leading-6 text-gray-600'>
                    Add Organiser Image (Optional)
                  </label>
                  <Box
                    sx={{
                      width: '100px',
                      height: '100px',

                      overflow: 'hidden',
                    }}
                    className='flex justify-center rounded-xl bg-gray-300'
                  >
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      id='upload-file-inputs'
                    />

                    {selectedFile ||
                    previewUrl ||
                    formInput?.organiser_image ? (
                      <div>
                        <img
                          src={previewUrl || formInput?.organiser_image}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ) : null}
                  </Box>
                  <div className='py-5'>
                    <label htmlFor='upload-file-inputs'>
                      <Button
                        variant='contained'
                        component='span'
                        className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
                      >
                        Upload Image
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='sm:col-span-6'>
                  <label className='font-poppins text-md block font-bold leading-6 text-gray-600 after:ml-0.5 after:text-gray-600 after:content-["*"]'>
                    Add Organiser Name
                  </label>
                  <div className='mt-2'>
                    <input
                      id='org_name'
                      placeholder='Enter Organiser Name'
                      type='text'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                      value={formInput?.organiser_name}
                      onChange={(e) =>
                        setFormInput({
                          ...formInput,
                          organiser_name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='sm:col-span-6'>
                  <label className='text-md block font-bold leading-6 text-gray-600'>
                    Add Organiser Description
                  </label>
                  <div className='mt-2 font-medium'>
                    <textarea
                      placeholder='Add Organiser Description'
                      rows='4'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      value={formInput?.organiser_desc}
                      onChange={(e) => {
                        setFormInput({
                          ...formInput,
                          organiser_desc: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='py-6' />
          </Box>
        </div>
        <div className='col-auto'>
          <div className='py-4 px-9 text-lg font-bold text-gray-600'>
            Preview
          </div>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '400px',
              backgroundColor: 'white',
              margin: 4,
            }}
          >
            <div className='py-6 px-6'>
              <div className='py-4 font-bold'>
                Title: {formInput?.event_title}
              </div>

              <Box
                className='rounded-md'
                sx={{
                  width: '345px',
                  height: 'auto',
                  backgroundColor: 'white',
                }}
              >
                <div className='py-2 px-1 font-bold text-cyan-700'>
                  Event Description:
                </div>
                <div className='py-2 px-1 text-sm font-bold text-cyan-600'>
                  {formInput?.event_desc &&
                    formInput?.event_desc
                      .split('\n')
                      .map((paragraph, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={index} style={{ textAlign: 'justify' }}>
                          {paragraph}{' '}
                        </p>
                      ))}
                </div>
              </Box>
              <br />
              <Box
                className='rounded-md'
                sx={{
                  width: '345px',
                  height: 'auto',
                  backgroundColor: '#F3F3F3',
                }}
              >
                <div className='px-4 py-4 font-bold text-cyan-700'>
                  Meet the organiser: <br />
                </div>
                <div className='px-4 py-4 text-sm font-bold text-cyan-600'>
                  <div className='justify-center py-2 px-1 text-sm font-bold text-cyan-600'>
                    {formInput?.organiser_desc &&
                      formInput?.organiser_desc
                        .split('\n')
                        .map((paragraph, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <p key={index} style={{ textAlign: 'justify' }}>
                            {paragraph}{' '}
                          </p>
                        ))}
                  </div>
                </div>
              </Box>
              <span className='flex justify-between py-6'>
                {/* <div className="flex justify-right">
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '160px',
                    }}
                    className="font-bold px-6 border border-cyan-600 rounded-md text-cyan-600"
                    variant="outlined"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleUpdate(formInput?._id)}
                  >
                    Add Info
                  </Button>
                </div> */}
                <Box sx={{ width: '400px' }} className='flex justify-center'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      height: '50px',
                      width: '345px',
                    }}
                    className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                    variant='outlined'
                    disabled={!isUploadedFilesDialogMounted && isLoading}
                    onClick={handleSaveButtonClick}
                  >
                    Save
                  </Button>
                </Box>
              </span>
            </div>
          </Box>
          <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>Save Successful</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The learning journey has been created successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Box>
  );
}
