import mitt from 'mitt';
import * as tus from 'tus-js-client';
import { v1 as uuid } from 'uuid';

export const getFileExtension = (uri) => {
  const match = /\.([a-zA-Z]+)$/.exec(uri);
  if (match !== null) {
    return match[1];
  }

  return '';
};

export const getMimeType = (extension) => {
  if (extension === 'jpg') return 'image/jpeg';
  return `image/${extension}`;
};

export const extractIdFromUrl = (url) => {
  const match = String(url).match(/uploads\/files\/([0-9a-fA-F]+)-/);
  return match ? match[1] : null;
};

async function getUploadData({
  file,
  onError,
  onProgress,
  onSuccess,
  onHandleResume,
  headers = {},
}) {
  if (
    !file ||
    typeof onError !== 'function' ||
    typeof onProgress !== 'function' ||
    typeof onSuccess !== 'function' ||
    typeof onHandleResume !== 'function'
  ) {
    throw new Error(
      `Params invalid, must contain: File file, function onError, function onProgress, function onSuccess, function onHandleResume`,
    );
  }

  const options = {
    endpoint: `${process.env.REACT_APP_API_URL}/v2/uploads`,
    // removeFingerprintOnSuccess: true,
    // chunkSize: Infinity,
    chunkSize: 5 * 1024 * 1024, // Each uploaded chunk will have ~5MB,
    // chunkSize: 50 * 1024, // test only: ~50KB
    retryDelays: [0, 1000, 3000, 5000],
    metadata: {
      filename: file.name,
      filetype: file.type,
    },
    headers: {
      ...headers,
      // "Authorization": "Bearer ..."
    },
    onError,
    onProgress,
    onSuccess,
  };

  const upload = new tus.Upload(file, options);

  if (typeof onHandleResume === 'function') {
    const previousUploads = await upload.findPreviousUploads();
    const onSelectIndex = async (uploadFileMetadata) => {
      if (!upload.url) {
        upload.url = uploadFileMetadata.uploadUrl;
      }
      upload.resumeFromPreviousUpload(uploadFileMetadata);
      return upload;
    };

    await onHandleResume(previousUploads, upload, onSelectIndex);
  }

  return upload;
}

export const clipFloatNumber = (num, dec = 2) => {
  return (num * 100).toFixed(dec);
};

export const getFilename = (inputString) => {
  const match = String(inputString).match(/tus-br-(.*?)-([^/]+)\//);
  if (match && match[1]) {
    return match[1];
  }

  return 'Err: no filename';
};

export async function createUploadData(
  uploadFile,
  onHandleResume,
  headers = {},
) {
  let onErrorCb = () => {};
  let onProgressCb = () => {};
  let onCompleteCb = () => {};
  const UPLOAD_EVENT = uuid();
  const uploadEvent = mitt();

  const uploadData = {
    uploadFile,
    isSuccess: false,
    isError: false,
    isOnProgress: false,
    upload: await getUploadData({
      file: uploadFile,
      onError: (e) => {
        uploadData.isError = true;
        uploadData.isOnProgress = false;
        uploadData.isSuccess = false;
        onErrorCb(e);
      },
      onProgress: (uploaded, total) => {
        uploadData.isError = false;
        uploadData.isSuccess = false;
        uploadData.isOnProgress = true;
        uploadData.totalBytes = total;
        uploadData.uploadedBytes = uploaded;
        uploadData.progress = uploadData.getProgress();
        onProgressCb(uploaded, total);
      },
      onSuccess: () => {
        uploadData.isError = false;
        uploadData.isSuccess = true;
        uploadData.isOnProgress = false;
        uploadData.uploadUrl = uploadData.getUploadUrl();
        uploadEvent.emit(UPLOAD_EVENT, uploadData.uploadUrl);
        onCompleteCb(uploadData.getUploadUrl());
      },
      onUploadUrlAvailable: () => {
        uploadData.uploadUrl = uploadData.getUploadUrl();
      },
      onHandleResume,
      headers,
    }),
    get fileId() {
      return extractIdFromUrl(this.upload?.url || null);
    },
    progress: 0,
    getProgress() {
      return clipFloatNumber(
        this.uploadData?.uploadedBytes || 1 / this.uploadData?.totalBytes || 1,
      );
    },
    uploadUrl: null,
    getUploadUrl() {
      return this.upload?.url || null;
    },
    startUpload(onError, onProgress, onComplete) {
      const context = this;

      function startUploadPromise(resolve, reject) {
        try {
          uploadEvent.on(UPLOAD_EVENT, (data) => {
            uploadEvent.off(UPLOAD_EVENT);
            resolve(data);
          });
          onErrorCb = onError;
          onProgressCb = onProgress;
          onCompleteCb = onComplete;
          context.upload.start();
        } catch (e) {
          uploadEvent.off(UPLOAD_EVENT);
          reject(e);
        }
      }

      return new Promise(startUploadPromise);
    },
  };

  return uploadData;
}
