import '@fontsource/poppins';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAuth } from '@src/contexts/authContext';
import { openLinkInPopUpWindow } from '@utils';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/interview`;

export default function EditInterviewPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const state = useLocation();
  const { interviewId } = useParams();
  const [data, setData] = useState({
    userId: '',
    jobId: '',
    displayName: '',
    email: '',
    interview: {
      attendees: [],
    },
    time: '',
    note: '',
    status: '',
  });

  const [open, setOpen] = React.useState(false);

  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const fetchDataAsync = async () => {
    try {
      if (interviewId) {
        const response = await axios.get(`${url}/${interviewId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        });
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchDataAsync();
  }, [auth.idToken]);

  const handleAddAttendee = () => {
    const { displayName, email } = data;
    if (displayName && email) {
      const newAttendee = { display_name: displayName, email };
      setData((prevData) => ({
        ...prevData,
        interview: {
          ...prevData.interview,
          attendees: [...prevData.interview.attendees, newAttendee],
        },
        // displayName: '',
        // email: '',
      }));
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handlePost = () => {
    const formData = {
      jobId: data.interview?.job_id,
      userId: data.interview?.user?._id,
      time: data.time || data?.interview?.datetime,
      attendees: data.interview.attendees,
      note: data.note,
      status: data.status,
    };

    axios
      .post(`${url}/set-draft`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item updated successfully:', response);
        setData({ ...data });
        fetchDataAsync();
        setOpenModal(true);
      })
      .catch((error) => {
        console.log(error.message);
        // toast.error('Change the date is required', {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      });
  };

  const handlePutStatus = () => {
    const formData = {
      status: data.status,
      attendees: data.interview.attendees,
      note: data.note,
    };

    axios
      .put(`${url}/${interviewId}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item updated successfully:', response);
        setData({ ...data });
        fetchDataAsync();
        toast.success('Status updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleCancel = () => {
    const formData = {
      userId: data?.calendar?.user_id,
      jobId: data.calendar.job_id,
      googleCalendarId: data?.calendar?.google_calendar_id,
      reply_note: 'The interview has been canceled by admin',
    };

    axios
      .patch(`${url}/schedule/cancel`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item canceled successfully:', response);
        setData({ ...data });
        fetchDataAsync();
        toast.success('Interview canceled successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleScheduled = () => {
    axios
      .post(
        `${url}/schedule/${interviewId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        },
      )
      .then((response) => {
        console.log('Item scheduled successfully:', response);
        setData({ ...data });
        fetchDataAsync();
        toast.success('Interview scheduled successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;

    if (newStatus === 'canceled') {
      handleCancel();
    } else if (newStatus === 'scheduled') {
      handleScheduled();
    }

    if (data) {
      setData({
        ...data,
        status: newStatus,
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/announcement-interview');
  };

  const handleDateChange = (newDate) => {
    setData((prevData) => ({
      ...prevData,
      time: newDate ? newDate.format('YYYY-MM-DDTHH:mm') : '',
    }));
    setOpen(false);
  };

  const handleRemoveAttendee = (email) => {
    setData((prevData) => ({
      ...prevData,
      interview: {
        ...prevData.interview,
        attendees: prevData.interview.attendees.filter(
          (attendee) => attendee.email !== email,
        ),
      },
    }));
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <br />
      <br />
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='px-16 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
        <div>
          <div className='grid grid-cols-2 px-12'>
            <div className='col-end-1 flex justify-center'>
              <div>
                <Box
                  className='rounded-xl'
                  sx={{
                    height: 'auto',
                    width: 'auto',
                    backgroundColor: 'white',
                    margin: 2,
                  }}
                >
                  <br />
                  <div className='text-sm'>
                    <div className='flex justify-start px-12 py-1'>
                      <div>Applicant`s name:</div> &nbsp; &nbsp;
                      <div className='px-4 text-gray-600'>
                        {data?.interview?.user?.full_name || '-'} |{' '}
                        {data?.interview?.user?.role_id}
                      </div>
                    </div>
                    <div className='flex justify-start px-12 py-1'>
                      <div>Phone number:</div>
                      <div className='px-12 text-gray-600'>
                        {data?.interview?.user?.phone_number || '-'}
                      </div>
                    </div>
                    <div className='flex justify-start px-12 py-1'>
                      <div>Clinic:</div> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp;
                      <div className='px-16 text-gray-600'>
                        {data?.interview?.clinic?.clinicName || '-'}
                      </div>
                    </div>
                    <div className='flex justify-start px-12 py-1'>
                      <div>Job Id:</div>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;
                      <div className='px-12 text-sm text-gray-600'>
                        {data?.interview?.job?.code || '-'}
                      </div>
                    </div>
                  </div>
                  <br />
                </Box>
                <Box
                  className='rounded-xl'
                  sx={{
                    height: 'auto',
                    width: 'auto',
                    backgroundColor: 'white',
                    margin: 2,
                  }}
                >
                  <div className='px-12 py-4'>
                    <div className='flex justify-between py-4'>
                      <div className='text-xl font-bold'>
                        Edit Request Interview
                      </div>
                      <div>
                        <Button
                          disabled
                          className='rounded-md border-gray-400 text-gray-400'
                          sx={{ height: '35px' }}
                          variant='outlined'
                        >
                          {data?.interview?.status || 'no status'}
                        </Button>
                      </div>
                    </div>
                    {data ? (
                      <div className='flex py-3'>
                        {/* <div>
                    <Icon
                      icon='tabler:trash'
                      color='#189bd3'
                      width='22'
                      className='py-1.5'
                      onClick={() =>
                        showDeleteConfirmationDialog(data?.interview?._id)
                      }
                    />
                  </div> */}
                        {/* &nbsp; &nbsp; &nbsp; */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div>
                            <DateTimePicker
                              reduceAnimations
                              open={open}
                              format='ddd, MMM DD, YYYY - hh:mm A'
                              sx={{ width: '280px' }}
                              value={
                                data?.interview?.datetime
                                  ? dayjs(
                                      data?.interview?.datetime,
                                      'YYYY-MM-DDTHH:mm',
                                    )
                                  : null
                              }
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={handleDateChange}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                  onClick: () => setOpen(true),
                                },
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>
                    ) : null}
                    <div className='py-3' />
                    <div className=' text-gray-900'>Add new attendee:</div>
                    <div className='py-3' />
                    <div className='flex justify-between'>
                      <input
                        placeholder='name'
                        className=' focus:ring- -600 block w-full rounded-md border-0 bg-white px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                        style={{
                          width: '200px',
                          height: '45px',
                        }}
                        // value={data.displayName}
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            displayName: e.target.value,
                          }))
                        }
                      />
                      &nbsp; &nbsp; &nbsp;
                      <input
                        placeholder='email'
                        className=' focus:ring- -600 block rounded-md border-0 bg-white px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
          focus:ring-inset sm:text-sm sm:leading-6'
                        style={{
                          width: '230px',
                          height: '45px',
                        }}
                        // value={data.email}
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            email: e.target.value,
                          }))
                        }
                      />
                      <Button
                        sx={{ color: '#189bd3' }}
                        onClick={handleAddAttendee}
                      >
                        ADD
                      </Button>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <div>
                      <input
                        placeholder='add note'
                        className=' focus:ring- -600 block rounded-md border-0 bg-white px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
            focus:ring-inset sm:text-sm sm:leading-6'
                        style={{
                          width: '520px',
                          height: '45px',
                        }}
                        value={data.note}
                        onChange={(e) =>
                          setData({
                            ...data,
                            note: e.target.value,
                          })
                        }
                      />
                    </div>
                    <br />
                    <div className='text-gray-900'>Attendees:</div>
                    <div className='text-sm text-gray-600'>
                      {data?.interview?.attendees &&
                      data?.interview?.attendees.length > 0 ? (
                        data?.interview?.attendees.map((attendee) =>
                          attendee.removed ? null : (
                            <ul
                              key={attendee._id}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <li>
                                {attendee?.display_name} - {attendee?.email}
                              </li>
                              <li
                                style={{ marginLeft: 'auto', color: 'white' }}
                              >
                                <Icon
                                  icon='clarity:remove-solid'
                                  color='gray'
                                  width='20'
                                  className='h-5 w-5 scale-100 transform rounded-full py-0.5 px-2 transition-transform group-hover:scale-110'
                                  onClick={() =>
                                    handleRemoveAttendee(attendee.email)
                                  }
                                />
                              </li>
                            </ul>
                          ),
                        )
                      ) : (
                        <div className=' py-4 px-4 text-gray-600'>-</div>
                      )}
                    </div>
                    <div className='py-2 text-gray-900'>Link:</div>
                    <div className='py-3 px-4 text-sm text-gray-600'>
                      <Button
                        variant='text'
                        className='justify-start text-gray-600'
                        sx={{
                          textTransform: 'none',
                          padding: 0,
                          width: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          openLinkInPopUpWindow(data?.interview?.link || '');
                        }}
                      >
                        {data?.interview?.link || '-'}
                      </Button>
                    </div>
                    <div className='py-2 text-gray-900'>Note:</div>
                    <div className='py-3 px-4 text-sm text-gray-600'>
                      {data?.interview?.note || <div>-</div>}
                    </div>
                    <div className='py-2 text-gray-900'>Reply status:</div>
                    <div className='py-3 px-4 text-sm text-gray-600'>
                      {data?.interview?.reply_status || <div>-</div>}
                    </div>
                    <div className='py-2 text-gray-900'>Reply note:</div>
                    <Box
                      sx={{
                        height: 'auto',
                        width: '500px',
                      }}
                      className='py-3 px-4 text-sm text-gray-600'
                    >
                      {data?.interview?.reply_notes || <div>-</div>}
                    </Box>
                    <br />
                    <div className='flex justify-end'>
                      <Button
                        sx={{ textTransform: 'none', width: '130px' }}
                        className='font-bold'
                        color='secondary'
                        onClick={handleBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{ textTransform: 'none', color: '#189bd3' }}
                        onClick={() => {
                          handlePost();
                          handleOpenModal();
                        }}
                        className='font-bold'
                        disabled={
                          data?.interview?.status === 'scheduled' ||
                          data?.interview?.status === 'passed' ||
                          data?.interview?.status === 'deleted'
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Update Successful</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        The interview has been updated successfully.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseModal} autoFocus>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </div>
            </div>
            <div className='col-auto py-4'>
              <div>
                <Box
                  className='rounded-xl'
                  sx={{
                    height: '160px',
                    width: '300px',
                    backgroundColor: 'white',
                  }}
                >
                  <div className='px-4 py-4 text-gray-900'>Change status:</div>
                  <div className='flex justify-between py-2 px-4'>
                    <div>
                      <select
                        id='status'
                        value={data?.status || ''}
                        onChange={handleStatusChange}
                        className='font-poppins block w-full rounded-md border-0 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                        style={{ height: '38px', width: '150px' }}
                      >
                        <option value=''>
                          {data?.interview?.status
                            ? data.interview.status.charAt(0).toUpperCase() +
                              data.interview.status.slice(1)
                            : 'Select status'}
                        </option>
                        <option value='pending'>Pending</option>
                        <option value='accepted'>Accepted</option>
                        <option value='rejected'>Rejected</option>
                        <option value='canceled'>Canceled</option>
                        <option value='scheduled'>Scheduled</option>
                      </select>
                    </div>
                    <div>
                      <Button
                        sx={{ textTransform: 'none', color: '#189bd3' }}
                        className='rounded-md font-bold'
                        variant='outlined'
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={handlePutStatus}
                      >
                        change
                      </Button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </Box>
    </Box>
  );
}
