import api from '@api';

export const fileDownloader = async (token, url, configs = {}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
      ...configs,
    };

    const response = await api.get(url, config);

    if (response.data && response.headers['content-disposition']) {
      const param = 'filename=';
      const contentDisposition = response.headers['content-disposition'];
      const startIndex = contentDisposition.indexOf(param);

      if (startIndex !== -1) {
        const endIndex = contentDisposition.length;
        const filename = contentDisposition.substring(
          startIndex + param.length,
          endIndex,
        );
        response.data.name = filename;
      } else {
        response.data.name = 'download';
      }
    }

    return response.data || {};
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.log(error);
    return null;
  }
};
