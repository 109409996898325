import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CreateListedEventForm from '@src/components/forms/createListedEvent.form';
import ListedEventLocation from '@src/components/forms/listedEventLocation.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import { mapDuplicatePastEvent } from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/event`;
const format_date = { year: 'numeric', month: 'long', day: 'numeric' };
const initialUpdateData = {
  event_title: '',
  event_link: '',
  event_desc: '',
  live_stream: '',
  roles: '',
  organiser_name: '',
  organiser_desc: '',
  organiser_image: '',
  event_image: '',
  learning_file: '',
  event_offline: false,
  street: '',
  country: '',
  postal_code: '',
  city: '',
  unit_number: '',
  event_date: null,
  from: null,
  to: null,
  latitude: '',
  longitude: '',
  is_hybrid: false,
  is_cme: false,
};

export default function DuplicatePastEvent() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { eventId } = useParams();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [formInput, setFormInput] = React.useState(null);
  const [update, setUpdate] = React.useState(initialUpdateData);

  const [learnVideoTus, setLearnVideoTus] = React.useState(null);
  const [eventImageTus, setEventImageTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const setPostStateData = (data) => {
    setIsLoading(true);
    if (isMounted.current) {
      setFormInput(data);
      setUpdate(data);
    }
    setIsLoading(false);
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const duplicatePastEvent = () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let learnVideo;
      let eventImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Duplicate pas event');

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapDuplicatePastEvent(formInput);

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .post(`${url}/duplicate-past-event/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setPostStateData({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/update-learning-journey/${eventId}`);
  };

  React.useEffect(() => {
    if (auth?.idToken && eventId) {
      axios
        .get(`${url}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setFormInput(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [auth, eventId]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef]);

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <UploadedFilesDialog ref={uploadResumeRef} />
      <Box
        sx={{ width: '980px', height: '60px' }}
        className='flex justify-start'
      >
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
      </Box>
      <div className='grid grid-cols-2'>
        <div className='col-end-1'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '569px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <CreateListedEventForm
              formInput={formInput}
              setFormInput={setFormInput}
              update={update}
              setUpdate={setUpdate}
              setFileTus={{ setLearnVideoTus, setEventImageTus }}
              onTusResume={handleOnUploadResume}
              setIsLoading={setIsLoading}
            />
            <div className='py-6' />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '569px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='text-md py-8 px-6 font-bold text-gray-600'>
                Event Location
              </div>
              <div className='flex justify-start px-2 py-4'>
                <div className='text-md px-2 py-4 font-bold text-gray-600'>
                  This is an online event
                </div>
                <Checkbox
                  defaultChecked
                  onChange={(e) =>
                    setFormInput({
                      ...formInput,
                      event_offline: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <ListedEventLocation
              formInput={formInput}
              setFormInput={setFormInput}
              update={update}
              setUpdate={setUpdate}
            />
            <div className='py-6' />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '162px',
              width: '569px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='text-md py-6 px-6 font-bold text-gray-600'>
              Event Date/Time
            </div>
            <div className='flex'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='px-5'>
                  <DatePicker
                    sx={{ width: '200px' }}
                    label='Event Date'
                    className='bg-gray-100'
                    format='LL'
                    // value={formInput?.date}
                    value={formInput?.date}
                    onChange={(newValue) =>
                      setFormInput({
                        ...formInput,
                        date: newValue?.toISOString(),
                      })
                    }
                  />
                </div>
                <div className='flex'>
                  <div>
                    <TimePicker
                      sx={{ width: '130px' }}
                      label='From'
                      className='bg-gray-100'
                      value={formInput?.from}
                      onChange={(newValue) => {
                        const selectedTime = new Date(newValue);
                        setFormInput({ ...formInput, from: selectedTime });
                      }}
                    />
                  </div>
                  <div className='px-2'>
                    <TimePicker
                      sx={{ width: '130px' }}
                      label='To'
                      className='bg-gray-100'
                      value={formInput?.to}
                      onChange={(newValue) => {
                        const selectedTime = new Date(newValue);
                        setFormInput({ ...formInput, to: selectedTime });
                      }}
                    />
                  </div>
                </div>
              </LocalizationProvider>
            </div>
          </Box>
        </div>
        <div className='col-auto'>
          <div className='py-4 px-6 text-lg font-bold text-gray-600'>
            Preview
          </div>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '389px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='py-6 px-6'>
              <div className='py-2 text-sm text-gray-400'>
                Event Type: {formInput?.roles}
              </div>
              <div className='py-4 font-bold'> {formInput?.event_title}</div>
              <div className='flex justify-start py-2'>
                <Icon
                  icon='material-symbols:location-on-outline'
                  color='#189bd3'
                  width='20'
                />
                <div className='px-1 text-sm text-gray-400'>
                  {formInput?.street
                    ? `${formInput?.street} ${formInput?.unit_number} ${formInput?.city} ${formInput?.country} ${formInput?.postal_code}`
                    : formInput?.live_stream}
                </div>
              </div>
              <div className='flex justify-start py-2'>
                <Icon
                  icon='material-symbols:calendar-month-outline'
                  color='#189bd3'
                  width='20'
                />
                <div className='px-1 text-sm text-gray-400'>
                  {new Date(formInput?.event_date).toLocaleDateString(
                    'au-AU',
                    format_date,
                  ) || ''}
                </div>
              </div>
              <div className='py-2 px-1 font-bold text-cyan-500'>
                Organised by: {formInput?.organiser_name}
              </div>
              <br />
              <span className='flex justify-center'>
                <Box sx={{ width: '400px' }} className='flex justify-end py-1'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '330px',
                    }}
                    className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                    variant='outlined'
                    onClick={() => {
                      duplicatePastEvent();
                      navigate('/event-management');
                    }}
                  >
                    Duplicate this event to new copy
                  </Button>
                </Box>
              </span>
            </div>
          </Box>
        </div>
      </div>
    </Box>
  );
}
