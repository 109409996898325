/* eslint-disable import/no-duplicates */
import '@fontsource/poppins';
import Button from '@mui/material/Button';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import React, { useEffect, useState } from 'react';

export default function ListedEventLocation({
  formInput,
  setFormInput,
  update,
  setUpdate,
  disabled,
}) {
  const [markerPosition, setMarkerPosition] = useState({
    lat: 0.0,
    lng: 0.0,
  });
  const [addressData, setAddressData] = useState({
    street: '',
    city: '',
    country: '',
    unit_number: '',
    postal_code: '',
    latitude: '',
    longitude: '',
  });

  async function reverseGeocode(lat, lng) {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDQyYInHNOU2rQg3pr1fdtEWBuwQ3nTZlw`,
      );
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        const { results } = data;
        const addressComponents = results[0]?.address_components || [];
        const street =
          addressComponents.find((comp) => comp.types.includes('route'))
            ?.long_name || '';
        const unit_number =
          addressComponents.find((comp) => comp.types.includes('street_number'))
            ?.long_name || '';
        const city =
          addressComponents.find(
            (comp) =>
              comp.types.includes('locality') ||
              comp.types.includes('neighborhood') ||
              comp.types.includes('political'),
          )?.long_name || '';
        const country =
          addressComponents.find((comp) => comp.types.includes('country'))
            ?.long_name || '';
        const postal_code =
          addressComponents.find((comp) => comp.types.includes('postal_code'))
            ?.long_name || '';

        const geometry = results[0]?.geometry || {};
        const location = geometry.location || {};
        const { lat: latitude, lng: longitude } = location;

        return {
          unit_number,
          street,
          city,
          country,
          postal_code,
          latitude,
          longitude,
        };
      }
      if (data.status === 'ZERO_RESULTS') {
        console.log('No results found for the given coordinates.');
        return {
          unit_number: '',
          street: '',
          city: '',
          country: '',
          postal_code: '',
          latitude: '',
          longitude: '',
        };
      }
      throw new Error(`Geocoding request failed with status: ${data.status}`);
    } catch (error) {
      console.error('Error fetching reverse geocoding data:', error);
      return {
        unit_number: '',
        street: '',
        city: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: '',
      };
    }
  }

  useEffect(() => {
    async function updateLocationData() {
      const { lat, lng } = markerPosition;
      const locationData = await reverseGeocode(lat, lng);
      setAddressData(locationData);
    }
    updateLocationData();
  }, [markerPosition]);

  useEffect(() => {
    const {
      street,
      city,
      country,
      unit_number,
      postal_code,
      latitude,
      longitude,
    } = addressData;
    if (formInput) {
      setFormInput({
        ...formInput,
        latitude: formInput.latitude || latitude,
        longitude: formInput.longitude || longitude,
        street: formInput.street || street,
        city: formInput.city || city,
        country: formInput.country || country,
        unit_number: formInput.unit_number || unit_number,
        postal_code: formInput.postal_code || postal_code,
      });
    } else {
      setUpdate({
        ...update,
        latitude: update.latitude || latitude,
        longitude: update.longitude || longitude,
        street: update.street || street,
        city: update.city || city,
        country: update.country || country,
        unit_number: update.unit_number || unit_number,
        postal_code: update.postal_code || postal_code,
      });
    }
  }, [addressData, formInput, setFormInput, update, setUpdate]);

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setMarkerPosition({ lat, lng });
  };

  const handleRefresh = () => {
    const newLat = 1.3 + Math.random() * 0.2;
    const newLng = 103.7 + Math.random() * 0.2;
    setMarkerPosition({ lat: newLat, lng: newLng });
  };

  const handleSearchByAddress = async () => {
    try {
      const address = formInput ? formInput.street : update.street;
      if (address) {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address,
          )}&key=AIzaSyDQyYInHNOU2rQg3pr1fdtEWBuwQ3nTZlw`,
        );
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === 'OK' && data.results.length > 0) {
          const { location } = data.results[0].geometry;
          setMarkerPosition({
            lat: location.lat,
            lng: location.lng,
          });
        } else {
          console.log('No results found for the given address.');
        }
      }
    } catch (error) {
      console.error('Error searching maps by address:', error);
    }
  };

  return (
    <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
      <div className='sm:col-span-6'>
        <div>
          <LoadScript
            googleMapsApiKey='AIzaSyDQyYInHNOU2rQg3pr1fdtEWBuwQ3nTZlw'
            libraries
          >
            <GoogleMap
              center={markerPosition}
              zoom={13}
              mapContainerStyle={{ height: '300px', width: '100%' }}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: true,
              }}
              onClick={handleMapClick}
            >
              <Marker
                position={markerPosition}
                draggable={!disabled}
                onDragEnd={(e) => {
                  const lat = e.latLng.lat();
                  const lng = e.latLng.lng();
                  setMarkerPosition({ lat, lng });
                }}
              />
            </GoogleMap>
            {/* <Button sx={{ textTransform: 'none' }} onClick={handleRefresh}>
              Refresh Map
            </Button> */}
          </LoadScript>
        </div>
      </div>

      <div className='sm:col-span-6'>
        <div className='mt-2'>
          <input
            placeholder='Street Name/Building'
            id='street'
            type='text'
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={
              formInput
                ? formInput.street || addressData.street
                : update.street || addressData.street
            }
            onChange={(e) =>
              formInput
                ? setFormInput({ ...formInput, street: e.target.value })
                : setUpdate({ ...update, street: e.target.value })
            }
            disabled={disabled}
          />
        </div>
      </div>
      <div className='sm:col-span-3'>
        <div>
          <input
            id='city'
            placeholder='City'
            type='text'
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={
              formInput
                ? formInput.city || addressData.city
                : update.city || addressData.city
            }
            onChange={(e) =>
              formInput
                ? setFormInput({ ...formInput, city: e.target.value })
                : setUpdate({ ...update, city: e.target.value })
            }
            disabled={disabled}
          />
        </div>
      </div>
      <div className='sm:col-span-3'>
        <div>
          <input
            id='unit'
            placeholder='Unit Number'
            type='text'
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={
              formInput
                ? formInput.unit_number || addressData.unit_number
                : update.unit_number || addressData.unit_number
            }
            onChange={(e) =>
              formInput
                ? setFormInput({ ...formInput, unit_number: e.target.value })
                : setUpdate({ ...update, unit_number: e.target.value })
            }
            disabled={disabled}
          />
        </div>
      </div>
      <div className='sm:col-span-3'>
        <div>
          <input
            id='country'
            placeholder='Country'
            type='text'
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={
              formInput
                ? formInput.country || addressData.country
                : update.country || addressData.country
            }
            onChange={(e) =>
              formInput
                ? setFormInput({ ...formInput, country: e.target.value })
                : setUpdate({ ...update, country: e.target.value })
            }
            disabled={disabled}
          />
        </div>
      </div>
      <div className='sm:col-span-3'>
        <div>
          <input
            id='post'
            placeholder='Postal Code'
            type='text'
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            value={
              formInput
                ? formInput.postal_code || addressData.postal_code
                : update.postal_code || addressData.postal_code
            }
            onChange={(e) =>
              formInput
                ? setFormInput({ ...formInput, postal_code: e.target.value })
                : setUpdate({ ...update, postal_code: e.target.value })
            }
            disabled={disabled}
          />
        </div>
      </div>
      <div className='flex justify-end sm:col-span-6'>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={handleSearchByAddress}
          disabled={disabled}
        >
          Search
        </Button>
      </div>

      <div className='sm:col-span-6'>
        <div className='text-xs text-gray-300'>
          *refresh the page if the maps did not show
        </div>
      </div>
    </div>
  );
}
