import { Icon } from '@iconify/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { getUserProfileById } from '@src/api/Users';
import { useAuth } from '@src/contexts/authContext';
import {
  menuAnnouncement,
  menuDatabase,
  menuMISC,
} from '@src/utils/consts/routesMainMenu.const';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MenuItem from './menuItem.common';

function SidebarMenu() {
  const [users, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const { auth, dispatch } = useAuth();
  const id = localStorage.getItem('authentication');
  const { user } = JSON.parse(id);

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    if (auth.idToken) {
      getUserProfileById(auth.idToken) // user._id
        .then((data) => {
          setUser(data.data);
        })
        .catch((error) => {
          console.error('Error fetching user profile:', error);
        });
    }
  }, [auth.idToken]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoutConfirm = () => {
    handleLogout();
    handleClose();
  };

  const forcedLogout = () => {
    const loginTime = localStorage.getItem('loginTime');
    const currentTime = new Date().getTime();
    const fourHours = 4 * 60 * 60 * 1000;

    if (currentTime - loginTime > fourHours) {
      handleLogout();
      toast.error(
        'You have been logged out due to inactivity. Please login again.',
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
    }
  };

  useEffect(() => {
    const loginTime = new Date().getTime();
    localStorage.setItem('loginTime', loginTime);

    const interval = setInterval(forcedLogout, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='fixed top-0 left-0 flex h-full w-[314px] flex-col border-r bg-white'>
      <Link
        to='/'
        className='my-5 ml-0 flex h-14 items-center justify-center border-b pl-0'
      >
        <img src='/logo.svg' alt='logo' className='w-44' />
      </Link>
      <div className='flex-grow overflow-y-auto overflow-x-hidden'>
        <div className='flex list-none flex-col space-y-1 py-4 pl-0 pb-16'>
          <MenuItem title='Database' menus={menuDatabase} />
          <MenuItem title='Announcement' menus={menuAnnouncement} />
          <MenuItem title='MISC' menus={menuMISC} />
        </div>
      </div>
      <div
        className='relative ml-0 flex items-center bg-white pl-10'
        style={{ borderTop: '1px solid #DFDFDF' }}
      >
        <div className='flex flex-col justify-center gap-y-1.5 py-4 leading-[1px]'>
          <span
            key={user._id}
            className='truncate text-lg font-semibold text-[#005073]'
          >
            {user?.full_name}
          </span>
          <span className='text-sm font-normal text-gray-400'>
            {user?.role}
          </span>
          <button
            type='button'
            name='menu user'
            onClick={handleOpen}
            className='absolute right-5 top-6 flex cursor-pointer items-center border-none bg-transparent text-3xl outline-none'
          >
            <Icon icon='ic:baseline-logout' color='#005073' />
          </button>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Logout Confirmation</DialogTitle>
        <DialogContent>
          <p className='font-poppins'>Are you sure you want to log out?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleLogoutConfirm}
            variant='contained'
            color='error'
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(SidebarMenu);
