import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddEventInformationForm from '@src/components/forms/addEventInformation.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import { mapUpdateEventHistoryFormInputData } from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';

import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/event`;
const format_date = { year: 'numeric', month: 'long', day: 'numeric' };
const initialUpdateData = {
  past_link: '',
  past_desc: '',
  learning_file: '',
  source_link: '',
  source_slide: '',
  link_question: '',
  event_title: '',
  event_link: '',
  live_stream: '',
  roles: '',
  organiser_name: '',
  organiser_desc: '',
  event_image: '',
  event_offline: '',
  street: '',
  country: '',
  postal_code: '',
  city: '',
  unit_number: '',
  event_date: '',
  event_from: '',
  event_to: '',
  links: [],
};

export default function UpdateEventHistory() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const { eventId } = useParams();
  const [formInput, setFormInput] = React.useState(null);
  const [update, setUpdate] = React.useState(initialUpdateData);

  const [slidePdfTus, setSlidePdfTus] = React.useState(null);
  const [learnVideoTus, setLearnVideoTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const setPostStateData = (data) => {
    setIsLoading(true);
    if (isMounted.current) {
      setFormInput(data);
      setUpdate(data);
    }
    setIsLoading(false);
  };

  const setOpenModalState = (data) => {
    if (isMounted.current) setOpen(data);
  };

  const handleOpenModal = async () => {
    setOpenModalState(true);
  };

  const handleCloseModal = async () => {
    setOpenModalState(false);
    navigate(state?.prevLocation || -1);
  };

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const savePastEvent = () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let slidePdf;
      let learnVideo;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Update event history');

      if (slidePdfTus) {
        slidePdf = slidePdfTus;
        uploads.push(slidePdf);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapUpdateEventHistoryFormInputData(formInput);

        if (slidePdf && slidePdf.upload.url) {
          formData.source_slide = slidePdf.upload.url;
        }

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .put(`${url}/past-event/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setPostStateData({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            handleOpenModal();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (auth?.idToken && eventId) {
      axios
        .get(`${url}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setFormInput(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [auth, eventId]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (eventId && uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef, eventId]);

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <UploadedFilesDialog ref={uploadResumeRef} />
      <Box
        sx={{ width: '980px', height: '60px' }}
        className='flex justify-start'
      >
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
      </Box>

      <div className='grid grid-cols-2'>
        <div className='col-end-1'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '569px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <AddEventInformationForm
              formInput={formInput}
              setFormInput={setFormInput}
              update={update}
              setUpdate={setUpdate}
              setFileTus={{
                setSlidePdfTus,
                setLearnVideoTus,
              }}
              onTusResume={handleOnUploadResume}
              setIsLoading={setIsLoading}
            />
            <div className='py-6' />
          </Box>
        </div>
        <div className='col-auto'>
          <div className='py-4 px-6 text-lg font-bold text-gray-600'>
            Preview
          </div>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '389px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='py-6 px-6'>
              <div className='py-2 text-sm text-gray-400'>
                Event Type: {formInput?.roles}
              </div>
              <div className='py-4 font-bold'> {formInput?.event_title}</div>
              <div className='flex justify-start py-2'>
                <Icon
                  icon='material-symbols:location-on-outline'
                  color='#189bd3'
                  width='20'
                />
                <div className='px-1 text-sm text-gray-400'>
                  {formInput?.street
                    ? `${formInput?.street} ${formInput?.unit_number} ${formInput?.city} ${formInput?.country} ${formInput?.postal_code}`
                    : formInput?.live_stream}
                </div>
              </div>
              <div className='flex justify-start py-2'>
                <Icon
                  icon='material-symbols:calendar-month-outline'
                  color='#189bd3'
                  width='20'
                />
                <div className='px-1 text-sm text-gray-400'>
                  {new Date(formInput?.event_date).toLocaleDateString(
                    'au-AU',
                    format_date,
                  ) || ''}
                </div>
              </div>
              <div className='py-2 px-1 font-bold text-cyan-500'>
                Organised by: {formInput?.organiser_name}
              </div>
              <br />
              <span className='flex justify-between'>
                <div className='justify-right flex py-1'>
                  {/* <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '150px',
                    }}
                    className="font-bold px-6 border border-cyan-600 rounded-md text-cyan-600"
                    variant="outlined"
                    onClick={() => {
                      navigate(`/duplicate-event/${eventId}`);
                    }}
                  >
                    Duplicate
                  </Button> */}
                </div>
                <Box sx={{ width: '400px' }} className='flex justify-end py-1'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '330px',
                    }}
                    className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                    variant='outlined'
                    onClick={() => {
                      savePastEvent();
                      handleOpenModal();
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </span>
            </div>
          </Box>
          <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>Update Successful</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The event has been updated successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Box>
  );
}
