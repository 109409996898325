import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CreateListedEventForm from '@src/components/forms/createListedEvent.form';
import EventDateForm from '@src/components/forms/eventDate.form';
import ListedEventLocation from '@src/components/forms/listedEventLocation.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import {
  mapUpdateLearningJourneyFormInputData,
  mapUpdateListedEventFormInputData,
  mapUpdateUnlistedEventFormInputData,
} from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';
import { dequal as isEqual } from 'dequal';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/event`;
const format_date = { year: 'numeric', month: 'long', day: 'numeric' };
const initialUpdateData = {
  event_title: '',
  event_link: '',
  event_desc: '',
  live_stream: '',
  roles: '',
  organiser_name: '',
  organiser_desc: '',
  organiser_image: '',
  event_image: '',
  learning_file: '',
  event_offline: false,
  street: '',
  country: '',
  postal_code: '',
  city: '',
  unit_number: '',
  event_date: '',
  event_from: '',
  event_to: '',
  latitude: '',
  longitude: '',
  is_hybrid: false,
  is_cme: false,
  sort: '',
};

export default function UpdateEventListed() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { eventId } = useParams();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [openUnlistModal, setOpenUnlistModal] = React.useState(false);
  const [openToLearningModal, setOpenToLearningModal] = React.useState(false);

  const [formInput, setFormInputState] = React.useState(null);
  const [update, setUpdate] = React.useState(initialUpdateData);
  const [splitTextLiveStream, setSplitTextLiveStream] = React.useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const [learnVideoTus, setLearnVideoTus] = React.useState(null);
  const [eventImageTus, setEventImageTus] = React.useState(null);
  const [orgImageTus, setOrgImageTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const setFormInput = (data, log) => {
    if (log) console.log(log);

    if (!isEqual(data, formInput)) {
      setFormInputState(data);
    }
  };

  const setPostStateData = (data) => {
    setIsLoading(true);
    if (isMounted.current) {
      setFormInput(data);
      setUpdate(data);
    }
    setIsLoading(false);
  };

  const handleModals = {
    open: {
      update: async () => setOpenUpdateModal(true),
      unlist: async () => setOpenUnlistModal(true),
      toLearning: async () => setOpenToLearningModal(true),
    },
    close: {
      update: async () => setOpenUpdateModal(false),
      unlist: async () => setOpenUnlistModal(false),
      toLearning: async () => setOpenToLearningModal(false),
    },
  };

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const handleCheckboxChange = async (event) => {
    setFormInput({ ...formInput, event_offline: event.target.checked });
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const updateLearningJourney = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let learnVideo;
      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Move to learning journey');

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapUpdateLearningJourneyFormInputData(formInput);

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .put(`${url}/learning-journey/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setPostStateData({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const updateListEvent = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let learnVideo;
      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Update event');

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapUpdateListedEventFormInputData(formInput);

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .put(`${url}/event-list/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setPostStateData({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            handleModals.open.update();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const updateUnlistEvent = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let learnVideo;
      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Unlist event');

      if (learnVideoTus) {
        learnVideo = learnVideoTus;
        uploads.push(learnVideo);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapUpdateUnlistedEventFormInputData(formInput);

        if (learnVideo && learnVideo.upload.url) {
          formData.learning_file = learnVideo.upload.url;
        }

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .put(`${url}/event-unlist/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setPostStateData({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  const handleImageChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const orgImage = await tusUtils.createUploadData(
          file,
          handleOnUploadResume,
          {
            Authorization: `Bearer ${auth.idToken}`,
          },
        );
        setOrgImageTus(orgImage);
      } catch (e) {
        console.error(e);
      }

      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        org_image: file,
      }));
    }

    setIsLoading(false);
  };

  // const handleSaveButtonClick = async () => {
  //   if (!update?.event_date && !update?.event_from && !update?.event_to) {
  //     toast.error('Date & Time are required to update.');
  //     return;
  //   }

  //   updateListEvent();
  // };

  React.useEffect(() => {
    const maxLength = 40;
    const inputText =
      formInput?.live_stream || 'please enter live stream link!';
    const newValue = [];

    for (let i = 0; i < inputText.length; i += maxLength) {
      newValue.push(inputText.substr(i, maxLength));
    }

    setSplitTextLiveStream(newValue);
  }, [formInput?.live_stream]);

  React.useEffect(() => {
    if (auth?.idToken && eventId) {
      axios
        .get(`${url}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setFormInput(response.data);
          setUpdate(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, eventId, setIsLoading]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef]);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  
  function formatEventTypes(eventTypes) {
    if (!eventTypes || !Array.isArray(eventTypes) || eventTypes.length === 0)
      return 'No role';

    const formattedEventTypes = eventTypes
      .map((eventType) =>
        eventType
          .split(/[._]/)
          .map((word) => capitalize(word))
          .join('-'),
      )
      .join(', ');

    return formattedEventTypes;
  }

  return (
    <Box sx={{ width: 'calc(100vw - 20rem)', fontFamily: 'Poppins' }}>
      <UploadedFilesDialog ref={uploadResumeRef} />
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='mr-6 grid grid-cols-12 gap-6'>
        <div className='col-span-7'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '100%',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <CreateListedEventForm
              formInput={formInput}
              setFormInput={setFormInput}
              update={update}
              setUpdate={setUpdate}
              setFileTus={{ setLearnVideoTus, setEventImageTus }}
              onTusResume={handleOnUploadResume}
              setIsLoading={setIsLoading}
            />
            <div className='py-6' />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '100%',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='py-2'>
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='sm:col-span-6'>
                  <label className='text-md block py-4 font-bold leading-6 text-gray-600'>
                    Organiser Image (Optional)
                  </label>
                  <Box
                    sx={{
                      width: '100px',
                      height: '100px',

                      overflow: 'hidden',
                    }}
                    className='flex justify-center rounded-xl bg-gray-300'
                  >
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      id='upload-file-inputs'
                    />

                    {selectedFile ||
                    previewUrl ||
                    formInput?.organiser_image ? (
                      <div>
                        <img
                          src={previewUrl || formInput?.organiser_image}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ) : null}
                  </Box>
                  <div className='py-5'>
                    <label htmlFor='upload-file-inputs'>
                      <Button
                        sx={{ textTransform: 'none' }}
                        variant='contained'
                        component='span'
                        className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
                      >
                        Upload Image
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='py-2 sm:col-span-6'>
                  <label className='font-poppins text-md block font-bold leading-6 text-gray-600 after:ml-0.5 after:text-gray-600 after:content-["*"]'>
                    Organiser Name
                  </label>
                  <div className='mt-2'>
                    <input
                      id='org_name'
                      placeholder='Enter Organiser Name'
                      type='text'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                      value={
                        formInput
                          ? formInput.organiser_name || ''
                          : update.organiser_name || ''
                      }
                      onChange={(e) =>
                        formInput
                          ? setFormInput({
                              ...formInput,
                              organiser_name: e.target.value,
                            })
                          : setUpdate({
                              ...update,
                              organiser_name: e.target.value,
                            })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='sm:col-span-6'>
                  <label className='text-md block font-bold leading-6 text-gray-600'>
                    Organiser Description
                  </label>
                  <div className='mt-2 font-medium'>
                    <textarea
                      placeholder='Add Organiser Description'
                      rows='4'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      value={
                        formInput
                          ? formInput.organiser_desc || ''
                          : update.organiser_desc || ''
                      }
                      onChange={(e) =>
                        formInput
                          ? setFormInput({
                              ...formInput,
                              organiser_desc: e.target.value,
                            })
                          : setUpdate({
                              ...update,
                              organiser_desc: e.target.value,
                            })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '100%',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='text-md py-8 px-6 font-bold text-gray-600'>
                Event Location
              </div>
              <div className='flex justify-start px-2 py-4'>
                <div className='text-md px-2 py-4 font-bold text-gray-600'>
                  This is an offline event
                </div>
                <Checkbox
                  checked={Boolean(formInput?.event_offline)}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
            <ListedEventLocation
              formInput={formInput}
              setFormInput={setFormInput}
              update={update}
              setUpdate={setUpdate}
              // disabled={Boolean(formInput?.event_offline)}
            />
            <div className='py-6' />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '162px',
              width: '100%',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='text-md py-6 px-6 font-bold text-gray-600'>
              Event Date/Time
            </div>
            <EventDateForm
              formInput={formInput}
              setFormInput={setFormInput}
              update={update}
              setUpdate={setUpdate}
            />
          </Box>
        </div>

        <div className='col-auto'>
          <div className='py-4 px-6 text-lg font-bold text-gray-600'>
            Preview
          </div>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              minWidth: '400px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='py-6 px-6'>
              <div className='py-2 text-sm text-gray-400'>
                Event Type: {formatEventTypes(formInput?.roles)}
              </div>
              <div className='py-4 font-bold'> {formInput?.event_title}</div>
              <div className='flex justify-start py-2'>
                <div>
                  <Icon
                    icon='material-symbols:location-on-outline'
                    color='#189bd3'
                    width='20'
                  />
                </div>
                <div className='px-2 text-sm text-gray-400'>
                  {(formInput?.unit_number ||
                    formInput?.street ||
                    formInput?.city ||
                    formInput?.country ||
                    formInput?.postal_code) &&
                  formInput?.live_stream ? (
                    <div>Hybrid event</div>
                  ) : formInput?.unit_number ||
                    formInput?.street ||
                    formInput?.city ||
                    formInput?.country ||
                    formInput?.postal_code ? (
                    `${formInput?.unit_number}, ${formInput?.street}, ${formInput?.city}, ${formInput?.country}, ${formInput?.postal_code}`
                  ) : formInput?.live_stream ? (
                    <div>Online at: {splitTextLiveStream.join('\n')}</div>
                  ) : (
                    'No location added'
                  )}
                </div>
              </div>
              <div className='flex justify-start py-2'>
                <Icon
                  icon='material-symbols:calendar-month-outline'
                  color='#189bd3'
                  width='20'
                />
                <div className='px-2 text-sm text-gray-400'>
                  {new Date(formInput?.event_date).toLocaleDateString(
                    'au-AU',
                    format_date,
                  ) || ''}
                </div>
              </div>
              <div className='py-2 px-1 font-bold text-cyan-500'>
                Organised by: {formInput?.organiser_name}
              </div>
              <br />
              <Box className='flex justify-between py-2'>
                <Box
                  sx={{ width: '400px' }}
                  className='flex justify-start py-1'
                >
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '150px',
                    }}
                    className='rounded-md border border-red-500 bg-red-500 px-2 font-bold text-white'
                    variant='outlined'
                    onClick={handleModals.open.unlist}
                  >
                    Unlist
                  </Button>
                </Box>
                <div className='justify-right flex py-1'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '150px',
                    }}
                    className='rounded-md border border-cyan-500 bg-white px-6 font-bold text-cyan-600'
                    variant='outlined'
                    disabled={!isUploadedFilesDialogMounted && isLoading}
                    onClick={updateListEvent}
                  >
                    Update
                  </Button>
                </div>
              </Box>
              <Box className='flex justify-between'>
                <Box sx={{ width: '400px' }} className='flex'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      margin: 1,
                      height: '50px',
                      width: '335px',
                    }}
                    className='rounded-md border border-purple-500 bg-purple-500 px-2 font-bold text-white'
                    variant='outlined'
                    onClick={handleModals.open.toLearning}
                  >
                    Add to Learning Journey
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
          {/* <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              minWidth: '400px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='py-4 px-8'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Reorder Sort
              </label>
              <div className='mt-2'>
                <select
                  onChange={(e) =>
                    setFormInput({ ...formInput, sorts: e.target.value })
                  }
                  style={{ height: '45px' }}
                  value={formInput ? formInput?.sorts : update?.sort}
                  className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-2 py-3.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                >
                  <option value={'0'}>0</option>
                  <option value={'1'}>1</option>
                  <option value={'2'}>2</option>
                  <option value={'3'}>3</option>
                  <option value={'4'}>4</option>
                  <option value={'5'}>5</option>
                  <option value={'6'}>6</option>
                  <option value={'7'}>7</option>
                  <option value={'8'}>8</option>
                  <option value={'9'}>9</option>
                  <option value={'10'}>10</option>
                </select>
              </div>
            </div>
            <br />
          </Box> */}
        </div>
      </div>

      <Dialog open={openUnlistModal} onClose={handleModals.close.unlist}>
        <DialogTitle>Unlist Event</DialogTitle>
        <DialogContent>
          <p className='font-poppins'>
            Are you sure you want to unlist this event?
          </p>
        </DialogContent>
        <DialogActions>
          <Button fontFamily='poppins' onClick={handleModals.close.unlist}>
            Cancel
          </Button>
          <Button
            color='error'
            fontFamily='poppins'
            onClick={() => {
              updateUnlistEvent();
              handleModals.close.unlist();
              navigate(state?.prevLocation || -1);
            }}
          >
            Unlist
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openToLearningModal}
        onClose={handleModals.close.toLearning}
      >
        <DialogTitle>Add to Learning Journey</DialogTitle>
        <DialogContent>
          <p className='font-poppins'>
            Are you sure you want to move this event?
          </p>
        </DialogContent>
        <DialogActions>
          <Button fontFamily='poppins' onClick={handleModals.close.toLearning}>
            Cancel
          </Button>
          <Button
            color='error'
            fontFamily='poppins'
            onClick={() => {
              updateLearningJourney();
              handleModals.close.toLearning();
              navigate(state?.prevLocation || -1);
            }}
          >
            Move
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUpdateModal} onClose={handleModals.close.update}>
        <DialogTitle>Update Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The event has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModals.close.update} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
