import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';

import React from 'react';

export default function LicenseInvoice({ invoice }) {
  return (
    <div>
      <Box sx={{ width: '100%' }} className='flex w-2/3 justify-end px-6 py-4'>
        <Button
          variant='contained'
          className='rounded-xl bg-cyan-600 text-xs font-bold text-white'
          sx={{
            textTransform: 'none',
            backgroundColor: '#FAF9FC',
            '&:hover': {
              backgroundColor: 'white',
            },
            width: '250px',
            height: '35px',
          }}
          // onClick={() =>

          // }
        >
          Generate License Invoice
        </Button>
      </Box>
      <span className='flex w-2/3 flex-col items-start px-6 py-4'>
        <TableContainer
          className='rounded-xl'
          component={Paper}
          style={{
            width: '100%',
            paddingBottom: '12px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ width: '50px' }}
                >
                  Trx ID
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '100px' }}
                >
                  Invoice Date
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '70px' }}
                >
                  Pricing
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '140px' }}
                >
                  License Amount ($)
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '140px' }}
                >
                  License Duration
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '180px' }}
                >
                  Notes
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '100px' }}
                >
                  Status
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '100px' }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(invoice) && invoice.length > 0 ? (
                invoice.map((row) => (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.license_id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      className='border-white text-sm font-bold text-gray-500'
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        {/* {row?.clinic_code} */}
                      </span>
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* {row?.clinic_name} */}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* {row.clinic_initials} */}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* {row?.clinic_location} */}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* {row.clinic_address || 'No address added'} */}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* {row?.clinic_location} */}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* {row.clinic_address || 'No address added'} */}
                    </TableCell>
                    <TableCell
                      className='border-white text-sm font-bold text-gray-500'
                      style={{ padding: 0 }}
                    >
                      {/* <Button
                          variant='contained'
                          className='text-xs font-bold text-cyan-600'
                          sx={{
                            textTransform: 'none',
                            backgroundColor: '#FAF9FC',
                            '&:hover': {
                              backgroundColor: 'white',
                            },
                            width: '130px',
                            height: '35px',
                          }}
                          // onClick={() =>

                          // }
                        >
                          View
                        </Button> */}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    className='border border-white'
                    colSpan={6}
                    align='center'
                  >
                    <div className='font-poppins py-6 text-gray-500'>
                      No invoice listed
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </span>
    </div>
  );
}
