import '@fontsource/poppins';

import React from 'react';

export default function ContactInformation({ getProfile, setGetProfile }) {
  return (
    <>
      <div className='font-poppins py-4 px-6 text-lg font-bold'>
        Contact Information
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className='font-poppins  block text-sm font-medium leading-6 text-gray-600'>
            Email
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins  block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={getProfile?.email}
              onChange={(e) =>
                setGetProfile({ ...getProfile, email: e.target.value })
              }
              disabled
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className='font-poppins  block text-sm font-medium leading-6 text-gray-600'>
            Mobile No.
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={getProfile?.phone_number}
              onChange={(e) =>
                setGetProfile({ ...getProfile, phone_number: e.target.value })
              }
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
}
