import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;

export default function ListAdminOrg() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { orgId } = useParams();
  const { state } = useLocation();

  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [getAdmin, setGetAdmin] = React.useState();

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/get-admins/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetAdmin(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId, deleteStatus]);

  const handleDelete = async (userId, clinicId) => {
    try {
      const response = await axios.delete(
        `${url}/delete-admin?userId=${userId}&clinicId=${clinicId}&companyId=${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      console.log(response);
      setGetAdmin({ ...getAdmin });
      setDeleteStatus('Delete successful');
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins', margin: 2 }} className='w-2/3'>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-8 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='flex justify-center'>
        <Box
          className='rounded-xl'
          sx={{
            height: 'auto',
            width: '560px',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <div className='font-poppins py-4 px-6  text-lg font-bold'>
            Admin Listing
          </div>
          <div className='flex justify-between'>
            <List
              sx={{
                width: '100%',
                maxWidth: 500,
                bgcolor: 'background.paper',
              }}
              className='px-2'
            >
              {Array.isArray(getAdmin)
                ? getAdmin?.map((row) => (
                    <>
                      <ListItem alignItems='flex-start'>
                        <ListItemText
                          primary={
                            <span className='font-bold'>{row?.full_name}</span>
                          }
                          secondary={
                            <>
                              <div>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component='span'
                                  variant='body2'
                                  color='text.secondary'
                                >
                                  {row?.email}
                                </Typography>
                                <br />
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component='span'
                                  variant='body2'
                                  color='text.secondary'
                                >
                                  {row?.role}
                                </Typography>
                                <br />
                              </div>
                              {/* <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.clinicName || 'No clinic added'}
                              </Typography> */}
                            </>
                          }
                        />
                        <div className='flex justify-end'>
                          <Button
                            sx={{
                              textTransform: 'none',
                              paddingLeft: 0,
                              paddingTop: 0,
                            }}
                            className='py-3 font-bold text-red-500'
                            variant='text'
                            onClick={() =>
                              showDeleteConfirmationDialog(row?._id)
                            }
                          >
                            Delete
                          </Button>
                        </div>
                        {showDeleteConfirmation && (
                          <Dialog
                            open={showDeleteConfirmation}
                            onClose={handleConfirmationDialogClose}
                          >
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Are you sure you want to delete this admin?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleConfirmationDialogClose}
                                color='primary'
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleConfirmationDialogConfirm}
                                color='error'
                              >
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog>
                        )}
                      </ListItem>
                      {getAdmin.indexOf(row) < 10 && (
                        <Divider
                          sx={{ marginLeft: 2 }}
                          variant='inset'
                          component='li'
                          className='w-full'
                        />
                      )}
                    </>
                  ))
                : null}
              <br />
            </List>
          </div>
        </Box>
      </div>
    </Box>
  );
}
