import { useAuth } from '@contexts/authContext';
import Button from '@mui/material/Button';
import VerifyCodeForm from '@src/components/forms/verifyCode.form';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import bgLogin from '../../assets/images/bg_login.svg';

export default function VerifyCodePage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.isAuthenticated !== null && auth.isAuthenticated) {
      navigate('/');
    }
  }, [auth, navigate]);

  return (
    <main className='font-poppins flex items-center justify-center overflow-hidden bg-[#F7FBFF]'>
      <aside
        className='h-screen w-1/2 bg-cover bg-bottom'
        style={{
          backgroundImage: `url(${bgLogin})`,
        }}
      >
        {/* <div className="flex justify-center">
          <img
            src="/logo.svg"
            alt="logo"
            className="flex justify-center px-16 fixed top-10 left-10 w-48"
          />
        </div> */}
      </aside>
      <aside className='h-full w-1/2'>
        <div className='px-20'>
          <h1 className='font-poppins mb-10 font-bold'>Verify the code</h1>
          <VerifyCodeForm />
        </div>
        <br />
        <br />
        <div className='flex items-center justify-center px-20 py-2 text-sm text-gray-400'>
          Please check your email to get the code
        </div>

        <div className='flex items-center justify-center px-20'>
          <div className='text-gray-800'>Did not get code?</div>
          <Button
            sx={{ textTransform: 'none' }}
            variant='text'
            component='span'
            className='underlined text-purple-800'
            onClick={() => {
              navigate('/resend-code');
            }}
          >
            Resend code
          </Button>
        </div>
      </aside>
    </main>
  );
}
