// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Button from '@mui/material/Button';
import LabTabs from '@src/components/commons/tabs.common';

import { useLocation, useNavigate } from 'react-router-dom';

import EventAttendee from './eventAttendee';
import VideoAnalytic from './videoAnalytic';
import ViewerInformation from './viewerInformation';

export default function EventReportPage() {
  const { pathname: state } = useLocation();
  const navigate = useNavigate();

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const menuEvent = [
    {
      idx: 0,
      title: 'Attendance',
      component: (
        <div>
          <EventAttendee />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Video Analytic Information',
      component: (
        <div>
          <VideoAnalytic />
        </div>
      ),
    },
    {
      idx: 2,
      title: 'Viewer Information',
      component: (
        <div>
          <ViewerInformation />
        </div>
      ),
    },
  ];

  return (
    <div className='py-8 px-2'>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='py-2'/>
      <LabTabs menus={menuEvent} />
    </div>
  );
}
