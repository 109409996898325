/* eslint-disable prettier/prettier */

/* eslint-disable import/no-extraneous-dependencies */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// theme css file
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Bar, BarChart, Legend, Tooltip, XAxis } from 'recharts';

import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';

const data = [
  { name: '2023', uv: 100, pv: 300 },
  { name: '2024' },
  { name: '2025' },
  { name: '2026' },
  { name: '2027' },
  { name: '2028' },
  { name: '2029' },
  { name: '2030' },
  { name: '2031' },
  { name: '2032' },
  { name: '2033' },
  { name: '2034' },
];

// const getTotal = () => {
//   let total = 0;
//   data.forEach(item => {
//     total += item.pv + item.uv;
//   });
//   return total;
// };

export default function ClinicAnalytic() {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const numbers = [
    { label: 'Average of listing created' },
    { label: 'Total dollar value all of listing' },
    { label: 'Number of cancelled booking' },
    { label: '% of successful booking of clinic' },
  ];

  const allclinic = [
    { label: 'Clinic 1' },
    { label: 'Clinic 2' },
    { label: 'Clinic 3' },
  ];

  return (
    <div style={{ paddingLeft: 0 }}>
      <div
        style={{
          height: '100%',
          maxHeight: '100vh',
          overflowY: 'scroll',
          paddingBottom: '16px',
        }}
      />
      <Box
        sx={{
          paddingLeft: 0,
          height: '60px',
          width: '930px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <TextField
          sx={{
            width: '270px',
            fontSize: '10px',
          }}
          label='Search Branches'
          variant='outlined'
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </Box>

      <Box className='py-8'>
        <Box
          sx={{
            width: 960,
            height: 150,
            backgroundColor: '#0F5D7E',
            border: 'blue',
          }}
          className='rounded-xl'
        >
          <Grid container spacing={2} justifyContent='left' className='px-6'>
            <Grid item sx={{ fontSize: 14, fontFamily: 'Poppins' }}>
              <div className='font-poppins py-2 font-medium text-white'>
                Data type:
              </div>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={numbers}
                sx={{
                  width: 300,
                  backgroundColor: '#1081B2',
                  fontWeight: 'bold',
                  color: 'blue',
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label='Numbers of Applied'
                    className='rounded-md border-white bg-white text-blue-900'
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ fontSize: 14, fontFamily: 'Poppins' }}>
              <div className='font-poppins py-2 font-medium text-white'>
                Select Clinics:
              </div>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={allclinic}
                sx={{
                  width: 300,
                  backgroundColor: '#1081B2',
                  fontWeight: 'bold',
                  color: 'blue',
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label='All Clinics'
                    className='text-blue rounded-md border-white bg-white'
                  />
                )}
              />
            </Grid>

            <Grid
              item
              className='space-x-1 py-2'
              sx={{ fontSize: 14, fontFamily: 'Poppins' }}
            >
              <div className='py-3 px-2 font-medium text-white'>Range</div>
              <Button
                variant='contained'
                className='... h-14 bg-white py-3 font-bold text-blue-900 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110'
              >
                YTD
              </Button>
              <Button
                variant='outlined'
                className='... h-14 border-white py-3 font-bold text-white transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110'
              >
                Y
              </Button>
              <Button
                variant='outlined'
                className='... h-14 border-white py-3 font-bold text-white transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110'
              >
                M
              </Button>
              <Button
                variant='outlined'
                className='... h-14 border-white py-3 font-bold text-white transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110'
              >
                D
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ height: 50, width: 750 }}>
        <div className='font-poppins flex justify-end py-4 font-bold text-blue-900'>
          Date range:
        </div>
      </Box>
      <Box sx={{ height: 60, width: 930 }} className='flex justify-end'>
        <div style={{ position: 'relative' }}>
          <Button
            onClick={handleToggle}
            sx={{ fontFamily: 'Poppins', width: '270px' }}
            variant='outlined'
          >
            Pick the date
          </Button>
          {toggle && (
            <div style={{ position: 'absolute', top: '100%', right: '100%' }}>
              <DateRange
                // eslint-disable-next-line react/jsx-boolean-value
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
            </div>
          )}
        </div>
      </Box>

      <Box className='py-8'>
        <Box sx={{ bgcolor: '#ffffff', height: 680, width: 960 }}>
          <div>
            <Grid
              container
              spacing={1}
              justifyContent='left'
              className='px-4 py-4'
            >
              <Grid item className='flex-row- flex'>
                <Box
                  sx={{
                    margin: 1,
                    width: 217,
                    height: 122,
                    backgroundColor: '#0EA4D6',
                    fontFamily: 'Poppins',
                    fontWeight: 4,
                    fontSize: 14,
                  }}
                  className='rounded-xl text-center'
                >
                  <div className='font-poppins px-6 py-3 text-center text-white'>
                    Total Number of&nbsp; Clinics
                  </div>
                  <div className='font-poppins px-16 text-4xl font-bold text-white'>
                    123
                  </div>
                </Box>
                <Box
                  sx={{
                    margin: 1,
                    width: 217,
                    height: 122,
                    backgroundColor: '#CA00CE',
                    fontFamily: 'Poppins',
                    fontWeight: 4,
                    fontSize: 14,
                  }}
                  className='rounded-xl text-center'
                  style={{ textAlign: 'center' }}
                >
                  <div className='font-poppins px-6 py-3 text-center text-white'>
                    Total Number of Applicants
                  </div>
                  <div className='font-poppins px-16 text-4xl font-bold text-white'>
                    70
                  </div>
                </Box>
                <Box
                  sx={{
                    margin: 1,
                    width: 217,
                    height: 122,
                    backgroundColor: '#AD44FF',
                    fontFamily: 'Poppins',
                    fontWeight: 4,
                    fontSize: 14,
                  }}
                  className='rounded-xl text-center'
                  style={{ textAlign: 'center' }}
                >
                  <div className='font-poppins px-6 py-3 text-center text-white'>
                    Total Number of&nbsp; Locum Applied
                  </div>
                  <div className='font-poppins px-16 text-4xl font-bold text-white'>
                    45
                  </div>
                </Box>
                <Box
                  sx={{
                    margin: 1,
                    width: 217,
                    height: 122,
                    backgroundColor: '#3CB381',
                    fontFamily: 'Poppins',
                    fontWeight: 4,
                    fontSize: 14,
                  }}
                  className='rounded-xl text-center'
                  style={{ textAlign: 'center' }}
                >
                  <div className='font-poppins px-6 py-3 text-center text-white'>
                    Total Number of Clinic Assistant Applied
                  </div>
                  <div className='font-poppins px-16 text-4xl font-bold text-white'>
                    20
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>

          <Box
            sx={{
              margin: 3,
              width: 918,
              height: 400,
              backgroundColor: '#F7FBFF',
            }}
            className='rounded-xl text-center '
          >
            <BarChart
              width={850}
              height={300}
              data={data}
              className='justify-center py-16 px-8'
              barSize={20}
            >
              <XAxis
                dataKey='name'
                angle={-45}
                textAnchor='end'
                tick={{ fontWeight: 100, fontSize: 12, fontFamily: 'Poppins' }}
              />
              <Tooltip />
              <Legend
                verticalAlign='top'
                font='poppins'
                fontWeight={8}
                align='right'
                iconType='square'
                iconSize={12}
                layout='horizontal'
                wrapperStyle={{ top: 20, right: 25 }}
              />
              <Bar
                name='Locum Doctors'
                dataKey='pv'
                fill='#6873D8'
                fontFamily='Poppins'
              />
              <Bar
                name='Clinic Assistants'
                dataKey='uv'
                fill='#F258E3'
                fontFamily='Poppins'
              />
            </BarChart>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
