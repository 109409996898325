/* eslint-disable jsx-a11y/anchor-is-valid */
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddVaccinationCert from '@src/components/modals/addVaccinationCert';
import NurseSkill from '@src/components/modals/nurseSkill';
import { useAuth } from '@src/contexts/authContext';
import { openLinkInPopUpWindow } from '@utils';
import axios from 'axios';
import image from 'src/assets/images/rectangle.png';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AppNurseProfile() {
  const { auth } = useAuth();
  const { userId } = useParams();

  const handleOpenSlide = (file) => async () => openLinkInPopUpWindow(file);

  const [getProfile, setGetProfile] = useState();
  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetProfile(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getSkill, setGetSkill] = useState([]);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/skill/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetSkill(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getVaccine, setGetVaccine] = useState([]);

  const fetchDataVaccine = () => {
    if (auth?.idToken) {
      axios
        .get(`${url}/vaccination/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetVaccine(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const [getIt, setGetIt] = useState([]);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/it-systems/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetIt(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getExperience, setGetExperience] = useState([]);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/experiences/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetExperience(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  // const [openChangeModal, setOpenChangeModal] = useState(false);
  // const showChangeModal = () => {
  //   setOpenChangeModal(true);
  // };

  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };

  const [openModals, setOpenModals] = useState(false);
  const showModals = () => {
    setOpenModals(true);
  };

  const [deletedStatus, setDeletedStatus] = useState();
  const [deletedId, setDeletedId] = useState(null);
  const [showDeleteConfirmations, setShowDeleteConfirmations] = useState(false);
  const [showDeletedSuccess, setShowDeletedSuccess] = useState(false);

  const handleDeleteVaccine = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/vaccinations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'My-Custom-Header': 'foobar',
          },
        },
      );

      if (response.status === 200) {
        console.log(response);
        setDeletedStatus('Delete successful');
        setShowDeletedSuccess(true);
        toast.success('Item deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      } else {
        console.error('Delete operation failed');
      }
    } catch (error) {
      console.error(error);
      toast.error(`${error.response.data.message}`, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
  };

  const showDeletedConfirmationDialog = (id) => {
    setDeletedId(id);
    setShowDeleteConfirmations(true);
  };

  const handleConfirmationsDialogClose = () => {
    setShowDeleteConfirmations(false);
  };

  const handleConfirmationsDialogConfirm = () => {
    handleDeleteVaccine(deletedId);
    setShowDeleteConfirmations(false);
  };

  React.useEffect(() => {
    fetchDataVaccine();
  }, [auth, deletedStatus]);

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-3/4'>
      <div className='grid grid-cols-2'>
        <div className='col-end-2'>
          <Box
            className='rounded-xl'
            sx={{
              height: '250px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex gap-x-5 px-6 py-6'>
              <img
                src={
                  getProfile?.profile_pict ? getProfile?.profile_pict : image
                }
                alt=''
                className='h-20 w-20 rounded-full border-2 border-cyan-500 shadow'
              />
              <div className='leading-3'>
                <p className='text-[16px] font-semibold text-cyan-700'>
                  {getProfile?.full_name || 'No name added'}
                </p>
                <p className='text-[14px] text-gray-500'>
                  {getProfile?.email || 'No email added'}
                </p>
                <p className='text-[14px] text-gray-500'>
                  {getProfile?.phone_number || 'No number added'}
                </p>
              </div>
            </div>
            <div className='flex gap-x-3 px-6'>
              <p className='rounded-full bg-blue-50 px-3 py-1 text-cyan-600'>
                <div className='font-bold'>
                  {getProfile?.mcr || 'No MCR added'}
                </div>
              </p>
              <p className='rounded-full bg-blue-50 px-3 py-1 text-cyan-600'>
                <div className='font-bold'> {getProfile?.role_id}</div>
              </p>
            </div>
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins px-6 py-6 font-bold'>ABOUT</div>
            <div className='px-6 text-gray-500'>
              {getProfile?.about_me
                ? getProfile?.about_me.split('\n').map((paragraph, index) => (
                    <p
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{ textAlign: 'justify' }}
                    >
                      {paragraph}{' '}
                    </p>
                  ))
                : 'No about added'}
            </div>
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='font-poppins px-6 py-6 font-bold'>
                EXPERIENCES
              </div>
              {/* <Button
                sx={{
                  textTransform: 'none',
                  width: '170px',
                  backgroundColor: 'white',
                }}
                className='text-xs font-bold text-cyan-600 underline'
                variant='text'
              >
                Edit experience
              </Button> */}
            </div>
            <div className='text-gray-500'>
              {getExperience && getExperience.length > 0 ? (
                getExperience.map((item) => (
                  <div className='flex px-4' key={item._id}>
                    <Icon
                      icon='fluent:checkbox-checked-20-filled'
                      color='#4ed39c'
                      width='30'
                      height='30'
                    />
                    <div className='px-2 py-1'>
                      {item.job_title} at {item.institution}{' '}
                    </div>
                  </div>
                ))
              ) : (
                <div className='px-6'>No experience added</div>
              )}
            </div>

            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins px-6 py-6 font-bold'>REFERRAL</div>
            <div className='flex justify-start px-6'>
              <div className='text-sm text-gray-600'>Referral code:</div>
              <div className='px-8 text-gray-500'>
                {getProfile?.referal_code || 'no referral'}
              </div>
            </div>
            <div className='flex justify-start px-6 py-2'>
              <div className='text-sm text-gray-600'>Referred by:</div>
              <div className='px-12 text-gray-500'>
                {getProfile?.refered_by_name || '-'}
              </div>
            </div>

            <br />
          </Box>
        </div>
        <div className='col-auto'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='font-poppins px-6 py-6 font-bold'>
                CERTIFICATES
              </div>
              {/* <Button
                sx={{
                  textTransform: 'none',
                  width: '200px',
                  backgroundColor: 'white',
                }}
                className='px-6 text-xs font-bold text-cyan-600 underline'
                variant='text'
              >
                Add new certificate
              </Button> */}
            </div>

            <span>
              <div className=' text-gray-500'>
                {getProfile?.certification &&
                getProfile.certification.length > 0 ? (
                  getProfile.certification.map((cert) => (
                    <div className='flex justify-between px-4' key={cert.item}>
                      <div className='px-2'> {cert.item}</div>

                      <div className='px-2'>
                        <Icon
                          icon='carbon:view-filled'
                          onClick={handleOpenSlide(cert?.file)}
                          color='#1788b9'
                          width='28'
                        />
                        <Icon
                          // onClick={() =>
                          //   showDeleteConfirmationDialog(cert?._id)
                          // }
                          icon='clarity:remove-solid'
                          color='#FF5555'
                          width='28'
                          className='h-6 w-6 scale-100 transform rounded-full py-0.5 px-2 transition-transform group-hover:scale-110'
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='px-6'>No certificate added</div>
                )}
              </div>
            </span>
            <br />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='font-poppins px-6 py-6 font-bold'>
                VACCINATION CERTIFICATES
              </div>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '130px',
                  backgroundColor: 'white',
                }}
                className='px-6 text-xs font-bold text-cyan-600 underline'
                variant='text'
                onClick={showModals}
              >
                Add new
              </Button>
            </div>

            <span>
              <div className=' text-gray-500'>
                {getVaccine && getVaccine.length > 0 ? (
                  getVaccine.map((cert) => (
                    <div className='flex justify-between px-4' key={cert.item}>
                      <div className='px-2'> {cert.item}</div>

                      <div className='px-2'>
                        <Icon
                          icon='carbon:view-filled'
                          onClick={handleOpenSlide(cert?.file)}
                          color='#1788b9'
                          width='28'
                        />
                        <Icon
                          onClick={() =>
                            showDeletedConfirmationDialog(cert?._id)
                          }
                          icon='clarity:remove-solid'
                          color='#FF5555'
                          width='28'
                          className='h-6 w-6 scale-100 transform rounded-full py-0.5 px-2 transition-transform group-hover:scale-110'
                        />
                      </div>
                      {showDeleteConfirmations && (
                        <Dialog
                          open={showDeleteConfirmations}
                          onClose={handleConfirmationsDialogClose}
                        >
                          <DialogTitle>Confirm Delete</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Are you sure you want to delete this item?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleConfirmationsDialogClose}
                              color='primary'
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleConfirmationsDialogConfirm}
                              color='error'
                            >
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                      )}
                    </div>
                  ))
                ) : (
                  <div className='px-6'>No certificate added</div>
                )}
              </div>
            </span>
            <br />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='font-poppins px-6 py-6 font-bold'>SKILLS</div>
              {/* <Button
                sx={{
                  textTransform: 'none',
                  width: '120px',
                  backgroundColor: 'white',
                }}
                className='text-xs font-bold text-cyan-600 underline'
                variant='text'
                // onClick={showModal}
              >
                Edit skill
              </Button> */}
            </div>
            <div className='text-gray-500'>
              {getSkill && getSkill.length > 0 ? (
                getSkill.map((item) => (
                  <div className='flex px-4' key={item._id}>
                    <Icon
                      icon='fluent:checkbox-checked-20-filled'
                      color='#4ed39c'
                      width='30'
                      height='30'
                    />
                    <div className='px-2 py-1'>{item.item}</div>
                  </div>
                ))
              ) : (
                <div className='px-6'>No skill added</div>
              )}
            </div>

            <br />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-between'>
              <div className='font-poppins px-6 py-6 font-bold'>IT SYSTEMS</div>
              {/* <Button
                sx={{
                  textTransform: 'none',
                  width: '160px',
                  backgroundColor: 'white',
                }}
                className='text-xs font-bold text-cyan-600 underline'
                variant='text'
              >
                Edit IT System
              </Button> */}
            </div>
            <div className='text-gray-500'>
              {getIt && getIt.length > 0 ? (
                getIt.map((item) => (
                  <div className='flex px-4' key={item._id}>
                    <Icon
                      icon='fluent:checkbox-checked-20-filled'
                      color='#4ed39c'
                      width='30'
                      height='30'
                    />
                    <div className='px-2 py-1'>{item.item}</div>
                  </div>
                ))
              ) : (
                <div className='px-6'>No data added</div>
              )}
            </div>

            <br />
          </Box>
        </div>
      </div>

      <AddVaccinationCert
        showModals={showModals}
        openModals={openModals}
        fetchDataVaccine={fetchDataVaccine}
        setOpenModals={setOpenModals}
      />

      <Dialog maxWidth='md' open={openModal} onClose={setOpenModal}>
        <DialogTitle>Confirm Change</DialogTitle>
        <DialogContent>
          <NurseSkill getSkill={getSkill} setGetSkill={setGetSkill} />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setOpenModal(false)}
            color='error'
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setOpenModal(false)}
            color='secondary'
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
