import { useAuth } from '@contexts/authContext';
import { LOGIN } from '@contexts/contextConsts';
import axios from 'axios';
import { useFormik } from 'formik';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import bgLogin from '../../assets/images/bg_login.svg';

export default function ResendCodePage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch: authDispatch } = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated !== null && auth.isAuthenticated) {
      navigate('/');
    }
  }, [auth, navigate]);

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/admin/auth/resend-2fa`,
        {
          email: values.email,
          password: values.password,
        },
      );
      // console.log(response);
      if (response.data.message === 'The code has been sent.') {
        toast.success('The code has been sent to your email!', {
          position: 'top-center',
          autoClose: 2000,
        });
      }
      navigate(`/verify-code?email=${values.email}`);

      if (response?.status === 200 && response.data?.Admin?._id) {
        authDispatch({
          type: LOGIN,
          payload: {
            isAuthenticated: true,
            user: response.data.Admin,
            idToken: response.data.idToken,
            refreshToken: response.data.refreshToken,
          },
        });
        setTimeout(() => {
          navigate(location.state ? location.state.from.pathname : '/');
        }, 500);
        return;
      }
      // console.log(response.data);
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
  });

  const isBtnFormActive = !formik.isValid || formik.isSubmitting;

  return (
    <main className='font-poppins flex items-center justify-center overflow-hidden bg-[#F7FBFF]'>
      <aside
        className='h-screen w-1/2 bg-cover bg-bottom'
        style={{
          backgroundImage: `url(${bgLogin})`,
        }}
      >
        {/* <div className="flex justify-center">
          <img
            src="/logo.svg"
            alt="logo"
            className="flex justify-center px-16 fixed top-10 left-10 w-48"
          />
        </div> */}
      </aside>
      <aside className='h-full w-1/2'>
        <div className='px-20'>
          <h1 className='font-poppins mb-10 font-bold'>Resend the code</h1>
          <form
            onSubmit={formik.handleSubmit}
            className='flex w-full flex-col justify-center gap-y-5'
          >
            <div className='flex flex-col gap-y-2'>
              <input
                id='email'
                name='email'
                type='email'
                placeholder='E.g, name@email.com'
                className='font-poppins rounded-xl border border-gray-200 bg-[#FAFAFA] py-3 px-3 shadow-inner focus:outline-none focus:ring-0'
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email ? (
                <div className='text-sm text-red-500'>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className='flex flex-col gap-y-2'>
              <input
                id='password'
                name='password'
                type='password'
                placeholder='Enter your password'
                className='font-poppins rounded-xl border border-gray-200 bg-[#FAFAFA] py-3 px-3 shadow-inner focus:outline-none focus:ring-0'
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password ? (
                <div className='text-sm text-red-500'>
                  {formik.errors.password}
                </div>
              ) : null}
            </div>

            <button
              type='submit'
              disabled={isBtnFormActive}
              className={`${
                isBtnFormActive ? 'bg-blue-200' : 'bg-blue-500'
              } mt-5 w-full rounded-2xl border-transparent py-2 text-lg font-bold text-white focus:border-transparent focus:ring-0`}
            >
              Resend code
            </button>
          </form>
        </div>
      </aside>
    </main>
  );
}
