import { Box, Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/informations/attachments/create`;

const options = [
  { value: 'doctor', label: 'Doctor' },
  { value: 'clinical assistant', label: 'Clinic Assistant' },
  { value: 'nurse', label: 'Nurse' },
];

function CheckboxGroup({ selectedValues = [], onChange }) {
  return (
    <div className='text-md py-2 px-2 text-gray-600'>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                style={{ color: '#1788b9' }}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => onChange(option.value, e.target.checked)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default function AddAdditionalInfo({
  openModal,
  setOpenModal,
  fetchData,
}) {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [info, setInfo] = useState([]);

  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleFile = (e) => {
    const files = e.target.files[0];
    if (files) {
      setSelectedFile(files.name);
      setInfo((prevInput) => ({
        ...prevInput,
        attachment: files,
      }));
    }
  };

  const handlePost = () => {
    const formData = {
      title: info?.title,
      profession: info?.profession,
      type: info?.type,
      name: info?.name,
      attachment: info?.attachment,
      attachment_url: info?.attachment_url,
    };

    axios
      .post(`${url}/${clinicId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item added successfully:', response);
        setInfo({ ...info });
        fetchData();
        toast.success('Item added successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const handleProfession = (value, isChecked) => {
    if (info) {
      const professions = Array.isArray(info.profession) ? info.profession : [];

      const updatedSelection = isChecked
        ? [...professions, value]
        : professions.filter((selected) => selected !== value);
      setInfo({ ...info, profession: updatedSelection });
    } else {
      console.error('not defined');
    }
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new item</DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          <br />
          <div>Title*</div>
          <br />
          <input
            placeholder='enter title'
            className='font-poppins w-full rounded-md'
            style={{
              width: '350px',
              height: '38px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            onChange={(e) =>
              setInfo({
                ...info,
                title: e.target.value,
              })
            }
          />
          <br />
          <br />

          <div>Profession*</div>
          <br />
          <CheckboxGroup
            selectedValues={info?.profession}
            onChange={handleProfession}
          />
          <br />
          <div>Type*</div>
          <br />
          <div>
            <select
              id='type'
              onChange={(e) =>
                setInfo({
                  ...info,
                  type: e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              style={{ width: '360px', height: '45px' }}
            >
              <option value=''>Select one</option>
              <option value='file'>File</option>
              <option value='link'>Link</option>
            </select>
            <br />
            <br />
            <br />

            <div>Attachment*</div>
            <br />
            {info.type === 'file' && (
              <input
                type='file'
                id='info'
                accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
                onChange={handleFile}
                className='text-md w-1/3'
              />
            )}
            {info.type === 'link' && (
              <input
                name='link'
                placeholder='https://www.example.com'
                className='font-poppins w-full rounded-md'
                style={{
                  width: '348px',
                  height: '38px',
                  fontSize: 14,
                  color: '#2d3748',
                  paddingLeft: 5,
                }}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    attachment_url: e.target.value,
                  })
                }
              />
            )}
          </div>
          <br />
          <br />
          <div>Attachment Name</div>
          <br />
          <input
            placeholder='customized the name'
            className='font-poppins w-full rounded-md'
            style={{
              width: '350px',
              height: '38px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            onChange={(e) =>
              setInfo({
                ...info,
                name: e.target.value,
              })
            }
          />

          <br />
          <br />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={handlePost}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
