/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Container from '@src/components/commons/containerPage.common';
import PropTypes from 'prop-types';

import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import ExpiredPCDoctor from './expiredPCDoctor';
import ExpiredPCNurse from './expiredPCNurse';

function TabPanel(props) {
  const { children, value, index, style = {} } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabLabel(index) {
  return {
    id: `simple-tab-${index}`,
    poppins: `simple-tabpanel-${index}`,
  };
}

export default function ExpiredPracticingCert() {
  const [value, setValue] = React.useState(0);
  const path = useLocation().pathname;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: '100%' }} className='py-6'>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className=' border-gray-50'
        >
          <Tabs
            value={value}
            indicatorColor='white'
            onChange={handleChange}
            aria-label='basic tabs example'
            className='px-6'
          >
            <Tab
              sx={{
                textTransform: 'none',
                width: '130px',
                borderColor: 'blue',
              }}
              label='Doctor'
              className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                value === 0 ? 'bg-cyan-600 text-white' : 'bg-white'
              }`}
              {...tabLabel(0)}
            />
            <Box sx={{ width: '16px' }} />
            <Tab
              sx={{ textTransform: 'none', width: '130px' }}
              label='Nurse'
              className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                value === 2 ? 'bg-cyan-600 text-white' : 'bg-white'
              }`}
              {...tabLabel(1)}
            />
            <Box sx={{ width: '500px' }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ExpiredPCDoctor />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ExpiredPCNurse />
        </TabPanel>
      </Box>
    </div>
  );
}
