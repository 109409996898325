import { Box, Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data/country`;

export default function EditCountry({ openModals, setOpenModals, fetchData }) {
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const { itemId } = useParams();

  React.useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (openModals) {
          const response = await axios.get(`${url}/${openModals}`, {
            headers: {
              Authorization: `Bearer ${auth.idToken}`,
            },
          });
          setData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAsync();
  }, [auth.idToken, openModals]);

  const handlePost = () => {
    const formData = {
      country_name: data.country_name,
      code_alfa_2: data.code_alfa_2,
      code_alfa_3: data.code_alfa_3,
      code_numeric: data.code_numeric,
      label: data.label,
      note: data.note,
    };

    axios
      .put(`${url}/${openModals}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item updated successfully:', response);
        setData({ ...data });
        fetchData();
        toast.success('Item updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModals}
      onClose={() => setOpenModals(false)}
    >
      <DialogTitle>Edit item</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <div>Country Name*</div>
          <br />
          <input
            className='font-poppins w-full rounded-md'
            style={{
              width: '360px',
              height: '30px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            value={data?.country_name}
            onChange={(e) =>
              setData({
                ...data,
                country_name: e.target.value,
              })
            }
          />
          <br />
          <br />

          <div>Code Alfa 2*</div>
          <br />
          <input
            className='font-poppins w-full rounded-md'
            style={{
              width: '360px',
              height: '30px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            value={data?.code_alfa_2}
            onChange={(e) =>
              setData({
                ...data,
                code_alfa_2: e.target.value,
              })
            }
          />
          <br />
          <br />

          <div>Code Alfa 3*</div>
          <br />
          <input
            className='font-poppins w-full rounded-md'
            style={{
              width: '360px',
              height: '30px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            value={data?.code_alfa_3}
            onChange={(e) =>
              setData({
                ...data,
                code_alfa_3: e.target.value,
              })
            }
          />
          <br />
          <br />

          <div>Code Numeric*</div>
          <br />
          <input
            className='font-poppins w-full rounded-md'
            style={{
              width: '360px',
              height: '30px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            value={data?.code_numeric}
            onChange={(e) =>
              setData({
                ...data,
                code_numeric: e.target.value,
              })
            }
          />
          <br />
          <br />

          <div>Label*</div>
          <br />
          <div>
            <input
              className='font-poppins w-full rounded-md'
              style={{
                width: '360px',
                height: '30px',
                fontSize: 14,
                color: '#2d3748',
                paddingLeft: 5,
              }}
              value={data?.label}
              onChange={(e) =>
                setData({
                  ...data,
                  label: e.target.value,
                })
              }
            />
            <br />
            <br />
            <div>Note</div>
            <br />
            <input
              className='font-poppins w-full rounded-md'
              style={{
                width: '360px',
                height: '30px',
                fontSize: 14,
                color: '#2d3748',
                paddingLeft: 5,
              }}
              value={data?.note}
              onChange={(e) =>
                setData({
                  ...data,
                  note: e.target.value,
                })
              }
            />
          </div>

          <br />
          <br />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModals(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={() => handlePost()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
