/* eslint-disable react/jsx-props-no-spreading */
import { Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import * as React from 'react';

function a11yProps(index) {
  return {
    id: `common-tab-${index}`,
    'aria-controls': `common-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, style = {} } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      className='-ml-5'
      style={style}
    >
      {value !== null &&
      value !== undefined &&
      index !== null &&
      index !== undefined &&
      value === index &&
      React.isValidElement(children) ? (
        <Box sx={{ p: 3 }}>{children}</Box>
      ) : null}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function LabTabs({ menus }) {
  const [value, setValue] = React.useState(() => {
    const storedValue = localStorage.getItem('selectedTab');
    return storedValue ? parseInt(storedValue, 10) : 0;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('selectedTab', newValue.toString());
  };

  React.useEffect(() => {
    handleChange(null, value);
  }, []);

  return (
    <div>
      {menus ? (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginLeft: '14px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='learning journey tabs'
              TabIndicatorProps={{
                title: 'indicator',
                sx: { backgroundColor: '#9081D5', height: 3, color: 'black' },
              }}
              sx={{
                '& button:active': { color: '#54657E' },
                '& button.Mui-selected': { color: '#54657E' },
              }}
            >
              {menus.map((menu) =>
                menu ? (
                  <Tab
                    key={`tab-${menu.key || menu.idx}`}
                    {...a11yProps(menu.idx)}
                    sx={{
                      fontWeight: '600',
                      fontSize: '15px',
                      color: 'rgba(84, 101, 126, 0.36)',
                    }}
                    label={menu.title}
                  />
                ) : null,
              )}
            </Tabs>
          </Box>
          {menus.map((menu) => {
            return value !== null &&
              value !== undefined &&
              menu.idx !== null &&
              menu.idx !== undefined &&
              menu.component !== null &&
              menu.component !== undefined &&
              React.isValidElement(menu.component) ? (
              <TabPanel
                key={`tab-panel-${menu.key || menu.idx}`}
                value={value}
                index={menu.idx}
              >
                {menu.component}
              </TabPanel>
            ) : null;
          })}
        </>
      ) : null}
    </div>
  );
}

export default React.memo(LabTabs);
