// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}`;

export default function AdminClinicList() {
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const [getAdmin, setGetAdmin] = useState({});
  const navigate = useNavigate();
  const { clinicId } = useParams();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios
      .get(`${urls}/admin/clinics/admin?clinicId=${clinicId}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setGetAdmin(response.data);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }, [auth, deleteStatus]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/delete-user/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'My-Custom-Header': 'foobar',
          },
        },
      );

      if (response.status === 200) {
        console.log(response);
        setDeleteStatus('Delete successful');
        setShowDeleteSuccess(true);
      } else {
        console.error('Delete operation failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  return (
    <div>
      <div className='w-3/4 px-4 py-8'>
        <TableContainer
          className='rounded-xl'
          component={Paper}
          style={{
            width: '100%',
            paddingBottom: '12px',
            paddingRight: '12px',
            backgroundColor: '#ffffff',
            borderColor: 'ffffff',
          }}
        >
          <Table
            sx={{
              minWidth: 350,
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
            }}
            aria-label='simple table'
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ width: '180px', border: 'gray', paddingLeft: 20 }}
                >
                  Name
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '270px', border: 'gray' }}
                >
                  Email
                </TableCell>

                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '180px', border: 'gray' }}
                >
                  Role
                </TableCell>

                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '200px', border: 'gray' }}
                >
                  Phone Number
                </TableCell>

                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '100px', border: 'gray' }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(getAdmin) && getAdmin.length > 0 ? (
                getAdmin
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row?._id}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        className='border-white px-5 text-sm font-bold text-gray-500'
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                          }}
                        >
                          {row?.full_name || ''}
                        </span>
                      </TableCell>

                      <TableCell
                        className='border-white text-sm font-bold text-gray-500'
                        style={{ padding: 4 }}
                      >
                        {row.email}
                      </TableCell>

                      <TableCell
                        className='border-white  text-sm font-bold text-gray-500'
                        style={{ padding: 4, margin: 2 }}
                      >
                        {row?.role
                          ? row.role
                              .replace(/_/g, ' ')
                              .split(' ')
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1),
                              )
                              .join(' ')
                          : ''}
                      </TableCell>

                      <TableCell
                        className='border-white  text-sm font-bold text-gray-500'
                        style={{ padding: 4, margin: 2 }}
                      >
                        {row.phone_number || '-'}
                      </TableCell>
                      <TableCell
                        style={{ padding: 4, margin: 2 }}
                        className='border-white '
                      >
                        <div className='flex justify-start border-white '>
                          <Button
                            variant='contained'
                            className='text-xs font-bold text-red-500'
                            sx={{
                              textTransform: 'none',
                              backgroundColor: '#FAF9FC',
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                            }}
                            onClick={() =>
                              showDeleteConfirmationDialog(row?._id)
                            }
                          >
                            Delete
                          </Button>
                        </div>
                        {showDeleteConfirmation && (
                          <Dialog
                            open={showDeleteConfirmation}
                            onClose={handleConfirmationDialogClose}
                          >
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Are you sure you want to delete this item?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleConfirmationDialogClose}
                                color='primary'
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleConfirmationDialogConfirm}
                                color='error'
                              >
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    className='border border-white'
                    colSpan={6}
                    align='center'
                  >
                    <div className='font-poppins py-6 text-gray-500'>
                      No Admin listed
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box
            className='flex justify-end py-3'
            sx={{ width: '100%', fontFamily: 'Poppins' }}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={getAdmin.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
        <br />
        <br />
        <br />
      </div>

      {showDeleteSuccess && (
        <Dialog
          open={showDeleteSuccess}
          onClose={() => setShowDeleteSuccess(false)}
        >
          <DialogTitle>Delete Successful</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The Admin has been deleted successfully.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteSuccess(false)} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
