/* eslint-disable no-param-reassign */

/* eslint-disable import/no-extraneous-dependencies */
import {
  getCertificateTemplates,
  putTemplateById,
} from '@api/GenerateCertificate';
import { useAuth } from '@contexts/authContext';
import '@fontsource/poppins';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Container from '@src/components/commons/containerPage.common';
import Toast, { ERROR, SUCCESS } from '@src/components/toast';
import {
  capitalizeWords,
  fileNameFromUrl,
  mapToCertificateTemplateData,
  noop,
  uuid,
} from '@utils';
import { FieldArray, Form, Formik } from 'formik';
import cloneDeep from 'lodash.clonedeep';
import { serialize } from 'object-to-formdata';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TemplateSelection from './templateSelection';

const TextField = styled(MuiTextField)({
  '& label.Mui-focused': {
    color: '#9350E5',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#A855F7',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A855F7',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#A855F7',
    },
  },
});

export default function EditTemplatePage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [formKey, setFormKey] = React.useState(uuid());
  const [fileNames, setFileNames] = React.useState({});
  const [formDataInitialValues, setFormDataInitialValues] =
    React.useState(null);
  const [openToast, setOpenToast] = React.useState(false);
  const [toastData, setToastData] = React.useState({
    severity: ERROR,
    text: 'Error',
  });
  const isTemplateSelected = React.useMemo(
    () => Boolean(selectedTemplate?.template?.type),
    [selectedTemplate],
  );
  const templateType = React.useMemo(
    () => ({
      type: capitalizeWords(selectedTemplate?.template?.type || 'Unknown'),
      topic: capitalizeWords(selectedTemplate?.template?.topic || 'Unknown'),
    }),
    [selectedTemplate],
  );

  const refs = {
    logo: React.useRef(null),
    'signatory.0.signature': React.useRef(null),
    'signatory.1.signature': React.useRef(null),
  };

  const handleSelectTemplate = async (template) => {
    setSelectedTemplate(template);
  };

  const handleToast = {
    open: async () => {
      setOpenToast(true);
    },
    close: async () => {
      setOpenToast(false);
    },
    popUp: async (
      text,
      severity = SUCCESS,
      callback = noop,
      timeout = 1000,
    ) => {
      setToastData({
        severity,
        text,
      });
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
        callback();
      }, timeout);
    },
  };

  const handleClick = {
    ref: (ref) => async () => {
      if (ref?.current?.click) {
        ref.current.click();
      }
    },
    back: () => {
      navigate('/event-certificate');
    },
  };

  const handleSubmitForm = async (values, { setSubmitting }) => {
    if (auth.isAuthenticated) {
      const formData = cloneDeep({
        ...values,
        type: capitalizeWords(values.type),
      });
      const { template } = selectedTemplate;

      if (formData.logo.name === template.logo) {
        delete formData.logo;
      }
      if (formData.site === template.site) {
        delete formData.site;
      }
      if (formData.type === capitalizeWords(template.type)) {
        delete formData.type;
      }
      if (formData.description === template.description) {
        delete formData.description;
      }
      if (formData.topic === template.topic) {
        delete formData.topic;
      }
      if (formData.location === template.location) {
        delete formData.location;
      }
      if (formData.signatory && formData.signatory[0]) {
        formData.signatory[0].index = 0;
      }
      if (
        formData.signatory &&
        formData.signatory[0] &&
        formData.signatory[0].name === template.signatory1.name
      ) {
        delete formData.signatory[0].name;
      }
      if (
        formData.signatory &&
        formData.signatory[0] &&
        formData.signatory[0].title === template.signatory1.title
      ) {
        delete formData.signatory[0].title;
      }
      if (
        formData.signatory &&
        formData.signatory[0] &&
        formData.signatory[0].signature.name === template.signatory1.signature
      ) {
        delete formData.signatory[0].signature;
      }
      if (formData.signatory && formData.signatory[1]) {
        formData.signatory[1].index = 1;
      }
      if (
        formData.signatory &&
        formData.signatory[1] &&
        formData.signatory[1].name === template.signatory2.name
      ) {
        delete formData.signatory[1].name;
      }
      if (
        formData.signatory &&
        formData.signatory[1] &&
        formData.signatory[1].title === template.signatory2.title
      ) {
        delete formData.signatory[1].title;
      }
      if (
        formData.signatory &&
        formData.signatory[1] &&
        formData.signatory[1].signature.name === template.signatory2.signature
      ) {
        delete formData.signatory[1].signature;
      }

      if (
        !!formData.signatory[0] &&
        !formData.signatory[0].name &&
        !formData.signatory[0].title &&
        !formData.signatory[0].signature
      ) {
        formData.signatory.splice(0, 1);
      }

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!formData.signatory[0] || !!formData.signatory[1]) {
        if (
          formData.signatory.length === 2 &&
          !formData.signatory[1].name &&
          !formData.signatory[1].title &&
          !formData.signatory[1].signature
        ) {
          formData.signatory.splice(1, 1);
        }

        if (
          formData.signatory.length === 1 &&
          !formData.signatory[0].name &&
          !formData.signatory[0].title &&
          !formData.signatory[0].signature
        ) {
          formData.signatory.splice(0, 1);
        }
      }

      if (formData.signatory && formData.signatory.length === 0) {
        // remove signatory if empty
        delete formData.signatory;
      }

      console.log(formData);

      const options = {
        indices: true,
        allowEmptyArrays: false,
        dotsForObjectNotation: true,
      };
      const uploadFormData = serialize(formData, options);

      try {
        const response = await putTemplateById(
          auth.idToken,
          template.id,
          uploadFormData,
        );

        if (response.status !== 200) {
          await handleToast.popUp('Error', ERROR);

          console.error(
            response.data?.message
              ? response.data.message
              : response.data
              ? JSON.stringify(response.data)
              : JSON.stringify(response),
          );

          setSubmitting(false);
          return;
        }

        handleToast.popUp('Success', SUCCESS, handleClick.back);
      } catch (error) {
        console.error('Error:', error);
        handleToast.popUp(error.message);
      }

      setSubmitting(false);

      return;
    }

    console.error('Error: token is invalid');

    setSubmitting(false);
  };

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      (async () => {
        const tempRes = await getCertificateTemplates(auth.idToken);

        if (tempRes && tempRes.data && Array.isArray(tempRes.data)) {
          const temps = tempRes.data.map(({ type, data }) => ({
            type: capitalizeWords(type),
            data: Array.isArray(data)
              ? data.map(mapToCertificateTemplateData)
              : [],
          }));

          setTemplates(temps);
        }
      })();
    }
  }, [auth]);

  React.useEffect(() => {
    if (isTemplateSelected && selectedTemplate) {
      const { template } = selectedTemplate;

      (async () => {
        const newInitialValues = {
          site: template.site,
          type: template.type,
          description: template.description,
          topic: template.topic,
          location: template.location,
          logo: { name: template.logo },
          signatory: [
            {
              name: template.signatory1.name,
              title: template.signatory1.title,
              signature: { name: template.signatory1.signature },
            },
            {
              name: template.signatory2.name,
              title: template.signatory2.title,
              signature: { name: template.signatory2.signature },
            },
          ],
        };
        const newFileNames = {
          ...fileNames,
          logo: fileNameFromUrl(newInitialValues.logo.name),
          'signatory.0.signature': fileNameFromUrl(
            newInitialValues.signatory[0].signature.name,
          ),
          'signatory.1.signature': fileNameFromUrl(
            newInitialValues.signatory[1].signature.name,
          ),
        };

        setFileNames(newFileNames);
        setFormDataInitialValues(newInitialValues);
        setFormKey(uuid());
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateSelected, selectedTemplate]);

  return (
    <Container pathUrl={path}>
      <Toast
        open={openToast}
        onClose={handleToast.close}
        onOpen={handleToast.open}
        severity={toastData.severity}
        text={toastData.text}
      />
      <Box sx={{ width: '100%', fontFamily: 'Poppins', paddingBottom: '2rem' }}>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleClick.back}
        >
          Back
        </Button>
        <Box sx={{ width: '66.666%' }}>
          <Box sx={{ marginBottom: '32px' }}>
            <Typography variant='h5' gutterBottom>
              Edit Template
            </Typography>
          </Box>

          <Box className='pl-2'>
            <TemplateSelection
              templates={templates}
              handleSelectTemplate={handleSelectTemplate}
            />

            <Box sx={{ ml: 1 }}>
              <Box
                sx={{
                  color: isTemplateSelected ? 'rgba(0, 0, 0, 0.8)' : 'gray',
                  fontSize: '1.3rem',
                  ml: -1,
                  mt: 4,
                  mb: 3,
                  fontStyle: isTemplateSelected ? 'initial' : 'italic',
                }}
              >
                {isTemplateSelected ? (
                  `${templateType.type} - ${templateType.topic}`
                ) : (
                  <span>No template selected</span>
                )}
              </Box>
              {isTemplateSelected ? (
                formDataInitialValues ? (
                  <Formik
                    key={formKey}
                    initialValues={formDataInitialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleSubmitForm}
                  >
                    {/* eslint-disable-next-line no-shadow */}
                    {({
                      values,
                      setFieldValue,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div
                          style={{
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontSize: '1.3rem',
                            marginBottom: '12px',
                          }}
                        >
                          Certificate data:
                        </div>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <label
                              htmlFor='logo'
                              style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontSize: '0.75rem',
                                marginLeft: '12px',
                              }}
                            >
                              <input
                                ref={refs.logo}
                                type='file'
                                accept='image/jpeg,image/png,image/webp'
                                name='logo'
                                id='logo'
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                  if (event.target.files[0] && !isSubmitting) {
                                    const file = event.target.files[0];
                                    setFileNames({
                                      ...fileNames,
                                      [event.target.name]: file.name,
                                    });
                                    setFieldValue(event.target.name, file);
                                  }
                                }}
                              />
                              <span style={{ marginRight: '8px' }}>Logo:</span>
                              <Button
                                component='span'
                                size='small'
                                disableElevation
                                disabled={isSubmitting}
                                // onClick={handleClick.ref(refs.logo)}
                                sx={{
                                  textTransform: 'none',
                                  color: '#A855F7',
                                }}
                              >
                                {fileNames?.logo
                                  ? fileNames.logo
                                  : 'select file'}
                              </Button>
                            </label>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Site'
                              name='site'
                              value={values.site}
                              onChange={handleChange}
                              fullwidth
                              disabled={isSubmitting}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Type'
                              name='type'
                              value={values.type}
                              onChange={handleChange}
                              fullwidth
                              required
                              disabled={isSubmitting}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                style: { textTransform: 'capitalize' },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label='Description'
                              name='description'
                              value={values.description}
                              onChange={handleChange}
                              fullwidth
                              required
                              disabled={isSubmitting}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Topic'
                              name='topic'
                              value={values.topic}
                              onChange={handleChange}
                              fullwidth
                              required
                              disabled={isSubmitting}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label='Location'
                              name='location'
                              value={values.location}
                              onChange={handleChange}
                              fullwidth
                              required
                              disabled={isSubmitting}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: '24px' }}>
                            {/* Signatories */}
                            <div
                              style={{
                                color: 'rgba(0, 0, 0, 0.8)',
                                fontSize: '1.3rem',
                                marginBottom: '8px',
                              }}
                            >
                              Signatories:
                            </div>
                            <FieldArray name='signatory'>
                              {
                                (/* { insert, push, remove } */) => (
                                  <>
                                    {values.signatory.map(
                                      (signatory, index) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          // eslint-disable-next-line react/no-array-index-key
                                          key={index}
                                          sx={{ marginTop: '0px' }}
                                        >
                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                              label={`Signatory ${
                                                index + 1
                                              } Name`}
                                              name={`signatory.${index}.name`}
                                              value={signatory.name}
                                              onChange={handleChange}
                                              fullwidth
                                              required
                                              disabled={isSubmitting}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                              label={`Signatory ${
                                                index + 1
                                              } Title`}
                                              name={`signatory.${index}.title`}
                                              value={signatory.title}
                                              onChange={handleChange}
                                              fullwidth
                                              required
                                              disabled={isSubmitting}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <label
                                              htmlFor={`signatory.${index}.signature`}
                                              style={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontSize: '0.75rem',
                                                marginLeft: '12px',
                                              }}
                                            >
                                              <input
                                                ref={
                                                  refs[
                                                    `signatory.${index}.signature`
                                                  ]
                                                }
                                                type='file'
                                                accept='image/jpeg,image/png,image/webp'
                                                name={`signatory.${index}.signature`}
                                                id={`signatory.${index}.signature`}
                                                style={{ display: 'none' }}
                                                onChange={(event) => {
                                                  if (
                                                    event.target.files[0] &&
                                                    !isSubmitting
                                                  ) {
                                                    const file =
                                                      event.target.files[0];
                                                    setFileNames({
                                                      ...fileNames,
                                                      [event.target.name]:
                                                        file.name,
                                                    });
                                                    setFieldValue(
                                                      event.target.name,
                                                      file,
                                                    );
                                                  }
                                                }}
                                              />
                                              <span
                                                style={{ marginRight: '8px' }}
                                              >
                                                {`Signature ${index + 1}`}:
                                              </span>
                                              <Button
                                                component='span'
                                                size='small'
                                                disableElevation
                                                disabled={isSubmitting}
                                                // onClick={handleClick.ref(
                                                //   refs[
                                                //     `signatory.${index}.signature`
                                                //   ],
                                                // )}
                                                sx={{
                                                  textTransform: 'none',
                                                  color: '#A855F7',
                                                }}
                                              >
                                                {fileNames[
                                                  `signatory.${index}.signature`
                                                ]
                                                  ? fileNames[
                                                      `signatory.${index}.signature`
                                                    ]
                                                  : 'select file'}
                                              </Button>
                                            </label>
                                          </Grid>
                                          {/*
                                      <Button
                                        variant='outlined'
                                        color='secondary'
                                        size='small'
                                        style={{ marginTop: '8px', marginBottom: '16px' }}
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    */}
                                        </Grid>
                                      ),
                                    )}
                                    {/*
                                  <br />
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() =>
                                      push({ name: '', title: '', signature: null })
                                    }
                                  >
                                    Add Signatory
                                  </Button>
                                */}
                                  </>
                                )
                              }
                            </FieldArray>
                          </Grid>
                        </Grid>
                        <br />
                        <br />
                        <LoadingButton
                          type='submit'
                          size='small'
                          variant='contained'
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          onClick={isSubmitting ? noop : handleSubmit}
                          // loadingPosition='start'
                          // startIcon={<div>Loading...</div>}
                          className='cursor-pointer rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-2 px-4 font-semibold shadow hover:shadow-cyan-500'
                          sx={{
                            paddingTop: '8px',
                            textTransform: 'none',
                          }}
                        >
                          <span>{isSubmitting ? 'Loading...' : 'Submit'}</span>
                        </LoadingButton>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className='ml-2 italic text-gray-400'>Loading...</div>
                )
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
