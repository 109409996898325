// eslint-disable-next-line import/no-extraneous-dependencies
import { LOGIN } from '@contexts/contextConsts';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import { useFormik } from 'formik';

import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function VerifyCodeForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailFromQuery = searchParams.get('email');
  const { dispatch: authDispatch } = useAuth();

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/admin/auth/verify-2fa`,
        {
          email: values.email,
          code: values.code,
        },
      );
      //  console.log(response);

      toast.success('Login successful!', {
        position: 'top-center',
        autoClose: 2000,
      });

      if (response?.status === 200 && response.data?.Admin?._id) {
        authDispatch({
          type: LOGIN,
          payload: {
            isAuthenticated: true,
            user: response.data.Admin,
            idToken: response.data.idToken,
            refreshToken: response.data.refreshToken,
          },
        });
        setTimeout(() => {
          navigate(location.state ? location.state.from.pathname : '/');
        }, 500);
        return;
      }
      //  console.log(response.data);
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: emailFromQuery || '',
      code: '',
    },
    onSubmit,
  });

  const isBtnFormActive = !formik.isValid || formik.isSubmitting;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='flex w-full flex-col justify-center gap-y-5'
    >
      {/* <div className='flex flex-col gap-y-2'>
        <input
          id='email'
          name='email'
          type='email'
          placeholder='E.g, name@email.com'
          className='font-poppins rounded-xl border border-gray-200 bg-[#FAFAFA] py-3 px-3 shadow-inner focus:outline-none focus:ring-0'
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.errors.email ? (
          <div className='text-sm text-red-500'>{formik.errors.email}</div>
        ) : null}
      </div> */}

      <div className='flex flex-col gap-y-2'>
        <input
          id='code'
          name='code'
          type='text'
          placeholder='Enter your code'
          className='font-poppins rounded-xl border border-gray-200 bg-[#FAFAFA] py-3 px-3 shadow-inner focus:outline-none focus:ring-0'
          onChange={formik.handleChange}
          value={formik.values.code}
        />
      </div>

      <button
        type='submit'
        disabled={isBtnFormActive}
        className={`${
          isBtnFormActive ? 'bg-blue-200' : 'bg-blue-500'
        } mt-5 w-full rounded-2xl border-transparent py-2 text-lg font-bold text-white focus:border-transparent focus:ring-0`}
      >
        Verify code
      </button>
    </form>
  );
}
