import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@src/components/commons/containerPage.common';
import BusinessDocuments from '@src/components/forms/businessDocuments.form';
import BusinessInformation from '@src/components/forms/businessInformation.form';
import MailingDetails from '@src/components/forms/mailingDetails.form.jsx';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;

export default function CreateAccountOrganisation() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [createOrg, setCreateOrg] = React.useState({
    business_name: '',
    type_of_company: '',
    business_registration_no: '',
    date: '',
    business_address: '',
    postal_code: '',
    phone_number: '',
    email: '',
    mailing_details_business_name: '',
    mailing_details_delivery_address: '',
    mailing_details_postal_code: '',
    moh_licence: '',
    acra: '',
    from: '',
    to: '',
  });

  const [createdOrgId, setCreatedOrgId] = useState(null);

  function createOrganisation() {
    const formData = {
      business_name: createOrg.business_name,
      type_of_company: createOrg.type_of_company,
      business_registration_no: createOrg.business_registration_no,
      date_of_registration: createOrg.date,
      business_address: createOrg.business_address,
      postal_code: createOrg.postal_code,
      phone_number: createOrg.phone_number,
      email: createOrg.email,
      mailing_details_business_name: createOrg.mailing_details_business_name,
      mailing_details_delivery_address:
        createOrg.mailing_details_delivery_address,
      mailing_details_postal_code: createOrg.mailing_details_postal_code,
      moh_licence: createOrg.moh_licence,
      acra: createOrg.acra,
      valid_from: createOrg.from,
      valid_to: createOrg.to,
    };

    axios
      .post(`${url}/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        const id = response.data._id;
        console.log('Account created successfully:', response.data);
        setCreatedOrgId(id);

        setOpen(true);
        setCreateOrg({ ...createOrg });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error('Please complete the data!', {
          autoClose: 2000,
        });
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    if (createdOrgId) {
      navigate(`/verified-organisation/${createdOrgId}`);
    }
    // navigate('/organisation');
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+65/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleSaveButtonClick = () => {
    if (!createOrg?.business_name) {
      toast.error('Business Name is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.type_of_company) {
      toast.error('Company is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.business_registration_no) {
      toast.error('Business No is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.date) {
      toast.error('Date Registration is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.postal_code) {
      toast.error('Postal code is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.business_address) {
      toast.error('Business Address is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.phone_number) {
      toast.error('Phone number is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (
      !createOrg?.phone_number ||
      !isValidPhoneNumber(createOrg.phone_number)
    ) {
      toast.error('+65 Phone Number format is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.email) {
      toast.error('Email is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.mailing_details_business_name) {
      toast.error('Business name is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.mailing_details_delivery_address) {
      toast.error('Business Address is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.mailing_details_postal_code) {
      toast.error('Postal code is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (
      !createOrg?.moh_licence &&
      !createOrg?.from &&
      !createOrg?.to &&
      !createOrg?.acra
    ) {
      toast.error('Business Documents are required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createOrg?.mailing_details_postal_code) {
      toast.error('Postal code is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    setTimeout(() => {
      createOrganisation();
      handleOpenModal();
      // toast.success('Organisation has been listed');
    }, 1000);
  };

  return (
    <Container pathUrl={path}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <Box
        sx={{ width: '100%', fontFamily: 'Poppins', margin: 2 }}
        className='w-3/4'
      >
        <div className='grid grid-cols-2'>
          <div className='col-end-2'>
            <Box
              className='rounded-xl'
              sx={{
                height: '575px',
                width: 'auto',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='font-poppins py-4 px-4 text-lg font-bold'>
                Business Information
              </div>
              <BusinessInformation
                createOrg={createOrg}
                setCreateOrg={setCreateOrg}
              />
            </Box>
            <Box
              className='rounded-xl'
              sx={{
                height: '303px',
                width: 'auto',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='font-poppins py-4 px-6 text-lg font-bold'>
                Mailing Details
              </div>
              <MailingDetails
                createOrg={createOrg}
                setCreateOrg={setCreateOrg}
              />
            </Box>
          </div>
          <div className='col-auto'>
            <Box
              className='rounded-xl'
              sx={{
                height: '422px',
                width: 'auto',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='font-poppins py-4 px-6 text-lg font-bold'>
                Business Documents
              </div>
              <BusinessDocuments
                createOrg={createOrg}
                setCreateOrg={setCreateOrg}
              />
            </Box>
            <Box
              className='rounded-xl'
              sx={{
                height: '90px',
                width: 'auto',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='flex justify-end px-6 py-6'>
                <Button
                  sx={{
                    textTransform: 'none',
                    width: '200px',
                    height: '48px',
                  }}
                  className='text-md rounded-md bg-cyan-600 font-bold text-white'
                  variant='contained'
                  onClick={handleSaveButtonClick}
                >
                  Save
                </Button>
              </div>
            </Box>
          </div>
        </div>
        <br />
        <br />
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
