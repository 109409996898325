import '@fontsource/poppins';
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import AuthContextProvider from './contexts/authContext';
import ContextProvidersHelper from './contexts/contextProvidersHelper';
import FormDataContextProvider from './contexts/formDataContext';
import TaskQueueContextProvider from './contexts/taskQueueContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById('root');
const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: [
        'Poppins',
        'Nunito',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
});

root.render(
  <React.StrictMode>
    <ContextProvidersHelper
      providers={[
        <AuthContextProvider />,
        <FormDataContextProvider />,
        <TaskQueueContextProvider />,

        /* mui */
        <StyledEngineProvider injectFirst />,
        <ThemeProvider theme={theme} />,
      ]}
    >
      <App />
    </ContextProvidersHelper>
    <ToastContainer />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
