/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ButtonAction from '@src/components/buttons/action.button';
import Container from '@src/components/commons/containerPage.common';
import PropTypes from 'prop-types';

import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { useLocation, useNavigate } from 'react-router-dom';
import UpcomingPayment from './upcomingPaymentClinic';

function TabPanel(props) {
  const { children, value, index, style = {} } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabLabel(index) {
  return {
    id: `simple-tab-${index}`,
    poppins: `simple-tabpanel-${index}`,
  };
}

export default function PaymentRequestClinic() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  // const [value, setValue] = React.useState('one');
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <Container pathUrl={path} />
      </div>
      <div>
        <Box sx={{ width: '100%' }} className='px-2 py-10'>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            className=' border-gray-50'
          >
            <Tabs
              value={value}
              indicatorColor='white'
              onChange={handleChange}
              aria-label='basic tabs example'
              className='px-6'
            >
              <Tab
                sx={{
                  textTransform: 'none',
                  width: 'auto',
                  borderColor: 'blue',
                }}
                label='Upcoming Payment List'
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 0 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(0)}
              />
              <Box sx={{ width: '9px' }} />
              <Tab
                sx={{ textTransform: 'none', width: 'auto' }}
                label='Payment History'
                className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                  value === 2 ? 'bg-cyan-600 text-white' : 'bg-white'
                }`}
                {...tabLabel(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <UpcomingPayment />
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* < /> */}
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
