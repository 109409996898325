/* eslint-disable react/no-unknown-property */

/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import Avatar from '@mui/material/Avatar';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// const url = `${process.env.REACT_APP_API_URL}`;

export default function AuditLogPage() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const { auth } = useAuth();
  const format_date = { year: 'numeric', month: 'long', day: 'numeric' };
  // const [searchQuery, setSearchQuery] = useState('');

  // const handleSearch = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  // const filteredList = list.filter((row) => {
  //   return row?.business_name.toLowerCase().includes(searchQuery.toLowerCase());
  // });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useEffect(() => {
  //   if (auth?.idToken) {
  //     axios
  //       .get(`${url}`, {
  //         headers: {
  //           Authorization: `Bearer ${auth?.idToken}`,
  //         },
  //       })
  //       .then((response) => {
  //         setList(response.data);
  //       })
  //       .catch((error) => {
  //         console.log('Error', error.message);
  //       });
  //   }
  // }, [auth]);

  return (
    <Container pathUrl={path}>
      <div className='py-8 px-4'>
        <Box sx={{ width: '100%' }} className=' w-3/4 flex justify-end px-6 py-4'>
          <input
            placeholder='Search by name'
            // value={searchQuery}
            // onChange={handleSearch}
            className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            style={{ height: '20px', width: '200px' }}
          />
        </Box>
        <span className='w-3/4 flex flex-col items-start pr-20'>
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '200px' }}
                  >
                    Event Time
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '200px' }}
                  >
                    Event Detail
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '200px' }}
                  >
                    Administrator
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '200px' }}
                  >
                    IP Address
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(list) && list.length > 0 ? (
                  list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.code}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className='border-white text-sm font-bold text-gray-500'
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '12px',
                            }}
                          >
                            {/* {row?.business_name} */}
                          </span>
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {/* {row?.code} */}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {/* {row.type_of_company} */}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {/* {row.createdAt} */}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No audit log listed
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
        <style jsx>{`
          @media (max-width: 768px) {
            .flex {
              display: block;
            }
            .pr-20 {
              padding-right: 0;
            }
          }
          @media (min-width: 769px) {
            .flex {
              display: flex;
            }
            .pr-20 {
              padding-right: 20px;
            }
          }
        `}</style>
      </div>
    </Container>
  );
}
