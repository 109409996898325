/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable react/no-unknown-property */

/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function TableNurseVerified() {
  const navigate = useNavigate();
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };
  const handleClickDetail = (userId) => {
    navigate(`/verified-nurse-detail/${userId}`);
  };

  const [loading, setLoading] = useState();

  const [list, setList] = useState([]);
  const { auth } = useAuth();

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [sortOrder, setSortOrder] = useState('desc');
  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    const filteredList = list?.filter((row) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return (
        (row &&
          row.full_name &&
          row.full_name.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (row?.email &&
          row.email.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (row?.phone_number &&
          row.phone_number.toLowerCase().includes(lowerCaseSearchQuery))
      );
    });
    const sortedData = [...filteredList].sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    setSortedList(sortedData);
  }, [list, searchQuery, sortOrder]);

  const handleSortToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [status, setStatus] = useState('verified');

  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${url}/admin/users?role=nurse&status=${status}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        );
        setList(response.data);
        setLoading(false);
      } catch (error) {
        console.log('Error', error.message);
        setLoading(false);
      }
    };

    if (auth?.idToken) {
      fetchData();
    }
  }, [auth, status, deleteStatus]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/admin/delete-user/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
      });
      console.log(response);
      setDeleteStatus('Delete successful');
      toast.success('Account has been deleted successfully', {
        autoClose: 2000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };
  return (
    <div>
      <Box sx={{ width: '100%' }} className='flex w-3/4 justify-end px-6 py-4'>
        <input
          placeholder='Search by name, email/phone number'
          value={searchQuery}
          onChange={handleSearch}
          className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-xs text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:leading-6'
          style={{ height: '20px', width: '250px' }}
        />
      </Box>
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <span className='flex w-3/4 flex-col items-start py-3 pr-20'>
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '220px' }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '160px' }}
                  >
                    Phone Number
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '260px' }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '150px' }}
                  >
                    <TableSortLabel
                      active
                      direction={sortOrder}
                      onClick={handleSortToggle}
                      className='text-xs font-bold text-gray-400'
                      sx={{ width: '50px' }}
                    >
                      Registered Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '170px' }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(sortedList) && sortedList.length > 0 ? (
                  sortedList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className='border-white text-sm font-bold text-gray-500'
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '12px',
                            }}
                          >
                            <Avatar alt='' src={`${url}${row?.profile_pict}`} />
                            {row?.full_name || 'No name added'}
                          </span>
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row?.phone_number}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {/* {row?.createdAt
                          ? `${new Date(row?.createdAt).toLocaleDateString(
                              'au-AU',
                              format_date,
                            )} | ${formatTime(row?.createdAt)}`
                          : 'No date and time added'} */}

                          {row?.createdAt
                            ? `${new Date(row?.createdAt).toLocaleDateString(
                                'au-AU',
                                format_date,
                              )}`
                            : 'No date added'}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          <Button
                            variant='contained'
                            className='text-xs font-bold text-cyan-600'
                            sx={{
                              // width: '160px',
                              // height: '35px',
                              textTransform: 'none',
                              backgroundColor: '#FAF9FC',
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                            }}
                            onClick={() => handleClickDetail(row?._id)}
                          >
                            View
                          </Button>
                          &nbsp;
                          <Button
                            variant='contained'
                            className='text-xs  font-bold text-red-500'
                            sx={{
                              textTransform: 'none',
                              backgroundColor: '#FAF9FC',
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                            }}
                            onClick={() =>
                              showDeleteConfirmationDialog(row?._id)
                            }
                          >
                            Delete
                          </Button>
                          {showDeleteConfirmation && (
                            <Dialog
                              open={showDeleteConfirmation}
                              onClose={handleConfirmationDialogClose}
                            >
                              <DialogTitle>Confirm Delete</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to delete this account?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleConfirmationDialogClose}
                                  color='primary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmationDialogConfirm}
                                  color='error'
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No nurses listed
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
      )}
      <style jsx>{`
        @media (max-width: 768px) {
          .flex {
            display: block;
          }
          .pr-20 {
            padding-right: 0;
          }
        }
        @media (min-width: 769px) {
          .flex {
            display: flex;
          }
          .pr-20 {
            padding-right: 20px;
          }
        }
      `}</style>
    </div>
  );
}
