import dayjs from 'dayjs';

export const sortObject = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((a, v) => {
      a[v] = obj[v];
      return a;
    }, {});
};

export const mapToCertificateData = ({
  _id,
  date,
  share_link,
  is_deleted,
  __v,
  ...rest
}) => {
  return sortObject({
    id: _id,
    date: dayjs(date).format('MMM D, YYYY'),
    shareLink: share_link,
    isDeleted: is_deleted,
    ...rest,
  });
};

export const mapToCertificateTemplateData = ({
  _id,
  is_deleted,
  __v,
  ...rest
}) => {
  return sortObject({
    id: _id,
    isDeleted: is_deleted,
    ...rest,
  });
};
