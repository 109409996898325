import Box from '@mui/material/Box';

import React from 'react';

export default function EditCertItemsDoctor() {
  return (
    <Box sx={{ width: '980px', fontFamily: 'Poppins' }}>
      <div>d</div>
    </Box>
  );
}
