// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from '@mui/material';
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}/admin/payment`;

export default function UpcomingPaymentClinic() {
  const { auth } = useAuth();

  const [payment, setPayment] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(`${urls}`, {
  //       headers: {
  //         Authorization: `Bearer ${auth.idToken}`,
  //       },
  //     })
  //     .then((response) => {
  //       setPayment(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log('Error', error.message);
  //       setLoading(false);
  //     });
  // }, [auth]);

  return (
    <div>
      <div>
        {loading ? (
          <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
        ) : (
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '960px',
              paddingBottom: '12px',
              paddingRight: '12px',
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
            }}
          >
            <Table
              sx={{
                minWidth: 350,
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '150px', border: 'gray', paddingLeft: 20 }}
                  >
                    Slot ID
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '250px', border: 'gray' }}
                  >
                    Recipient
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '250px', border: 'gray' }}
                  >
                    Invoice Amount
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '250px', border: 'gray' }}
                  >
                    Payment Type
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '200px', border: 'gray' }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '200px', border: 'gray' }}
                  >
                    Auto Sent
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(payment) && payment.length > 0 ? (
                  payment
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row?._id}
                        hover
                        tabIndex={-1}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ margin: 2, paddingLeft: 20 }}
                        >
                          {/* 1234 */}
                        </TableCell>
                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {/* John Doe */}
                        </TableCell>
                        <TableCell
                          className='text-sm font-bold text-gray-500'
                          style={{ padding: 4 }}
                        >
                          {/* $300 */}
                        </TableCell>
                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {/* Transfer */}
                        </TableCell>
                        <TableCell style={{ padding: 4, margin: 2 }}>
                          <div className='flex justify-start '>
                            <Button
                              variant='contained'
                              className='bg-cyan-700 text-xs font-bold text-white'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                                width: '150px',
                              }}
                            >
                              Manual transfer
                            </Button>
                          </div>
                        </TableCell>
                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          <div>
                            <Icon
                              icon='ion:toggle'
                              color='#1788b9'
                              width='30'
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No payment listed
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '960px', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={payment.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        )}
      </div>
    </div>
  );
}
