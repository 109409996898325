/* eslint-disable no-param-reassign */

/* eslint-disable import/no-extraneous-dependencies */
import { saveTemplate } from '@api/GenerateCertificate';
import { useAuth } from '@contexts/authContext';
import '@fontsource/poppins';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Container from '@src/components/commons/containerPage.common';
import Toast, { ERROR, SUCCESS } from '@src/components/toast';
import { capitalizeWords, createFileFromImagePath, noop } from '@utils';
import { FieldArray, Form, Formik } from 'formik';
import { serialize } from 'object-to-formdata';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const WWLogoImg = '/work-wiz.png';
const BlankLogoImg = '/blank.jpg';

const initialValues = {
  logo: null,
  site: 'www.work-wiz.com',
  type: 'Certificate Of Attendance',
  description:
    'for having attended and passed the examination for the Workshop on',
  topic: '',
  location: 'Singapore',
  signatory: [
    { name: '', title: '', signature: null },
    { name: '', title: '', signature: null },
  ],
};

const TextField = styled(MuiTextField)({
  '& label.Mui-focused': {
    color: '#9350E5',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#A855F7',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A855F7',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#A855F7',
    },
  },
});

const getTemplateFiles = async () => {
  let files = [
    { name: 'logo', url: WWLogoImg },
    { name: 'signature', url: BlankLogoImg },
  ].map(async ({ name, url }) => {
    const file = await createFileFromImagePath(url);

    return { name, file };
  });
  files = await Promise.all(files);
  files = files.reduce((acc, item) => {
    acc[item.name] = item.file;
    return acc;
  }, {});

  return files;
};

export default function CreateTemplatePage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const [openToast, setOpenToast] = React.useState(false);
  const [toastData, setToastData] = React.useState({
    severity: ERROR,
    text: 'Error',
  });
  const [fileNames, setFileNames] = React.useState({});
  const [formDataInitialValues, setFormDataInitialValues] =
    React.useState(null);
  const refs = {
    logo: React.useRef(null),
    'signatory.0.signature': React.useRef(null),
    'signatory.1.signature': React.useRef(null),
  };

  const handleToast = {
    open: async () => {
      setOpenToast(true);
    },
    close: async () => {
      setOpenToast(false);
    },
    popUp: async (
      text,
      severity = SUCCESS,
      callback = noop,
      timeout = 1000,
    ) => {
      setToastData({
        severity,
        text,
      });
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
        callback();
      }, timeout);
    },
  };

  const handleClick = {
    ref: (ref) => async () => {
      if (ref?.current?.click) {
        ref.current.click();
      }
    },
    back: () => {
      navigate('/event-certificate');
    },
  };

  const handleSubmitForm = async (values, { setSubmitting }) => {
    if (auth.isAuthenticated) {
      const formData = { ...values, type: capitalizeWords(values.type) };
      const options = {
        indices: true,
        allowEmptyArrays: false,
        dotsForObjectNotation: true,
      };
      const uploadFormData = serialize(formData, options);

      try {
        const response = await saveTemplate(auth.idToken, uploadFormData);

        if (response.status !== 201) {
          await handleToast.popUp('Error', ERROR);

          console.error(
            response.data?.message
              ? response.data.message
              : response.data
              ? JSON.stringify(response.data)
              : JSON.stringify(response),
          );

          setSubmitting(false);
          return;
        }

        await handleToast.popUp('Success', SUCCESS, handleClick.back);
      } catch (error) {
        console.error('Error:', error);
        await handleToast.popUp(error.message);
      }

      setSubmitting(false);

      return;
    }

    console.error('Error: token is invalid');

    setSubmitting(false);
  };

  React.useEffect(() => {
    (async () => {
      const files = await getTemplateFiles();
      const newInitialValues = {
        ...initialValues,
        logo: files.logo,
        signatory: [
          {
            ...initialValues.signatory[0],
            signature: files.signature,
          },
          {
            ...initialValues.signatory[1],
            signature: files.signature,
          },
        ],
      };
      const newFileNames = {
        ...fileNames,
        logo: newInitialValues.logo.name,
        'signatory.0.signature': newInitialValues.signatory[0].signature.name,
        'signatory.1.signature': newInitialValues.signatory[1].signature.name,
      };

      setFileNames(newFileNames);
      setFormDataInitialValues(newInitialValues);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container pathUrl={path}>
      <Toast
        open={openToast}
        onClose={handleToast.close}
        onOpen={handleToast.open}
        severity={toastData.severity}
        text={toastData.text}
      />
      <Box sx={{ width: '100%', fontFamily: 'Poppins', paddingBottom: '2rem' }}>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleClick.back}
        >
          Back
        </Button>
        <Box sx={{ width: '66.666%' }}>
          <Box sx={{ marginBottom: '32px' }}>
            <Typography variant='h5' gutterBottom>
              Create New Template
            </Typography>
          </Box>

          <Box sx={{ paddingLeft: '10px' }}>
            {formDataInitialValues ? (
              <Formik
                initialValues={formDataInitialValues}
                // validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
              >
                {/* eslint-disable-next-line no-shadow */}
                {({
                  values,
                  setFieldValue,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div
                      style={{
                        color: 'rgba(0, 0, 0, 0.8)',
                        fontSize: '1.3rem',
                        marginBottom: '12px',
                      }}
                    >
                      Certificate data:
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <label
                          htmlFor='logo'
                          style={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontSize: '0.75rem',
                            marginLeft: '12px',
                          }}
                        >
                          <input
                            ref={refs.logo}
                            type='file'
                            accept='image/jpeg,image/png,image/webp'
                            name='logo'
                            id='logo'
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              if (event.target.files[0] && !isSubmitting) {
                                const file = event.target.files[0];
                                setFileNames({
                                  ...fileNames,
                                  [event.target.name]: file.name,
                                });
                                setFieldValue(event.target.name, file);
                              }
                            }}
                          />
                          <span style={{ marginRight: '8px' }}>Logo:</span>
                          <Button
                            component='span'
                            size='small'
                            disableElevation
                            disabled={isSubmitting}
                            // onClick={handleClick.ref(refs.logo)}
                            sx={{
                              textTransform: 'none',
                              color: '#A855F7',
                            }}
                          >
                            {fileNames?.logo ? fileNames.logo : 'select file'}
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Site'
                          name='site'
                          value={values.site}
                          onChange={handleChange}
                          fullwidth
                          disabled={isSubmitting}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Type'
                          name='type'
                          value={values.type}
                          onChange={handleChange}
                          fullwidth
                          required
                          disabled={isSubmitting}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label='Description'
                          name='description'
                          value={values.description}
                          onChange={handleChange}
                          fullwidth
                          required
                          disabled={isSubmitting}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Topic'
                          name='topic'
                          value={values.topic}
                          onChange={handleChange}
                          fullwidth
                          required
                          disabled={isSubmitting}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label='Location'
                          name='location'
                          value={values.location}
                          onChange={handleChange}
                          fullwidth
                          required
                          disabled={isSubmitting}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: '24px' }}>
                        {/* Signatories */}
                        <div
                          style={{
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontSize: '1.3rem',
                            marginBottom: '8px',
                          }}
                        >
                          Signatories:
                        </div>
                        <FieldArray name='signatory'>
                          {
                            (/* { insert, push, remove } */) => (
                              <>
                                {values.signatory.map((signatory, index) => (
                                  <Grid
                                    container
                                    spacing={2}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    sx={{ marginTop: '0px' }}
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label={`Signatory ${index + 1} Name`}
                                        name={`signatory.${index}.name`}
                                        value={signatory.name}
                                        onChange={handleChange}
                                        fullwidth
                                        required
                                        disabled={isSubmitting}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label={`Signatory ${index + 1} Title`}
                                        name={`signatory.${index}.title`}
                                        value={signatory.title}
                                        onChange={handleChange}
                                        fullwidth
                                        required
                                        disabled={isSubmitting}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <label
                                        htmlFor={`signatory.${index}.signature`}
                                        style={{
                                          color: 'rgba(0, 0, 0, 0.6)',
                                          fontSize: '0.75rem',
                                          marginLeft: '12px',
                                        }}
                                      >
                                        <input
                                          ref={
                                            refs[`signatory.${index}.signature`]
                                          }
                                          type='file'
                                          accept='image/jpeg,image/png,image/webp'
                                          name={`signatory.${index}.signature`}
                                          id={`signatory.${index}.signature`}
                                          style={{ display: 'none' }}
                                          onChange={(event) => {
                                            if (
                                              event.target.files[0] &&
                                              !isSubmitting
                                            ) {
                                              const file =
                                                event.target.files[0];
                                              setFileNames({
                                                ...fileNames,
                                                [event.target.name]: file.name,
                                              });
                                              setFieldValue(
                                                event.target.name,
                                                file,
                                              );
                                            }
                                          }}
                                        />
                                        <span style={{ marginRight: '8px' }}>
                                          {`Signature ${index + 1}`}:
                                        </span>
                                        <Button
                                          component='span'
                                          size='small'
                                          disableElevation
                                          disabled={isSubmitting}
                                          // onClick={handleClick.ref(
                                          //   refs[
                                          //     `signatory.${index}.signature`
                                          //   ],
                                          // )}
                                          sx={{
                                            textTransform: 'none',
                                            color: '#A855F7',
                                          }}
                                        >
                                          {fileNames[
                                            `signatory.${index}.signature`
                                          ]
                                            ? fileNames[
                                                `signatory.${index}.signature`
                                              ]
                                            : 'select file'}
                                        </Button>
                                      </label>
                                    </Grid>
                                    {/*
                                      <Button
                                        variant='outlined'
                                        color='secondary'
                                        size='small'
                                        style={{ marginTop: '8px', marginBottom: '16px' }}
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    */}
                                  </Grid>
                                ))}
                                {/*
                                  <br />
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() =>
                                      push({ name: '', title: '', signature: null })
                                    }
                                  >
                                    Add Signatory
                                  </Button>
                                */}
                              </>
                            )
                          }
                        </FieldArray>
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <LoadingButton
                      type='submit'
                      size='small'
                      variant='contained'
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onClick={isSubmitting ? noop : handleSubmit}
                      // loadingPosition='start'
                      // startIcon={<div>Loading...</div>}
                      className='cursor-pointer rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-2 px-4 font-semibold shadow hover:shadow-cyan-500'
                      sx={{
                        paddingTop: '8px',
                        textTransform: 'none',
                      }}
                    >
                      <span>{isSubmitting ? 'Loading...' : 'Submit'}</span>
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            ) : (
              <div className='ml-2 italic text-gray-400'>Loading...</div>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
