import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import * as tusUtils from '@utils/tus';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const extractFileName = (url) => {
  try {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const fileName = pathSegments[pathSegments.length - 1];
    // const shortFileName = fileName.slice(-10);
    return fileName || 'No file chosen';
  } catch (error) {
    console.error(url);
    return '';
  }
};

export default function AddEventInformationForm({
  formInput,
  setFormInput,
  update,
  setUpdate,
  setFileTus,
  onTusResume,
  setIsLoading,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(formInput?.learning_file || '');
  const [isVideo, setIsVideo] = useState(false);
  const [linkType, setLinkType] = useState('link');
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [videoPlayerId, setVideoPlayerId] = useState(uuid());
  const videoAttachment = React.useMemo(() => {
    if (selectedFile && selectedFile.name) return selectedFile.name;
    if (previewUrl) return previewUrl;

    return 'Add attachment';
  }, [selectedFile, previewUrl]);

  const handleLinkTypeChange = async (event) => {
    setLinkType(event.target.value);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const learnVideo = await tusUtils.createUploadData(file, onTusResume, {
          Authorization: `Bearer ${auth.idToken}`,
        });
        setFileTus.setLearnVideoTus(learnVideo);
      } catch (e) {
        console.error(e);
      }

      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        learning_file: file,
      }));
      setIsVideo(file.type.includes('video'));

      setTimeout(() => {
        setVideoPlayerId(uuid());
      }, 1000);
    }
  };

  const handlePdfChange = async (ev) => {
    const file = ev.target.files[0];

    if (file) {
      try {
        const slidePdf = await tusUtils.createUploadData(file, onTusResume, {
          Authorization: `Bearer ${auth.idToken}`,
        });
        setFileTus.setSlidePdfTus(slidePdf);
      } catch (e) {
        console.error(e);
      }

      setSelectedSlide(file);
      setFormInput((prevInput) => ({
        ...prevInput,
        source_slide: file,
      }));
    }
  };

  const handleDeleteVideo = async () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setIsVideo(false);
    setFileTus.setLearnVideoTus(null);
    setFormInput((prevFormInput) => {
      const updatedFormInput = { ...prevFormInput };
      delete updatedFormInput.learn_video;
      delete updatedFormInput.learning_file;
      return updatedFormInput;
    });
  };

  React.useEffect(() => {
    if (setIsLoading) {
      if (!auth?.idToken) {
        setIsLoading(true);
      } else if (auth?.idToken) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setIsLoading]);

  React.useEffect(() => {
    if (formInput?.learning_file) {
      setTimeout(() => {
        setVideoPlayerId(uuid());
      }, 1000);
    }
  }, [formInput?.learning_file]);

  return (
    <>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-3 font-bold leading-6 text-gray-600'>
            Add Link
          </label>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='https://'
              id='title'
              type='text'
              className='font-poppins  block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                formInput ? formInput.past_link || '' : update.past_link || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, past_link: e.target.value })
                  : setUpdate({ ...update, past_link: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 py-2 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block font-bold leading-6 text-gray-600'>
            Enter Description
          </label>
          <div className='mt-2 font-medium'>
            <input
              id='link'
              placeholder='Enter Description'
              type='text'
              className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
              value={
                formInput ? formInput.past_desc || '' : update.past_desc || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({ ...formInput, past_desc: e.target.value })
                  : setUpdate({ ...update, past_desc: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-2 font-bold leading-6 text-gray-600'>
            Add Video
          </label>
          <div>
            <input
              type='file'
              accept='video/*'
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id='upload-file-input'
            />
            <label htmlFor='upload-file-input'>
              <Button
                sx={{
                  justifyContent: 'left',
                  width: '530px',
                  height: '48px',
                  textTransform: 'none',
                }}
                variant='outlined'
                component='span'
                className='border border-gray-300 bg-gray-100 font-medium text-gray-400'
              >
                {videoAttachment}
              </Button>
            </label>
            <br />
            <div key={videoPlayerId} className='relative mt-8 w-[200px]'>
              {previewUrl ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  src={previewUrl}
                  controls
                  width='200px'
                  height='auto'
                  style={{ marginTop: 20 }}
                />
              ) : !isVideo ? (
                <img
                  src={previewUrl}
                  alt=''
                  style={{ width: '200px', marginTop: 20 }}
                />
              ) : null}
              {selectedFile ? (
                <div className='group absolute top-1 right-1 py-2 pt-1'>
                  <div className='absolute -right-1/2 -top-1/2 h-10 w-10 translate-x-1/2 -translate-y-1/2 transform rounded-full bg-gray-300 transition-all duration-300 group-hover:bg-white group-hover:shadow-lg'>
                    <Icon
                      onClick={handleDeleteVideo}
                      icon='clarity:remove-solid'
                      color='#FF5555'
                      width='28'
                      className='h-10 w-10 scale-100 transform rounded-full transition-transform group-hover:scale-110'
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className='mt-3 px-5 py-4'>
        <div className='sm:col-span-6'>
          <label className='text-md block py-3 font-bold leading-6 text-gray-600'>
            Add Link/Slide
          </label>
        </div>

        <div>
          <RadioGroup
            value={linkType}
            onChange={handleLinkTypeChange}
            row
            aria-label='link-type'
            name='link-type'
          >
            <FormControlLabel value='link' control={<Radio />} label='Link' />
            <FormControlLabel
              value='slides'
              control={<Radio />}
              label='Slide'
            />
            <div className='font-poppins mt-2 px-8 font-medium'>
              {linkType === 'link' ? (
                <input
                  placeholder='Enter link'
                  id='link'
                  type='text'
                  className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-10 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  value={formInput ? formInput.links : update.links}
                  onChange={(e) =>
                    setFormInput((prevInput) => ({
                      ...prevInput,
                      links: e.target.value,
                    }))
                  }
                  disabled={linkType !== 'link'}
                />
              ) : (
                <div>
                  <input
                    type='file'
                    id='attachment'
                    accept='.pdf,.ppt,.pptx'
                    onChange={handlePdfChange}
                  />
                  <div className='py-4 text-xs'>
                    {selectedSlide?.name ||
                      extractFileName(formInput?.source_slide || '')}
                  </div>
                </div>
              )}
            </div>
          </RadioGroup>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='py-2 sm:col-span-6'>
          <div className='flex justify-between'>
            <label className='text-md block py-3 font-bold leading-6 text-gray-600'>
              Add Event Questionnare
            </label>
            <div>
              <Button
                variant='contained'
                sx={{ textTransform: 'none' }}
                component='span'
                className='bg-cyan-600 from-cyan-500 to-cyan-600 font-bold'
                onClick={() => {
                  navigate('/event-questionnare');
                }}
              >
                Create Questionnare
              </Button>
            </div>
          </div>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='Enter Link'
              id='title'
              type='text'
              className='font-poppins  block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                formInput
                  ? formInput.link_question || ''
                  : update.link_question || ''
              }
              onChange={(e) =>
                formInput
                  ? setFormInput({
                      ...formInput,
                      link_question: e.target.value,
                    })
                  : setUpdate({ ...update, link_question: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
