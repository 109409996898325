/* eslint-disable react/jsx-props-no-spreading */
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import * as React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function VerifyCertificate() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant='contained'
        sx={{
          textTransform: 'none',
          margin: 2,
          height: '30px',
          width: '100px',
        }}
        className='bg-cyan-600 from-cyan-500 to-cyan-600 px-6 font-bold'
        onClick={handleClickOpen}
      >
        Check
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
        >
          <div className='font-bold text-gray-800'>Verify Certificate</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '299px 1fr',
              gap: '1rem',
              width: 1071,
              height: 372,
            }}
            className='rounded-xl'
          >
            <Box
              sx={{
                backgroundColor: '#FAFAFA',
                width: 300,
                height: 372,
                margin: 1,
              }}
              className='rounded-md'
            >
              <div className='px-4 py-4 text-sm font-bold text-gray-800'>
                Graduate Diploma in Family Practice Dermatology{' '}
              </div>
              <Button
                sx={{
                  textTransform: 'none',
                  margin: 1,
                }}
                className='px-2 text-cyan-500 underline'
                variant='text'
                component='label'
              >
                Download Graduate Diploma.PDF
              </Button>
            </Box>

            <div className='font-poppins mt-2 px-4 font-medium'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Certificate name
              </label>
              <br />
              <input
                placeholder='Graduate...'
                type='text'
                className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              />
              <br />
              <label className='text-md mt-4 block font-bold leading-6 text-gray-600'>
                Remarks:
              </label>
              <div className='mt-2'>
                <textarea
                  rows='4'
                  className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>
        </DialogContent>

        <div>
          <Button
            variant='contained'
            sx={{
              textTransform: 'none',
              margin: 2,
              height: '40px',
              width: '200px',
            }}
            className='rounded-xl bg-cyan-600 from-cyan-500 to-cyan-600 px-6 font-bold'
            autoFocus
            onClick={handleClose}
          >
            Verify Certificate
          </Button>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'none',
              margin: 2,
              height: '40px',
              width: '200px',
            }}
            className='rounded-xl bg-white px-6 font-bold text-cyan-600'
            autoFocus
            onClick={handleClose}
          >
            Reject Certificate
          </Button>
        </div>
      </BootstrapDialog>
    </div>
  );
}
