import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import BusinessDocuments from '@src/components/forms/businessDocuments.form';
import BusinessInformation from '@src/components/forms/businessInformation.form';
import MailingDetails from '@src/components/forms/mailingDetails.form.jsx';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;
const urls = `${process.env.REACT_APP_API_URL}/ww-platform/admin/v2/organization/license/initiate`;

export default function OrganisationUnverified() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { orgId } = useParams();
  const handleBack = () => {
    navigate('/organisation');
  };

  const handleAdminOrg = (admId) => {
    navigate(`/view-admin-org/${admId}`);
  };

  const [license, setLicense] = useState();
  const [createOrg, setCreateOrg] = useState(null);
  const [updateOrg, setUpdateOrg] = useState({
    business_name: '',
    type_of_company: '',
    business_registration_no: '',
    date_of_registration: '',
    business_address: '',
    postal_code: '',
    phone_number: '',
    mailing_details_business_name: '',
    mailing_details_delivery_address: '',
    mailing_details_postal_code: '',
    moh_licence: '',
    acra: '',
    valid_from: '',
    valid_until: '',
    status: '',
  });

  React.useEffect(() => {
    axios
      .get(`${url}/${orgId}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setCreateOrg(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  const [getAdmin, setGetAdmin] = React.useState();

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/get-admins/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetAdmin(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  const [update, setUpdate] = React.useState();

  const handleUpdateModal = () => {
    setUpdate(true);
  };

  const handleCloseUpdateModal = () => {
    setUpdate(false);
    navigate('/organisation');
  };

  function setVerifiedOrg() {
    axios
      .patch(
        `${url}/set-verified/${orgId}`,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth.idToken}`,
          },
        },
      )
      .then((response) => {
        console.log('Account verified successfully:', response.data);
        setCreateOrg({ ...createOrg });
        setUpdate(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }

  const newDate = new Date();

  function initiateLicense() {
    const license_start_at = newDate.toISOString().slice(0, 16);
    const ninetyDaysLater = new Date(newDate);
    ninetyDaysLater.setDate(ninetyDaysLater.getDate() + 90);
    const license_expired_at = ninetyDaysLater.toISOString().slice(0, 16);

    if (auth?.idToken) {
      const requestData = {
        request: {
          data: {
            license_key: 'uuid',
            license_is_active: true,
            license_package_type: 'TRIAL',
            license_start_at,
            license_expired_at,
            license_note: '',
          },
          metadata: {},
        },
        system: {
          audit: {
            do_audit_trail: false,
          },
        },
      };
      axios
        .post(`${urls}/${orgId}`, requestData, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setLicense(response.data.response.data);
        })
        .catch((error) => {
          console.log('Error', error.message);
          if (error.response && error.response.data) {
            const { system } = error.response.data;

            if (
              system &&
              system.success === false &&
              system.message_type === 'FAILED'
            ) {
              toast.error(system.message);
            }
          }
        });
    }
  }

  const [banned, setBanned] = React.useState();

  const handleBannedModal = () => {
    setBanned(true);
  };

  const handleCloseBannedModal = () => {
    setBanned(false);
    navigate('/organisation');
  };

  function bannedOrganisation() {
    const formData = {
      status: 'banned',
    };
    axios
      .patch(`${url}/${orgId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account banned successfully', response.data);
        setCreateOrg({ ...createOrg, status: 'banned' });
        setBanned(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }

  const [suspend, setSuspend] = React.useState();

  const handleSuspendModal = () => {
    setSuspend(true);
  };

  const handleCloseSuspendModal = () => {
    setSuspend(false);
    navigate('/organisation');
  };

  function suspendOrganisation() {
    const formData = {
      status: 'suspended',
    };
    axios
      .patch(`${url}/${orgId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account suspended successfully', response.data);
        setCreateOrg({ ...createOrg, status: 'suspended' });
        setSuspend(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }

  return (
    <Box
      sx={{ width: '100%', fontFamily: 'Poppins', margin: 2 }}
      className='w-3/4'
    >
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-8 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='grid grid-cols-2'>
        <div className='col-end-2'>
          <Box
            className='rounded-xl'
            sx={{
              height: '131px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Organisation
            </div>
            <div className='flex justify-center from-cyan-500 to-cyan-600 text-2xl font-bold text-cyan-600 underline'>
              {createOrg?.code}
            </div>
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '565px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Business Information
            </div>
            <BusinessInformation
              createOrg={createOrg}
              setCreateOrg={setCreateOrg}
              updateOrg={updateOrg}
              setUpdateOrg={setUpdateOrg}
            />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '303px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6 text-lg font-bold'>
              Mailing Details
            </div>
            <MailingDetails
              createOrg={createOrg}
              setCreateOrg={setCreateOrg}
              updateOrg={updateOrg}
              setUpdateOrg={setUpdateOrg}
            />
          </Box>
        </div>
        <div className='col-auto'>
          <Box
            className='rounded-xl'
            sx={{
              height: '392px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6 text-lg font-bold'>
              Business Documents
            </div>
            <BusinessDocuments
              createOrg={createOrg}
              setCreateOrg={setCreateOrg}
              updateOrg={updateOrg}
              setUpdateOrg={setUpdateOrg}
            />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: '357px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6  text-lg font-bold'>
              Admin Listing
            </div>
            <div>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 500,
                }}
                className='px-2'
              >
                {Array.isArray(getAdmin) && getAdmin.length > 0 ? (
                  getAdmin.slice(0, 3).map((row) => (
                    <>
                      <ListItem alignItems='flex-start'>
                        <ListItemText
                          primary={
                            <span className='font-bold'>{row?.full_name}</span>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.email}
                              </Typography>
                              &nbsp; | &nbsp;
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.role}
                              </Typography>
                              {/* <br />
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component='span'
                                  variant='body2'
                                  color='text.secondary'
                                >
                                  {row?.clinicName || 'No clinic added'}
                                </Typography> */}
                            </>
                          }
                        />
                      </ListItem>
                      {getAdmin.indexOf(row) < 2 && (
                        <Divider
                          sx={{ marginLeft: 2 }}
                          variant='inset'
                          component='li'
                          className='w-3/4'
                        />
                      )}
                    </>
                  ))
                ) : (
                  <div className='flex justify-center py-20 px-8 text-sm text-gray-600'>
                    <p>
                      This organisation has no admin, please add admin to
                      activate license!
                    </p>
                  </div>
                )}
              </List>
            </div>
            <div className='flex justify-end px-6'>
              <Button
                variant='text'
                className='text-xs font-bold text-cyan-600'
                sx={{
                  textTransform: 'none',

                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={() => handleAdminOrg(createOrg?._id)}
              >
                View more
              </Button>
            </div>
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: '182px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div>
              <div className='font-poppins py-10 px-12 text-lg font-bold'>
                Verify this Organisation?
              </div>
              <div className='flex justify-center px-12'>
                <Button
                  sx={{
                    textTransform: 'none',
                    width: '130px',
                    height: '38px',
                  }}
                  className='bg-[#FF5555] text-sm font-bold text-white'
                  variant='contained'
                  onClick={() => {
                    bannedOrganisation();
                    handleBannedModal();
                  }}
                >
                  Reject
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                  sx={{
                    textTransform: 'none',
                    width: '130px',
                    height: '38px',
                  }}
                  className='bg-[#3CB6B6] text-sm font-bold text-white'
                  variant='contained'
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    setVerifiedOrg();
                    handleUpdateModal();
                    initiateLicense();
                  }}
                >
                  Approve
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                  sx={{
                    textTransform: 'none',
                    width: '130px',
                    height: '38px',
                  }}
                  className='bg-purple-500 text-sm font-bold text-white'
                  variant='contained'
                  onClick={() => {
                    suspendOrganisation();
                    handleSuspendModal();
                  }}
                >
                  Suspend
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </div>
      <br />
      <Dialog open={banned} onClose={handleCloseBannedModal}>
        <DialogTitle>Banned Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been banned successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBannedModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={update} onClose={handleCloseUpdateModal}>
        <DialogTitle>Verified Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been verified successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={suspend} onClose={handleCloseSuspendModal}>
        <DialogTitle>Suspended Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been suspended successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuspendModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
