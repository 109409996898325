import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}/event`;
export default function ViewerInformation() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { eventId } = useParams();
  const [listEvents, setListEvents] = useState([]);

  // const handleBack = () => {
  //   navigate(state?.prevLocation || -1);
  // };

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${urls}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setListEvents(response.data);
        })
        .catch((error) => {
          console.log('Error', error.message);
        });
    }
  }, [auth, eventId]);
  return (
    <>
      {/* <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button> */}

      {/* <div className='py-8 px-6 font-bold text-gray-600'>Event Report Page</div> */}
      <div className='px-4'>
        <TableContainer
          className='rounded-xl px-3'
          component={Paper}
          style={{
            width: '960px',
            paddingBottom: '12px',
            paddingRight: '12px',
            backgroundColor: '#ffffff',
            borderColor: 'ffffff',
          }}
        >
          <Table
            sx={{
              minWidth: 350,
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-sm font-bold text-gray-400'
                  style={{ width: '250px', border: 'gray' }}
                >
                  Viewer of This Event
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={listEvents?._id}>
                <TableCell className='border border-white'>
                  {listEvents?.stat_view_unique_count} viewers
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
