import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LabTabs from '@src/components/commons/tabs.common';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import ClinicBranchFinances from '../organisation/organisationFinances';
import AdminClinicList from './adminClinicList';
import ClinicBranchActivities from './clinicActivities';
import ClinicAdditionalInfo from './clinicAdditionalInfo';
import ClinicFinance from './clinicFinance';
import ClinicListingPage from './clinicListing.page';
import ClinicBranchListing from './clinicListingNurse';
import ClinicBranchProfile from './clinicProfile.card';
import ClinicSetting from './clinicSetting';
import ClinicDetail from './detailClinic';

// eslint-disable-next-line import/default

export default function ViewClinicPage() {
  const navigate = useNavigate();

  const categories = [
    {
      idx: 0,
      title: 'Verification Information',
      component: (
        <div className='py-4'>
          <ClinicDetail />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Profile',
      component: (
        <div>
          <ClinicBranchProfile />
        </div>
      ),
    },
    {
      idx: 2,
      title: 'Listing',
      component: (
        <div className='py-4'>
          <ClinicListingPage />
        </div>
      ),
    },
    // {
    //   idx: 3,
    //   title: 'Activities',
    //   component: (
    //     <div>
    //       <ClinicBranchActivities />
    //     </div>
    //   ),
    // },
    // {
    //   idx: 4,
    //   title: 'Finances',
    //   component: (
    //     <div>
    //       <ClinicFinance />
    //     </div>
    //   ),
    // },
    {
      idx: 3,
      title: 'Team',
      component: (
        <div>
          <AdminClinicList />
        </div>
      ),
    },
    process.env.REACT_APP_FEATURE_CLINIC_ADDITIONAL_INFO === 'true' && {
      idx: 4,
      title: 'Additional Info',
      component: <ClinicAdditionalInfo />,
    },
    process.env.REACT_APP_FEATURE_CLINIC_SETTING === 'true' && {
      idx: 5,
      title: 'Setting',
      component: <ClinicSetting />,
    },
  ].filter(Boolean);

  const handleBack = () => {
    navigate('/clinic-branch');
  };
  return (
    <>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-8 px-8 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='py-6'>
        <LabTabs menus={categories} />
      </div>
    </>
  );
}
