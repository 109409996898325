/* eslint-disable consistent-return */
import { useAuth } from '@contexts/authContext';

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children, loggedOnly = true }) {
  const { auth } = useAuth();
  const location = useLocation();

  if (auth.isAuthenticated === null) {
    console.warn('Auth context is still loading...');
    return;
  }

  if (!auth.isAuthenticated && loggedOnly) {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  if (auth.isAuthenticated && !loggedOnly) {
    return <Navigate to='/' />;
  }

  return children;
}

export default ProtectedRoute;
