/* eslint-disable no-prototype-builtins */

/* eslint-disable no-restricted-syntax */
import { mainRoute } from '../consts/routesMainMenu.const';

export const compareKeyAndValue = (pathUrl) => {
  for (const key in mainRoute) {
    if (mainRoute?.hasOwnProperty(key) && mainRoute[key] === pathUrl) {
      return key;
    }
  }
  return null;
};
