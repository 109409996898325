import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';

import React from 'react';

export default function ClinicList({ branch }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <span className='flex w-2/3 flex-col items-start px-6 py-4'>
        <TableContainer
          className='rounded-xl'
          component={Paper}
          style={{
            width: '100%',
            paddingBottom: '12px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ width: '60px' }}
                >
                  Clinic ID
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '200px' }}
                >
                  Clinic Name
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '50px' }}
                >
                  Initial
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '70px' }}
                >
                  Location
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '250px' }}
                >
                  Address
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '100px' }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(branch) &&
              branch.some((item) => Object.keys(item).length > 0) ? (
                branch
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.license_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        className='border-white text-sm font-bold text-gray-500'
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                          }}
                        >
                          {row?.clinic_code}
                        </span>
                      </TableCell>
                      <TableCell
                        className='border-white text-sm font-bold text-gray-500'
                        style={{ padding: 0 }}
                      >
                        {row?.clinic_name}
                      </TableCell>
                      <TableCell
                        className='border-white text-sm font-bold text-gray-500'
                        style={{ padding: 0 }}
                      >
                        {row.clinic_initials}
                      </TableCell>
                      <TableCell
                        className='border-white text-sm font-bold text-gray-500'
                        style={{ padding: 0 }}
                      >
                        {row?.clinic_location}
                      </TableCell>
                      <TableCell
                        className='border-white text-sm font-bold text-gray-500'
                        style={{ padding: 0 }}
                      >
                        {row.clinic_address || 'No address added'}
                      </TableCell>
                      <TableCell
                        className='border-white text-sm font-bold text-gray-500'
                        style={{ padding: 0 }}
                      >
                        <Button
                          variant='contained'
                          className='text-xs font-bold text-cyan-600'
                          sx={{
                            textTransform: 'none',
                            backgroundColor: '#FAF9FC',
                            '&:hover': {
                              backgroundColor: 'white',
                            },
                            width: '100px',
                            height: '35px',
                          }}
                          // onClick={() =>

                          // }
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    className='border border-white'
                    colSpan={6}
                    align='center'
                  >
                    <div className='font-poppins py-6 text-gray-500'>
                      No clinic listed
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Box
            className='flex justify-end py-3'
            sx={{ width: '100%', fontFamily: 'Poppins' }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 25, 100]}
              component='div'
              count={branch.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      </span>
    </div>
  );
}
