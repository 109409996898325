import UploadedFilesDialog from '@components/dialog/UploadedFilesDialog';
import { ERROR, SUCCESS } from '@components/toast';
import {
  TQueue,
  TQueueStatus,
  getNumberedTitle,
  prepareTaskData,
  useTaskQueue,
} from '@contexts/taskQueueContext';
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateLearningJourneyForm from '@src/components/forms/createLearningJourney.form';
import { useAuth } from '@src/contexts/authContext';
import { uuid } from '@utils';
import { mapUpdateLearningJourneyFormInputData2 } from '@utils/mapper/eventManagement';
import * as tusUtils from '@utils/tus';
import axios from 'axios';
import { dequal as isEqual } from 'dequal';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/event`;
const initialUpdateData = {
  event_title: '',
  event_link: '',
  event_desc: '',
  organiser_name: '',
  organiser_desc: '',
  roles: '',
  event_tags: '',
  source_link: '',
  event_image: '',
  organiser_image: '',
  source_slide: '',
  learning_file: '',
  sort: '',
  links: [],

  // date: null,
  // from: null,
  // to: null,
};

export default function UpdateLearningJourney() {
  const { auth } = useAuth();
  const { eventId } = useParams();
  const { pathname: state } = useLocation();
  const navigate = useNavigate();

  const { dispatch: dispatchTask } = useTaskQueue();
  const isMounted = React.useRef(true);
  const uploadResumeRef = React.useRef(true);
  const [isUploadedFilesDialogMounted, setIsUploadedFilesDialogMounted] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [formInput, _setFormInputState] = React.useState(null);
  const [update, setUpdate] = React.useState(initialUpdateData);

  const [eventImageTus, setEventImageTus] = React.useState(null);
  const [orgImageTus, setOrgImageTus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const setFormInput = (data, log) => {
    if (log) console.log(log);

    if (!isEqual(data, formInput)) {
      _setFormInputState(data);
    }
  };

  const setFormInputState = (data) => {
    setIsLoading(true);
    if (isMounted.current) setFormInput(data);
    setIsLoading(false);
  };

  const setOpenModalState = (data) => {
    if (isMounted.current) setOpen(data);
  };

  const handleOpenModal = async () => {
    setOpenModalState(true);
  };

  const handleCloseModal = async () => {
    setOpenModalState(false);
    // navigate(state?.prevLocation || -1);
  };

  const handleOnUploadResume = async (
    previousUploads,
    currentUpload,
    onSelectIndex,
  ) => {
    if (previousUploads.length === 0) return;

    if (uploadResumeRef.current && uploadResumeRef.current.askToResumeUpload) {
      const index = await uploadResumeRef.current.askToResumeUpload(
        previousUploads,
      );
      if (!Number.isNaN(index) && index >= 0 && previousUploads[index]) {
        if (previousUploads[index].uploadUrl) {
          // eslint-disable-next-line no-param-reassign
          currentUpload.url = previousUploads[index].uploadUrl;
        }

        await onSelectIndex(previousUploads[index]);
      }
    }
  };

  const putNewLearning = async () => {
    if (isUploadedFilesDialogMounted && !isLoading) {
      setIsLoading(true);

      let eventImage;
      let orgImage;
      const uploads = [];
      const uploadsStatus = [];
      const { idToken } = auth;
      const taskId = uuid();
      const title = getNumberedTitle('Update learning journey');

      if (eventImageTus) {
        eventImage = eventImageTus;
        uploads.push(eventImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      if (orgImageTus) {
        orgImage = orgImageTus;
        uploads.push(orgImage);
        uploadsStatus.push(TQueueStatus.PENDING);
      }

      const onTaskComplete = (data) => {
        const formData = mapUpdateLearningJourneyFormInputData2(formInput);

        if (eventImage && eventImage.upload.url) {
          formData.event_image = eventImage.upload.url;
        }

        if (orgImage && orgImage.upload.url) {
          formData.organiser_image = orgImage.upload.url;
        }

        const uploadFormData = { ...formData };

        axios
          .put(`${url}/learning-journey/${eventId}`, uploadFormData, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setFormInputState({ ...formInput });
            dispatchTask({
              type: TQueue.COMPLETE_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.COMPLETE,
                text: `Task complete: ${title}`,
                severity: SUCCESS,
              },
            });
            handleOpenModal();
          })
          .catch((error) => {
            console.error(error);
            dispatchTask({
              type: TQueue.FAIL_TASK,
              payload: {
                id: taskId,
                status: TQueueStatus.FAILED,
                text: `Task failed: ${title}`,
                severity: ERROR,
                reason: error.message,
              },
            });
          });
      };

      const taskData = prepareTaskData({
        taskId,
        title,
        uploads,
        uploadsStatus,
        onTaskComplete,
        runImmediately: true,
      });

      dispatchTask({
        type: TQueue.ADD_NEW_TASK,
        payload: {
          taskData,
          toastData: {
            text: `Task added: ${title}`,
            severity: SUCCESS,
          },
        },
      });

      setIsLoading(false);
    }
  };

  // const handleBack = async () => {
  //   navigate(state?.prevLocation || -1);
  // };

  const handleImageChange = async (event) => {
    setIsLoading(true);

    const file = event.target.files[0];

    if (file) {
      try {
        const orgImage = await tusUtils.createUploadData(
          file,
          handleOnUploadResume,
          {
            Authorization: `Bearer ${auth.idToken}`,
          },
        );
        setOrgImageTus(orgImage);
      } catch (e) {
        console.error(e);
      }

      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        org_image: file,
      }));
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (auth?.idToken && eventId) {
      axios
        .get(`${url}/${eventId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setFormInput(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, eventId]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (formInput?._id && uploadResumeRef.current) {
      setIsLoading(false);
      setIsUploadedFilesDialogMounted(true);
    }
  }, [uploadResumeRef, formInput]);

  return (
    <div>
      <UploadedFilesDialog ref={uploadResumeRef} />
      <Box sx={{ width: 'calc(100vw - 25rem)', fontFamily: 'Poppins' }}>
        {/* <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button> */}
        <div className='mr-6 grid grid-cols-12 gap-6'>
          <div className='col-span-7'>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '100%',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <CreateLearningJourneyForm
                formInput={formInput}
                setFormInput={setFormInput}
                update={update}
                setUpdate={setUpdate}
                setFileTus={{ setEventImageTus }}
                onTusResume={handleOnUploadResume}
                setIsLoading={setIsLoading}
              />
              <div className='py-6' />
            </Box>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '100%',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='py-2'>
                <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                  <div className='sm:col-span-6'>
                    <label className='text-md block py-4 font-bold leading-6 text-gray-600'>
                      Add Organiser Image (Optional)
                    </label>
                    <Box
                      sx={{
                        width: '100px',
                        height: '100px',

                        overflow: 'hidden',
                      }}
                      className='flex justify-center rounded-xl bg-gray-300'
                    >
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        id='upload-image-input'
                      />

                      {selectedFile ||
                      previewUrl ||
                      formInput?.organiser_image ? (
                        <div>
                          <img
                            src={previewUrl || formInput?.organiser_image}
                            alt=''
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      ) : null}
                    </Box>
                    <div className='py-5'>
                      <label htmlFor='upload-image-input'>
                        <Button
                          variant='contained'
                          component='span'
                          className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
                        >
                          Upload Image
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                  <div className='sm:col-span-6'>
                    <label className='font-poppins text-md block font-bold leading-6 text-gray-600'>
                      Organiser Name*
                    </label>
                    <div className='mt-2'>
                      <input
                        id='org_name'
                        placeholder='Enter Organiser Name'
                        type='text'
                        className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                        value={
                          formInput
                            ? formInput.organiser_name || ''
                            : update.organiser_name || ''
                        }
                        onChange={(e) =>
                          formInput
                            ? setFormInput({
                                ...formInput,
                                organiser_name: e.target.value,
                              })
                            : setUpdate({
                                ...update,
                                organiser_name: e.target.value,
                              })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 py-2 px-5 sm:grid-cols-6'>
                  <div className='sm:col-span-6'>
                    <label className='text-md block font-bold leading-6 text-gray-600'>
                      Add Organiser Description
                    </label>
                    <div className='mt-2 font-medium'>
                      <textarea
                        placeholder='Add Organiser Description'
                        rows='4'
                        className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        value={
                          formInput
                            ? formInput.organiser_desc || ''
                            : update.organiser_desc || ''
                        }
                        onChange={(e) =>
                          formInput
                            ? setFormInput({
                                ...formInput,
                                organiser_desc: e.target.value,
                              })
                            : setUpdate({
                                ...update,
                                organiser_desc: e.target.value,
                              })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='py-6' />
            </Box>
          </div>

          <div className='col-auto'>
            <div className='py-4 px-9 text-lg font-bold text-gray-600'>
              Preview
            </div>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                minWidth: '400px',
                backgroundColor: 'white',
                margin: 4,
              }}
            >
              <div className='py-6 px-6'>
                <div className='py-4 font-bold'>
                  Title: {formInput?.event_title}
                </div>

                <Box
                  className='rounded-md'
                  sx={{
                    width: '345px',
                    height: 'auto',
                    backgroundColor: 'white',
                  }}
                >
                  <div className='py-2 px-1 font-bold text-cyan-700'>
                    Event Description:
                  </div>
                  <div className='justify-center py-2 px-1 text-sm font-bold text-cyan-600'>
                    {formInput?.event_desc &&
                      formInput?.event_desc
                        .split('\n')
                        .map((paragraph, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <p key={index} style={{ textAlign: 'justify' }}>
                            {paragraph}{' '}
                          </p>
                        ))}
                  </div>
                </Box>
                <br />
                <Box
                  className='rounded-md'
                  sx={{
                    width: '345px',
                    height: 'auto',
                    backgroundColor: '#F3F3F3',
                  }}
                >
                  <div className='px-4 py-4 font-bold text-cyan-700'>
                    Meet the organiser: <br />
                  </div>
                  <div className='px-4 py-4 text-sm font-bold text-cyan-600'>
                    {formInput?.organiser_desc &&
                      formInput?.organiser_desc
                        .split('\n')
                        .map((paragraph, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <p key={index} style={{ textAlign: 'justify' }}>
                            {paragraph}{' '}
                          </p>
                        ))}
                  </div>
                </Box>
                <Box className='flex justify-between py-6'>
                  <Box sx={{ width: '400px' }} className='flex justify-between'>
                    <Button
                      sx={{
                        textTransform: 'none',
                        height: '50px',
                        width: '345px',
                      }}
                      className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                      variant='outlined'
                      disabled={!isUploadedFilesDialogMounted || isLoading}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={putNewLearning}
                    >
                      Update
                    </Button>
                    <br />
                    {/* <Button
                      sx={{
                        textTransform: 'none',
                        margin: 1,
                        height: '50px',
                        width: '155px',
                      }}
                      className='rounded-md border border-cyan-600 bg-white px-6 font-bold text-cyan-600'
                      variant='outlined'
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        navigate('/duplicate-event/:eventId');
                      }}
                    >
                      Duplicate
                    </Button> */}
                  </Box>
                </Box>
              </div>
            </Box>
            {/* <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                minWidth: '400px',
                backgroundColor: 'white',
                margin: 4,
              }}
            >
              <div className='py-4 px-8'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Reorder Sort
                </label>
                <div className='mt-2'>
                  <select
                    onChange={(e) =>
                      setFormInput({ ...formInput, sorts: e.target.value })
                    }
                    style={{ height: '45px' }}
                    value={formInput ? formInput?.sorts : update?.sort}
                    className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-2 py-3.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  >
                    <option value={'0'}>0</option>
                    <option value={'1'}>1</option>
                    <option value={'2'}>2</option>
                    <option value={'3'}>3</option>
                    <option value={'4'}>4</option>
                    <option value={'5'}>5</option>
                    <option value={'6'}>6</option>
                    <option value={'7'}>7</option>
                    <option value={'8'}>8</option>
                    <option value={'9'}>9</option>
                    <option value={'10'}>10</option>
                  </select>
                </div>
              </div>
              <br />
            </Box> */}
          </div>
        </div>
      </Box>

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Update Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Learning Journey has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
