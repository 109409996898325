import '@fontsource/poppins';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import React from 'react';

export default React.memo(function BusinessInformation({
  createOrg,
  setCreateOrg,
  updateOrg,
  setUpdateOrg,
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Business name*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.business_name || ''
                  : updateOrg?.business_name || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      business_name: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      business_name: e.target.value,
                    })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Type of Company*
          </label>
          <div className='mt-2'>
            <select
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      type_of_company: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      type_of_company: e.target.value,
                    })
              }
              value={
                createOrg
                  ? createOrg?.type_of_company
                  : updateOrg?.type_of_company
              }
              className='font-poppins block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            >
              <option value={'One'}>Select One</option>
              <option value={'Sole Proprietorship'}>Sole Proprietorship</option>
              <option value={'Partnership'}>Partnership</option>
              <option value={'Limited Partnership'}>Limited Partnership</option>
              <option value={'Limited Liability Partnership'}>
                Limited Liability Partnership
              </option>
              <option value={'west'}>Company</option>
            </select>
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Business Registration No*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.business_registration_no || ''
                  : updateOrg?.business_registration_no || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      business_registration_no: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      business_registration_no: e.target.value,
                    })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Date of Registration*
          </label>
          <div className='mt-2'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='px-2 text-xs'
                  reduceAnimations
                  open={open}
                  sx={{ width: 'auto' }}
                  format='YYYY-MM-DD'
                  value={
                    createOrg?.date_of_registration
                      ? dayjs(createOrg.date_of_registration, 'YYYY-MM-DD')
                      : null
                  }
                  onChange={(date) => {
                    setCreateOrg({
                      ...createOrg,
                      date: date ? date.format('YYYY-MM-DD') : '',
                    });
                    setOpen(false);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      onClick: () => setOpen(true),
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Phone Number*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              placeholder='+65'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.phone_number || ''
                  : updateOrg?.phone_number || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      phone_number: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      phone_number: e.target.value,
                    })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Postal Code*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.postal_code || ''
                  : updateOrg?.postal_code || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      postal_code: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      postal_code: e.target.value,
                    })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-full'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Email*
          </label>
          <div className='mt-2'>
            <input
              type='text'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              value={
                createOrg ? createOrg?.email || '' : updateOrg?.email || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      email: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      email: e.target.value,
                    })
              }
            />
          </div>
        </div>
        <div className='col-span-full'>
          <label className=' block text-sm font-medium leading-6 text-gray-600'>
            Business Address*
          </label>
          <div className='mt-2'>
            <textarea
              id='about'
              name='about'
              rows='3'
              className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={
                createOrg
                  ? createOrg?.business_address || ''
                  : updateOrg?.business_address || ''
              }
              onChange={(e) =>
                createOrg
                  ? setCreateOrg({
                      ...createOrg,
                      business_address: e.target.value,
                    })
                  : setUpdateOrg({
                      ...updateOrg,
                      business_address: e.target.value,
                    })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
});
