import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import React, { useState } from 'react';

const options = [
  { value: 'General Practice', label: 'General Practice' },
  { value: 'Health Screening', label: 'Health Screening' },
  { value: 'Aesthetic', label: 'Aesthetic' },
  { value: 'A&E', label: 'A&E' },
  { value: 'Operating Theatre', label: 'Operating Theatre' },
  { value: 'Critical Care', label: 'Critical Care' },
  { value: 'High Dependency Ward', label: 'High Dependency Ward' },
  { value: 'General Ward', label: 'General Ward' },
  { value: 'Clinic', label: 'Clinic' },
  { value: 'Home Care', label: 'Home Care' },
  { value: 'School/Corporate', label: 'School/Corporate' },
  { value: 'Specialist Clinic', label: 'Specialist Clinic' },
];

export default function CreateClinicForm({
  groupData,
  createClinic,
  setCreateClinic,
  updateClinic,
  setUpdateClinic,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const imgUrl = URL.createObjectURL(file);
    setSelectedFile(file);
    setPreviewUrl(imgUrl);
    setCreateClinic((prevcreateClinic) => ({
      ...prevcreateClinic,
      logo: file,
    }));

    // console.log('CA:', file, imgUrl);
  };
  const handleTypeChange = (event) => {
    const selectedValues = event.target.value || [];
    if (createClinic) {
      setCreateClinic({ ...createClinic, type: selectedValues });
    } else {
      setUpdateClinic({ ...updateClinic, type: selectedValues });
    }
  };

  return (
    <div>
      {/* <h3 className='px-6'>Create new clinic for {groupData?.business_name}</h3> */}
      <div className='grid grid-cols-2'>
        <div className='col-end-2'>
          <div className='px-10 py-4'>
            <label className='font-poppins text-md block py-4 font-bold leading-6 text-gray-600'>
              Clinic Logo*
            </label>
            <Box
              sx={{
                width: '150px',
                height: '150px',
                overflow: 'hidden',
              }}
              className='flex justify-center rounded-xl bg-gray-300'
            >
              <input
                type='file'
                accept='image/jpeg,image/png,image/webp,image/svg+xml'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id='upload-file-input'
              />

              {selectedFile || previewUrl || createClinic?.logo ? (
                <img
                  src={previewUrl || createClinic?.logo}
                  alt=''
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              ) : null}
            </Box>
            <div className='py-5'>
              <label htmlFor='upload-file-input'>
                <Button
                  sx={{ textTransform: 'none' }}
                  variant='contained'
                  component='span'
                  className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
                >
                  Upload Image
                </Button>
              </label>
            </div>
            <div className='sm:col-span-6'>
              <div className='text-xs  text-gray-300'>*size 150 x 150</div>
            </div>
          </div>
          <div className='mt-3 grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-10 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Clinic Name*
              </label>
              <div className='font-poppins mt-2 font-medium'>
                <input
                  placeholder='Enter Clinic Name'
                  id='title'
                  type='text'
                  className='font-poppins block  w-full rounded-md border-0 bg-white px-2 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.clinicName || ''
                      : updateClinic?.clinicName || ''
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          clinicName: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          clinicName: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>
          <div className='mt-3 grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-10 sm:grid-cols-6'>
            <div className='py-2 sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Initial
              </label>
              <div className='mt-2 font-medium'>
                <input
                  id='initials'
                  placeholder='Enter Initials'
                  type='text'
                  className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-white px-2 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.initials || ''
                      : updateClinic?.initials || ''
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          initials: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          initials: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>
          <div className='mt-3  grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-10 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Email*
              </label>
              <div className='mt-2 font-medium'>
                <input
                  id='email'
                  placeholder='clinic@gmail.com'
                  type='email'
                  className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-white px-2 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.corporateEmail || ''
                      : updateClinic?.corporateEmail || ''
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          corporateEmail: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          corporateEmail: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>
          <div className='mt-3 grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-10 sm:grid-cols-6'>
            <div className='py-2 sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Telephone*
              </label>
              <div className='mt-2 font-medium'>
                <input
                  id='telp'
                  placeholder='+65'
                  type='text'
                  className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-white px-2 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.companyTel 
                      // || groupData?.phone_number
                      : updateClinic?.companyTel 
                      // || groupData?.phone_number
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          companyTel: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          companyTel: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-auto'>
          <div className='mt-8 grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Clinic Address*
              </label>
              <div className='mt-2 font-medium'>
                <input
                  id='address'
                  placeholder='Enter Clinic Address'
                  type='text'
                  className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-white px-2 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.clinicAddress
                      : updateClinic?.clinicAddress
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          clinicAddress: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          clinicAddress: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>

          <div className='mt-2  grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 py-2 px-5 sm:grid-cols-6'>
            <div className='py-1 sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Country*
              </label>
              <div className='mt-2 font-medium'>
                <input
                  id='country'
                  placeholder='Enter Country'
                  type='text'
                  className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-white px-2 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.country || 'Singapore'
                      : updateClinic?.country || 'Singapore'
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          country: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          country: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>

          <div className='mt-2 grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Location*
              </label>

              <div className='mt-2'>
                <FormControl>
                  <Select
                    style={{ height: '45px', width: '370px', fontSize: 14 }}
                    onChange={(e) => {
                      if (e?.target?.value) {
                        if (createClinic) {
                          setCreateClinic({
                            ...createClinic,
                            location: e.target.value,
                          });
                          return;
                        }

                        setUpdateClinic({
                          ...updateClinic,
                          location: e.target.value,
                        });
                      }
                    }}
                    value={
                      createClinic
                        ? createClinic?.location
                        : updateClinic?.location
                    }
                    className='rounded-md text-gray-600'
                  >
                    <MenuItem disabled value=''>
                      <em style={{ color: '#8C8C8C' }}>Select</em>
                    </MenuItem>
                    <MenuItem value='central'>Central</MenuItem>
                    <MenuItem value='east'>East</MenuItem>
                    <MenuItem value='north'>North</MenuItem>
                    <MenuItem value='north-east'>North East</MenuItem>
                    <MenuItem value='west'>West</MenuItem>
                    <MenuItem value='south'>South</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='mt-3 grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
            <div className='py-3 sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                CMS
              </label>
              <div className='mt-2'>
                <FormControl>
                  <Select
                    id='cms'
                    className='rounded-md text-gray-600'
                    style={{ height: '45px', width: '370px', fontSize: 14 }}
                    onChange={(e) => {
                      if (e?.target?.value) {
                        if (createClinic) {
                          setCreateClinic({
                            ...createClinic,
                            cms: e.target.value,
                          });
                          return;
                        }

                        setUpdateClinic({
                          ...updateClinic,
                          cms: e.target.value,
                        });
                      }
                    }}
                    value={createClinic ? createClinic?.cms : updateClinic?.cms}
                  >
                    <MenuItem disabled value=''>
                      <em style={{ color: '#8C8C8C' }}>Select</em>
                    </MenuItem>
                    <MenuItem value='Clinic Assist'>Clinic Assist</MenuItem>
                    <MenuItem value='Plato'>Plato</MenuItem>
                    <MenuItem value='GP connect'> GP connect</MenuItem>
                    <MenuItem value='SGiMED Hummingbird E-Clinic'>
                      SGiMED Hummingbird E-Clinic
                    </MenuItem>
                    <MenuItem value='LinkDoctors'>LinkDoctors</MenuItem>
                    <MenuItem value='Medical Suite'> Medical Suite</MenuItem>
                    <MenuItem value='Galen Helath'>Galen Helath</MenuItem>
                    <MenuItem value='HeartVoice'>HeartVoice</MenuItem>
                    <MenuItem value='OtterSG'>OtterSG</MenuItem>
                    <MenuItem value='Julius'>Julius</MenuItem>
                    <MenuItem value='UNO CMS'>UNO CMS</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className='grid w-3/4 grid-cols-1 gap-x-6 gap-y-6 py-3 px-5 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Clinic Type*
              </label>
              <div className='mt-2'>
                <FormControl fullWidth>
                  <Select
                    className='rounded-md text-gray-600'
                    labelId='clinic-type-label'
                    id='clinic-type'
                    sx={{ height: '48px', width: '370px', fontSize: 13 }}
                    value={
                      createClinic
                        ? createClinic.type || []
                        : updateClinic
                        ? updateClinic.type || []
                        : []
                    }
                    onChange={handleTypeChange}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className='mt-1  grid w-3/4 grid-cols-1 gap-x-6 gap-y-6  px-5 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Description*
              </label>
              <div className='mt-2 py-1 font-medium'>
                <textarea
                  placeholder='Enter Clinic Description'
                  rows='6'
                  className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  value={
                    createClinic
                      ? createClinic?.description 
                      : updateClinic?.description 
                  }
                  onChange={(e) =>
                    createClinic
                      ? setCreateClinic({
                          ...createClinic,
                          description: e.target.value,
                        })
                      : setUpdateClinic({
                          ...updateClinic,
                          description: e.target.value,
                        })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>
  );
}
