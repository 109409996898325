// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import ButtonAction from '@src/components/buttons/action.button';
import Container from '@src/components/commons/containerPage.common';
import LabTabs from '@src/components/commons/tabs.common';

import { useLocation } from 'react-router-dom';

import DuplicatePastEvent from './duplicatePastEvent';
import UpdateEventHistory from './updateEventHistory';

export default function ViewDetailPastEvent() {
  const path = useLocation().pathname;

  const menuEvent = [
    {
      idx: 0,
      title: 'History',
      component: (
        <div>
          <DuplicatePastEvent />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Add Event Information',
      component: (
        <div>
          <UpdateEventHistory />
        </div>
      ),
    },
  ];

  return (
    <div className='py-8 px-2'>
      <LabTabs menus={menuEvent} />
    </div>
  );
}
