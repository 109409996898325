/* eslint-disable react/no-unknown-property */

/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import React from 'react';

import OrganisationOverview from '../clinicBranches/clinic.page';

function createData(date, desc, number, invoice, status, viewInvoice) {
  return { date, desc, number, invoice, status, viewInvoice };
}

const rows = [
  createData(
    '11 Oct 2022',
    'Tier 1, Subcription',
    3,
    '$140',
    'Unpaid',
    'View Invoice',
  ),
];

const preventDefault = (event) => event.preventDefault();

export default function TableOrgFinance() {
  return (
    <div>
      <span className=' flex flex-col items-start pr-20'>
        <TableContainer
          component={Paper}
          className='rounded-xl'
          style={{
            width: '960px',
            paddingBottom: '12px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          <Table sx={{ minWidth: 350 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ width: '200px' }}
                >
                  Date
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '200px' }}
                >
                  Description
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '200px' }}
                >
                  Number of Clinic Licences
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 0, width: '150px' }}
                >
                  Invoice Total
                </TableCell>
                <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{ padding: 4, width: '200px' }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.date}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                      padding: 2,
                    },
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    className=' text-sm font-bold text-gray-500'
                  >
                    <Link
                      to={<OrganisationOverview />}
                      color='inherit'
                      underline='none'
                    >
                      {row.date}
                    </Link>
                  </TableCell>
                  <TableCell
                    className=' text-sm font-bold text-gray-500'
                    style={{ padding: 0, margin: 2 }}
                  >
                    {row.desc}
                  </TableCell>
                  <TableCell
                    className='text-sm font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    {row.number}
                  </TableCell>
                  <TableCell
                    className=' text-sm font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    {row.invoice}
                  </TableCell>
                  <TableCell style={{ padding: 1 }}>
                    <div className=' py-1 text-sm font-bold text-red-500'>
                      {row.status}
                    </div>
                    <div className='py-1 text-sm text-cyan-500'>
                      {' '}
                      <Link
                        href={<OrganisationOverview />}
                        color='inherit'
                        underline='none'
                      >
                        {' '}
                        {row.viewInvoice}
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </span>
      <style jsx>{`
        @media (max-width: 768px) {
          .flex {
            display: block;
          }
          .pr-20 {
            padding-right: 0;
          }
        }
        @media (min-width: 769px) {
          .flex {
            display: flex;
          }
          .pr-20 {
            padding-right: 20px;
          }
        }
      `}</style>
    </div>
  );
}
