/* eslint-disable no-param-reassign */

/* eslint-disable import/no-extraneous-dependencies */
import {
  generateCertificate,
  getCertificateTemplates,
} from '@api/GenerateCertificate';
import { useAuth } from '@contexts/authContext';
import '@fontsource/poppins';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Container from '@src/components/commons/containerPage.common';
import Toast, { ERROR, SUCCESS } from '@src/components/toast';
import { capitalizeWords, mapToCertificateTemplateData, noop } from '@utils';
import { Form, Formik } from 'formik';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TemplateSelection from './templateSelection';

const initialValues = {
  recipient: '',
  date: '',
  // other details will follow template
};

const TextField = styled(MuiTextField)({
  '& label.Mui-focused': {
    color: '#9350E5',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#A855F7',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A855F7',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#A855F7',
    },
  },
});

const TextFieldDate = function TextFieldDate(props) {
  return (
    <TextField
      label='Date'
      name='date'
      fullwidth
      required
      InputLabelProps={{ shrink: true }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default function CreataCertificatePage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [openToast, setOpenToast] = React.useState(false);
  const [toastData, setToastData] = React.useState({
    severity: ERROR,
    text: 'Error',
  });
  const isTemplateSelected = React.useMemo(
    () => Boolean(selectedTemplate?.template?.type),
    [selectedTemplate],
  );
  const templateType = React.useMemo(
    () => ({
      type: capitalizeWords(selectedTemplate?.template?.type || 'Unknown'),
      topic: capitalizeWords(selectedTemplate?.template?.topic || 'Unknown'),
    }),
    [selectedTemplate],
  );

  const handleSelectTemplate = async (template) => {
    setSelectedTemplate(template);
  };

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      (async () => {
        const tempRes = await getCertificateTemplates(auth.idToken);

        if (tempRes && tempRes.data && Array.isArray(tempRes.data)) {
          const temps = tempRes.data.map(({ type, data }) => ({
            type: capitalizeWords(type),
            data: Array.isArray(data)
              ? data.map(mapToCertificateTemplateData)
              : [],
          }));

          setTemplates(temps);
        }
      })();
    }
  }, [auth]);

  const handleToast = {
    open: async () => {
      setOpenToast(true);
    },
    close: async () => {
      setOpenToast(false);
    },
    popUp: async (
      text,
      severity = SUCCESS,
      callback = noop,
      timeout = 1000,
    ) => {
      setToastData({
        severity,
        text,
      });
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
        callback();
      }, timeout);
    },
  };

  const handleBack = () => {
    navigate('/event-certificate');
  };

  const handleSubmitForm = async (values, { setSubmitting }) => {
    if (
      auth.isAuthenticated &&
      auth.idToken &&
      selectedTemplate?.template?.id
    ) {
      const formData = {
        ...values,
        templateId: selectedTemplate.template.id,
        date: values.date.format('YYYY-MM-DD'),
      };

      try {
        const response = await generateCertificate(auth.idToken, formData);

        if (response.status !== 201) {
          await handleToast.popUp('Error', ERROR);

          console.error(
            response.data?.message
              ? response.data.message
              : response.data
              ? JSON.stringify(response.data)
              : JSON.stringify(response),
          );

          setSubmitting(false);
          return;
        }

        await handleToast.popUp('Success', SUCCESS, handleBack);
      } catch (error) {
        console.error('Error:', error);
        await handleToast.popUp(error.message);
      }

      setSubmitting(false);

      return;
    }

    console.error('Error submitting', selectedTemplate?.template?.type);

    setSubmitting(false);
  };
  return (
    <Container pathUrl={path}>
      <Toast
        open={openToast}
        onClose={handleToast.close}
        onOpen={handleToast.open}
        severity={toastData.severity}
        text={toastData.text}
      />
      <Box sx={{ width: '100%', fontFamily: 'Poppins', paddingBottom: '2rem' }}>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <Box sx={{ width: '66.666%' }}>
          <Box sx={{ marginBottom: '32px' }}>
            <Typography variant='h5' gutterBottom>
              Create New Certificate
            </Typography>
          </Box>

          <Box className='pl-2'>
            <TemplateSelection
              templates={templates}
              handleSelectTemplate={handleSelectTemplate}
            />

            <Box sx={{ ml: 1 }}>
              <Box
                sx={{
                  color: isTemplateSelected ? 'rgba(0, 0, 0, 0.8)' : 'gray',
                  fontSize: '1.3rem',
                  ml: -1,
                  mt: 4,
                  mb: 3,
                  fontStyle: isTemplateSelected ? 'initial' : 'italic',
                }}
              >
                {isTemplateSelected ? (
                  `${templateType.type} - ${templateType.topic}`
                ) : (
                  <span>No template selected</span>
                )}
              </Box>

              {isTemplateSelected ? (
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={handleSubmitForm}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  {({
                    values,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label='Recipient'
                            name='recipient'
                            value={values.recipient}
                            onChange={handleChange}
                            fullwidth
                            required
                            disabled={isSubmitting}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label='Date'
                              name='date'
                              orientation='landscape'
                              reduceAnimations
                              value={values.date}
                              disabled={isSubmitting}
                              onChange={(value) => {
                                setFieldValue('date', value);
                              }}
                              slots={{
                                textField: TextFieldDate,
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        {/* other details will follow template */}
                      </Grid>
                      <br />
                      <br />
                      <LoadingButton
                        type='submit'
                        size='small'
                        variant='contained'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        // loadingPosition='start'
                        // startIcon={<Box>Loading...</Box>}
                        className='cursor-pointer rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-2 px-4 font-semibold shadow hover:shadow-cyan-500'
                        sx={{
                          paddingTop: '8px',
                          textTransform: 'none',
                        }}
                      >
                        <span>{isSubmitting ? 'Loading...' : 'Submit'}</span>
                      </LoadingButton>
                    </Form>
                  )}
                </Formik>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
