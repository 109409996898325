/* eslint-disable no-case-declarations */
import { dropItemFrom } from '@src/utils';

import { createContext, memo, useContext, useReducer } from 'react';

import {
  CLEAR_LOCAL_DATA,
  CLEAR_PREVIOUS_DATA,
  DELETE_FORM_DATA,
  FORM_DATA_CONTEXT_DATA,
  LOAD_LOCAL_DATA,
  PREPARE_DIRS,
  REMOVE_ITEM_FROM_FORM_DATA,
  SET_FORM_DATA,
} from './contextConsts';

const FormDataContext = createContext();

const formDataInitialState = {
  info: "Context to save form data, doesn't support file data",
  data: new Map(),
  withLocal: false,
};

const formDataReducer = (state, action) => {
  const newFormData = new Map(Array.from(state.data));
  const dataDirectoryMap = new Map();

  switch (action.type) {
    case PREPARE_DIRS:
      if (state.data.size === 0) {
        dataDirectoryMap.set(action.payload.key, action.payload.data);
        const initState = {
          ...state,
          withLocal: action.payload.withLocal,
          data: Array.from(dataDirectoryMap),
        };
        return { ...initState, data: dataDirectoryMap };
      }
      return state;
    case LOAD_LOCAL_DATA:
      const localData = JSON.parse(
        localStorage.getItem(FORM_DATA_CONTEXT_DATA),
      );

      if (localData === null || localData.data.length === 0) {
        dataDirectoryMap.set(action.payload.key, action.payload.data);
        const initState = {
          ...state,
          withLocal: action.payload.withLocal,
          data: Array.from(dataDirectoryMap),
        };

        localStorage.setItem(
          FORM_DATA_CONTEXT_DATA,
          JSON.stringify({
            ...initState,
          }),
        );

        return { ...initState, data: dataDirectoryMap };
      }

      const localFormData = new Map(localData.data);
      return { ...state, data: localFormData };
    case CLEAR_LOCAL_DATA:
      localStorage.removeItem(FORM_DATA_CONTEXT_DATA);
      return { ...state, data: new Map() };
    case SET_FORM_DATA:
      if (!action.payload.key || action.payload.key === '') {
        throw new Error(
          'FormDataContext: Must provide a key to store form data.',
        );
      }

      const prevData = action.payload[CLEAR_PREVIOUS_DATA]
        ? {}
        : newFormData.get(action.payload.key);
      // 'Auth FormData': {..prevData, {email: 'auth@email.ex'}}
      newFormData.set(action.payload.key, {
        ...prevData,
        ...action.payload.data,
      });

      if (action.payload.withLocal) {
        localStorage.setItem(
          FORM_DATA_CONTEXT_DATA,
          JSON.stringify({
            ...state,
            withLocal: action.payload.withLocal,
            data: Array.from(newFormData),
          }),
        );
      }

      return {
        ...state,
        withLocal: action.payload.withLocal,
        data: newFormData,
      };
    case DELETE_FORM_DATA:
      if (!action.payload.key || action.payload.key === '') {
        console.log(`FormDataContext: Key ${action.payload.key} not found.`);
      }

      newFormData.delete(action.payload.key);
      if (action.payload.withLocal) {
        localStorage.setItem(
          FORM_DATA_CONTEXT_DATA,
          JSON.stringify({
            ...state,
            withLocal: action.payload.withLocal,
            data: Array.from(newFormData),
          }),
        );
      }

      return {
        ...state,
        withLocal: action.payload.withLocal,
        data: newFormData,
      };
    case REMOVE_ITEM_FROM_FORM_DATA:
      if (typeof action.payload.itemKey === 'undefined') {
        throw new Error(
          'FormDataContext: Must provide an item key to remove item from form data.',
        );
      }

      const formData = newFormData.get(action.payload.key);
      const newData = dropItemFrom(formData)(action.payload.itemKey);
      newFormData.set(action.payload.key, newData);

      if (action.payload.withLocal) {
        localStorage.setItem(
          FORM_DATA_CONTEXT_DATA,
          JSON.stringify({
            ...state,
            withLocal: action.payload.withLocal,
            data: Array.from(newFormData),
          }),
        );
      }

      return {
        ...state,
        withLocal: action.payload.withLocal,
        data: newFormData,
      };
    default:
      console.log(
        `FormDataContext: Unknown action type. Possible types: ${SET_FORM_DATA}, ${DELETE_FORM_DATA}.`,
      );
      return state;
  }
};

class FormDataItemHelper {
  #key;

  #dispatch;

  #ready = false;

  #withLocal = false;

  #localLoaded = false;

  #dataDirectory = {};

  constructor(dataKey, dispatcher, dataDirectory, withLocal = false) {
    this.#key = dataKey;
    this.#dispatch = dispatcher;
    this.#ready = true;
    this.#dataDirectory = dataDirectory;
    this.#withLocal = withLocal;
    this.#prepareDirs();
  }

  #checkIsReady() {
    if (!this.#ready) {
      throw new Error(
        'FormDataContext: Must instantiate helper with key and dispatcher.',
      );
    }
  }

  #prepareDirs() {
    this.#checkIsReady();
    this.#dispatch({
      type: PREPARE_DIRS,
      payload: {
        key: this.#key,
        data: this.#dataDirectory,
      },
    });
  }

  loadLocalData(dataDirectory) {
    if (this.#withLocal && !this.#localLoaded) {
      this.#dispatch({
        type: LOAD_LOCAL_DATA,
        payload:
          {
            key: this.#key,
            data: dataDirectory || this.#dataDirectory,
            withLocal: true,
          } || {},
      });
      this.#localLoaded = true;
    }
  }

  setKey(key) {
    this.#checkIsReady();
    this.#key = key;
  }

  getKey() {
    this.#checkIsReady();
    return this.#key;
  }

  set(data, clearPrevData = false) {
    if (typeof clearPrevData !== 'boolean') {
      throw new Error('FormDataContext: clearPrevData param must be a boolean');
    }
    const isObject = data && !Array.isArray(data) && typeof data === 'object';
    this.#checkIsReady();
    if (isObject) {
      this.#dispatch({
        type: SET_FORM_DATA,
        payload: {
          key: this.#key,
          data,
          [CLEAR_PREVIOUS_DATA]: clearPrevData ? true : undefined,
          withLocal: this.#withLocal,
        },
      });
      return;
    }

    throw new Error('FormDataContext: data param must be an object');
  }

  delete(key) {
    this.#checkIsReady();
    this.#dispatch({
      type: REMOVE_ITEM_FROM_FORM_DATA,
      payload: {
        key: key || this.#key,
        itemKey: key,
        withLocal: this.#withLocal,
      },
    });
  }

  drop(key) {
    this.#checkIsReady();
    this.#dispatch({
      type: DELETE_FORM_DATA,
      payload: {
        key: key || this.#key,
        withLocal: this.#withLocal,
      },
    });
  }
}

const FormDataContextProvider = memo(({ children }) => {
  const [formData, dispatch] = useReducer(
    formDataReducer,
    formDataInitialState,
  );

  const Helper = FormDataItemHelper;

  return (
    <FormDataContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formData,
        dispatch,
        Helper,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
});

export const useFormData = () => {
  const context = useContext(FormDataContext);

  if (!context) {
    throw new Error(
      'useFormData must be used within a FormDataContextProvider.',
    );
  }

  return context;
};

export default FormDataContextProvider;
