import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}`;

export default function UpdatePastSlot() {
  const { clinicId } = useParams();
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const { jobsId } = useParams();
  const [jobs, setJobs] = useState({
    price: '',
    scope: '',
    job_description: '',
    date: '',
    work_time_start: '',
    work_time_finish: '',
    break: [
      {
        start: '',
        finish: '',
      },
    ],
  });

  const [updateSlot, setUpdateSlot] = useState({
    breaks: [{ start: '', finish: '' }],
    price: '',
    scope: '',
    job_description: '',
    work_time_start: '',
    work_time_finish: '',
  });

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/jobs/${jobsId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setJobs(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [auth, jobsId]);

  React.useEffect(() => {
    if (jobs?.date && jobs?.work_time_start && jobs?.work_time_finish) {
      const parsedDate = dayjs(jobs.date, 'DD MMMM YYYY');

      const formattedWorkTimeStart = parsedDate
        .set('hour', dayjs(jobs.work_time_start, 'h:mm A').hour())
        .set('minute', dayjs(jobs.work_time_start, 'h:mm A').minute())
        .format('YYYY-MM-DDTHH:mm');

      const formattedWorkTimeFinish = parsedDate
        .set('hour', dayjs(jobs.work_time_finish, 'h:mm A').hour())
        .set('minute', dayjs(jobs.work_time_finish, 'h:mm A').minute())
        .format('YYYY-MM-DDTHH:mm');

      setUpdateSlot({
        breaks: jobs.break || [{ start: '', finish: '' }],
        price: jobs.price || '',
        scope: jobs.scope || '',
        job_description: jobs.job_description || '',
        date: jobs?.date || '',
        work_time_start: formattedWorkTimeStart || '',
        work_time_finish: formattedWorkTimeFinish || '',
      });
    }
  }, [jobs]);

  function updatePastSlot() {
    const formData = {
      price: updateSlot.price || '',
      scope: updateSlot.scope || '',
      job_description: updateSlot.job_description || '',
      work_time_start: updateSlot.work_time_start || '',
      work_time_finish: updateSlot.work_time_finish || '',
      break: updateSlot?.breaks.map((b) => ({
        break_start: b.break_start,
        break_finish: b.break_finish,
      })),
    };

    axios
      .put(`${url}/admin/jobs/update-completed-job/${jobsId}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Slot updated successfully:', response.data);
        setUpdateSlot({ ...updateSlot });
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 2000,
          position: 'top-center',
        });
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate(state?.prevLocation || -1);
  };

  const handleStartDateChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setUpdateSlot({
        ...updateSlot,
        work_time_start: newDate.format('YYYY-MM-DDTHH:mm'),
      });
    } else {
      setUpdateSlot({
        ...updateSlot,
        work_time_start: '',
      });
    }
  };

  const handleStartTimeChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setUpdateSlot({
        ...updateSlot,
        work_time_start: newDate.format('YYYY-MM-DDTHH:mm'),
      });
    } else {
      setUpdateSlot({
        ...updateSlot,
        work_time_start: '',
      });
    }
  };

  const handleEndTimeChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setUpdateSlot({
        ...updateSlot,
        work_time_finish: newDate.format('YYYY-MM-DDTHH:mm'),
      });
    } else {
      setUpdateSlot({
        ...updateSlot,
        work_time_finish: '',
      });
    }
  };

  const handleStartBreakChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setUpdateSlot((prevSlot) => ({
        ...prevSlot,
        breaks: prevSlot.breaks.map((b, idx) =>
          idx === 0
            ? {
                ...b,
                break_start: newDate.format('YYYY-MM-DDTHH:mm'),
              }
            : b,
        ),
      }));
    }
  };

  const handleEndBreakChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      setUpdateSlot((prevSlot) => ({
        ...prevSlot,
        breaks: prevSlot.breaks.map((b, idx) =>
          idx === 0
            ? {
                ...b,
                break_finish: newDate.format('YYYY-MM-DDTHH:mm'),
              }
            : b,
        ),
      }));
    }
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    setUpdateSlot((prevState) => ({
      ...prevState,
      price: value,
    }));
  };

  const handleScopeChange = (e) => {
    const { value } = e.target;
    setUpdateSlot((prevState) => ({
      ...prevState,
      scope: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setUpdateSlot((prevState) => ({
      ...prevState,
      job_description: e.target.value,
    }));
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-2/3 py-8'>
        <div className='flex justify-center'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '561px',
              backgroundColor: 'white',
            }}
          >
            <div className='font-poppins py-8 px-4 text-lg font-bold'>
              Slot Information
            </div>
            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Working Date
                </label>
                <div className='mt-4 flex justify-between font-medium'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '520px' }}
                      className='bg-white'
                      label='Date Start-End'
                      format='DD MMM YYYY'
                      value={jobs?.date ? dayjs(jobs?.date) : null}
                      onChange={handleStartDateChange}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Working Hours
                </label>
                <div className='mt-4 flex justify-between font-medium'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      sx={{ width: '250px' }}
                      className='bg-white'
                      label='Time Start'
                      format='hh:mm A'
                      value={
                        jobs?.work_time_start
                          ? dayjs(jobs?.work_time_start, 'HH:mm')
                          : null
                      }
                      onChange={handleStartTimeChange}
                    />

                    <DateTimePicker
                      sx={{ width: '250px' }}
                      className='bg-white'
                      placeholder='Time Start'
                      format='hh:mm A'
                      label='Time End'
                      value={
                        jobs?.work_time_finish
                          ? dayjs(jobs?.work_time_finish, 'HH:mm')
                          : null
                      }
                      onChange={handleEndTimeChange}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Break Time (optional)
                </label>
                {jobs?.break.map((b, idx) => (
                  <div className='mt-4 flex justify-between font-medium'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        sx={{ width: '250px' }}
                        className='bg-white'
                        label='Time Start'
                        format='hh:mm A'
                        value={
                          jobs?.break[0]?.start
                            ? dayjs(jobs?.break[0]?.start, 'HH:mm')
                            : null
                        }
                        onChange={(newDate) =>
                          handleStartBreakChange(newDate, idx)
                        }
                      />

                      <DateTimePicker
                        sx={{ width: '250px' }}
                        className='bg-white'
                        placeholder='Time Start'
                        format='hh:mm A'
                        label='Time End'
                        value={
                          jobs?.break[0]?.finish
                            ? dayjs(jobs?.break[0]?.finish, 'HH:mm')
                            : null
                        }
                        onChange={(newDate) =>
                          handleEndBreakChange(newDate, idx)
                        }
                      />
                    </LocalizationProvider>
                  </div>
                ))}
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Hourly rate
                </label>
                <div className='mt-2 flex font-medium'>
                  <input
                    id='price'
                    placeholder='Enter price (only number)'
                    style={{ width: '225px' }}
                    className='font-poppins focus:ring-600 text-md block w-full rounded-md border-0 px-3 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                    focus:ring-1 focus:ring-inset sm:leading-6'
                    value={updateSlot?.price || jobs?.price || ''}
                    onChange={handlePriceChange}
                  />
                  <div className='py-3 px-6'>SGD</div>
                </div>
              </div>
            </div>

            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Scope
                </label>
                <div className='mt-2'>
                  <FormControl>
                    <Select
                      id='scope'
                      className='rounded-md text-gray-600'
                      style={{ height: '53px', width: '520px', fontSize: 14 }}
                      value={updateSlot?.scope || jobs?.scope || []}
                      onChange={handleScopeChange}
                      multiple
                      renderValue={(selected) =>
                        Array.isArray(selected) ? selected.join(', ') : ''
                      }
                    >
                      <MenuItem disabled value=''>
                        <em style={{ color: '#8C8C8C' }}>Select</em>
                      </MenuItem>
                      <MenuItem value='GP'>GP</MenuItem>
                      <MenuItem value='Aesthetics'>Aesthetics</MenuItem>
                      <MenuItem value='CHAS'>CHAS</MenuItem>
                      <MenuItem value='PHPC'>PHPC</MenuItem>
                      <MenuItem value='Covid Vaccines'>Covid Vaccines</MenuItem>
                      <MenuItem value='DWD'>DWD</MenuItem>
                      <MenuItem value='2nd Doctor'>2nd Doctor</MenuItem>
                      <MenuItem value='Low Load'>Low Load</MenuItem>
                      <MenuItem value='Moderate Load'>Moderate Load</MenuItem>
                      <MenuItem value='Heavy Load'>Heavy Load</MenuItem>
                      <MenuItem value='Specialist Clinic'>
                        Specialist Clinic
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Job Description
                </label>
                <div className='mt-2 font-medium'>
                  <textarea
                    id='desc'
                    rows='3'
                    placeholder='Enter description'
                    style={{ width: '497px' }}
                    className='font-poppins focus:ring- -600 block w-full rounded-md border-0 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
                    focus:ring-inset sm:text-sm sm:leading-6'
                    value={
                      updateSlot?.job_description || jobs?.job_description || ''
                    }
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
            </div>
            <div className='flex justify-center px-6 py-8'>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '200px',
                  height: '48px',
                }}
                className='text-md rounded-md bg-cyan-600 font-bold text-white'
                variant='contained'
                onClick={() => updatePastSlot()}
              >
                Update
              </Button>
            </div>
          </Box>
        </div>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The past slot has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
