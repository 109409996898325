/* eslint-disable react/destructuring-assignment */

/* eslint-disable react/jsx-props-no-spreading */
import '@fontsource/poppins';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@src/components/commons/containerPage.common';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function QuestionnarePage() {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/event-management');
  };
  return (
    <Container pathUrl={path}>
      <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <div className='grid grid-cols-2'>
          <div className='col-end-1'>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '569px',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='py-2 sm:col-span-6'>
                  <div className='mt-2'>
                    <select className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-2 py-3.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'>
                      <option value={'Handed'}>Open Handed Question</option>
                      <option value={'MCQ'}>MCQ Question</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
                <div className='py-2 sm:col-span-6'>
                  <label className='font-poppins text-md block font-bold leading-6 text-gray-600'>
                    1. Question No. 1
                  </label>
                  <div className='mt-2'>
                    <input
                      id='org_name'
                      placeholder='Type in the right answer here'
                      type='text'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                  <div className='mt-2'>
                    <input
                      id='org_name'
                      placeholder='Type in the right answer here'
                      type='text'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                  <div className='mt-2'>
                    <input
                      id='org_name'
                      placeholder='Type in the right answer here'
                      type='text'
                      className='font-poppins block w-full rounded-md border-0 bg-gray-100 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                    />
                  </div>{' '}
                </div>
              </div>
            </Box>
          </div>

          <div className='col-auto'>
            <Box
              className='rounded-xl'
              sx={{
                height: 'auto',
                width: '389px',
                backgroundColor: 'white',
                margin: 2,
              }}
            >
              <div className='py-6 px-6'>
                <span className='flex justify-between py-6'>
                  <div className='justify-right flex'>
                    <Button
                      sx={{
                        textTransform: 'none',
                        margin: 1,
                        height: '50px',
                        width: '150px',
                      }}
                      className='rounded-md border border-cyan-600 px-6 font-bold text-cyan-600'
                      variant='outlined'
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        navigate('/event-management');
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <Box sx={{ width: '400px' }} className='flex justify-end'>
                    <Button
                      sx={{
                        textTransform: 'none',
                        margin: 1,
                        height: '50px',
                        width: '150px',
                      }}
                      className='rounded-md bg-cyan-600 px-6 font-bold text-white'
                      variant='outlined'
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        navigate('/event-management');
                      }}
                    >
                      Upload
                    </Button>
                  </Box>
                </span>
              </div>
            </Box>
          </div>
        </div>
      </Box>
    </Container>
  );
}
