import DrawerCustom from '@src/components/commons/drawerProfile.common';
import Layout from '@src/components/commons/layout.common';

export default function Dashboard() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      <Layout />
      <DrawerCustom />
    </>
  );
}
