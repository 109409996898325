import api from '@api';

export const saveTemplate = async (token, formData, axiosInstance = api) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      timeout: 60000,
    };
    const response = await axiosInstance.post(
      '/admin/generate-certificate/template',
      formData,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const getCertificateTemplates = async (token, axiosInstance = api) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.get(
      '/admin/generate-certificate/template',
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const getCertificateTemplateById = async (
  token,
  id,
  axiosInstance = api,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.get(
      `/admin/generate-certificate/template/${id}`,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const putTemplateById = async (
  token,
  id,
  formData,
  axiosInstance = api,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      timeout: 60000,
    };
    const response = await axiosInstance.put(
      `/admin/generate-certificate/template/${id}`,
      formData,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const deleteCertificateTemplateById = async (
  token,
  id,
  axiosInstance = api,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(
      `/admin/generate-certificate/template/${id}`,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const generateCertificate = async (
  token,
  formData,
  axiosInstance = api,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'content-type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      timeout: 60000,
    };
    const response = await axiosInstance.post(
      '/admin/generate-certificate',
      formData,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const getGeneratedCertificates = async (token, axiosInstance = api) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.get(
      '/admin/generate-certificate',
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const getGeneratedCertificateById = async (
  token,
  id,
  axiosInstance = api,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.get(
      `/admin/generate-certificate/${id}`,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};

export const deleteGeneratedCertificateById = async (
  token,
  id,
  axiosInstance = api,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.delete(
      `/admin/generate-certificate/${id}`,
      config,
    );

    return response;
  } catch (error) {
    if (error.name === 'AxiosError') {
      return {
        status: error.response?.status || error.code,
        data: error.response?.data || { message: error.message },
      };
    }

    console.error(error);
    return error;
  }
};
