import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateClinicForm from '@src/components/forms/createClinic.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;

export default function CreateAccountClinic() {
  const { orgId } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleBack = () => {
    navigate(`/verified-organisation/${orgId}`);
  };

  const [groupData, setGroupData] = useState({});
  const [createClinic, setCreateClinic] = useState({
    group: '',
    clinicName: '',
    description: '',
    type: [],
    logo: '',
    location: '',
    country: '',
    corporateEmail: '',
    companyTel: '',
    clinicAddress: '',
    cms: '',
    initials: '',
  });

  React.useEffect(() => {
    if (auth.idToken && typeof orgId === 'string' && orgId.length === 24) {
      axios
        .get(`${url}/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          console.log('CA:', response);
          setGroupData(response.data);
        })
        .catch((error) => {
          console.error('CA: Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const createClinics = () => {
    const formData = {
      group: groupData._id,
      clinicName: createClinic.clinicName,
      description: createClinic.description,
      type: createClinic.type,
      logo: createClinic.logo,
      location: createClinic.location,
      country: createClinic.country,
      corporateEmail: createClinic.corporateEmail,
      companyTel: createClinic.companyTel || groupData?.phone_number,
      clinicAddress: createClinic.clinicAddress,
      cms: createClinic.cms,
      initials: createClinic.initials,
    };

    axios
      .post(`${url}/${orgId}/clinics`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account created successfully:', response.data);
        setCreateClinic({ ...createClinic });
        handleOpenModal();
        toast.success('Clinic created successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message.message}. Please use another clinic name`,
          {
            position: 'top-center',
            autoClose: 4000,
          },
        );
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
    // navigate(`/verification-organisation/${orgId}`);
    navigate('/clinic-branch');
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+65/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleSaveButtonClick = () => {
    if (!createClinic?.logo) {
      toast.error('Logo is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createClinic?.clinicName) {
      toast.error('Clinic Name is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createClinic?.corporateEmail) {
      toast.error('Email is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createClinic?.companyTel) {
      toast.error('Phone Number is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    // if (
    //   !groupData?.phone_number ||
    //    !isValidPhoneNumber(createClinic?.companyTel) ||
    //   !isValidPhoneNumber(groupData?.phone_number)
    // ) {
    //   toast.error('+65 Phone Number format is required.', {
    //     position: 'top-center',
    //     autoClose: 2000,
    //   });
    //   return;
    // }
    if (!createClinic?.clinicAddress) {
      toast.error('Address is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    // if (!createClinic?.country) {
    //   toast.error('Country is required.', {
    //     position: 'top-center',
    //     autoClose: 2000,
    //   });
    //   return;
    // }
    if (!createClinic?.location) {
      toast.error('Location is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    if (!createClinic?.type) {
      toast.error('Clinic Type is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }

    if (!createClinic?.description) {
      toast.error('Description is required.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    setTimeout(() => {
      createClinics();
      // handleOpenModal();
      // toast.success('Clinic has been listed');
    }, 1000);
  };

  return (
    <Box sx={{ width: '100%', margin: 2, fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-8 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='w-2/3 px-8'>
        <Box
          className='flex justify-end'
          sx={{
            height: '90px',
            width: '100%',
          }}
        >
          <div className='flex justify-end px-4 py-6'>
            <Button
              sx={{
                textTransform: 'none',
                width: '200px',
                height: '48px',
              }}
              className='text-md rounded-xl bg-cyan-600 font-bold text-white'
              variant='contained'
              onClick={handleSaveButtonClick}
            >
              Save
            </Button>
          </div>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            background: 'white',
            fontFamily: 'Poppins',
          }}
          className='rounded-xl py-4'
        >
          <CreateClinicForm
            groupData={groupData}
            createClinic={createClinic}
            setCreateClinic={setCreateClinic}
          />
        </Box>
        <br />
      </div>

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The clinic has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
