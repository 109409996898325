const sfe = (value) => {
  if (typeof value === 'string') {
    return value;
  }

  return '';
};

export const mapNewEventFormInputData = ({ ...formInput }) => {
  const data = {
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    live_stream: formInput.live_stream,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    event_offline: Boolean(formInput.event_offline || formInput.offline),
    street: formInput.street,
    country: formInput.country,
    postal_code: formInput.postal_code,
    city: formInput.city,
    unit_number: formInput.unit_number,
    event_date: formInput.date || formInput.event_date,
    event_from: formInput.from || formInput.event_from,
    event_to: formInput.to || formInput.event_to,
    latitude: formInput.latitude,
    longitude: formInput.longitude,
    is_hybrid: Boolean(formInput.is_hybrid || formInput.hybrid),
    is_cme: Boolean(formInput.is_cme || formInput.points),
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    event_image: sfe(formInput.event_image || formInput.image),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
  };

  return data;
};

export const mapNewLearningJourneyFormInputData = ({ ...formInput }) => {
  const data = {
    _id: formInput._id,
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    source_link: formInput.source_link,
    past_link: formInput.past_link,
    past_desc: formInput.past_desc,
    links: formInput.links,
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    event_image: sfe(formInput.event_image || formInput.image),
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
    source_slide: sfe(formInput.slide || formInput.source_slide),
  };

  return data;
};

export const mapUpdateNewEventInformationFormInputData = ({ ...formInput }) => {
  const data = {
    past_link: formInput.past_link,
    past_desc: formInput.past_desc,
    source_link: formInput.source_link,
    link_question: formInput.link_question,
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    links: formInput.links,
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    event_image: sfe(formInput.event_image || formInput.image),
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    source_slide: sfe(formInput.slide || formInput.source_slide),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
    sorts: formInput.sorts,
  };

  return data;
};

export const mapUpdateLearningJourneyFormInputData = ({ ...formInput }) => {
  const data = {
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    live_stream: formInput.live_stream,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    event_offline: Boolean(formInput.event_offline || formInput.offline),
    street: formInput.street,
    country: formInput.country,
    postal_code: formInput.postal_code,
    city: formInput.city,
    unit_number: formInput.unit_number,
    event_date: formInput.date,
    event_from: formInput.from,
    event_to: formInput.to,
    latitude: formInput.latitude,
    longitude: formInput.longitude,
    is_hybrid: Boolean(formInput.is_hybrid || formInput.hybrid),
    is_cme: Boolean(formInput.is_cme || formInput.points),
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    event_image: sfe(formInput.event_image || formInput.image),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
    sorts: formInput.sorts,
  };

  return data;
};

export const mapUpdateListedEventFormInputData = ({ ...formInput }) => {
  const data = {
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    live_stream: formInput.live_stream,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    event_offline: Boolean(formInput.event_offline || formInput.offline),
    street: formInput.street,
    country: formInput.country,
    postal_code: formInput.postal_code,
    city: formInput.city,
    unit_number: formInput.unit_number,
    event_date: formInput.date,
    event_from: formInput.from,
    event_to: formInput.to,
    latitude: formInput.latitude,
    longitude: formInput.longitude,
    is_hybrid: Boolean(formInput.is_hybrid || formInput.hybrid),
    is_cme: Boolean(formInput.is_cme || formInput.points),
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    event_image: sfe(formInput.event_image || formInput.image),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
    sorts: formInput.sorts,
  };

  return data;
};

export const mapUpdateUnlistedEventFormInputData = ({ ...formInput }) => {
  const data = {
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    live_stream: formInput.live_stream,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    event_offline: Boolean(formInput.event_offline || formInput.offline),
    street: formInput.street,
    country: formInput.country,
    postal_code: formInput.postal_code,
    city: formInput.city,
    unit_number: formInput.unit_number,
    event_date: formInput.date,
    event_from: formInput.from,
    event_to: formInput.to,
    latitude: formInput.latitude,
    longitude: formInput.longitude,
    is_hybrid: Boolean(formInput.is_hybrid || formInput.hybrid),
    is_cme: Boolean(formInput.is_cme || formInput.points),
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    event_image: sfe(formInput.event_image || formInput.image),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
  };

  return data;
};

export const mapUpdateLearningJourneyFormInputData2 = ({ ...formInput }) => {
  const data = {
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    source_link: formInput.source_link,
    // event_date: formInput.date,
    // event_from: formInput.from,
    // event_to: formInput.to,
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    event_image: sfe(formInput.event_image || formInput.image),
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    learning_file: sfe(formInput.learn_video || formInput.learning_file),
    source_slide: sfe(formInput.slide || formInput.source_slide),
    sorts: formInput.sorts,
    links: formInput.links,
  };

  return data;
};

export const mapDuplicatePastEvent = ({ ...formInput }) => {
  const data = {
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    event_desc: formInput.event_desc,
    live_stream: formInput.live_stream,
    roles: formInput.roles,
    event_tags: formInput.event_tags,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    event_offline: formInput.event_offline,
    street: formInput.street,
    country: formInput.country,
    postal_code: formInput.postal_code,
    city: formInput.city,
    unit_number: formInput.unit_number,
    event_date: formInput.date,
    event_from: formInput.from,
    event_to: formInput.to,
    latitude: formInput.latitude,
    longitude: formInput.longitude,
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    organiser_image: sfe(formInput.org_image || formInput.organiser_image),
    event_image: sfe(formInput.event_image || formInput.image),
    learning_file: sfe(formInput.video),
  };

  return data;
};

export const mapUpdateEventHistoryFormInputData = ({ ...formInput }) => {
  const data = {
    past_link: formInput.past_link,
    past_desc: formInput.past_desc,
    source_link: formInput.source_link,
    link_question: formInput.link_question,
    event_title: formInput.event_title,
    event_link: formInput.event_link,
    live_stream: formInput.live_stream,
    roles: formInput.roles,
    organiser_name: formInput.organiser_name,
    organiser_desc: formInput.organiser_desc,
    event_offline: formInput.event_offline,
    street: formInput.street,
    country: formInput.country,
    postal_code: formInput.post,
    city: formInput.city,
    unit_number: formInput.unit_number,
    event_date: formInput.event_date,
    event_from: formInput.event_from,
    event_to: formInput.event_to,
    // files? -> tus
    // will use posted data if exist and ensure string or
    // if an update, value will be handled on onTaskComplete
    event_image: sfe(formInput.event_image || formInput.image),
    source_slide: sfe(formInput.source_slide),
    learning_file: sfe(formInput.learning_file),
    sorts: formInput.sorts,
    links: formInput.links,
  };

  return data;
};

const mapEventManagement = {
  mapNewEventFormInputData,
  mapNewLearningJourneyFormInputData,
  mapUpdateNewEventInformationFormInputData,
  mapUpdateLearningJourneyFormInputData,
  mapUpdateListedEventFormInputData,
  mapUpdateUnlistedEventFormInputData,
  mapUpdateLearningJourneyFormInputData2,
  mapUpdateEventHistoryFormInputData,
  mapDuplicatePastEvent,
};

export default mapEventManagement;
