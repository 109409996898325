import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { openLinkInPopUpWindow } from '@utils';
import dayjs from 'dayjs';

import React, { useState } from 'react';

export default function PractisingInformationDoctor({
  setGetDocument,
  handlePutDocument,
  handlePutInfo,
  getInfo,
  setGetInfo,
  getMandatory,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const files = event.target.files[0];
    setSelectedFile(files);
    setPreviewUrl(URL.createObjectURL(files));
    setGetDocument((prevFormInput) => ({
      ...prevFormInput,
      practicing_certificate: files,
    }));
  };

  const handleOpenSlide = (file) => async () => openLinkInPopUpWindow(file);

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);

  const fileExtension = (previewUrl || getMandatory?.file)?.split('.').pop();

  const [isExpired, setIsExpired] = useState(false);

  React.useEffect(() => {
    const selectedDate = dayjs(getInfo['Valid Until']);
    const currentDate = dayjs();

    setIsExpired(selectedDate.isBefore(currentDate));
  }, [getInfo['Valid Until']]);

  return (
    <>
      <div className='grid grid-cols-2 py-2'>
        <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
          <div className='sm:col-span-3'>
            <label className='block text-sm font-medium leading-6 text-gray-600'>
              Registration No*
            </label>
            <div className='mt-2 px-1'>
              <input
                type='text'
                className='font-poppins block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                value={getInfo?.['Registration Number']}
                onChange={(e) =>
                  setGetInfo({
                    ...getInfo,
                    registration_number: e.target.value,
                    'Registration Number': e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className='sm:col-span-3'>
            <label className=' block text-sm font-medium leading-6 text-gray-600'>
              Select*
            </label>
            <div className='py-1.5'>
              <select
                className='font-poppins block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                label='Select'
                onChange={(e) =>
                  setGetInfo({
                    ...getInfo,
                    type_registration: e.target.value,
                    'Type Registration': e.target.value,
                  })
                }
                value={
                  getInfo?.['Type Registration'] || getInfo?.type_registration
                }
              >
                <option value={''}>Select one</option>
                <option value={'Full Registration'}>Full Registration</option>
                <option value={'Conditional Registration'}>
                  Conditional Registration
                </option>
                <option value={'Provisional Registration'}>
                  Provisional Registration
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className='col-end-1'>
          <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-6 sm:grid-cols-6'>
            <div className='sm:col-span-3'>
              <label className=' block text-sm font-medium leading-6 text-gray-600'>
                Valid from*
              </label>
              <div className='mt-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                    <DatePicker
                      className='px-2 text-xs'
                      reduceAnimations
                      open={open}
                      sx={{ width: '200px' }}
                      format='YYYY-MM-DD'
                      value={
                        getInfo?.['Valid From']
                          ? dayjs(getInfo?.['Valid From'])
                          : null
                      }
                      onChange={(from) => {
                        setGetInfo({
                          ...getInfo,
                          from: from ? from.format('YYYY-MM-DD') : '',
                        });
                        setOpen(false);
                      }}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          onClick: () => setOpen(true),
                          InputProps: {
                            sx: {
                              fontSize: '14px',
                              '& > input': {
                                padding: '4px 2px',
                              },
                              '& > fieldset': {
                                border: 'none',
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label className=' block text-sm font-medium leading-6 text-gray-600'>
                Valid to*
              </label>
              <div className='mt-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                    <DatePicker
                      className='px-2 text-xs'
                      reduceAnimations
                      open={opens}
                      sx={{ width: '200px' }}
                      format='YYYY-MM-DD'
                      value={
                        getInfo?.['Valid Until']
                          ? dayjs(getInfo?.['Valid Until'])
                          : null
                      }
                      onChange={(to) => {
                        setGetInfo({
                          ...getInfo,
                          to: to ? to.format('YYYY-MM-DD') : '',
                        });
                        setOpens(false);
                      }}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          onClick: () => setOpens(true),
                          InputProps: {
                            sx: {
                              fontSize: '14px',
                              '& > input': {
                                padding: '4px 2px',
                              },
                              '& > fieldset': {
                                border: 'none',
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
                {isExpired && (
                  <p className='py-1 px-2 text-xs text-red-500'>
                    Warning: The cert is expired, please update!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-auto'>
        <div className='px-6'>
          <div className='py-2'>
            <Box
              sx={{
                height: '150px',
                width: '250px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Box
                className='flex justify-center rounded-xl bg-gray-300'
                sx={{
                  height: '110px',
                  width: '220px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <input
                  type='file'
                  accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id='upload-cert-input'
                />

                {selectedFile || previewUrl || getMandatory?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    {fileExtension === 'pdf' ? (
                      <embed
                        src={previewUrl || getMandatory?.file}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                      />
                    ) : (
                      <img
                        src={previewUrl || getMandatory?.file}
                        alt='no cert added'
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }}
                      />
                    )}
                  </div>
                ) : null}
                <label htmlFor='upload-cert-input'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                </label>
              </Box>
            </Box>
          </div>

          <div className='flex justify-end px-4 py-2'>
            <Button
              variant='contained'
              className='bg-white text-xs font-bold text-cyan-600'
              sx={{ textTransform: 'none', padding: 0, width: '80px' }}
              onClick={handleOpenSlide(getMandatory?.file || '')}
            >
              Check
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant='contained'
              sx={{ textTransform: 'none' }}
              component='span'
              className='bg-cyan-600 from-cyan-500 to-cyan-600 text-xs font-bold'
              onClick={() => {
                handlePutDocument();
                handlePutInfo();
              }}
            >
              Update
            </Button>
          </div>
          <br />
        </div>
      </div>
    </>
  );
}
