// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ButtonAction from '@src/components/buttons/action.button';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import { openLinkInPopUpWindow } from '@utils';
import axios from 'axios';
import images from 'src/assets/images/rectangle.png';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}/admin/resource`;

export default function TableResources() {
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const [resource, setResource] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenSlide = (res_link) => async () =>
    openLinkInPopUpWindow(res_link);

  const handleUpdateResource = (resId) => {
    navigate(`/update-resource/${resId}`);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${urls}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setResource(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error', error.message);
        setLoading(false);
      });
  }, [auth, deleteStatus]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/resource/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'My-Custom-Header': 'foobar',
          },
        },
      );

      if (response.status === 200) {
        console.log(response);
        setDeleteStatus('Delete successful');
        setShowDeleteSuccess(true); // Show the delete success pop-up
      } else {
        console.error('Delete operation failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const extractHostName = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (error) {
      console.error('url');
      return 'unknown link';
    }
  };

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatTypes(types) {
    if (!types) return 'No role';

    return types
      .split(/[._]/)
      .map((word) => capitalize(word))
      .join('-');
  }
  return (
    <Container pathUrl={path}>
      <div className='px-4'>
        <Box
          sx={{ width: '100%', height: '60px' }}
          className='flex w-3/4 justify-end'
        >
          <ButtonAction
            onNavigate={() => {
              navigate('/create-resource');
            }}
            label='Add New Resource'
          />
        </Box>
        {loading ? (
          <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
        ) : (
          <span className='flex w-3/4 flex-col items-start pr-20'>
            <TableContainer
              className='rounded-xl'
              component={Paper}
              style={{
                width: '100%',
                paddingBottom: '12px',
                paddingRight: '12px',
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
            >
              <Table
                sx={{
                  minWidth: 350,
                  backgroundColor: '#ffffff',
                  borderColor: 'ffffff',
                }}
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ width: '70px', border: 'gray', paddingLeft: 20 }}
                    >
                      Resource Image
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '250px', border: 'gray' }}
                    >
                      Resource Title
                    </TableCell>
                    {/* <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ padding: 4, width: '300px', border: 'gray' }}
              >
                Description
              </TableCell> */}
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '200px', border: 'gray' }}
                    >
                      Link
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '150px', border: 'gray' }}
                    >
                      Featured
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '100px', border: 'gray' }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '200px', border: 'gray' }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(resource) && resource.length > 0 ? (
                    resource
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => (
                        <TableRow
                          key={row?._id}
                          hover
                          tabIndex={-1}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            style={{ margin: 2, paddingLeft: 20 }}
                          >
                            <img
                              src={row?.res_image ? row?.res_image : images}
                              alt='resource'
                              width='55px'
                              height='55px'
                            />
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.res_title || ''}
                          </TableCell>
                          {/* <TableCell
                      className='text-sm font-bold text-gray-500'
                      style={{ padding: 4 }}
                    >
                      {row?.res_desc ? row?.res_desc : 'No desc'}
                    </TableCell> */}
                          <TableCell
                            className='text-sm font-bold text-gray-500'
                            style={{ padding: 4 }}
                          >
                            {row.res_link ? (
                              <Button
                                variant='text'
                                className='justify-left flex text-sm font-bold text-cyan-600 underline'
                                sx={{ textTransform: 'none', padding: 0 }}
                                onClick={handleOpenSlide(row.res_link || '')}
                              >
                                {extractHostName(row.res_link)}
                              </Button>
                            ) : (
                              'No source added'
                            )}
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {/* {row?.res_title || ''} */}
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {formatTypes(row?.res_type) || 'No role'}
                          </TableCell>
                          <TableCell style={{ padding: 4, margin: 2 }}>
                            <div className='flex justify-start '>
                              <Button
                                variant='contained'
                                className='text-xs font-bold text-purple-600'
                                sx={{
                                  textTransform: 'none',
                                  backgroundColor: '#FAF9FC',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() => handleUpdateResource(row?._id)}
                              >
                                Edit
                              </Button>
                              &nbsp; &nbsp;
                              <Button
                                variant='contained'
                                className='text-xs font-bold text-red-500'
                                sx={{
                                  textTransform: 'none',
                                  backgroundColor: '#FAF9FC',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() =>
                                  showDeleteConfirmationDialog(row?._id)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                            {showDeleteConfirmation && (
                              <Dialog
                                open={showDeleteConfirmation}
                                onClose={handleConfirmationDialogClose}
                              >
                                <DialogTitle>Confirm Delete</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Are you sure you want to delete this item?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleConfirmationDialogClose}
                                    color='primary'
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={handleConfirmationDialogConfirm}
                                    color='error'
                                  >
                                    Delete
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        className='border border-white'
                        colSpan={6}
                        align='center'
                      >
                        <div className='font-poppins py-6 text-gray-500'>
                          No resource listed
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box
                className='flex justify-end py-3'
                sx={{ width: '100%', fontFamily: 'Poppins' }}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component='div'
                  count={resource.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </TableContainer>
          </span>
        )}
      </div>
      <br />
      <br />
      <br />

      {showDeleteSuccess && (
        <Dialog
          open={showDeleteSuccess}
          onClose={() => setShowDeleteSuccess(false)}
        >
          <DialogTitle>Delete Successful</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The resource has been deleted successfully.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteSuccess(false)} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
