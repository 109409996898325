import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AddProfessionalCert({
  openModal,
  setOpenModal,
  fetchData,
}) {
  // const [openModal, setOpenModal] = useState(false);
  // const showModal = () => {
  //   setOpenModal(true);
  // };
  const { auth } = useAuth();
  const { userId } = useParams();
  const [getProfCert, setGetProfCert] = useState([]);

  const [getItem, setGetItem] = useState({});
  const [role, setRole] = useState('doctor');

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/certificate-items?role=${role}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetItem(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const handleAProfCert = () => {
    const formData = {
      certificate: getProfCert.certificate,
      file: getProfCert.file,
    };

    axios
      .post(`${url}/admin/certificates/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Cert updated successfully:', response);
        setGetProfCert({ ...getProfCert });
        fetchData();
        toast.success('Cert updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error('Select type is required', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFile = (e) => {
    const files = e.target.files[0];
    if (files) {
      setSelectedFile(files.name);
      setGetProfCert((prevInput) => ({
        ...prevInput,
        file: files,
      }));
    }
  };
  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new professional cert</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <select
            id='type'
            value={getProfCert?.certificate}
            onChange={(e) =>
              setGetProfCert({
                ...getProfCert,
                certificate: e.target.value,
              })
            }
            className='font-poppins block w-full rounded-md border-0 bg-gray-100 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            style={{ height: '38px', width: '370px' }}
          >
            <option value=''>Select one</option>
            <option value={getItem[0]?._id}>{getItem[0]?.item}</option>
            <option value={getItem[1]?._id}>{getItem[1]?.item}</option>
            <option value={getItem[2]?._id}>{getItem[2]?.item}</option>
            <option value={getItem[3]?._id}>{getItem[3]?.item}</option>
            <option value={getItem[4]?._id}>{getItem[4]?.item}</option>
            <option value={getItem[5]?._id}>{getItem[5]?.item}</option>
            <option value={getItem[6]?._id}>{getItem[6]?.item}</option>
          </select>
          <input
            type='file'
            id='profCert'
            accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
            onChange={handleFile}
            className='text-md w-1/3'
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => handleAProfCert()}
          color='primary'
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
