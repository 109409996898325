// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/interview`;

export default function InterviewPage() {
  const path = useLocation().pathname;
  const { auth } = useAuth();
  const navigate = useNavigate();
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };

  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      });

      if (response.status === 200) {
        console.log(response);
        setDeleteStatus('Delete successful');
        setShowDeleteSuccess(true);
        toast.success('Interview deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [searchName, setSearchName] = useState('');
  const [searchClinic, setSearchClinic] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchStatus, setSearchStatus] = useState('');

  const clearSearch = () => {
    setSearchName('');
    setSearchClinic('');
    setSearchId('');
    setSearchStatus('');
  };

  const handleName = (event) => {
    setSearchName(event.target.value);
  };

  const handleClinic = (event) => {
    setSearchClinic(event.target.value);
  };
  const handleId = (event) => {
    setSearchId(event.target.value);
  };

  const handleStatus = (event) => {
    setSearchStatus(event.target.value);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [loading, setLoading] = useState();
  // const [openModal, setOpenModal] = useState();
  // const showModal = (itemId) => {
  //   setOpenModal(itemId);
  // };

  const showModal = (itemId) => {
    navigate(`/edit-request-interview/${itemId}`);
  };

  const [interview, setInterview] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedList, setSortedList] = useState([]);

  const handleSortToggle = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const sortedData = [...sortedList].sort((a, b) => {
      const dateA = new Date(a.interview?.datetime).getTime();
      const dateB = new Date(b.interview?.datetime).getTime();

      return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setSortedList(sortedData);
  };

  const fetchData = () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      applicant: searchName,
      clinic_name: searchClinic,
      job_code: searchId,
      status: searchStatus,
      sort_interview_datetime: 'asc',
    }).toString();

    axios
      .get(`${url}?${queryParams}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        // setInterview(response.data.data);
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        const sortedData = [...response.data.data].sort((a, b) => {
          const dateA = new Date(a.interview?.datetime).getTime();
          const dateB = new Date(b.interview?.datetime).getTime();

          return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setSortedList(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [searchName, searchClinic, searchId, searchStatus, deleteStatus]);

  return (
    <>
      <Container pathUrl={path}>
        <div className='flex justify-start px-6'>
          <Box
            sx={{ width: '100%', height: '140px' }}
            className='w-2/3 rounded-xl bg-white px-12'
          >
            <div className='flex'>
              <div>
                <div className='font-poppins py-3 text-sm text-gray-400'>
                  Applicant Name
                </div>
                <input
                  id='name'
                  value={searchName}
                  onChange={handleName}
                  className='font-poppins  block  rounded-md border-0 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  style={{ height: '10px', width: '190px' }}
                />
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div>
                <div className='font-poppins py-3 text-sm text-gray-400'>
                  Clinic Name
                </div>
                <input
                  id='clinic'
                  value={searchClinic}
                  onChange={handleClinic}
                  className='font-poppins  block  rounded-md border-0 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  style={{ height: '10px', width: '190px' }}
                />
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div>
                <div className='font-poppins py-3 text-sm text-gray-400'>
                  Job ID
                </div>
                <input
                  id='id'
                  value={searchId}
                  onChange={handleId}
                  className='font-poppins  block  rounded-md border-0 py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  style={{ height: '10px', width: '190px' }}
                />
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div>
                <div className='font-poppins py-3 text-sm text-gray-400'>
                  Status
                </div>
                <select
                  id='status'
                  value={searchStatus}
                  onChange={handleStatus}
                  className='font-poppins block w-full rounded-md border-0 px-1 py-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  style={{ height: '34px', width: '200px' }}
                >
                  <option value=''>ALL</option>
                  <option value='draft'>Draft</option>
                  <option value='pending'>Pending</option>
                  <option value='accepted'>Accepted</option>
                  <option value='rejected'>Rejected</option>
                  <option value='canceled'>Canceled</option>
                  <option value='scheduled'>Scheduled</option>
                  <option value='passed'>Passed</option>
                  <option value='deleted'>Deleted</option>
                </select>
              </div>
            </div>

            <div className='flex justify-end px-10 py-4'>
              <Button
                variant='contained'
                className='rounded-xl bg-red-600 text-sm font-bold'
                sx={{
                  width: '80px',
                  height: '30px',
                  textTransform: 'none',
                  backgroundColor: '#FAF9FC',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </Box>
        </div>
        <Box sx={{ width: '100%' }} className='px-6'>
          <div className='w-3/4 py-5'>
            {loading ? (
              <div className='font-poppins py-6 px-8 text-gray-500'>
                Loading...
              </div>
            ) : (
              <span className='flex flex-col items-start pr-20'>
                <TableContainer
                  className='rounded-xl'
                  component={Paper}
                  style={{
                    width: '100%',
                    paddingBottom: '12px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                  }}
                >
                  <Table sx={{ minWidth: 350 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className='text-xs font-bold text-gray-400'
                          style={{ width: '120px' }}
                        >
                          Applicant Name
                        </TableCell>

                        <TableCell
                          className='text-xs font-bold text-gray-400'
                          style={{ padding: 0, width: '170px' }}
                        >
                          Clinic Name
                        </TableCell>
                        <TableCell
                          className='text-xs font-bold text-gray-400'
                          style={{ padding: 0, width: '110px' }}
                        >
                          Job ID
                        </TableCell>

                        <TableCell
                          className='text-xs font-bold text-gray-400'
                          style={{ padding: 0, width: '100px' }}
                        >
                          <TableSortLabel
                            active
                            direction={sortOrder}
                            onClick={handleSortToggle}
                            className='text-xs font-bold text-gray-400'
                            sx={{ width: '50px' }}
                          >
                            Interview Date
                          </TableSortLabel>
                        </TableCell>

                        <TableCell
                          className='text-xs font-bold text-gray-400'
                          style={{ padding: 4, width: '80px' }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className='text-xs font-bold text-gray-400'
                          style={{ padding: 4, width: '120px' }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(sortedList) && sortedList.length > 0 ? (
                        sortedList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                          .map((row) => (
                            <TableRow
                              hover
                              role='checkbox'
                              tabIndex={-1}
                              key={row?._id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component='th'
                                scope='row'
                                className='border-white text-sm font-bold text-gray-500'
                              >
                                {row.interview?.user?.full_name}
                              </TableCell>

                              <TableCell
                                className='border-white text-sm font-bold text-gray-500'
                                style={{ padding: 0 }}
                              >
                                {row.interview?.job?.clinic?.clinicName}
                              </TableCell>
                              <TableCell
                                className='border-white text-sm font-bold text-gray-500'
                                style={{ padding: 0, margin: 2 }}
                              >
                                {row.interview?.job?.code}
                              </TableCell>

                              <TableCell
                                className='border-white text-sm font-bold text-gray-500'
                                style={{ padding: 0 }}
                              >
                                {row?.interview.datetime_parts?.date}{' '}
                                {row?.interview.datetime_parts?.month_short}{' '}
                                {row?.interview.datetime_parts?.year}
                              </TableCell>
                              <TableCell
                                className='border-white text-sm font-bold text-gray-500'
                                style={{ padding: 0 }}
                              >
                                {row.interview.status}
                              </TableCell>
                              <TableCell
                                className='border-white text-sm font-bold text-gray-500'
                                style={{ padding: 0 }}
                              >
                                <Button
                                  sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#FAF9FC',
                                    '&:hover': {
                                      backgroundColor: 'white',
                                    },
                                  }}
                                  className='rounded-md text-xs font-bold text-cyan-600'
                                  variant='contained'
                                  onClick={() => showModal(row?.interview._id)}
                                >
                                  View
                                </Button>
                                &nbsp;
                                <Button
                                  variant='contained'
                                  className='rounded-md text-xs font-bold text-red-500'
                                  sx={{
                                    width: '70px',
                                    textTransform: 'none',
                                    backgroundColor: '#FAF9FC',
                                    '&:hover': {
                                      backgroundColor: 'white',
                                    },
                                  }}
                                  onClick={() =>
                                    showDeleteConfirmationDialog(
                                      row?.interview?._id,
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                                {showDeleteConfirmation && (
                                  <Dialog
                                    open={showDeleteConfirmation}
                                    onClose={handleConfirmationDialogClose}
                                  >
                                    <DialogTitle>Confirm Delete</DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                        Are you sure you want to delete this
                                        interview?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={handleConfirmationDialogClose}
                                        color='primary'
                                        sx={{ textTransform: 'none' }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleConfirmationDialogConfirm();
                                        }}
                                        color='error'
                                        sx={{ textTransform: 'none' }}
                                      >
                                        Delete
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell
                            className='border border-white'
                            colSpan={6}
                            align='center'
                          >
                            <div className='font-poppins py-6 text-gray-500'>
                              No data
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Box
                    className='flex justify-end py-3'
                    sx={{ width: '100%', fontFamily: 'Poppins' }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component='div'
                      count={sortedList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                </TableContainer>
              </span>
            )}
          </div>
        </Box>
      </Container>
      {/* <EditInterview
        setOpenModal={setOpenModal}
        showModal={showModal}
        openModal={openModal}
      /> */}
    </>
  );
}
