// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const urls = `${process.env.REACT_APP_API_URL}/admin/users/expired-pcs`;

export default function ExpiredPCDoctor() {
  const { auth } = useAuth();
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (userId) => {
    navigate(`/verified-doctor-detail/${userId}`);
  };

  const [expiredDate, setExpiredDate] = useState(null);

  const handleSearch = () => {
    setLoading(true);
    const currentDate = '2024-01-01';
    const requestData = new URLSearchParams({
      role: 'doctor',
      status: 'verified',
      expired_date: expiredDate || currentDate,
    }).toString();

    axios
      .get(`${urls}?${requestData}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setData(response.data.response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error', error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [expiredDate]);

  const formatToDateTime = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  const handleExpDate = (date) => {
    setExpiredDate(formatToDateTime(date));
  };

  return (
    <div>
      <div className='flex w-3/4 justify-end'>
        <Box sx={{ width: '400px' }} className='flex justify-end'>
          <div>
            <div className='px-1 text-sm text-gray-500'>Select the date:</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='px-2 text-xs'
                  reduceAnimations
                  value={expiredDate}
                  onChange={handleExpDate}
                  sx={{ width: '180px' }}
                  format='YYYY-MMM-DD'
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </Box>
      </div>

      <div className='py-5'>
        {loading ? (
          <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
        ) : (
          <span className='flex w-3/4 flex-col items-start pr-20'>
            <TableContainer
              className='rounded-xl'
              component={Paper}
              style={{
                width: '100%',
                paddingBottom: '12px',
                paddingRight: '12px',
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
            >
              <Table
                sx={{
                  minWidth: 350,
                  backgroundColor: '#ffffff',
                  borderColor: 'ffffff',
                }}
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{
                        width: '220px',
                        border: 'gray',
                        paddingLeft: 40,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '120px', border: 'gray' }}
                    >
                      ID
                    </TableCell>

                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '150px', border: 'gray' }}
                    >
                      Phone Number
                    </TableCell>

                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '150px', border: 'gray' }}
                    >
                      Expired Date
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '100px', border: 'gray' }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data.length > 0 ? (
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => (
                        <TableRow
                          key={row?._id}
                          hover
                          tabIndex={-1}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            className=' text-sm font-bold text-gray-500'
                            style={{ margin: 2, paddingLeft: 40 }}
                          >
                            {row?.full_name || ''}
                          </TableCell>
                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.role_id || ''}
                          </TableCell>

                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.phone_number || ''}
                          </TableCell>

                          <TableCell
                            className=' text-sm font-bold text-gray-500'
                            style={{ padding: 4, margin: 2 }}
                          >
                            {row?.expired_date && row.expired_date
                              ? `${new Date(
                                  row.expired_date,
                                ).toLocaleDateString('en-AU', format_date)}`
                              : 'No date'}
                          </TableCell>
                          <TableCell style={{ padding: 4, margin: 2 }}>
                            <div className='flex justify-start '>
                              <Button
                                variant='contained'
                                className='text-xs font-bold text-cyan-600'
                                sx={{
                                  textTransform: 'none',
                                  backgroundColor: '#FAF9FC',
                                  '&:hover': {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onClick={() => handleClick(row?.user_id)}
                              >
                                View
                              </Button>
                              &nbsp; &nbsp;
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        className='border border-white'
                        colSpan={6}
                        align='center'
                      >
                        <div className='font-poppins py-8 text-gray-500'>
                          No data, please select the date!
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box
                className='flex justify-end py-3'
                sx={{ width: '100%', fontFamily: 'Poppins' }}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component='div'
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </TableContainer>
          </span>
        )}
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
