/* eslint-disable no-param-reassign */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { noop, range } from '@utils';
import dayjs from 'dayjs';

import React from 'react';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  gap: 'px',
  width: 'fit-content',
  height: 'fit-content',
  flexWrap: 'wrap',
  fontFamily: 'Poppins,Nunito,Roboto,"Helvetica Neue",Arial,sans-serif',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.4375em',
  letterSpacing: '0.00938em',
  color: 'rgba(0, 0, 0, 0.87)',
  boxSizing: 'border-box',
  position: 'relative',
  cursor: 'text',
  WebkitAlignItems: 'center',
  WebkitBoxAlign: 'center',
  MsFlexAlign: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  paddingRight: '14px',
  borderColor: 'rgba(0, 0, 0, 0.63)',
  marginTop: 'auto',
  marginBottom: 'auto',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.87)',
  },
}));

const StyledSelect = styled(MuiSelect)(() => ({
  '& .MuiInputBase-input': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    // color: '#025579',
  },
  '&:before': {
    display: 'none',
  },
  '&:after': {
    display: 'none',
  },
}));

const Select = function Select(props) {
  return (
    <StyledSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            '& .MuiMenu-list': {
              height: 'fit-content',
              maxHeight: '300px',
            },
          },
        },
      }}
    />
  );
};

const CenteredTextMenuItem = function CenteredTextMenuItem(props) {
  return (
    <MenuItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      // eslint-disable-next-line react/destructuring-assignment
      sx={{ ...props.sx, justifyContent: 'center' }}
    />
  );
};

const UNITS_TO_CHECK = ['hour', 'minute'];

const areDatesSameInUnits = (date1, date2, units) => {
  for (let i = 0; i < units.length; i += 1) {
    const unit = units[i];

    if (!dayjs(date1).isSame(dayjs(date2), unit)) {
      return false;
    }
  }
  return true;
};

const isDateTimeSame = (date1, date2) => {
  let value = date1;

  if (!dayjs.isDayjs(value) && typeof value === 'string') {
    value = dayjs(value, 'HH:mm');
  }

  if (!dayjs.isDayjs(value) && typeof value !== 'string') {
    value = dayjs().startOf('day');
  }

  const isSameValue = areDatesSameInUnits(value, date2, UNITS_TO_CHECK);

  return isSameValue;
};

function DateTimePicker({
  value: _timeValue,
  onChange = noop,
  minuteSteps = 1,
}) {
  const [timeValue, setTimeValue] = React.useState(
    _timeValue || dayjs().startOf('day'),
  );
  const [timeValueSelect, setTimeValueSelect] = React.useState({
    hour: Number(timeValue.format('hh')),
    minute: timeValue.minute(),
    ampm: timeValue.format('A'),
  });
  const timeSelectList = {
    hourList: Array.from(range(1, 13, 1)).map((it) =>
      String(it).padStart(2, '0'),
    ),
    minuteList: Array.from(range(0, 60, minuteSteps)).map((it) =>
      String(it).padStart(2, '0'),
    ),
  };

  const handleTime = {};
  handleTime.change = async (value) => {
    const isSame = isDateTimeSame(value, timeValue);

    if ((isSame !== null && !isSame) || _timeValue === null) {
      setTimeValue(value);
      return onChange(value);
    }

    return false;
  };
  handleTime.select = {
    timeValue: {
      hour: async (ev) => {
        const {
          target: { value },
        } = ev;

        const newValue = value.length < 2 ? `0${value}` : value;
        const dateStr = timeValue
          .format('YYYY-MM-DDT_._:mm A')
          .replace('_._', newValue);
        const newDateTime = dayjs(dateStr, 'YYYY-MM-DDThh:mm A');

        setTimeValueSelect({
          hour: newValue,
          minute: newDateTime.minute(),
          ampm: newDateTime.format('A'),
        });

        await handleTime.change(newDateTime);
      },
      minute: async (ev) => {
        const {
          target: { value },
        } = ev;

        const newValue = Number(value);
        const newDateTime = dayjs(timeValue).minute(newValue);

        setTimeValueSelect({
          hour: Number(newDateTime.format('hh')),
          minute: newValue,
          ampm: newDateTime.format('A'),
        });

        await handleTime.change(newDateTime);
      },
      ampm: async (ev) => {
        const {
          target: { value },
        } = ev;

        const dateStr = `${timeValue.format('YYYY-MM-DDThh:mm')} ${value}`;
        const newDateTime = dayjs(dateStr, 'YYYY-MM-DDThh:mm A');

        setTimeValueSelect({
          hour: Number(newDateTime.format('hh')),
          minute: newDateTime.minute(),
          ampm: value,
        });

        await handleTime.change(newDateTime);
      },
    },
  };
  handleTime.keyPress = {
    timeValue: {
      hour: async (ev) => {
        const { key: pressedKey } = ev;

        if (/^[0-9]$/.test(pressedKey)) {
          console.log('[date-hour] Key Pressed:', pressedKey);

          const value = (() => {
            if (
              pressedKey === '0' &&
              (timeValue.hour() === 1 || timeValue.hour() === 13)
            ) {
              return '10';
            }

            if (
              pressedKey === '1' &&
              (timeValue.hour() === 1 || timeValue.hour() === 13)
            ) {
              return '11';
            }

            if (
              pressedKey === '2' &&
              (timeValue.hour() === 1 || timeValue.hour() === 13)
            ) {
              return '12';
            }

            if (pressedKey === '0') {
              return '12';
            }

            return `0${pressedKey}`;
          })();

          ev.target.value = value;

          await handleTime.select.timeValue.hour(ev);
        }
      },
      minute: async (ev) => {
        const { key: pressedKey } = ev;

        if (/^[0134]$/.test(pressedKey)) {
          console.log('[date-hour] Key Pressed:', pressedKey);

          const value = (() => {
            if (pressedKey === '1') {
              return '15';
            }

            if (pressedKey === '3') {
              return '30';
            }

            if (pressedKey === '4') {
              return '45';
            }

            return '00';
          })();

          ev.target.value = value;

          await handleTime.select.timeValue.minute(ev);
        }
      },
      ampm: async (ev) => {
        const { key: pressedKey } = ev;

        if (/^[aApP]$/.test(pressedKey)) {
          console.log('[date-hour] Key Pressed:', pressedKey);

          const value = (() => {
            if (pressedKey === 'p' || pressedKey === 'P') {
              return 'PM';
            }

            return 'AM';
          })();

          ev.target.value = value;

          await handleTime.select.timeValue.ampm(ev);
        }
      },
    },
  };

  return (
    <StyledBox>
      <FormControl size='small' variant='standard'>
        <Select
          labelId='date-time-select-hour-label'
          id='date-time-select-hour'
          displayEmpty
          value={String(timeValueSelect.hour).padStart(2, '0')}
          onChange={handleTime.select.timeValue.hour}
          onKeyPress={handleTime.keyPress.timeValue.hour}
        >
          {timeSelectList.hourList &&
            timeSelectList.hourList.map((it, idx) => (
              <CenteredTextMenuItem
                value={String(it).padStart(2, '0')}
                // eslint-disable-next-line react/no-array-index-key
                key={`date-time-hour-select-item-${idx}`}
              >
                {it}
              </CenteredTextMenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl size='small' variant='standard'>
        <Select
          labelId='date-time-select-minute-label'
          id='date-time-select-minute'
          displayEmpty
          value={String(timeValueSelect.minute).padStart(2, '0')}
          onChange={handleTime.select.timeValue.minute}
          onKeyPress={handleTime.keyPress.timeValue.minute}
        >
          {timeSelectList.minuteList &&
            timeSelectList.minuteList.map((it, idx) => (
              <CenteredTextMenuItem
                value={String(it).padStart(2, '0')}
                // eslint-disable-next-line react/no-array-index-key
                key={`date-time-select-minute-item-${idx}`}
              >
                {it}
              </CenteredTextMenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl size='small' variant='standard'>
        <Select
          labelId='date-time-select-ampm-label'
          id='date-time-select-ampm'
          displayEmpty
          value={timeValueSelect.ampm}
          onChange={handleTime.select.timeValue.ampm}
          onKeyPress={handleTime.keyPress.timeValue.ampm}
        >
          <CenteredTextMenuItem value='AM'>AM</CenteredTextMenuItem>
          <CenteredTextMenuItem value='PM'>PM</CenteredTextMenuItem>
        </Select>
      </FormControl>
    </StyledBox>
  );
}

export default DateTimePicker;
