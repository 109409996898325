/* eslint-disable react/display-name */
import { createContext, memo, useContext, useEffect, useReducer } from 'react';

import { AUTHENTICATION, LOGIN, LOGOUT } from './contextConsts';

const AuthContext = createContext();

const authInitialState = {
  isAuthenticated: null,
  isNewAccount: null,
  user: null,
  idToken: null,
  refreshToken: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem(
        AUTHENTICATION,
        JSON.stringify({ ...state, ...action.payload }),
      );
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      localStorage.removeItem(AUTHENTICATION);
      return {
        idToken: null,
        refreshToken: null,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

const AuthContextProvider = memo(({ children }) => {
  const [auth, dispatch] = useReducer(authReducer, authInitialState);

  useEffect(() => {
    const authentication = JSON.parse(localStorage.getItem(AUTHENTICATION));

    if (authentication?.isAuthenticated) {
      dispatch({
        type: LOGIN,
        payload: { ...authentication, isAuthenticated: true },
      });
    }

    if (authentication === null || !authentication?.isAuthenticated) {
      dispatch({
        type: LOGOUT,
        payload: {},
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        auth,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }

  return context;
};

export default AuthContextProvider;
