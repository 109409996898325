import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import React from 'react';

export default function DetailLicenseForm({
  org,
  list,
  detail,
  setList,
  update,
  setUpdate,
  showChangeModal,
  open,
  setOpen,
  opens,
  setOpens,
  updateLicense,
  showPackageModal,
  licensePackage,
}) {
  return (
    <div>
      <div className='grid grid-cols-1'>
        <div className='col-end-1'>
          <div className='font-poppins py-4 px-4 text-lg font-bold'>
            License Detail:
          </div>
          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Key
          </div>
          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Package
          </div>
          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Amount
          </div>
          <div className='font-poppins flex justify-start px-8 py-2 text-sm text-gray-600'>
            License Quota
          </div>

          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Type
          </div>
          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License is Active
          </div>
          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Start at
          </div>
          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Expired at
          </div>

          <div className='font-poppins flex justify-start px-8 py-2.5 text-sm text-gray-600'>
            License Notes
          </div>
        </div>
        <div className='col-auto'>
          <div className='font-poppins py-4 text-lg font-bold text-white'>
            License Detail:
          </div>
          <div className='font-poppins flex justify-start py-2.5 px-1 text-sm text-gray-600'>
            {list?.license_key || '-'}
          </div>

          <div className='font  -poppins flex justify-start py-1.5 px-1 text-sm text-gray-600'>
            <div>
              {detail?.license_package_name || 'no package added'} &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp;
              <Button
                variant='text'
                className='text-xs font-bold text-cyan-600'
                sx={{
                  textTransform: 'none',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={() => {
                  showPackageModal();
                  licensePackage();
                }}
              >
                change package
              </Button>
            </div>
          </div>
          <div className='font-poppins flex justify-start py-2.5 px-1 text-sm text-gray-600'>
            ${detail?.license_package_price || '-'} SGD
          </div>
          <div className='font-poppins flex justify-start py-2 px-1 text-sm text-gray-600'>
            {org?.branch_info?.clinic_count || '-'}/
            {detail?.license_package_configs?.clinic_quota === -1
              ? 'All'
              : detail?.license_package_configs?.clinic_quota || '-'}
          </div>
          <div className='font-poppins flex justify-start py-1 text-sm text-gray-600'>
            <select
              id='status'
              className='font-poppins block w-full rounded-md border-0 px-1 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
              style={{ height: '34px', width: '260px' }}
              value={
                list
                  ? list?.license_package_type || ''
                  : update?.license_package_type || ''
              }
              onChange={(e) =>
                list
                  ? setList({
                      ...list,
                      license_package_type: e.target.value,
                    })
                  : setUpdate({
                      ...update,
                      license_package_type: e.target.value,
                    })
              }
            >
              <option value='TRIAL'>Trial</option>
              <option value='PAID'>Paid</option>
            </select>
          </div>
          <div className='flex'>
            <div className='font-poppins flex justify-start py-2 px-2 text-sm text-gray-600'>
              {list?.license_is_active ? 'true' : 'false'}
            </div>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Button
              variant='text'
              className='text-xs font-bold text-cyan-600'
              sx={{
                textTransform: 'none',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
              onClick={() => showChangeModal()}
            >
              change status
            </Button>
          </div>
          <div className='font-poppins flex justify-start py-1 text-sm text-gray-600'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins w-3/3 block rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='px-2 text-xs'
                  reduceAnimations
                  open={open}
                  sx={{ width: '243px' }}
                  format='YYYY-MM-DD'
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      onClick: () => setOpen(true),
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                  value={
                    list?.license_start_at
                      ? dayjs(list?.license_start_at, 'YYYY-MM-DD')
                      : list?.start_at
                      ? dayjs(list?.start_at, 'YYYY-MM-DD')
                      : null
                  }
                  onChange={(from) => {
                    setList({
                      ...list,
                      license_start_at: from
                        ? from.format('YYYY-MM-DDTHH:mm')
                        : '',
                    });
                    setOpen(false);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div className='font-poppins flex justify-start py-1 text-sm text-gray-600'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='font-poppins w-3/3 block rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'>
                <DatePicker
                  className='px-2 text-xs'
                  reduceAnimations
                  open={opens}
                  sx={{ width: '243px' }}
                  format='YYYY-MM-DD'
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      onClick: () => setOpens(true),
                      InputProps: {
                        sx: {
                          fontSize: '14px',
                          '& > input': {
                            padding: '3px 2px',
                          },
                          '& > fieldset': {
                            border: 'none',
                          },
                        },
                      },
                    },
                  }}
                  value={
                    list?.license_expired_at
                      ? dayjs(list?.license_expired_at, 'YYYY-MM-DD')
                      : list?.expired_at
                      ? dayjs(list?.expired_at, 'YYYY-MM-DD')
                      : null
                  }
                  onChange={(to) => {
                    setList({
                      ...list,
                      license_expired_at: to
                        ? to.format('YYYY-MM-DDTHH:mm')
                        : '',
                    });
                    setOpens(false);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div className='py-1'>
            <textarea
              placeholder='Enter notes'
              rows='4'
              className='font-poppins block w-2/3 rounded-md border-0 px-2.5 py-2.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={list ? list?.license_notes : update?.license_notes}
              onChange={(e) => {
                setList({
                  ...list,
                  license_notes: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Box
        className='rounded-xl'
        sx={{
          height: '510px',
          width: '515px',
        }}
      >
        <div className='flex justify-end px-16 py-4'>
          <Button
            sx={{
              textTransform: 'none',
              width: '130px',
              height: '38px',
            }}
            className='text-md rounded-xl bg-cyan-600 font-bold text-white'
            variant='contained'
            onClick={() => {
              updateLicense();
            }}
          >
            Update
          </Button>
        </div>
      </Box>
    </div>
  );
}
