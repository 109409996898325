import axios from 'axios';

import { toast } from 'react-toastify';

import { AUTHENTICATION } from '../contexts/contextConsts';

export const baseUrl = String(process.env.REACT_APP_API_URL);

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const api = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    Accept: '*/*',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS',
  },
  credentials: 'include',
});

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    if (error && error.message === 'Request failed with status code 401') {
      localStorage.removeItem(AUTHENTICATION);
      toast.error('You have been logged out due to inactivity.', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      window.location.replace(process.env.REACT_APP_HOME_URL);
    }
    return Promise.reject(error);
  },
);

export default api;
