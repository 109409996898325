import { mapObjectValsTo } from '@utils/mapper';
import { dequal as isEqual } from 'dequal';

import React from 'react';
import { useSearchParams as useSearchParamsRouter } from 'react-router-dom';

export const useSelectiveState = (initialState, options = {}) => {
  const [state, setState] = React.useState(initialState);
  /* eslint-disable no-param-reassign */
  if (typeof options !== 'object') options = {};
  if (options.default === undefined) options.default = false;
  if (options.resetToDefault === undefined) options.resetToDefault = false;
  /* eslint-enable no-param-reassign */

  const setCustomState = (fieldToUpdate) => {
    setState((prevState) => ({
      ...(() => {
        return options.resetToDefault
          ? mapObjectValsTo(prevState, options.default)
          : prevState;
      })(),
      ...fieldToUpdate,
    }));
  };

  return [state, setCustomState];
};

/*
  To manually cancel interval just call hook with delay=null, eg. useInterval(callback, null)
  https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setInterval(tick, typeof delay === 'function' ? delay() : delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsRouter({});
  const [params, setParams] = React.useState(Object.fromEntries(searchParams));

  React.useEffect(() => {
    const newParams = Object.fromEntries(searchParams);
    if (!isEqual(params, newParams)) {
      setParams(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return [params, setSearchParams];
};
