import Container from '@src/components/commons/containerPage.common';
import LabTabs from '@src/components/commons/tabs.common';

import React from 'react';
import { useLocation } from 'react-router-dom';

import ClinicAnalytic from './clinicAnalytics';
import ClinicBranchList from './tableClinicList';

// eslint-disable-next-line import/default

export default function ClinicBranchPage() {
  const path = useLocation().pathname;

  const categories = [
    {
      idx: 0,
      title: 'Clinic Branch',
      component: (
        <div>
          <ClinicBranchList />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'App Overview',
      component: (
        <div className='py-4'>
          <ClinicAnalytic />
        </div>
      ),
    },
    // {
    //   idx: 2,
    //   title: 'Organisation',
    //   component: (
    //     <div className='py-4'>{/* <VerificationInformation /> */}</div>
    //   ),
    // },
  ];
  return (
    <Container pathUrl={path}>
      <div className='px-2 py-8'>
        <LabTabs menus={categories} />
      </div>
    </Container>
  );
}
